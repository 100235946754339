import React from 'react'
import actions from 'actions'
import TableView from "compositions/TableDisplay/TableView";
import { filterDataSource } from "lib/filter";
import { showSnackbar } from '../../../redux/utility/SnackbarUtil';

const headers = [
    {
      filterText: "Bank Account Id",
      header: () => "Bank Account Id",
      key: "bankAccountId"
    },
    {
      filterText: "First Name",
      header: () => "First Name",
      key: "firstName"
    },
    {
        filterText: "Last Name",
        header: () => "Last Name",
        key: "lastName"
      },
    {
      filterText: "SSN",
      header: () => "SSN",
      key: "ssn"
    },
    {
      filterText: "Status",
      header: () => "Status",
      key: "status"
    },
    {
      filterText: "Error Message",
      header: () => "Error Message",
      key: "errorMsg"
    }
  ]

class UploadVerification extends React.Component {
    constructor (props) {
        super (props);
        this.state = {
            dataSource: [],
            filteredDataSource: [],
            loading: false,
            submitted: false
        };
        this.actions = [];   
        this.handleVerifySubmit = this.handleVerifySubmit.bind(this);     
        this.handleVerifyCancel = this.handleVerifyCancel.bind(this);
        this.showDisabledPopUp = this.showDisabledPopUp.bind(this);
        this.handleRedirectViewReport = this.handleRedirectViewReport.bind(this);
    }

    onFilter (queryParams) {
        const { dataSource } = this.state;
        const newDataSource = filterDataSource(dataSource, queryParams);
        this.setState({ filteredDataSource: newDataSource });
      }
    /*
        * Get all transactions
        */
      componentWillMount () {
        this.getUploadVerificationResponse(this.props.uploadResponse)
    }

    getStatus(errorMsg){
      if(errorMsg == "")
            return (<span style={{color: "green"}}>&#10004;</span>)
        return (<span style={{color: "red"}}>&#10008;</span>)
    }
    getErrorMsg(errorMsg){
      if(errorMsg != "")
            return (<p style={{color: "red"}}> {errorMsg} </p>)
    }

    getUploadVerificationResponse (uploadData) {
        this.setState({loading: true})
        const results = {};
        return actions.accountEscheatment.getUploadVerificationResponse(uploadData).then(
            escheatments => {
                const mappedEscheatments = escheatments.map(escheatment => {
                  const {bankAccountId, firstName, lastName, ssn, errorMsg} = escheatment
                    return {
                      bankAccountId, 
                      firstName, 
                      lastName, 
                      ssn, 
                      status: this.getStatus(errorMsg),
                      errorMsg: this.getErrorMsg(errorMsg)                      
                    }
                    }).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
                      results.escheatments = mappedEscheatments;
                      this.setState({ dataSource: results.escheatments, filteredDataSource: results.escheatments, loading: false})
                }).catch((err) => {})
    }

    handleVerifySubmit() {
      this.setState({submitted: true});
      return actions.accountEscheatment.insertEscheatmentRecords(this.props.uploadResponse)
      .then((response) => {
        });
    }

    handleRedirectViewReport() {
      this.props.verifySubmit(1);
      this.props.showVerifyPageFlag(false);
    }

    handleVerifyCancel() {
      this.props.verifySubmit(0);
      this.props.showVerifyPageFlag(false);
    }

    showDisabledPopUp() {
      showSnackbar('Please fix the errors mentioned before submitting', 'error')
    }

  render() {
    if(!this.props.uploadAccess){
        return(
            <div>
                <h4 style={{color:"red", textAlign: 'center'}}>Unauthorized. You are not authorized to view the report.</h4>
            </div>
        );
    }
    if (this.state.submitted)
        return (
            <div>
                <p style={{textAlign: 'center'}}>The uploaded account escheatment file has been successfully submitted for processing. The processing might take a while. You can track the progress in the View Report page.</p>
                <button 
                  onClick={this.handleRedirectViewReport}
                  className='mini-button' 
                  type="button">
                  <span> 
                        OK
                  </span>
                </button>
            </div>
        );
    const { filteredDataSource } = this.state;
    const clickHandler = this.props.uploadResponse.errorCount == 0 ? this.handleVerifySubmit : this.showDisabledPopUp;
    return (
        <div>
          <div className="padding">
            <div className="row">
              <div className="col">
          <h5>Upload Verification</h5>
              </div>
            </div>
            <div className="margin-top" />
            <div className="fixed-height-scroll">
            <TableView
              dataActions={this.actions}
              dataSource={filteredDataSource}
              disableFilters={false}
              tableHeaders={headers}
              tableFilters={headers}
              onFilter ={this.onFilter.bind(this)}
            />
            </div>
        </div>
        <div className='side-by-side'>
        <button
          onClick={this.handleVerifyCancel}
          className='secondary-button button'
          type="button">
          <span>
              Cancel
          </span>
        </button>
        {this.props.uploadResponse.errorCount == 0 ?
          <button 
            onClick={clickHandler}
            className='button primary-button' 
            type="button">
            <span> 
                  Submit
            </span>
          </button>
          : <button 
              onClick={clickHandler}
              className='disabled-button' 
              type="button">
                <span> 
                      Submit
                </span>
            </button>}
        </div>
        </div>
      );
  }
}
export default UploadVerification;