import React from 'react'
import Button from 'components/Button'
import Card from 'components/Card'
import Field from 'components/forms/Field/index'
import Loading from 'components/Loading'
import fee from 'data/fee'
import actions from 'actions'
import { hasRole } from '../../../../redux/utility/SessionUtil'

class FeeForm extends React.Component {
  constructor (props) {
    super (props)
    this.state = {
      form: this.initializeFormData(),
      loading: false,
      submitting: false,
      writeAccess: hasRole('FeesMasterEdit')
    }

    this.onChange.bind(this)
  }

  /*
  * Get fee by id.
  */
  componentWillMount () {
    if (this.verifyAccess()) {
      this.getFee()
    }
  }

  /*
  * Verify that the user has access to fees
  */
  verifyAccess () {
    if (!(this.state.writeAccess)) {
      this.props.history.push('/nbtadmin/unauthorized')
      return false
    }

    return true
  }

  /*
  * Fetch fee by id.
  */
  getFee () {
    const {match: {params: {id}}} = this.props

    if (id) {
      this.setState({loading: true})
      actions.fees.getFeeById(id).then(fee => {
        this.setState({form: this.initializeFormData(fee), loading: false})
      }).catch(() => this.setState({loading: false}))
    }
  }

  /*
  * Initialize form data
  * Fee.
  */
  initializeFormData (data) {
    const {id, name, chargeFrequency, waivable, type, glCode, transactionCode, description} = data || {}

    return {
      id: id || '',
      chargeFrequency: chargeFrequency || '',
      waivable: waivable !== null ? waivable === true ? 'Yes' : 'No' : '',
      type: type || '',
      glCode: glCode || '',
      transactionCode: transactionCode || '',
      name: name || '',
      description: description || '',
    }

  }

  /*
  * Render the fields
  */
  getFields () {
    const {form: {
      name,
      chargeFrequency,
      waivable,
      type,
      glCode,
      transactionCode,
      description}} = this.state

    const {fields} = fee.us

    return [
        {
          ...fields.name,
          required: true,
          disabled: true,
          value: name
        },
        {
          ...fields.chargeFrequency,
          required: true,
          disabled: true,
          value: chargeFrequency
        },
        {
          ...fields.type,
          required: true,
          events: {
            onChange: (event) => {
              this.onChange(fields.type.name, event.target.value)
            }
          },
          value: type
        },
        {
          ...fields.waivable,
          required: true,
          events: {
            onChange: (event) => {
              this.onChange(fields.waivable.name, event.target.value)
            }
          },
          value: waivable
        },
        {
          ...fields.transactionCode,
          required: true,
          disabled: true,
          value: transactionCode
        },
        {
          ...fields.glCode,
          required: true,
          events: {
            onChange: (event) => {
              this.onChange(fields.glCode.name, event.target.value)
            }
          },
          value: glCode
        },
        {
          ...fields.description,
          required: false,
          events: {
            onChange: (event) => {
              this.onChange(fields.description.name, event.target.value)
            }
          },
          value: description
        }
      ]
  }

  /*
  * Update form values
  *
  * @param [String] name. Field name of form to update.
  * @param [Any] value. Value.
  */
  onChange (name, value) {
    let form = {...this.state.form}
    form[name] = value
    this.setState({form})
  }

  /**
   * On submit
   */
  handleSubmit(event) {
    event.preventDefault()
    let form = {...this.state.form}
    form.waivable = form.waivable ? form.waivable === 'Yes' ? true : false : ''
    !this.state.form.id ?
      this.onCreateFee(form): this.onUpdateFee(form)
  }

  /*
  * Update fee
  */
  onUpdateFee (form) {
    this.setState({submitting: true})
    actions.fees.updateFee(form).then((fee) => {
      const {history} = this.props
      history.push(`/nbtadmin/fees/${fee.id}`)
      this.setState({form: this.initializeFormData(fee), submitting: false})
    }).catch(() => { this.setState({submitting: false}) })
  }

  render () {
    if (this.state.loading) return <div/>
    const {form} = this.state
    return (
      <div className='page fee-form'>
        <h4>{form.id ? 'Edit' : 'Create'} Fee</h4>
        <form onSubmit={this.handleSubmit.bind(this)} className="fee-form">
          <Card className='padding'>
            {this.getFields().map((f, idx) => {
              return <Field key={`${f.name}-${idx}`} {...f} />
            })}
          </Card>
          <Button
            className='primary-button margin-top'
            disabled={this.state.submitting}
            type="submit"
          >
            <span>

              {this.state.submitting ?
                <Loading containerClassName='inline' className='white small' />
                : 'Submit'
              }
            </span>
          </Button>
        </form>
      </div>
    )
  }
}

FeeForm.propTypes = {

}

export default FeeForm
