import Fetch from 'lib/fetch'

/**
 * Fetches Batch Wire Records from Batch Wire Service.
 * @param form
 * @returns Batch wire records with wire transfer file name.
 */
 export function getBatchWireRecords (form) {
    let queryParams = Object.keys(form).filter(key => form[key]).map(key => {
        return [key, form[key]].map(encodeURIComponent).join("=");
    }).join("&"); 
    return Fetch.get(`${process.env.REACT_APP_BATCH_WIRE_SERVICE}/wire-transfers/batchWires?${queryParams}`, null)
        .then(response => response)
        .catch(error => Promise.reject(error))
}

/**
 * POST batch wire file ID for resend to Bacth Wire Service
 * @param {*} wireTransferFileId 
 * @returns Response for checking status
 */
export function sendBatchWireFileIdForFileResend (wireTransferFileId) {
    return Fetch.post(`${process.env.REACT_APP_BATCH_WIRE_SERVICE}/wire-transfers/batch-wire/${wireTransferFileId}/resend/trigger`, null)
        .then(response => response)
        .catch(error => Promise.reject(error))
}