import React from 'react'
import actions from 'actions'
import AutoCompleteField from '../../../../components/forms/AutoCompleteField'
import DatePicker from '../../../../components/forms/DatePicker'
import { showSnackbar } from '../../../../redux/utility/SnackbarUtil'

class EmployerBillingForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            employerText: '',
            form: this.initializeFormData(),
            dataSource: []
        }
    }

    initializeFormData() {
        const date = new Date()
        const yearMonth = date.getFullYear() + '-' + (date.getMonth()+1).toString().padStart(2,'0')
        return {
            employerName: '',
            yearMonth: yearMonth
        }
    }

    formSubmit(event) {
        event.preventDefault()
        const form = { ...this.state.form }
        if(this.isValid(form)) {
            this.props.onSubmit(form)
        }
    }

    isValid(form) {
        if(form.employerName && form.yearMonth) {
            return true
        }
        return false
    }

    getEmployers() {
        actions.employers
            .getEmployers({ employerName: this.state.employerText })
            .then(employers => {
                this.setState({dataSource: employers.map(row => {
                    return { name: row.name, id: row.id }
                })})
            })
            .catch((error) => {
                console.error(error)
                showSnackbar('Failed to retrieve employer data')
            });
    }

    renderAutoCompleteRow(data) {
        return (
        <div
            className="autocomplete-row"
            key={data.id}
            onClick={() => {
                    this.onChange("employerName", data.name); 
                    this.setState({ employerText: data.name, dataSource: [] });
                }
            }>
            {data.name}
        </div>
        );
    }

    onChange(name, value){
        let form = {...this.state.form}
        form[name] = value
        this.setState({form})
        this.props.onChange(form)
    }

    render() {
        return (
            <form onSubmit={this.formSubmit.bind(this)}>
                    <AutoCompleteField
                        name='employerName'
                        required={true}
                        label='Choose Employer'
                        placeholder='Choose Employer'
                        value={this.state.employerText}
                        events={
                            {
                                onChange: (name, value) => {
                                    if(value.length < this.state.employerText.length) {
                                        this.onChange(name,'')
                                    }
                                    this.setState({employerText: value})
                                }
                            }
                        }
                        dataValue={this.state.form.employerName}
                        dataSource={this.state.dataSource}
                        limit={20}
                        onSearch={this.getEmployers.bind(this)}
                        renderRow={this.renderAutoCompleteRow.bind(this)}
                        className='grid-width-50'
                    />
                        <DatePicker
                                label='Month Year'
                                name='yearMonth'
                                required={true}
                                onChange={(name, value) => {
                                    this.onChange(name, value)
                                }}
                                value={this.state.form.yearMonth}
                                startMode='month'
                                displayFormat='MM/YYYY'
                                returnFormat='YYYY-MM'
                                className='grid-width-25'
                        />
                    <button
                            style={{
                                width: 'auto',
                                display: 'block',
                                marginLeft: 'auto'
                            }}
                            className='button primary-button'
                            type='submit'
                        >Search
                    </button>
            </form>
        )

    }
}

export default EmployerBillingForm;