import React from 'react'

// Styles ------------------
// import 'layouts/layout.scss'


const AuthorizedLayout = ({children}) => (
  <div className="layout authorized">
     {children}
  </div>
)

export default AuthorizedLayout
