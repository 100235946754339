import React from 'react'
import Card from "../../../../components/Card";
import Loading from "../../../../components/Loading";
import Button from "../../../../components/Button";
import Field from "../../../../components/forms/Field";
import transferConsent from "../../../../data/transferConsent";
import actions from "../../../../actions";

class CreateCustodianForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      form: this.initializeFormData(),
      submitting: false,
    }
  }

  initializeFormData() {
    return {
      custodianName: '',
      receivingCustodian: 'ConnectYourCare',
      custodianVerbiage: '',
      transferInKind: null
    }
  }

  handleSubmit(event) {
    event.preventDefault()
    if (this.validateForm()) {
      let form = {...this.state.form}
      actions.consentForms.createConsentForm(form).then((createdForm) => {
        const {history} = this.props
        history.push(`/nbtadmin/custodian-consent-form/${createdForm.id}`)
        this.setState({submitting: false})
      }).catch(() => {
        this.setState({submitting: false}) 
      })
    }
  }

  validateForm(){
    const{custodianName, receivingCustodian, custodianVerbiage, transferInKind} = this.state.form
    return custodianName && receivingCustodian && custodianVerbiage && transferInKind
  }

  onChange(name, value){
    let form = {...this.state.form}
    form[name] = value
    this.setState({form})
  }

  
  renderForm() {
    const {form} = this.state
    return (
        <div className='page create-consent-form-verbiage-view'>
          <h4>Create Custodian Transfer Consent Form</h4>
          <form onSubmit={this.handleSubmit.bind(this)} className='create-custodian-form'>
            <Card className='padding'>
              <h5>Details</h5>
              <Field
                  name='custodianName'
                  required={true}
                  label='Custodian Name'
                  placeholder='Name'
                  value={form.custodianName}
                  events={
                    {
                      onChange: (event) => {this.onChange('custodianName', event.target.value)}
                    }
                  }
                  attrs={
                    {
                      maxLength: 40
                    }
                  }
              />
              <Field
                  name='receivingCustodian'
                  required={true}
                  label='Receiving Custodian'
                  placeholder='Receiving Custodian'
                  value={form.receivingCustodian}
                  events={
                    {
                      onChange: (event) => {this.onChange('receivingCustodian', event.target.value)}
                    }
                  }
                  attrs={
                    {
                      maxLength: 40
                    }
                  }
              />
              <Field
                  name='transferInKind'
                  required={true}
                  label='Transfer In Kind'
                  type='dropdown'
                  placeholder='Transfer In Kind'
                  value={form.transferInKind || ''}
                  data={transferConsent.us.fields.transferInKind.data}
                  events={
                    {
                      onChange: (event) => {this.onChange('transferInKind', event.target.value)}
                    }
                  }
              />
              <Field
                  name='custodianVerbiage'
                  required={true}
                  label='Authorization Text'
                  placeholder='Text'
                  value={form.custodianVerbiage}
                  type = 'textarea'
                  events={
                    {
                      onChange: (event) => {this.onChange('custodianVerbiage', event.target.value)}
                    }
                  }
                  attrs={
                    {
                      maxLength: 4000
                    }
                  }
              />
            </Card>

            <Button
                className='primary-button margin-top'
                disabled={this.state.submitting}
                type="submit"
            >
            <span>
              {this.state.submitting ?
                  <Loading containerClassName='inline' className='white small' />
                  : 'Submit'
              }
            </span>
            </Button>
          </form>
        </div>
    )
  }

  render() {
    return this.renderForm()
  }
}

export default CreateCustodianForm
