/**
 * Super straightforward localization and more!
 *
 * ```
 *  mail: {
 *      name: 'mail',
 *      type: 'mail',
 *      label: 'Mail',
 *      validator: 'mail'
 *  },
 *  date_admission: {
 *      name: 'date_admission',
 *      label: 'Date of Admission',
 *      placeholder: 'Date of Admission',
 *      type: 'month'
 *  }
 * ```
 * TODO Separate the translations from the definitions
 */

const mailTemplate = {
  us: {
    fields: {
      contentTypeset: {
        name: 'contentTypeset',
        label: "Letter Type",
        placeholder: 'type',
        type: 'dropdown'
      },
      contentKey: {
        name: 'contentKey',
        label: 'Content Key',
        placeholder: 'Content key'
      },
      link: {
        name: 'link',
        label: 'Link',
        placeholder: 'Link'
      },
      contentDocument: {
        name: 'contentDocument',
        label: 'Content Document',
        placeholder: 'Content document'
      },
      rowGroup:{
        type:'group',
        name:'groupPlaceholder',
        data:{
          isDefault: {
            name: 'isDefault',
            label: 'Default',
            type: 'checkbox',
            className:'defaultid_form'
          },
          optumCorrespondenceId: {
            name: 'optumCorrespondenceId',
            label: 'Optum ID',
            placeholder: 'Optum ID',
            className:'optumId_form'
          },
          welcomeKitNum: {
            name: 'welcomeKitNum',
            label: 'Welcome Kit # (assign only to welcome letters)',
            placeholder: 'Welcome Kit #'
          }
        }
      },
      startDate: {
        name: 'startDate',
        type: 'datePicker',
        label: 'Start Date',
        placeholder: 'Start Date',
        returnFormat: 'YYYY-MM-DD'
      },
      endDate: {
        name: 'endDate',
        type: 'datePicker',
        label: 'End Date',
        placeholder: 'End Date',
        returnFormat: 'YYYY-MM-DD'
      }
    }
  }
}

export default mailTemplate
