import React from 'react'
import PropTypes from 'prop-types'
import Card from 'components/Card'
import Comments from 'compositions/Comments'
import Field from 'components/forms/Field/index'
import TextField from 'components/TextField'
import actions from 'actions'
import { getSession } from '../../../../redux/utility/SessionUtil'
import { showSnackbar, hideSnackbar } from '../../../../redux/utility/SnackbarUtil'
import Loading from 'components/Loading'
import account from 'data/account'
import dayjs from 'dayjs'

class AccountClosure extends React.Component {
  constructor (props) {
    super (props)
    this.state = {
      form: this.initializeFormData(),
      previousClosedAccounts: this.getPreviousClosedCases(),
      uploading: false,
      loading: false
    }
  }

  getPreviousClosedCases(){
    return this.props.accountClosure.filter(function(cases){return cases.caseStatus === "Closed"});
  }

  componentDidUpdate(prevProps){
    if(this.props.isCaseAlreadyOpen !== prevProps.isCaseAlreadyOpen)
      this.setState({form: this.initializeFormData()})
  }

  //TODO - Null out optionalMailingAddress if user enters fields but changes allocation of funds method
  initializeFormData () {
    if (this.props.isCaseAlreadyOpen){
      let openCase = this.props.accountClosure.filter((cases) => {if(cases.caseStatus === "Open") return cases});
        const {
          id, bankAccountId, documents, allocationOfFundsMethod,
          notes, reasonForClosure, otherReasonForClosureDescription, submittedDate,
          signaturePresent, accountHolderInfoMatchingDocumentation,
          optionalMailingAddress
        } = openCase[0];

      const {fullName, addressOne, addressTwo, city, st, zip, advice} = optionalMailingAddress
      || {} //incase optimal mailing address is null, destructure those fields here.
      return ({
        id,
        bankAccountId,
        allocationOfFundsMethod,
        reasonForClosure,
        otherReasonForClosureDescription,
        documents,
        notes,
        submittedDate,
        signaturePresent,
        accountHolderInfoMatchingDocumentation,
        optionalMailingAddress: {
          id: (optionalMailingAddress && optionalMailingAddress.id) || '',
          fullName,
          addressOne,
          addressTwo,
          city,
          st,
          zip,
          advice
        }
      })
    } else {
      return null;
    }    
  }
  /*
  * Render the fields
  */
  getFields () {
    const {form: {
      allocationOfFundsMethod,
      reasonForClosure,
      otherReasonForClosureDescription
    }} = this.state

    const {fields} = account.us

    let f = [
      {
        ...fields.allocationOfFundsMethod,
        attrs: {
          disabled: !this.props.writeAccess
        },
        required: true,
        data: this.getAllocationOptions(),
        disabled: !this.props.writeAccess,
        events: {
          onChange: (event) => {
            this.onChange(fields.allocationOfFundsMethod.name, event.target.value)
          }
        },
        value: allocationOfFundsMethod
      }]
      f.push(
      {
        ...fields.reasonForClosure,
        attrs: {
          disabled: !this.props.writeAccess
        },
        required: true,
        events: {
          onChange: (event) => {
            this.onChange(fields.reasonForClosure.name, event.target.value)
          }
        },
        value: reasonForClosure
      })

    if(allocationOfFundsMethod === 'Penny Return') {
        f.pop()
        f.push(
            {
                ...fields.reasonForClosureForPennyFunding,
                attrs: {
                    disabled: !this.props.writeAccess
                },
                required: true,
                events: {
                    onChange: (event) => {
                        this.onChange(fields.reasonForClosure.name, event.target.value)
                    }
                },
                value: reasonForClosure
            })
    }

    if(allocationOfFundsMethod === 'Participant Has 0 Balance in Account' ||
       allocationOfFundsMethod === 'Auto-Close Account - No funds Returned'){
      f.pop()
      f.push(
          {
            ...fields.reasonForClosureZeroBalance,
            attrs: {
              disabled: !this.props.writeAccess
            },
            required: true,
            events: {
              onChange: (event) => {
                this.onChange(fields.reasonForClosure.name, event.target.value)
              }
            },
            value: reasonForClosure
          })
    }
    if (reasonForClosure === 'Other')
    f.push(
      {
        ...fields.otherReasonForClosureDescription,
        required: false,
        attrs: {
          maxLength: 255,
          disabled: !this.props.writeAccess
        },
        events: {
          onChange: (event) => {
            this.onChange(fields.otherReasonForClosureDescription.name, event.target.value)
          }
        },
        value: otherReasonForClosureDescription
      }
    )

    return f
  }

  getMailingFields () {
    const {form: {
      optionalMailingAddress: {
        fullName,
        addressOne,
        addressTwo,
        city,
        st,
        zip,
        advice
      }}} = this.state
    const {mailingFields} = account.us
    let f = [
      {
        ...mailingFields.fullName,
        attrs: {
          disabled: !this.props.writeAccess
        },
        required: true,
        disabled: !this.props.writeAccess,
        events: {
          onChange: (event) => {
            this.onChange(mailingFields.fullName.name, event.target.value)
          }
        },
        value: fullName
      },
      {
        ...mailingFields.addressOne,
        attrs: {
          disabled: !this.props.writeAccess,
          maxLength: 40
        },
        required: true,
        disabled: !this.props.writeAccess,
        events: {
          onChange: (event) => {
            this.onChange(mailingFields.addressOne.name, event.target.value)
          }
        },
        value: addressOne
      },
      {
        ...mailingFields.addressTwo,
        attrs: {
          disabled: !this.props.writeAccess,
          maxLength:40
        },
        required: false,
        disabled: !this.props.writeAccess,
        events: {
          onChange: (event) => {
            this.onChange(mailingFields.addressTwo.name, event.target.value)
          }
        },
        value: addressTwo,
      },
      {
        ...mailingFields.city,
        attrs: {
          disabled: !this.props.writeAccess,
          maxLength:20
        },
        required: true,
        disabled: !this.props.writeAccess,
        events: {
          onChange: (event) => {
            this.onChange(mailingFields.city.name, event.target.value)
          }
        },
        value: city
      },
      {
        ...mailingFields.st,
        attrs: {
          disabled: !this.props.writeAccess,
          maxLength:2
        },
        required: true,
        disabled: !this.props.writeAccess,
        events: {
          onChange: (event) => {
            this.onChange(mailingFields.st.name, event.target.value)
          }
        },
        value: st
      },
      {
        ...mailingFields.zip,
        attrs: {
          disabled: !this.props.writeAccess,
          maxLength:9
        },
        required: true,
        disabled: !this.props.writeAccess,
        events: {
          onChange: (event) => {
            this.onChange(mailingFields.zip.name, event.target.value)
          }
        },
        value: zip
      },
      {
        ...mailingFields.advice,
        attrs: {
          disabled: !this.props.writeAccess
        },
        required: true,
        disabled: !this.props.writeAccess,
        events: {
          onChange: (event) => {
            this.onChange(mailingFields.advice.name, event.target.value)
          }
        },
        value: advice
      }
    ]
    return f
  }
  /*
  * Get drop down options for allocationOfFundsMethod
  */
  getAllocationOptions () {
    let options = {
      'Electronic Funds Transfer (EFT)': {
        name: 'Electronic Funds Transfer (EFT)',
        disabled: this.props.account.currentBalance === 0
      },
      'Send Check By Mail to Mailing Address on File': {
        name: 'Send Check By Mail to Mailing Address on File',
        disabled: this.props.account.currentBalance === 0
      },
      'Participant Has 0 Balance in Account': {
        name: 'Participant Has 0 Balance in Account',
        disabled: this.props.account.currentBalance !== 0
      },
      'Penny Return': {
        name: 'Penny Return',
        disabled: this.props.account.currentBalance !== 0.01
      },
      'Send Check By Mail to New Mailing Address': {
        name: 'Send Check By Mail to New Mailing Address',
        disabled: this.props.account.currentBalance === 0
      },
      'Auto-Close Account - No funds Returned': {
        name: 'Auto-Close Account - No funds Returned'
      }
    }

    return options
  }


  /*
  * Render the account details
  */
  renderAccountDetails () {
    const {accountType, currentBalance, investmentBalance} = this.props.account
    const {caseStatus} = this.props.accountClosure

    //TODO: Case status and investment being defaulted until api is stable
    return (
      <div className='row'>
        <div className='col'>
          <TextField
            label='Case Status'
            name='caseStatus'
            value={caseStatus || 'Open'}
          />
        </div>
        <div className='col'>
          <TextField
            label='Account Type'
            name='accountType'
            value={accountType}
          />
        </div>
        <div className='col'>
          <TextField
            label='Account Balance'
            name='currentBalance'
            value={currentBalance}
          />
        </div>
        <div className='col'>
          <TextField
            label='Investment Balance'
            name='investmentBalance'
            value={investmentBalance || 0}
          />
        </div>
      </div>
    )
  }

  /*
   * Render physical mail fields, used if participant wants to use mail address
    * not on file to send check
   */
  renderMailFields() {
    const {form: {allocationOfFundsMethod}} = this.state
    if (allocationOfFundsMethod.toLowerCase() === "Send Check By Mail to New Mailing Address".toLowerCase()) {
      
      return (
          <Card className='padding'>
            <h5>Check Information For New Mailing Address</h5>
            {this.getMailingFields().map((f, idx) => {
              return <Field key={`${f.name}-${idx}`} {...f}
                            className='margin-top'
                            disabled={this.getIsCompleted()}/>
            })}
          </Card>
      )
    }
  }

  /*
  * Render account documents
  */
  renderDocuments () {
    const {fields} = account.us
    const {form: {allocationOfFundsMethod}} = this.state
    if(!(allocationOfFundsMethod === 'Penny Return')) {
        return (
            <Card className='padding'>
              <h5>{allocationOfFundsMethod}</h5>
              <h5>Account Closure Documents</h5>
                {
                <div className='form-control margin-top'>
                  <label className='input-label' htmlFor={'file-input'}>Closure Documents</label>
                  <div>
                    <input
                        disabled={!this.props.writeAccess}
                        name='file-input'
                        type='file'
                        ref={input => {
                            this.fileInput = input;
                        }}
                        onChange={this.onDrop.bind(this)}
                    />
                  </div>
                </div>
                }
              {this.state.uploading && <div
                        style={{fontSize: '12px'}}>Uploading document...</div>}
              {this.state.form.documents.length > 0 ?
                  <div>
                    <h5>Account Closure Documents:</h5>
                    {this.renderFiles()}                    
                  </div>
                  :
                  (!(allocationOfFundsMethod === 'Auto-Close Account - No funds Returned')) ?
                <div className='margin-top margin-bottom' style={{fontSize: '12px'}}>
                <span>
                No documents have been uploaded yet.
                Account closure document is required to close account.
                </span>
                </div>
                :
                <div className='margin-top margin-bottom' style={{fontSize: '12px'}}>
                <span>
                No documents have been uploaded yet.
                Account closure document is not required for auto closures.
                </span>
                </div>
              }
              <div className='form-control'>
                <input
                    className='margin-right margin-bottom'
                    name={fields.signaturePresent.name}
                    type="checkbox"
                    onChange={(event) => {
                        this.onChange(fields.signaturePresent.name, event.target.checked)
                    }}
                    checked={this.state.form.signaturePresent}
                />
                <label className='input-label' htmlFor='signaturePresent'>
                    {fields.signaturePresent.label}
                </label>
              </div>
            </Card>
        )
    }
  }

  /*
  * Render account closure comments
  */
  renderComments () {
    return (
      <Card className='padding'>
        <h5>Account Closure Notes</h5>
        <div className='line margin-bottom'></div>
        <Comments
          hideInput={!this.props.writeAccess}
          comments={this.state.form.notes.map(note => (
            {
              author: note.commenterName,
              body: note.note,
              date: note.modifiedDate
            }
          ))}
          onSubmitComment={this.createAccountClosureNote.bind(this)}
        />
      </Card>
    )
  }

  /*
  * Render optional mail address for the closure case
  */
  renderOptionalMailAddress (closedCase) {
    if (closedCase.optionalMailingAddress ){
    return(
      <div className='row'>
        <div className='col'>
        </div>
        <div className='col'>
          <div style={{fontSize: '10px', borderBottom: '1px solid #ebedf1'}}>
            <div style={{fontSize: '10px', fontWeight: 'bold'}}><span>Optional Mailing Address</span> </div>
            <div><span>{closedCase.optionalMailingAddress.addressOne ? closedCase.optionalMailingAddress.addressOne.toString() : ""}</span> </div>
            <div><span>{closedCase.optionalMailingAddress.addressTwo ? closedCase.optionalMailingAddress.addressTwo.toString() : ""}</span> </div>
            <div><span>{closedCase.optionalMailingAddress.city? closedCase.optionalMailingAddress.city.toString() : ""}</span></div>
            <div><span>{closedCase.optionalMailingAddress.st ? closedCase.optionalMailingAddress.st.toString() : ""}</span></div>
            <div><span>{closedCase.optionalMailingAddress.zip ? closedCase.optionalMailingAddress.zip.toString() : ""}</span></div>
          </div>
        </div>
        <div className='col'>
          <TextField
            label='Advice'
            name='address'
            value={closedCase.optionalMailingAddress.advice ? closedCase.optionalMailingAddress.advice.toString() : ""}
          />
        </div>
        <div className='col'>
        </div>
        <div className='col'>
        </div>
      </div>
    ) }
    else {
      return (<div></div>);
    }
    
  }

  renderClosedFiles (closedCase) {
    const classname = closedCase.documents.length > 6 ? 'prevCloseNotes' : '';
    return (<div className={classname}> {closedCase.documents.map((file, index) => {
      return (
        <div key={index} style={{fontSize: '10px'}}>
          <a target="blank" href={file.documentLink}>{file.documentType} ({index+1})</a>
        </div>
      )
    })}</div>)
  }

  renderClosureNotes (closedCase) {
    // Adding prevCloseNotes class to div when length of notes exceeds 3 to support multiple notes within same row height
    const classname = closedCase.notes.length > 3 ? 'prevCloseNotes' : '';
    return (<div className={classname}> {closedCase.notes.map((note,index) => {
      return (
        <div key={index} style={{fontSize: '10px', borderBottom: '1px solid #ebedf1'}}>
          <span>{note.commenterName} : {note.note}</span>
        </div>
      )
    })}</div>)    
  }

  renderPreviousClosedCases(){    
    return this.state.previousClosedAccounts.map((closedCase, index)=> {
            return (            
            <Card key={index} className='padding closed-case'>
              <div className='row'>
                <div className='col'>
                  <TextField
                    label='Case ID'
                    name='caseId'
                    value={closedCase.id ? closedCase.id.toString() : ""}
                  />
                </div>
                <div className='col'>
                  <TextField
                    label='Case Status'
                    name='caseStatus'
                    value={closedCase.caseStatus}
                  />
                </div>
                <div className='col'>
                  <TextField
                    label='Reason for Closure'
                    name='reasonForClosure'
                    value={closedCase.reasonForClosure}
                  />
                </div>
                <div className='col'>
                  <TextField
                    label='Allocation Of Funds Method'
                    name='allocationOfFundsMethod'
                    value={closedCase.allocationOfFundsMethod}
                  />
                </div>
                <div className='col'>
                  <TextField
                    label='Submitted By'
                    name='submittedBy'
                    value={closedCase.submittedBy}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  
                </div>
                <div className='col'>
                  <TextField
                    label='Submitted On'
                    name='submittedDate'
                    value={closedCase.submittedDate}
                  />
                </div>
                <div className='col'>
                  <TextField
                    label='Modified Date'
                    name='modifiedDate'
                    value={closedCase.modifiedDate}
                  />
                </div>
                <div className='col'>
                  <div style={{fontSize: '12px'}}>
                    <span style={{fontWeight: 'bold'}}>Account Closure Documents</span>
                    {this.renderClosedFiles(closedCase)}
                  </div>
                </div>
                <div className='col'>
                  <div style={{fontSize: '12px'}}>
                    <span style={{fontWeight: 'bold'}}>Notes</span><br/>
                    {this.renderClosureNotes(closedCase)}
                  </div>
                </div>
              </div>
              {this.renderOptionalMailAddress(closedCase)}
            </Card>)
        })
  }

  /*
   * Update form.
   *
   * @param [String] name. Key of form.
   * @param [Any] value. Value of key value pair.
   */
  onChange (name, value) {
    //this method allows nested attributes to be updated
    let newForm = this.state.form
    const parts = name.split('.')
    let field = newForm
    for (let i=0; i < parts.length - 1; i++) {
      field = field[parts[i]]
    }
    field[parts[parts.length -1]] = value
    this.setState({form: newForm})
  }
  /*
  * Render all selected files to be uploaded
  */
  renderFiles () {
    this.state.form.documents.sort(function(a,b) {
      return new Date(a.created) - new Date(b.created);
    });
    return this.state.form.documents.map((file, index) => {
      return (
        <div style={{fontSize: '12px'}}>
          <a target="blank" href={file.documentLink}>{file.documentType} ({index+1})</a>
          {(this.getIsCompleted() === null || new Date(file.created) > new Date(this.getIsCompleted())) &&
          <a onClick={() => this.onRemoveFile(index)}>
          {
            <i className='material-icons orange'>clear</i>
          }
          </a>
          }
        </div>
      )
    })
  }

  /*
  * Handle file drop.
  *
  * @param [Event] event. Drop event.
  */
  onDrop (event) {
    this.onCreateAccountClosureDocument(event.target.files[0])
    this.fileInput.value = ''
  }

  /*
  * Handle file remvoe
  *
  * @param [Event] event. Drop event.
  */
  onRemoveFile (index) {
    this.setState({ loading: true })
    actions.accounts.deleteAccountClosureDocument(this.state.form.id, this.state.form.documents[index].id).then(data => {
    }).catch((error) => false)
    this.createDocumentDeleteNote(this.state.form.documents[index])
    let newFiles = this.state.form.documents
    newFiles.splice(index, 1)
    this.setState({ files: newFiles, loading: false })
    showSnackbar('Account Closure Document successfully removed.', 'info')
  }

  /*
  * Save account closure
  */
  onSaveAccountClosure () {
    this.props.onUpdateAccountClosure(this.state.form)
  }

  /*
  * Checks if the string is empty or not
  */
  isEmpty (str) {
    return (!str || str.trim().length === 0 );
  }

  /*
  * On submit account closure
  */
  onSubmitAccountClosure () {
    if(this.state.uploading){
      return showSnackbar('Please wait until file upload completes')
    }
    const {form: {signaturePresent, accountHolderInfoMatchingDocumentation}} = this.state
    const {documents, allocationOfFundsMethod, reasonForClosure} = this.state.form
    var isPennyReturn = Boolean(allocationOfFundsMethod === "Penny Return")
    var isAutoCloseAccount = Boolean(allocationOfFundsMethod === 'Auto-Close Account - No funds Returned')
	  var isDirectDepositAndEtf = Boolean(allocationOfFundsMethod === 'Electronic Funds Transfer (EFT)' && this.props.hasDirectDeposit === false)
    var isDirectDepositNotConfirmed = Boolean(allocationOfFundsMethod === 'Electronic Funds Transfer (EFT)' && this.props.directDepositConfirmed === false)
    if(isDirectDepositAndEtf){
      return showSnackbar('No Confirmed Direct Deposit exists for this member\'s job, please update the Allocation of Funds Method to be via Check.', 'error')
    }    
    if(isDirectDepositNotConfirmed){
      return showSnackbar('No Confirmed Direct Deposit exists for this member\'s job, please update the Allocation of Funds Method to be via Check.', 'error')
    }    
	  //first, check if investment balance is zero
    if(this.props.account.investmentBalance > 0){
      return showSnackbar('The closure could not be submitted because there is still an Investment Balance') 
    } else {
      let form = this.state.form
      form.submittedDate = dayjs.utc().format('YYYY-MM-DD HH:mm:ss')
      // if it isn't a penny return or autoclose account
      if(!(isPennyReturn || isAutoCloseAccount)) {        
        if (!signaturePresent) {
            form.submittedDate = null
            showSnackbar('Signature Present, under \'Account Closure Documents\' is required to submit this form.')
        }
        else if (!accountHolderInfoMatchingDocumentation) {
            form.submittedDate = null
            showSnackbar('Please verify that the information listed for the case is correct before submitting account closure')
        } else if (allocationOfFundsMethod === 'Send Check By Mail to New Mailing Address') {
          hideSnackbar();
          let errMsg = []
          let optionalMailingAddressInfo=form.optionalMailingAddress.fullName +form.optionalMailingAddress.addressOne+form.optionalMailingAddress.addressTwo+form.optionalMailingAddress.city+form.optionalMailingAddress.st
          
          if (this.isEmpty(form.optionalMailingAddress.fullName)) errMsg = errMsg.concat('Full Name field is missing')
          if (this.isEmpty(form.optionalMailingAddress.addressOne)) errMsg = errMsg.concat('Address one field is missing')
          if (this.isEmpty(form.optionalMailingAddress.city)) errMsg = errMsg.concat('City field is missing')
          if (this.isEmpty(form.optionalMailingAddress.st)) errMsg = errMsg.concat('State field is missing')
          if (this.isEmpty(form.optionalMailingAddress.zip)) errMsg = errMsg.concat('Zip field is missing')
          if(optionalMailingAddressInfo.includes ("|")) errMsg = errMsg.concat('New Mailing Address can not have "|"')
          
          if(errMsg.length === 0){
            this.props.onUpdateAccountClosure(form)
            this.setState({form})
          } else {
            form.submittedDate = null;
            showSnackbar(errMsg[0])
          }
        } else {
          if (documents.length > 0 && allocationOfFundsMethod && reasonForClosure) {
              this.props.onUpdateAccountClosure(form)
              this.setState({form})
          } else {
              form.submittedDate = null
              let errMsg = ''
              if (documents.length === 0) errMsg = 'At least 1 document is required'
              else if (!allocationOfFundsMethod) errMsg = 'Allocation of funds required'
              else errMsg = 'Reason for closure requires'
              showSnackbar(errMsg)
          }
        }
      } else {
        //if those two fields are there, make api call to update closure
        if (allocationOfFundsMethod && reasonForClosure) {              
            this.props.onUpdateAccountClosure(form)
            this.setState({form})
        } else {
            form.submittedDate = null
            let errMsg = ''
            if (!allocationOfFundsMethod) errMsg = 'Allocation of funds required'
            else errMsg = 'Reason for closure requires'
            showSnackbar(errMsg)
        }
      }
    }
  }

  /*
  * Create a note for the account closures
  */
  createAccountClosureNote (note) {
    let form = {
      note,
      commenterName: getSession().username || 'Unknown user'
    }

    return actions.accounts.createAccountClosureNote(this.state.form.id, form).then(note => {
      let newForm = this.state.form
      newForm.notes.push(note)
      this.setState({form: newForm})
    }).catch((error) => false)
  }

  /*
  * Create a note for document delete/audit purposes
  */
  createDocumentDeleteNote (fileName) {
    let form = {
      note: getSession().username + ' removed ' + fileName.documentType,
      commenterName: getSession().username || 'Unknown user'
    }

    return actions.accounts.createAccountClosureNote(this.state.form.id, form).then(note => {
      let newForm = this.state.form
      newForm.notes.push(note)
      this.setState({form: newForm})
    }).catch((error) => false)
  }
  /*
  * On upload document
  */
  onCreateAccountClosureDocument (file) {
    this.setState({uploading: true})
    return actions.accounts.createAccountClosureDocument(this.state.form.id, file).then(document => {
      let newForm = this.state.form
      newForm.documents.push(document)
      this.setState({form: newForm, uploading: false})
    }).catch(() => { this.setState({uploading: false }) })
  }

  /*
  * Get whether the account closure has been completed
  */
  getIsCompleted () {
    return this.state.form.submittedDate
  }

  render () {
    const {fields} = account.us
    const {form: {allocationOfFundsMethod}} = this.props.isCaseAlreadyOpen && this.state.form ? this.state : {form : {allocationOfFundsMethod : ""}}
    const submitClassName = this.state.uploading ? 'button primary-button margin-bottom close-submit disabled' : 'button primary-button margin-bottom close-submit'
    if (this.state.loading) return <div className='margin-top'><Loading/></div>
    return (
      <div>
        {this.state.form && this.props.isCaseAlreadyOpen &&
          <div>
          {!this.getIsCompleted() && this.props.writeAccess &&
            <button
              className='button secondary-button margin-bottom'
              onClick={this.onSaveAccountClosure.bind(this)}
              style={{width: 'auto', marginLeft: 'auto', display: 'block'}}
            >Save Account Closure</button>
          }

          <Card className='padding'>
            <h5>Account Closure Details</h5>
            {this.renderAccountDetails()}
            <div className='margin-top margin-bottom'>
              {this.getFields().map((f, idx) => {
                return <Field key={`${f.name}-${idx}`} {...f} className='margin-top' disabled={this.getIsCompleted()}/>
              })}
            </div>
          </Card>

          {this.renderMailFields()}
          {this.renderDocuments()}
          {this.renderComments()}

          {!this.getIsCompleted() && this.props.writeAccess &&

            <div>
              <button
                className='button secondary-button margin-bottom'
                onClick={this.onSaveAccountClosure.bind(this)}
                >Save Account Closure</button>
              <button
                className={submitClassName}
                onClick={this.onSubmitAccountClosure.bind(this)}
              >Submit Account Closure</button>

                {!(allocationOfFundsMethod === 'Penny Return' || allocationOfFundsMethod ==='Auto-Close Account - No funds Returned') &&
                  <div className='form-control'>
                    <input
                        className='margin-right margin-bottom'
                        name={fields.accountHolderInfoMatchingDocumentation.name}
                        type="checkbox"
                        onChange={(event) => {
                            this.onChange(fields.accountHolderInfoMatchingDocumentation.name, event.target.checked)
                        }}
                        checked={this.state.form.accountHolderInfoMatchingDocumentation}
                    />
                    <label className='input-label' htmlFor='accountHolderInfoMatchingDocumentation'>
                        {fields.accountHolderInfoMatchingDocumentation.label}
                    </label>
                  </div>
                }
            </div>
          }
          </div>
        }
        {this.props.accountClosure.filter(function(cases){return cases.caseStatus === "Closed"}).length > 0 &&
          <div>
            <h5>Previous Account Closure Details</h5>
            {this.renderPreviousClosedCases()}
          </div>
        }
      </div>
    )
  }
}

AccountClosure.propTypes = {
  account: PropTypes.object
}

export default AccountClosure