import PropTypes from "prop-types";
import React from "react";
import Modal from 'components/Modal';

class TransferStatus extends React.Component  {
  render () {
    return (
        <Modal
            header={'Unenrolled Report: Please take action to transfer/not transfer to temporary policy.'}
            onClose={() => this.props.onClose()}
        >
          <div className='modal-body'>
            <div className='margin-top margin-bottom'>
              <p>For: {this.props.firstName} {this.props.lastName}</p>
            </div>
          </div>
          <div className='modal-footer'>
            <div className='modal-actions'>
              <button
                  className='button primary-button'
                  onClick={() => this.props.onTransfer()}
              >Transfer</button>
              <button
                  className='button secondary-button'
                  onClick={() => this.props.onDoNotTransfer()}
              >Do Not Transfer</button>
            </div>
          </div>
        </Modal>
    )
  }
}

TransferStatus.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  onClose: PropTypes.func,
  onTransfer: PropTypes.func,
  onDoNotTransfer: PropTypes.func
}

export default TransferStatus
