import Fetch from 'lib/fetch'
import { showSnackbar } from '../redux/utility/SnackbarUtil'

/*
* Upload batch transactions
*/
export function transactionUpload (file) {
    let form = new FormData();
    form.append(`file`, file);
    return Fetch.postMultipartBatch(`${process.env.REACT_APP_NBT_ADMIN_DOMAIN}/nbt-admin/upload/transaction-upload`, form)
    .then(data => {
        showSnackbar('Transactions have been successfully uploaded', 'info');
        return data;
       })
      .catch(error => {
        Promise.resolve(error).then((e) => {
            showSnackbar(e, 'error');
            return e;
        });
    });
  }
