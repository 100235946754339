import React from "react";
import { hasRole } from "../../../../redux/utility/SessionUtil";
import termsAndConditionsSetup from "data/termsAndConditionsSetup";
import Field from "components/forms/Field/index";
import Card from "components/Card";
import actions from "actions";
import Loading from "components/Loading";
import Button from "components/Button";
import Modal from 'components/Modal';
import { showSnackbar } from "../../../../redux/utility/SnackbarUtil";

class TermsAndConditionsSetupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initializeState();
  }

  componentWillMount() {
    this.verifyAccess()
  }

  /*
   * Initialize State
   */
  initializeState() {
    return {
      form: this.initializeFormData(),
      submitting: false,
      showSuccessModal: false,
      writeAccess: hasRole("TCMasterEdit"),
      loading: false,

    }
  }

  /*
  * Verify that the user has access to terms and conditions setup
  */
  verifyAccess () {
    if (!this.state.writeAccess) {
      this.props.history.push('/nbtadmin/unauthorized')
      return false
    }
    return true
  }

  /*
  * initialize all of the form fields
  */
  initializeFormData() {
    return {
      name: "",
      pdfName: "",
      type: ""
    };
  }
  
  /*
   * Update form values
   *
   * @param [String] name. Field name of form to update.
   * @param [Any] value. Value.
   */
  onChange(name, value) {
    let form = { ...this.state.form };
    form[name] = value;
    this.setState({ form });
  }

  onCloseModal() {
    const { history } = this.props
    this.setState({ showSuccessModal: false });
    history.push(`/nbtadmin/terms-and-conditions`)
  }

  /*
   * Handle file drop.
   *
   * @param [Event] event. Drop event.
   */
  onDrop(event) {

  }

  getFields() {
    if (!this.state.loading) {
    const { fields } = termsAndConditionsSetup.us;
    const {
      form: { name, pdfName, type }
    } = this.state;
    let f = [
      {
        ...fields.name,
        required: true,
        events: {
          onChange: event => {
            this.onChange(event.target.name, event.target.value);
          }
        },
        value: name
      },
      {
        ...fields.pdfName,
        required: true,
        events: {
          onChange: event => {
            this.onChange(event.target.name, event.target.value);
          }
        },
        value: pdfName
      },
      {
        ...fields.type,
        required: true,
        events: {
          onChange: event => {
            this.onChange(event.target.name, event.target.value);
          }
        },
        value: type
      }
    ];

    return f;
    }
  }

  validateSubmit() {
    const pdf = ".pdf";
    if (!this.fileInput.value) {
      showSnackbar("File selection is required.");
    } else {
      if(!this.fileInput.files[0].name.includes(pdf)) {
        showSnackbar("File selection must be a PDF.");
      } else {
        return true;
      }
    }
    return false;
  }

  /**
   * On submit
   */
  handleSubmit(event) {
    event.preventDefault();
    this.setState({submitting: true})

    if(this.validateSubmit()) {
      let form = { ...this.state.form };
      let formData = new FormData();

      formData.append("name", form.name);
      formData.append("file_name", form.pdfName);
      formData.append("agreement_type", form.type);
      formData.append("file", this.fileInput.files[0]);

      actions.termsAndConditions
        .uploadTermsAndConditions(formData)
        .then(() => {
          this.setState({submitting: false, showSuccessModal: true});
        })
        .catch(error => {
          this.setState({submitting: false})
          showSnackbar(
              'There was an error processing your request')});
    }
  }

  goBack() {
    const {history} = this.props
    history.push(`/nbtadmin/terms-and-conditions`)
  }

  /*
   * Open success modal
   */
  renderSuccessModal() {
    const { showSuccessModal } = this.state
    if (showSuccessModal) {
      return (
          <Modal
              header={"Success"}
              onClose={this.onCloseModal.bind(this)}
              showClose={true}
          >
            <div className='modal-body'>
              <div className='margin-top margin-bottom'>
                <p>PDF successfully added!</p>
              </div>
            </div>
            <div className='modal-footer'>
              <div className='modal-actions'>
                <button
                    className='button primary-button'
                    onClick={this.onCloseModal.bind(this)}
                >Proceed to Terms and Conditions</button>
              </div>
            </div>
          </Modal>
      );
    }
  }

  render() {
    return (
        <div className="page submit-terms-and-conditions-setup">
          <h4>Add Document</h4>
          <i className="material-icons"
             type="button"
             onClick={this.goBack.bind(this)}>{"chevron_left"}</i>
          <form onSubmit={this.handleSubmit.bind(this)} className="fee-form">
            <Card className="padding">
              {this.getFields().map((f, idx) => {
                return (
                    <Field className="margin-top" key={`${f.name}-${idx}`} {...f} />
                );
              })}
              <div className= "margin-top" />
              <label className="input-label" htmlFor={"file-input"}>
                Select File To Upload
              </label>
              <div>
                <input
                    name="file-input"
                    type="file"
                    ref={input => {
                      this.fileInput = input;
                    }}
                    onChange={this.onDrop.bind(this)}
                />
              </div>
            </Card>
            <Button
                className="primary-button margin-top"
                disabled={this.state.submitting}
                type="submit"
            >
            <span>
              {this.state.submitting ? (
                  <Loading containerClassName="inline" className="white small" />
              ) : (
                  "Submit"
              )}
            </span>
            </Button>
          </form>
          {this.renderSuccessModal()}
        </div>
    );
  }

}
export default TermsAndConditionsSetupForm;
