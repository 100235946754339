import React from 'react'
import PropTypes from 'prop-types'

// Styles --------------------------------
import 'components/forms/FormSection/form-section.scss'

class FormSection extends React.Component {
  render () {
    const {children, className, title} = this.props
    const classes = ['form-section']
    if (className) classes.push(className)
    return (
      <fieldset className={classes.join(' ')}>
        <div className='header'>
          <h5>{title}</h5>
        </div>
        <div className='body'>
          {children}
        </div>
      </fieldset>
    )
  }
}

FormSection.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string
}

export default FormSection
