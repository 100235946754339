import React from 'react'
import PropTypes from 'prop-types'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker as MuiDatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from 'dayjs';

class DatePicker extends React.Component {

    render () {
        const {name, disabled, className, label, required, placeholder, value,
            returnFormat, displayFormat, readOnly, startMode, minDate, maxDate,
            defaultValue} = this.props 
        const classes = ['my-react-component']
        if (className) classes.push(className)

        return (
            <div className="form-control">
                <label className="input-label" htmlFor={name}>{label}</label>
                {label && <br/>}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MuiDatePicker
                        slotProps={{ 
                            textField: { 
                                size: 'small',
                                required: {required}
                            } 
                        }}
                        sx={{
                            "& .MuiInputBase-input": {
                                height: "5px"
                            }
                        }}
                        defaultValue={defaultValue ? dayjs(defaultValue) : dayjs()}
                        format={displayFormat || 'MM/DD/YYYY'}
                        openTo={startMode}
                        disabled={disabled || readOnly}
                        minDate={minDate ? dayjs(minDate) : null}
                        maxDate={maxDate ? dayjs(maxDate) : null}
                        value={value ? dayjs(value) : null}
                        onChange={(value, context) => {
                            this.props.onChange(this.props.name, value.format(returnFormat || 'MM-DD-YYYY'))
                        }}
                        className={classes.join(' ')}
                        reduceAnimations={true}
                    />
                </LocalizationProvider>
            </div>
        )
    }
}

DatePicker.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    displayFormat: PropTypes.string,
    returnFormat: PropTypes.string,
    readOnly: PropTypes.bool,
    startMode: PropTypes.string,
    minDate: PropTypes.any,
    maxDate: PropTypes.any
}

export default DatePicker