import React from 'react'
import Employers from './Employers'
import HierarchyTable from './HierarchyTable'
import {Tabs, Tab} from 'components/Tabs'
import actions from 'actions'
import { hasRole } from '../../../../redux/utility/SessionUtil'

class Hierarchies extends React.Component {
  constructor (props) {
    super (props);
    this.state = {
      distributors: [],
      distributorOptions: {},
      sponsors: [],
      sponsorOptions: {},
      tabIndex: 0,
      readAccess: hasRole('HierarchyMasterView'),
      writeAccess: hasRole('HierarchyMasterEdit')
    }
  }


  /*
  * Verify access
  */
  componentWillMount () {
    if (this.verifyAccess()) {
      this.initializeSearchParams()
    }
  }

  /*
  * Verify that the user has access to heiarchies
  */
  verifyAccess () {
    if (!(this.state.readAccess || this.state.writeAccess)) {
      this.props.history.push('/nbtadmin/unauthorized')
      return false
    }

    return true
  }

  /*
  * Initialize heiarchy search params, i.e. the sponsors and distributors
  */
  initializeSearchParams () {
    this.setState({loading: true})
    actions.hierarchies.initializeSearchParams().then((searchParams) => {
      let sponsorOptions = {}
      searchParams.sponsors.forEach(o => {
        sponsorOptions[o.id] = o.name
      })

      let distributorOptions = {}
      searchParams.distributors.forEach(o => {
        distributorOptions[o.id] = o.name
      })

      this.setState({
        sponsorOptions,
        distributorOptions,
        sponsors: searchParams.sponsors,
        distributors: searchParams.distributors,
        loading: false
      })
    }).catch(() => { this.setState({loading: false}) })
  }


  /*
  * Render tabs
  */
  renderTabs () {
    return (
      <Tabs
        className='margin-top margin-bottom'
        currentIndex={this.state.tabIndex}
        onChange={(index) => {
          this.setState({tabIndex: index})
        }}
      >
        <Tab name='Sponsors' />
        <Tab name='Distributors' />
        <Tab name='Employers' />
      </Tabs>
    )
  }

  /*
  * Render Tab Content
  */
  renderTabContent () {
    switch (this.state.tabIndex) {
      case 0:
        return (
          <HierarchyTable
            {...this.props}
            title='Sponsors'
            dataSource={this.state.sponsors}
            loading={this.state.loading}
          />
        )
      case 1:
        return (
          <HierarchyTable
            {...this.props}
            title='Distributors'
            dataSource={this.state.distributors}
            loading={this.state.loading}
          />
        )
      case 2:
        return (
          <Employers
            {...this.props}
            distributorOptions={this.state.distributorOptions}
            sponsorOptions={this.state.sponsorOptions}
          />
        )
      default:
        return <div>This section is coming soon.</div>
    }
  }


  render () {
    return (
      <div className='search-page hierarchy-view'>
        <h4>Hierarchies</h4>
        <p>Manage hierarchies.</p>
        {this.renderTabs()}
        {this.renderTabContent()}
      </div>
    )
  }
}

export default Hierarchies
