import React from 'react'

import 'compositions/navigation/site-nav.scss'
import logo from 'assets/images/logo.jpg'

class SiteNav extends React.Component {

  render() {
    return (
      <nav className="site-nav">
        <h1><img className="logo" src={logo} alt="Connect Your Care" /></h1>
      </nav>
    )
  }
}

export default SiteNav
