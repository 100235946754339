
import React from 'react'
import PropTypes from 'prop-types'
import Pagination  from 'rc-pagination'
import localeInfo from 'rc-pagination/lib/locale/en_US'
import './paginate.scss'


const COUNT_PER_PAGE = 20

class Paginate extends React.Component {

    updatePage(current) {
        const to = this.props.countPerPage * current
        const from = to - this.props.countPerPage
        this.props.updatePage(current, from, to)
    }

    render() {
        const { countPerPage, total, style } = this.props
        return (
            <Pagination
                className="ant-pagination"
                pageSize={countPerPage || COUNT_PER_PAGE}
                onChange={(current) => this.updatePage(current)}
                showTotal={(total, range) =>
                    `${range[0]} - ${range[1]} of ${total} items`
                }
                total={total}
                style={style}
                locale={localeInfo}
            />
        )
    }
}

Paginate.propTypes = {
    countPerPage: PropTypes.number,
    total: PropTypes.number,
    style: PropTypes.object
}


export default Paginate