import React from 'react'
import Button from 'components/Button'
import Card from 'components/Card'
import Field from 'components/forms/Field/index'
import Loading from 'components/Loading'
import mailTemplate from 'data/mailTemplate'
import actions from 'actions'
import CKEditor from "react-ckeditor-component";
import DatePicker from "../../../../components/forms/DatePicker";
import { showSnackbar } from '../../../../redux/utility/SnackbarUtil'
import dayjs from 'dayjs'

class MailTemplateForm extends React.Component {
  constructor (props) {
    super (props)
    this.state = {
      form: this.initializeFormData(),
      loading: false,
      submitting: false,
      initialized: false
    }

    this.onChange.bind(this)
  }

  /*
  * Get mail template by id .
  */
  componentWillMount () {
    this.getLetterTypes()
    this.getMailTemplate()
  }

  /*
  * Fetch mail templates by id.
  */
  getMailTemplate() {
    let {match: {params: {id}}} = this.props
    if(localStorage.getItem('copyTemplateId')){
      id = localStorage.getItem('copyTemplateId')      
    }
    this.setState({loading: true})
	  if(id){
      actions.mailTemplates.getMailTemplateById(id).then(template => {
        if(localStorage.getItem('copyTemplateId')){
          template.welcomeKitNum = ''
          template.isDefault = false
          template.optumCorrespondenceId = ''
          template.startDate = this.validDate(template.endDate) ? dayjs(template.endDate).add(1, 'days').format("YYYY-MM-DD") : dayjs().format('YYYY-MM-DD');
          template.endDate = '';
          delete template.contentId
          localStorage.removeItem('copyTemplateId')
        }
        this.setState({form: this.initializeFormData(template), loading: false, initialized: true})
    }).catch(() =>
      localStorage.removeItem('copyTemplateId'),
      this.setState({loading: false}))
    } else {
    	 this.setState({initialized : true});
    }
  }

  /*
   * get the listing of letter types
   */

  getLetterTypes() {
	return actions.mailTemplates.getLetterTypes()
      .then(letterTypes => {
        let letterTypeOptions = {}
        letterTypes.forEach(o => {
          letterTypeOptions[o] = o
        })
        this.setState({ letterTypeOptions })
      })
      .catch(() => false)
  }

  /*
  * Initialize form data
  * Interest profile.
  */
 initializeFormData (data) {
  const {contentId, contentKey, contentTypeset, contentDocument, link, isDefault, optumCorrespondenceId, welcomeKitNum,startDate,endDate} = data || {}
  return {
    id: contentId || '',
    contentId: contentId || '',
    contentKey: contentKey || '',
    contentTypeset: contentTypeset || '',
    contentDocument: contentDocument || '',
    link: link || '',
    isDefault: isDefault || false,
    optumCorrespondenceId: optumCorrespondenceId || '',
    welcomeKitNum: welcomeKitNum || '',
    startDate: startDate || '',
    endDate: endDate ||  '',
    disableEndDate: false
  }
}
/*
* Render the fields
*/
getFields () {
  const {form: {contentKey,contentTypeset,isDefault,optumCorrespondenceId,welcomeKitNum,startDate,endDate,disableEndDate}} = this.state
  const {fields} = mailTemplate.us
  return [
      {    ...fields.contentTypeset,
      required: true,
      data: this.state.letterTypeOptions,
      attrs: {
        style: { height: "auto" }
      },
      events: {
           onChange: (event) => {
             this.onChange(fields.contentTypeset.name, event.target.value)
           }
       },
       value: contentTypeset
      },
      {
        ...fields.contentKey,
        required: true,
        events: {
          onChange: (event) => {
            this.onChange(fields.contentKey.name, event.target.value)
          }
        },
        value: contentKey
      },
    {
      ...fields.rowGroup,
      data: [
        {
          ...fields.rowGroup.data.isDefault,
          events: {
            onChange: (event) => {
              this.onChange(fields.rowGroup.data.isDefault.name,event.target.checked)
            }
          },
          attrs: {
            checked:isDefault
          }
        },
        {
          ...fields.rowGroup.data.optumCorrespondenceId,
          required: false,
          attrs: {
            maxLength: 25
          },
          events: {
            onChange: (event) => {
              this.onChange(fields.rowGroup.data.optumCorrespondenceId.name, event.target.value)
            }
          },
          value: optumCorrespondenceId
        },
        {
          ...fields.rowGroup.data.welcomeKitNum,
          required: false,
          attrs: {
            maxLength: 25
          },
          events: {
            onChange: (event) => {
              this.onChange(fields.rowGroup.data.welcomeKitNum.name, event.target.value)
            },
            onKeyPress: (event) =>{
              if(event.target.value==='' && event.key==='-'){
                event.preventDefault();
              }
            }
          },
          value: welcomeKitNum
        },
      ]
      },
          {
            ...fields.startDate,
            required: true,
            onChange: (name, value) => {
              this.onChange(name, value)
            },
            value: startDate
          },
          {
            ...fields.endDate,
            required: true,
            disabled: disableEndDate,
            onChange: (name, value) => {
              this.onChange(name, value)
            },
            value: endDate
          }
    ]
}
  /*
  * Update form values
  *
  * @param [String] name. Field name of form to update.
  * @param [Any] value. Value.
  */
  onChange (name, value) {
    let form = {...this.state.form}
    form[name] = value
    if(name === 'isDefault')
    {
      if(value === true){
        form['endDate'] = null
        form['disableEndDate'] = true
      }else{
        form['disableEndDate'] = false
      }
    }
    this.setState({form})
  }

   /*
  * handles the key press
  *
  * @param [Event] event. key press event
  * @param [Any] regex. regex to match the key press.
  */
  hanleKeyPress(event,regex){
    if(event.key.match(regex)==null){
      event.preventDefault()
    }
  }


  validDate = date => {
    const currentDate = dayjs().subtract(1, 'days').format("YYYY-MM-DD")
    return date > currentDate
  }

  /**
   * On submit
   */
  handleSubmit(event) {
    event.preventDefault()
    let form = {...this.state.form}
    if (!this.validDate(form.startDate)) {
      showSnackbar('Please enter a valid \'Start Date\' greater than or equal to current date')
    } else if ((form.endDate && !this.validDate(form.endDate)) || (form.endDate && form.endDate < form.startDate)) {
      showSnackbar('Please enter a valid \'End Date\' greater than start date')
    }else {
    !this.state.form.id ?
      this.onCreateMailTemplate(form): this.onUpdateMailTemplate(form)
    }
  }

  /*
  * Create new mail template
  */
  onCreateMailTemplate(form) {
    delete form.id
    this.setState({submitting: true})
    actions.mailTemplates.createMailTemplate(form).then((template) => {
      const {history} = this.props
      history.push(`/nbtadmin/mail-templates/${template.contentId}/edit`)
      this.setState({form: this.initializeFormData(template), submitting: false})
    }).catch(() => { this.setState({submitting: false}) })
  }
  
  /*
  * Update mail template
  */
  onUpdateMailTemplate(form) {
    this.setState({submitting: true})
    actions.mailTemplates.updateMailTemplate(form).then((template) => {
      this.setState({form: this.initializeFormData(template), submitting: false})
    }).catch(() => { this.setState({submitting: false}) })
  }


  renderFields(f,idx){
    if(f.type === 'group'){
        return (<div className='flex-display'>
         {f.data.map((l, ldx) => {
           
              return  <Field key={`${l.name}-${ldx}`} {...l} />
            
            })}
        </div>
        )
    }else{
      if(f.type !=='datePicker'){
      return  <Field key={`${f.name}-${idx}`} {...f} />
    }else{
      return <DatePicker key={`${f.name}-${idx}`} {...f} />
    }
    }
  }

  render () {
    const {form} = this.state
    return (
      <div className='page mail-template-view'>
        <h4>{form.id ? 'Edit' : 'Create'} Mail Template</h4>
        <form onSubmit={this.handleSubmit.bind(this)}>
          <Card className='padding'>
            {this.getFields().map((f, idx) => {
              return this.renderFields(f,idx)
            })}
          </Card>

          <Card className='padding'>
            <h5>Template</h5>

            {this.state.initialized ?
                <CKEditor
                    activeClass="p10"
                    name='contentEditor'
                    content={form.contentDocument}
                    events={{
                        "change": event => {
                            this.onChange('contentDocument', event.editor.getData())},
                        "blur": this.onBlur,
                        "afterPaste": this.afterPaste
                    }}
                    scriptUrl={"/assets/javascript/ckeditor/4.6.2/full-all/ckeditor.js"}
                    />
                    : null
            }
          </Card>


          <Button
            className='primary-button margin-top'
            disabled={this.state.submitting}
            type="submit"
          >
            <span>

              {this.state.submitting ?
                <Loading containerClassName='inline' className='white small' />
                : 'Submit'
              }
            </span>
          </Button>
        </form>
      </div>
    )
  }
}

export default MailTemplateForm
