import React from 'react'
import Card from 'components/Card'
import ConfirmDialog from 'components/ConfirmDialog'
import Loading from 'components/Loading'
import TextField from 'components/TextField'
import actions from 'actions'
import { hasRole } from '../../../../redux/utility/SessionUtil'
import {mapDateToForm} from 'lib/date'
import Modal from 'components/Modal'
import PropTypes from 'prop-types'
import Instances from './Instances'


class FeeProfile extends React.Component {
  constructor (props) {
    super (props)
    this.state = {
      profile: null,
      readAccess: hasRole('FeeProfileMasterView'),
      writeAccess: hasRole('FeeProfileMasterEdit'),
      showModal: false,
      openInstances: false
    }
  }

  /*
  * Get fee profile by id.
  */
  componentWillMount () {
    if (this.verifyAccess()) {
      this.getFeeProfile()
    }
  }

  /*
  * Verify that the user has access to fee profiles
  */
  verifyAccess () {
    if (!(this.state.readAccess || this.state.writeAccess)) {
      this.props.history.push('/nbtadmin/unauthorized')
      return false
    }

    return true
  }

  /*
  * Fetch fee profile by id.
  */
  getFeeProfile () {
    const {match: {params: {id}}} = this.props
    this.setState({loading: true})
    actions.feeProfiles.getFeeProfileById(id).then(profile => {
      this.getFeeProfileCountById(profile.id)
      this.setState({profile, loading: false})
    }).catch(() => this.setState({loading: false}))
  }

  getFeeProfileCountById(id) {
    return actions.feeProfiles.getFeeProfileCountById(id).then(profile => {
      this.setState({instances: profile})
    })
  }

  openModal = () => {
    this.setState({ openInstances: true });
  }

  onCloseAddTransaction() {
    this.setState({ openInstances: false });
  }

  /*
   * Open add transaction modal
   */
  renderInstanceModal() {
    const { openInstances } = this.state;
    if (openInstances) {
      return (
        <Modal
          header={"Instances"}
          onClose={this.onCloseAddTransaction.bind(this)}
          showClose={true}
        >
          <Instances
          profile={this.state.profile}
          {...this.props}/>
        </Modal>
      );
    }
  }

  renderAddButton () {
    if (this.state.writeAccess) {
      return (
        <div>
          <div>
            <span style={{float: 'right'}}>
              <a onClick={this.onAdd.bind(this)} className='orange'>
                <span style={{verticalAlign: 'top'}}>Create New Fee Profile</span>
                <i className='material-icons'>add_circle_outline</i>
              </a>
            </span>
          </div>
          <div style={{clear: 'both'}}></div>
        </div>
      )
    }
  }

  /*
  * Render the fee profile meta data
  */
  renderDetails () {
    const {name, deactivatedDate, createdDate, lastModifiedDate, description} = this.state.profile
    return (
      <Card className='padding'>
        {this.renderActionsMenu()}
        <h5>Details</h5>

        <div className='row'>
          <div className='col'>
            <TextField
              label='Name'
              name='name'
              value={name}
            />
          </div>
          <div className='col'>
            <TextField
              label='Created date'
              name='createdDate'
              value={mapDateToForm(createdDate)}
            />
          </div>
          <div className='col'>
            <TextField
              label='Modified date'
              name='modifiedDate'
              value={mapDateToForm(lastModifiedDate)}
            />
          </div>
          <div className='col'>
            <TextField
              label='Instances'
              name='instances'
              value={this.state.instances === 0 ? "None" : <a onClick={this.openModal}>{this.state.instances}</a>}
            />
          </div>
        </div>
        <div className='row margin-top'>
          <div className='col'>
            <TextField
              label='Deactivated date'
              name='deactivatedDate'
              value={mapDateToForm(deactivatedDate)}
            />
          </div>
        </div>
        <div className='row margin-top'>
          <div className='col'>
            <TextField
              label='Description'
              name='description'
              value={description}
            />
          </div>
        </div>
      </Card>
    )
  }

  /*
  * Render the actions menu
  */
  renderActionsMenu () {
    if (this.state.writeAccess) {
      return (
        <div className='text-right'>
          <a onClick={this.onEdit.bind(this)}>
            <i className='material-icons orange' style={{fontSize: '1.2em'}}>edit</i>
          </a>
          {this.renderDeleteButton()}
        </div>
      )
    }
  }

  renderDeleteButton () {
    if (!this.state.profile.deactivatedDate) {
      return (
        <a style={{marginLeft: '8px'}} onClick={this.onDelete.bind(this)}>
          <i className='material-icons orange' style={{fontSize: '1.2em'}}>delete</i>
        </a>
      )
    }
  }

  renderFees () {

    return (
      <Card className='padding' style={{marginLeft: '3em'}}>
        <h5>Profile Specific Fees</h5>
        {this.state.profile.profileSpecificFees.map((f, index) => {
          const {fee: {name}, amount, paidBy, thresholdAmount, thresholdType, enrollmentStatus} = f
          return (
            <div className='row margin-top'>
              <div className='col'>
                <TextField
                  label='Fee Name'
                  name='name'
                  value={name || `Fee ${index}`}
                />
              </div>

              <div className='col'>
                <TextField
                  label='Amount '
                  name='amount'
                  value={amount}
                />
              </div>
              <div className='col'>
                <TextField
                  label='Paid By'
                  name='paidBy'
                  value={paidBy}
                />
              </div>
              <div className='col'>
                <TextField
                  label='Threshold Amount'
                  name='thresholdAmount'
                  value={thresholdAmount}
                />
              </div>
              <div className='col'>
                <TextField
                  label='Threshold Type'
                  name='thresholdType'
                  value={thresholdType}
                />
              </div>
              <div className='col'>
                <TextField
                  label='Enrollment Status'
                  name='enrollmentStatus'
                  value={enrollmentStatus}
                />
              </div>
            </div>
          )
        })}
      </Card>
    )
  }

  /*
  * Render the confirm delete dialog
  */
  renderConfirmDialog () {
    if (this.state.openConfirmationDialog) {
      return (
        <ConfirmDialog
          description='Are you sure you want to deactivate fee profile?'
          onClose={() => { this.setState({openConfirmationDialog: false}) }}
          onConfirm={() => { this.deleteFeeProfile() }}
        />
      )
    }
  }

  onAdd () {
    const {history} = this.props
    history.push(`/nbtadmin/fee-profiles/create/new`)
  }

  /*
  * On edit
  */
  onEdit () {
    const {history} = this.props
    history.push(`/nbtadmin/fee-profiles/${this.state.profile.id}/edit`)
  }

  /*
  * On delete, open confirmation dialog
  */
  onDelete () {
    this.setState({openConfirmationDialog: true})
  }

  /*
  * Delete fee profile
  */
  deleteFeeProfile () {
    actions.feeProfiles.deactivateFeeProfile(this.state.profile).then((updatedProfile) => {
      this.setState({profile: updatedProfile, openConfirmationDialog: false})
    }).catch(() => false)
  }

  render () {
    if (this.state.loading) return <div className='margin-top'><Loading/></div>

    const {name} = this.state.profile

    return (
      <div className='page fee-profile-view'>
        <h4>{name}</h4>
        {this.state.showModal === true &&
        <Modal
        header={'Are you sure you want to add this transaction?'}
        onClose={() => this.props.onClose()}
        >
          <div>Hey</div>
        </Modal>  
        }
        {this.renderAddButton()}
        {this.renderDetails()}
        {this.renderFees()}
        {this.renderConfirmDialog()}
        {this.renderInstanceModal()}
      </div>
    )
  }
}

FeeProfile.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func
}

export default FeeProfile
