import Fetch from 'lib/fetch'
import { showSnackbar } from '../redux/utility/SnackbarUtil'

/*
* Get all fees
*/
export function getFees () {
  return Fetch.get(`${process.env.REACT_APP_FEE_DOMAIN}/fees`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

/*
* Get fee by id.
*
* @param [Number] id. Id of fee  to get.
*/
export function getFeeById (id) {
  return Fetch.get(`${process.env.REACT_APP_FEE_DOMAIN}/fees/${id}`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

/*
* Update a fee.
*
* @param [Object] form. Fee.
*/
export function updateFee (form) {
  return Fetch.put(`${process.env.REACT_APP_FEE_DOMAIN}/fees/${form.id}`, form, null)
    .then(data => {
      showSnackbar('Fee successfully updated', 'info')
      return data
    })
    .catch(error => Promise.reject(error))
}
