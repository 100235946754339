import React from 'react'
import PropTypes from 'prop-types'
import TableDisplay from "../../../../compositions/TableDisplay";
import {filterDataSource} from 'lib/filter'
import Modal from 'components/Modal'
import EditCustodianUrlForm from './EditCustodianUrlForm'
import actions from 'actions'
import { showSnackbar } from '../../../../redux/utility/SnackbarUtil';
import dayjs from 'dayjs';

const headers = [

  {
    filterText: 'Employer Name',
    header: () => 'Employer Name',
    key: 'employerName'
  },
  {
    filterText: 'Transfer Date',
    header: () => 'Transfer Date',
    key: 'transferDateDisplay'
  },
  {
    filterText: 'Url Expiration Date',
    header: () => 'Url Expiration Date',
    key: 'urlExpirationDateDisplay'
  },
  {
    filterText: 'Create Date',
    header: () => 'Create Date',
    key: 'createDateDisplay'
  }
];

class CustodianUrlTable extends React.Component {
  constructor(props) {
    super(props)

    let mappedCustodianUrls = []
    if (props.dataSource) {
      mappedCustodianUrls = props.dataSource.map(custodianUrl => {
        return this.mapCustodianUrlToDisplay(custodianUrl)
      })
    }

    this.state = {
      dataSource: mappedCustodianUrls,
      filteredDataSource: mappedCustodianUrls,
      originalSource: props.dataSource || [],
      openCustodianUrlModal: false,
      currentCustodianUrl: null
    }

    this.actions = [
      {
        action: this.onEditCustodianUrl.bind(this),
        icon: 'edit'
      },
      {
        action: this.onViewCustodianUrl.bind(this),
        icon: 'visibility'
      }
    ]
  }

  onEditCustodianUrl (custodianUrl) {
    const currentCustodianUrl = this.state.dataSource
    .find(d => d.id === custodianUrl.id);
    this.setState({currentCustodianUrl, openCustodianUrlModal: true})
  }

  onViewCustodianUrl ({id}) {
    const {history} = this.props
    history.push(`/nbtadmin/custodian-consent/generated-url/view/${id}`)
  }

  mapCustodianUrlToDisplay(custodianUrl) {
    const {id, employerName, transferDateDisplay, urlExpirationDateDisplay, createDateDisplay} = custodianUrl
    return {
      id,
      employerName,
      transferDateDisplay,
      urlExpirationDateDisplay,
      createDateDisplay
    }
  }

  onRowClick(consentUrl)  {
    const {history} = this.props
    history.push(`/nbtadmin/custodian-consent/generated-url/view/${consentUrl.id}`)
  }

  onFilter (queryParams) {
    const {dataSource} = this.state
    const newDataSource = filterDataSource(dataSource, queryParams)
    this.setState({filteredDataSource: newDataSource})
  }

  onCloseFeeProfileModal () {
    this.setState({openCustodianUrlModal: false})
  }

  mapForm (form) {
    const {
      id,
      transferDateDisplay,
      urlExpirationDateDisplay
    } = form;

    return {
      id: id,
      transferDate: transferDateDisplay,
      urlExpirationDate: urlExpirationDateDisplay
    }
  }

  validDate = date => {
    const currentDate = dayjs().format("YYYY-MM-DD")
    return dayjs(date, "MM-DD-YYYY").format('YYYY-MM-DD') > currentDate
  }
  
  //on submit do the valid date check then update state for current component and child component(dataSource)
  onSubmit(form) {
    const {transferDateDisplay, urlExpirationDateDisplay} = form;

    if (!this.validDate(transferDateDisplay)) {
      showSnackbar('Please enter a valid Transfer Date')
    } else if (!this.validDate(urlExpirationDateDisplay)) {
      showSnackbar('Please enter a valid Expiration Date')
    } else {
      const request = this.mapForm(form);
      actions.consentForms.updateConsentUrl(request)
      .then(data => {
        const updatedData = [...this.state.filteredDataSource.filter(({id}) => id !== data.id), this.mapCustodianUrlToDisplay(data)];
        this.setState({filteredDataSource: updatedData, dataSource: updatedData, originalSource: updatedData})
      });
      this.onCloseFeeProfileModal()
    }
  }

  renderCustodianUrlModal () {
    const {openCustodianUrlModal} = this.state;
    if (openCustodianUrlModal) {
      return (
          <Modal
              header={"Edit Custodian Url"}
              onClose={this.onCloseFeeProfileModal.bind(this)}
              showClose={true}
          >
            <EditCustodianUrlForm
                custodianUrl={this.state.currentCustodianUrl}
                onSubmit={this.onSubmit.bind(this)}
            />
          </Modal>
      )
    }
  }

  render() {
    return (
        <div>
          <div className='search-page custodian-form-urls-view'>
            <TableDisplay
                dataActions={this.actions}
                dataSource={this.state.filteredDataSource}
                loading={this.props.loading}
                onAdd={this.props.onAdd}
                onFilter={this.onFilter.bind(this)}
                pageDescription=''
                pageTitle='Generated Consent URLs'
                tableHeaders={headers}
            />
          </div>
          {this.renderCustodianUrlModal()}
        </div>
    )
  }
}

CustodianUrlTable.propTypes = {
  dataSource: PropTypes.array,
  title: PropTypes.string,
  onAdd: PropTypes.func
}

export default CustodianUrlTable