import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/Modal'

class ConfirmDialog extends React.Component {
  render () {
    return (
      <Modal
        header={'Are you sure?'}
        onClose={() => this.props.onClose()}
      >
        <div className='modal-body'>
          <div className='margin-top margin-bottom'>
            <p>{this.props.description}</p>
          </div>
        </div>
        <div className='modal-footer'>
          <div className='modal-actions'>
            <button
              className='button primary-button'
              onClick={() => this.props.onConfirm()}
            >Yes</button>
            <button
              className='button secondary-button'
              onClick={() => this.props.onClose()}
            >No</button>
          </div>
        </div>
      </Modal>
    )
  }
}

ConfirmDialog.propTypes = {
  description: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func
}

export default ConfirmDialog
