import React from 'react'
import PropTypes from 'prop-types'
import EmployerSearch from './EmployerSearch'
import HierarchyTable from './HierarchyTable'
import actions from 'actions'


class Employers extends React.Component {
  constructor (props) {
    super (props);
    this.state = {
      dataSource: [],
      hasSearched: false,
      showSearch: true,
      loading: false
    }
  }

  /*
  * On employer search
  */
  onSearch (value) {
    this.setState({loading: true})
    return actions.hierarchies.getHierarchies(value).then(hierarchies => {
      const mappedHierarchies = hierarchies.map(hierarchy => {
        const {id, name} = hierarchy
        return {
          id,
          name
        }
      })
      this.setState({dataSource: mappedHierarchies, loading: false, showSearch: false, hasSearched: true})
    }).catch(() => this.setState({loading: false}))
  }

  /*
  * Toggle if the search is showing
  *
  */
  toggleSearch () {
    if (this.state.hasSearched) {
      this.setState({showSearch: !this.state.showSearch})
    }
  }



  render () {
    return (
      <div className=''>
        <h5>Employers</h5>

        <i className="material-icons" onClick={this.toggleSearch.bind(this)}>{this.state.showSearch ? 'chevron_left' : 'chevron_right'}</i>
        <div style={{width: "100%", margin: "0 auto", display: this.state.showSearch ? 'block' : 'none'}}>
          <EmployerSearch
            {...this.props}
            onSubmit={this.onSearch.bind(this)}
          />
        </div>
        <div className='margin-top margin-bottom'/>
        {this.state.dataSource.length > 0 &&
          <HierarchyTable
            {...this.props}
            title=''
            dataSource={this.state.dataSource}
            loading={this.state.loading}
          />
        }
      </div>
    )
  }
}

Employers.propTypes = {
  history: PropTypes.object,
  distributorOptions: PropTypes.object,
  sponsorOptions: PropTypes.object
}


export default Employers
