import React from 'react'
import PropTypes from 'prop-types'

import './side-nav.scss'

class SideNav extends React.Component {
  render() {
    const {className} = this.props
    const classes = ['side-nav']
    if (className) classes.push(className)
    return (
      <ul className={classes.join(' ')}>
        {this.props.children}
      </ul>
    )
  }
}

SideNav.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
}

export default SideNav
