import Fetch from 'lib/fetch'
import { showSnackbar } from '../redux/utility/SnackbarUtil'

/*
* Upload transfer employer, member data
*/
export function expeditedUpload(form) {
  return Fetch.postMultipart(`${process.env.REACT_APP_CIP_DOMAIN}/expedited-upload/upload
`, form, null)
  .then(data => {
    if(data != null && data.message != null) {
      showSnackbar('Transferring '.concat(data.fileType).concat(' have been successfully uploaded. ').concat(data.message), 'info')
    } else {
      showSnackbar('Transferring '.concat(data.fileType).concat(' have been successfully uploaded. '), 'info')
    }
    return data
  })
  .catch(error => Promise.reject(error))
}
