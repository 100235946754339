import React from 'react'
import Button from 'components/Button'
import Card from 'components/Card'
import Field from 'components/forms/Field/index'
import InputTable from 'components/InputTable'
import interestProfile from 'data/interestProfile'
import Loading from 'components/Loading'
import actions from 'actions'
import { hasRole } from '../../../../redux/utility/SessionUtil'
import {maskDate} from 'lib/mask'
import {mapDateToForm, mapFormDateToDate} from 'lib/date'
import { showSnackbar } from '../../../../redux/utility/SnackbarUtil'

const headers = [
  {
    header: () => '',
    key: 'id'
  },
  {
    header: () => 'Balance From',
    key: 'balanceFrom',
    disabled: false,
    type: 'number',
    validate: (value) => {
      var re = new RegExp(/^\d+(\.\d{1,2})?$/);
      return value ? re.test(value) : true
    }
  },
  {
    header: () => 'Balance To',
    key: 'balanceTo',
    disabled: false,
    type: 'number',
    validate: (value) => {
      var re = new RegExp(/^\d+(\.\d{1,2})?$/);
      return value ? re.test(value) : true
    }
  },
  {
    header: () => 'Interest Rate',
    key: 'percentInterest',
    disabled: false,
    type: 'number',
    validate: (value) => {
      var re = new RegExp(/^\d+(\.\d{1,3})?$/);
      return value ? re.test(value) : true
    }
  },
  // {
  //   header: () => 'Low APY',
  //   key: 'lowApy',
  //   disabled: true,
  //   type: 'number'
  // },
  // {
  //   header: () => 'High APY',
  //   key: 'highApy',
  //   disabled: true,
  //   type: 'number'
  // }
]

class InterestProfileForm extends React.Component {
  constructor (props) {
    super (props)
    this.state = {
      form: this.initializeFormData(),
      loading: false,
      submitting: false,
      writeAccess: hasRole('InterestMasterEdit')
    }

    this.onChange.bind(this)
  }

  /*
  * Get profile by id.
  */
  componentWillMount () {
    if (this.verifyAccess()) {
      this.getProfile()
    }
  }

  /*
  * Verify that the user has access to interest profile
  */
  verifyAccess () {
    if (!(this.state.writeAccess)) {
      this.props.history.push('/nbtadmin/unauthorized')
      return false
    }

    return true
  }

  /*
  * Fetch profile by id.
  */
  getProfile () {
    const {match: {params: {id}}} = this.props

    if (id) {
      this.setState({loading: true})
      actions.interestProfiles.getInterestProfileById(id).then(profile => {
        this.setState({form: this.initializeFormData(profile), loading: false})
      }).catch(() => this.setState({loading: false}))
    }
  }

  /*
  * Initialize form data
  * Interest profile.
  */
  initializeFormData (data) {
    const {id, activeDate, name, description, accrualBalanceMethod,
      accrualCompoundMethod, postingSchedule, tiers, type} = data || {}

    return {
      id: id || '',
      activeDate: activeDate ? mapDateToForm(activeDate) : '',
      name: name || '',
      type: type || '',
      accrualBalanceMethod: accrualBalanceMethod || 'Daily',
      accrualCompoundMethod: accrualCompoundMethod || 'Monthly',
      postingSchedule: postingSchedule || 'Monthly',
      description: description || '',
      tiers: tiers || []
    }

  }

  /*
  * Render the fields
  */
  getFields () {
    const {form: {name, type, activeDate, description, accrualBalanceMethod,
      accrualCompoundMethod, postingSchedule}} = this.state

    const {fields} = interestProfile.us

    return [
        {
          ...fields.name,
          required: true,
          events: {
            onChange: (event) => {
              this.onChange(fields.name.name, event.target.value)
            }
          },
          value: name
        },
        {
          ...fields.type,
          required: true,
          events: {
            onChange: (event) => {
              this.onChange(fields.type.name, event.target.value)
            }
          },
          value: type
        },
        {
          ...fields.activeDate,
          required: true,
          events: {
            onChange: (event) => {
              const value = maskDate(this.state.form[fields.activeDate.name], event.target.value)
              this.onChange(fields.activeDate.name, value)
            }
          },
          value: activeDate
        },
        {
          ...fields.accrualBalanceMethod,
          required: true,
          events: {
            onChange: (event) => {
              //TODO: Will tell us when this is enabled.
              this.onChange(fields.accrualBalanceMethod.name, event.target.value)
            }
          },
          value: accrualBalanceMethod
        },
        {
          ...fields.accrualCompoundMethod,
          required: true,
          events: {
            onChange: (event) => {
              //TODO: Will tell us when this is enabled.
              this.onChange(fields.accrualCompoundMethod.name, event.target.value)
            }
          },
          value: accrualCompoundMethod
        },
        {
          ...interestProfile.us.fields.postingSchedule,
          required: true,
          events: {
            onChange: (event) => {
              //TODO: Will tell us when this is enabled.
              this.onChange(fields.postingSchedule.name, event.target.value)
            }
          },
          value: postingSchedule
        },
        {
          ...fields.description,
          required: false,
          events: {
            onChange: (event) => {
              this.onChange(fields.description.name, event.target.value)
            }
          },
          value: description
        }
      ]
  }

  /*
  * Update form values
  *
  * @param [String] name. Field name of form to update.
  * @param [Any] value. Value.
  */
  onChange (name, value) {
    let form = {...this.state.form}
    form[name] = value
    this.setState({form})
  }

  /**
   * On submit
   */
  handleSubmit(event) {
    event.preventDefault()
    let form = {...this.state.form}
    form.activeDate = mapFormDateToDate(form.activeDate)
    form.tiers = this.filterEmptyObjects(this.inputTable.getData())

    if (this.validateTiers(form.tiers)) {
      !this.state.form.id ?
        this.onCreateInterestProfile(form): this.onUpdateInterestProfile(form)
      }
  }

  /*
  * Filter out empty rows of input table
  */
  filterEmptyObjects (arr) {
    return arr.filter(obj => {
      for (let key in obj) {
        if (obj[key] !== null && obj[key] !== "") return true
      }
      return false
    })
  }


  /*
  * Validate tiers
  */
  validateTiers(tiers) {
    let isValid = true
    if (tiers.length > 1) {
      for (let i = 1; i < tiers.length; i++) {
        let diff = Math.round((parseFloat(tiers[i].balanceFrom) - parseFloat(tiers[i-1].balanceTo)) * 100) / 100

        if (diff !== 0.01) {
          isValid = false
          showSnackbar(`Gap in balances at row ${i +1} for  Balance From: ${tiers[i].balanceFrom}.`)
          i = tiers.length
          this.setState({showTierRules: true})
        } else {
          this.setState({showTierRules: false})
        }
      }
    }

    return isValid
  }

  /*
  * Create new interest profile
  */
  onCreateInterestProfile (form) {
    delete form.idea
    this.setState({submitting: true})
    actions.interestProfiles.createInterestProfile(form).then((profile) => {
      const {history} = this.props
      history.push(`/nbtadmin/interest-profiles/${profile.id}/edit`)
      this.setState({form: this.initializeFormData(profile), submitting: false})
    }).catch(() => { this.setState({submitting: false}) })
  }

  /*
  * Update interest profile
  */
  onUpdateInterestProfile (form) {
    this.setState({submitting: true})
    actions.interestProfiles.updateInterestProfile(form).then((profile) => {
      this.setState({form: this.initializeFormData(profile), submitting: false})
    }).catch(() => { this.setState({submitting: false}) })
  }

  render () {
    if (this.state.loading) return <div/>
    const {form} = this.state
    return (
      <div className='page interest-profile-form'>
        <h4>{form.id ? 'Edit' : 'Create'} Interest Profile</h4>
        <form onSubmit={this.handleSubmit.bind(this)} className="interest-profile-form" noValidate>
          <Card className='padding'>
            {this.getFields().map((f, idx) => {
              return <Field key={`${f.name}-${idx}`} {...f} />
            })}
          </Card>
          {this.state.showTierRules &&
            <div style={{fontSize: '12px', color: 'red'}}>Tiers should not have gaps in them, i.e. Balance To and the next Balance From should be $0.01 apart</div>
          }
          <InputTable
            ref={(component) => { this.inputTable = component }}
            tableHeaders={headers}
            dataSource={this.state.form.tiers}
          />
          <Button
            className='primary-button margin-top'
            disabled={this.state.submitting}
            type="submit"
          >
            <span>

              {this.state.submitting ?
                <Loading containerClassName='inline' className='white small' />
                : 'Submit'
              }
            </span>
          </Button>
        </form>
      </div>
    )
  }
}

InterestProfileForm.propTypes = {

}

export default InterestProfileForm
