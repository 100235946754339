import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    authToken: null,
    refreshToken: null,
    username: null,
    roles: [],
    expires: null
};

const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        setAuth(state, action) {
            state.authToken = action.payload.authToken;
            state.refreshToken = action.payload.refreshToken;
            state.username = action.payload.username;
            state.roles = action.payload.roles;
            state.expires = action.payload.expires;
        }
    }
})

export const {setAuth} = sessionSlice.actions;

export default sessionSlice.reducer;