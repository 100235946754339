import React from 'react'
import ConfirmDialog from 'components/ConfirmDialog'
import TableDisplay from 'compositions/TableDisplay'
import actions from 'actions'
import { hasRole } from '../../../../redux/utility/SessionUtil'
import {filterDataSource} from 'lib/filter'
import {mapDateToForm} from 'lib/date'

const headers = [
  {
    filterText: 'Name',
    header: () => 'Name',
    key: 'name'
  },
  {
    filterText: 'Type',
    header: () => 'Type',
    key: 'type'
  },
  {
    filterText: 'Description',
    header: () => 'Description',
    key: 'description'
  },
  {
    filterText: 'Balance method',
    header: () => 'Balance Method',
    key: 'accrualBalanceMethod'
  },
  {
    filterText: 'Compound method',
    header: () => 'Compound Method',
    key: 'accrualCompoundMethod'
  },
  {
    filterText: 'Posting schedule',
    header: () => 'Posting Schedule',
    key: 'postingSchedule'
  },
  {
    filterText: 'Acitve date',
    header: () => 'Active date',
    key: 'activeDate'
  },
  {
    filterText: 'Instance',
    header: () => 'Instance',
    key: 'instance'
  }
]

class InterestProfiles extends React.Component {
  constructor (props) {
    super (props);
    this.state = {
      dataSource: [],
      filteredActiveDataSource: [],
      filteredInactiveDataSource: [],
      originalSource: [],
      activeQueryParams: {},
      inactiveQueryParams: {},
      readAccess: hasRole('InterestMasterView'),
      writeAccess: hasRole('InterestMasterEdit')
    }

    this.actions = this.state.writeAccess ? [
      {
        action: this.onEdit.bind(this),
        icon: 'edit'
      },
      {
        action: this.onDelete.bind(this),
        icon: 'delete'
      }
    ] : []
  }

  /*
  * Get all interest profiles.
  */
  componentWillMount () {
    if (this.verifyAccess()) {
      this.getProfiles()
    }
  }

  /*
  * Verify that the user has access to interest profiles
  */
  verifyAccess () {
    if (!(this.state.readAccess || this.state.writeAccess)) {
      this.props.history.push('/nbtadmin/unauthorized')
      return false
    }

    return true
  }

  /*
  * Fetch interest instances.
  */
 getInterestInstancesById(profile) {
    return actions.interestProfiles.getInterestProfileCountById(profile.id).then(instance => {
      profile.instance = instance
      return profile
    })
  }

  /*
  * Fetch interest profiles.
  */
  getProfiles () {
    this.setState({loading: true})
    actions.interestProfiles.getInterestProfiles().then(profiles => {
      const promises = profiles.map(profile => {
        return this.getInterestInstancesById(profile)
      })

      Promise.all(promises).then(profiles => {
        const mappedProfiles = profiles.map(profile => {
          return this.mapProfileToDisplay(profile)
        })
        const activeDataSource = this.filterActive(mappedProfiles)
        const inactiveDataSource = this.filterInactive(mappedProfiles)

        this.setState({
          dataSource: mappedProfiles,
          originalSource: profiles,
          filteredActiveDataSource: activeDataSource,
          filteredInactiveDataSource:inactiveDataSource,
          loading: false
        })
      })
      

      
    }).catch(() => this.setState({loading: false}))
  }

  /*
  * Map profile to display
  */
  mapProfileToDisplay (profile) {
    const {id, name, type, description, accrualBalanceMethod,
      accrualCompoundMethod, postingSchedule, activeDate, instance} = profile
    return {
      id,
      name,
      type,
      description,
      accrualBalanceMethod,
      accrualCompoundMethod,
      activeDate: mapDateToForm(activeDate),
      postingSchedule, 
      instance
    }
  }

  /*
  * Render the confirm delete dialog
  */
  renderConfirmDialog () {
    if (this.state.openConfirmationDialog) {
      return (
        <ConfirmDialog
          description='Are you sure you want to deactivate interest profile?'
          onClose={() => { this.setState({openConfirmationDialog: false, currentProfile: null}) }}
          onConfirm={() => { this.deleteProfile(this.state.currentProfile) }}
        />
      )
    }
  }

  /*
  * Go to interest profile form
  */
  onAdd () {
    const {history} = this.props
    history.push(`/nbtadmin/interest-profiles/create/new`)
  }

  /*
  * View interest profile
  */
  onRowClick (profile) {
    const {history} = this.props
    history.push(`/nbtadmin/interest-profiles/${profile.id}`)
  }

  /*
  * Edit interest profile
  */
  onEdit (profile) {
    const {history} = this.props
    history.push(`/nbtadmin/interest-profiles/${profile.id}/edit`)
  }

  /*
  * On delete, open confirmation dialog
  */
  onDelete (profile) {
    this.setState({openConfirmationDialog: true, currentProfile: profile})
  }

  /*
  * Delete interest profile
  */
  deleteProfile () {
    // TODO: Api should not update fields that don't exist in form. Right now they null out if not provided
    // so keep copy of origianl record.
    const originalProfile = this.state.originalSource.find((ds) => ds.id === this.state.currentProfile.id)
    actions.interestProfiles.deactivateInterestProfile(originalProfile).then((updatedProfile) => {
      const index = this.state.dataSource.findIndex(ds => ds.id === this.state.currentProfile.id)
      let newDataSource = this.state.dataSource
      let newOriginalSource = this.state.originalSource
      newDataSource[index] = this.mapProfileToDisplay(updatedProfile)
      newOriginalSource[index] = updatedProfile

      const {activeQueryParams, inactiveQueryParams} = this.state
      const activeDataSource = this.filterActive(filterDataSource(newDataSource, activeQueryParams))
      const inactiveDataSource = this.filterInactive(filterDataSource(newDataSource, inactiveQueryParams))

      this.setState({
        dataSource: newDataSource,
        originalSource: newOriginalSource,
        filteredActiveDataSource: activeDataSource,
        filteredInactiveDataSource: inactiveDataSource,
        openConfirmationDialog: false,
        currentProfile: null
      })
    }).catch(() => false)
  }

  /*
  * Filter active data source.
  *
  * @param [Object] queryParams. Key value pair containing filters.
  */
  onFilterActive (queryParams) {
    const {dataSource} = this.state
    const newDataSource = this.filterActive(filterDataSource(dataSource, queryParams))
    this.setState({filteredActiveDataSource: newDataSource, activeQueryParams: queryParams})
  }

  /*
  * Filter inactive data source.
  *
  * @param [Object] queryParams. Key value pair containing filters.
  */
  onFilterInactive (queryParams) {
    const {dataSource} = this.state
    const newDataSource = this.filterInactive(filterDataSource(dataSource, queryParams))
    this.setState({filteredInactiveDataSource: newDataSource, inactiveQueryParams: queryParams})
  }

  /*
  * Filter active datasource
  */
  filterActive (dataSource) {
    return dataSource.filter(d => d.activeDate )
  }

  /*
  * Filter inactive datasource
  */
  filterInactive (dataSource) {
    return dataSource.filter(d => !d.activeDate )
  }

  render () {
    return (
      <div className='search-page interest-profiles-view'>

        <h4>Interest Profiles.</h4>
        <p style={{marginBottom: 0}}>Manage interest profiles.</p>
        {this.state.writeAccess &&
          <div className='text-right'>
            <a onClick={this.onAdd.bind(this)}>
              <i className='material-icons orange'>add_circle_outline</i>
            </a>
          </div>
        }

        <h5 style={{marginTop: 0}}> Active Profiles </h5>
        <div style={{minHeight: '210px', maxHeight: '210px', overflowY: 'scroll'}}>
          <TableDisplay
            dataActions={this.actions}
            dataSource={this.state.filteredActiveDataSource}
            disableAdd={true}
            loading={this.state.loading}
            onFilter={this.onFilterActive.bind(this)}
            onRowClick={this.onRowClick.bind(this)}
            pageDescription=''
            pageTitle=''
            tableHeaders={headers}
          />
        </div>

        <h5 style={{marginTop: '2em'}}> Inactive Profiles </h5>
        <div style={{minHeight: '210px', maxHeight: '210px', overflowY: 'scroll'}}>
          <TableDisplay
            dataActions={this.actions}
            dataSource={this.state.filteredInactiveDataSource}
            disableAdd={true}
            loading={this.state.loading}
            onFilter={this.onFilterInactive.bind(this)}
            onRowClick={this.onRowClick.bind(this)}
            pageDescription=''
            pageTitle=''
            tableHeaders={headers}
          />
        </div>

        {this.renderConfirmDialog()}
      </div>
    )
  }
}

export default InterestProfiles
