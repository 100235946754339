/**
 * Super straightforward localization and more!
 *
 * ```
 *  email: {
 *      name: 'email',
 *      type: 'email',
 *      label: 'E-mail',
 *      validator: 'email'
 *  },
 *  date_admission: {
 *      name: 'date_admission',
 *      label: 'Date of Admission',
 *      placeholder: 'Date of Admission',
 *      type: 'month'
 *  }
 * ```
 * TODO Separate the translations from the definitions
 */

const emailTemplate = {
  us: {
    fields: {
      contentSummary: {
        name: 'contentSummary',
        label: "Subject",
        placeholder: 'Name',
      },
      contentKey: {
        name: 'contentKey',
        label: 'Content Key',
        placeholder: 'Content key'
      },
      link: {
        name: 'link',
        label: 'Link',
        placeholder: 'Link'
      },
      contentDocument: {
        name: 'contentDocument',
        label: 'Content Document',
        placeholder: 'Content document'
      },
      defaultTemplate: {
        name: 'defaultTemplate',
        label: 'Default',
        type: 'checkbox'
      },
      optumCorrespondenceId: {
        name: 'optumCorrespondenceId',
        label: 'Optum ID',
        placeholder: 'Optum ID'
      },
      maxLength: {
        name: 'maxLength',
        label: 'Max length',
        placeholder: 'Max content length',
        type: 'number'
      },
      startDate: {
        name: 'startDate',
        type: 'datePicker',
        label: 'Start Date',
        placeholder: 'Start Date',
        returnFormat: 'YYYY-MM-DD'
      },
      endDate: {
        name: 'endDate',
        type: 'datePicker',
        label: 'End Date',
        placeholder: 'End Date',
        returnFormat: 'YYYY-MM-DD'
      }
    },
    hierarchyFields: {
      type: {
        name: 'type',
        label: "Type",
        placeholder: "Select Template Type",
        type: 'dropdown',
        data: {
          EMAIL: 'EMAIL',
          LETTER: 'LETTER'
        }
      },
      contentId: {
        name: 'contentId',
        label: "Template",
        placeholder: 'Select Template (<ContentKey> : <OptumID> : <WelcomeKitNum>)',
        type: 'dropdown',
        data: {}
      }
    }
  }
}

export default emailTemplate
