import React from 'react'
import PropTypes from 'prop-types'
import {FlexTable, TableRow, TableData} from '../FlexTable'

class InputTable extends React.Component {
  constructor (props) {
    super (props)
    this.state = {
      dataSource: []
    }
  }

  /*
  * Push headers to data source
  */
  componentWillMount () {
    this.initializeTable()
  }

  /*
  * Initialize table
  */
  initializeTable () {
    const {tableHeaders, dataSource} = this.props

    const newDataSource= []
    const headers = []

    // Table headers
    tableHeaders.forEach(col => {
      headers.push(col.header())
    })

    // Push 'remove' column
    headers.push('')

    newDataSource.push(headers)

    for (let j = 0; j < dataSource.length; j++) {
      const newRow = []
      for (let i = 0; i < headers.length - 1; i++) {
        newRow[i] = dataSource[j][tableHeaders[i].key]
      }
      newDataSource.push(newRow)
    }

     if (dataSource.length === 0)
      newDataSource.push(this.getEmptyRow())

    this.setState({dataSource: newDataSource})
  }

  /*
  * Render headers
  */
  renderHeaders () {
    const headers = []
    for (let i = 1; i < this.state.dataSource[0].length; i++) {
      if (i !== this.state.dataSource[0].length - 1) {
        headers.push(<TableData key={`table-data-${i}`}>{this.state.dataSource[0][i]}</TableData>)
      } else {
        headers.push(<TableData key={`table-data-${i}`} className='actions' />)
      }
    }

    return (<TableRow key={`table-row-0`} className='header'>{headers}</TableRow>)
  }

  /*
  * Render rows
  */
  renderRows () {
    const rows = []

    for (let i = 1; i < this.state.dataSource.length; i++) {
      rows.push(<TableRow key={`table-row-${i}`}>{this.renderColumns(i)}</TableRow>)
    }

    return rows

  }

  /*
  * Render row columns
  */
  renderColumns (rowIdx) {
    const columns = []

    for (let j = 1; j <= this.state.dataSource[rowIdx].length; j++) {
      if (j !== this.state.dataSource[rowIdx].length) {
        const classes = []
        const disabled = this.props.tableHeaders[j].disabled
        const type = this.props.tableHeaders[j].type || 'text'
        if (disabled) classes.push('disabled')
        columns.push(
          <TableData key={`table-data-${j}`}>
            <input
              className={classes.join(' ')}
              value={this.state.dataSource[rowIdx][j]}
              onChange={(event) => {
                const valid = this.props.tableHeaders[j].validate ? this.props.tableHeaders[j].validate(event.target.value)
                  : true;
                if (valid) {
                  const newDataSource = this.state.dataSource
                  newDataSource[rowIdx][j] = event.target.value
                  this.setState({dataSource: newDataSource})
                }
              }}
              disabled={disabled}
              type={type}
            />
          </TableData>
        )
      } else {
        columns.push(
          <TableData className='actions center' key={`table-data-${j}`}>
            <a style={{backgroundColor: 'white', border: 0, color: 'red', outline: 'none'}} onClick={() => this.removeRow(rowIdx)}>
              <i className="material-icons">indeterminate_check_box</i>
            </a>
          </TableData>
        )
      }
    }

    return columns
  }

  /*
  * Get empty row of data.
  */
  getEmptyRow () {
    const {tableHeaders} = this.props
    return Array(tableHeaders.length).fill('')
  }


  /*
  * Add row
  */
  addRow () {
    const newDataSource = this.state.dataSource
    newDataSource.push(this.getEmptyRow())
    this.setState({dataSource: newDataSource})
  }

  /*
  * Remove row
  */
  removeRow (rowIdx) {
    const newDataSource = this.state.dataSource
    newDataSource.splice(rowIdx, 1)
    this.setState({dataSource: newDataSource})
  }

  /*
  * Exposer method to build array of objects for data
  */
  getData () {
    const {tableHeaders} = this.props

    const data = []
    for (let i = 1; i < this.state.dataSource.length; i++) {
      let row = {}
      for (let j = 0; j < this.state.dataSource[i].length; j++) {
        row[tableHeaders[j].key] = this.state.dataSource[i][j]
      }
      data.push(row)
    }

    return data
  }

  render () {
    return (
      <div>
        <div className='text-right'>
          <a onClick={this.addRow.bind(this)}>
            <i className='material-icons orange'>add_circle_outline</i>
          </a>
        </div>
        <FlexTable>
          {this.renderHeaders()}
          {this.renderRows()}
        </FlexTable>
      </div>
    )
  }
}

InputTable.propTypes = {
  dataActions: PropTypes.array,
  dataSource: PropTypes.array,
  tableHeaders: PropTypes.array
}

export default InputTable
