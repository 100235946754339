import Fetch from 'lib/fetch'

/**
 * Fetches enrollments by employee and employer IDs from the Express-Enrollment-Service.
 * @param {employeeId, employerId}
 * @returns enrollment record list
 */
export function getEnrollmentsByEmployeeAndEmployerIds (employeeId, employerId) {
    return Fetch.get(`${process.env.REACT_APP_ENROLLMENT_DOMAIN}/enrollments/list/employee-id/${employeeId}/employer-id/${employerId}`, null)
        .then(data => data)
        .catch(error => Promise.reject(error))
}

/**
 * Fetches enrollment by ID from the Express-Enrollment-Service.
 * @param {id} id 
 * @returns enrollment record
 */
export function getEnrollmentByEnrollmentId (enrollmentId) {
    return Fetch.get(`${process.env.REACT_APP_ENROLLMENT_DOMAIN}/enrollments/status/${enrollmentId}`, null)
        .then(data => data)
        .catch(error => Promise.reject(error))
}

/**
 * Reprocess enrollment by ID in the Express-Enrollment-Service.
 * @param {id} id 
 * @returns enrollment record
 */
export function reprocessEnrollment (enrollmentId) {
    return Fetch.get(`${process.env.REACT_APP_ENROLLMENT_DOMAIN}/enrollments/process/${enrollmentId}`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}