import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Utils from 'lib/utils'
import Valid from 'lib/valid'

// Styles --------------------------------
import 'components/forms/Field/field.scss'


/**
 * TODO refactor errors -to-> messages
 * They are used more like messages than exclusively errors and have an
 * identifier that makes them an error....
 */
class Field extends Component {

  static propTypes = {
    attrs: PropTypes.object,
    className: PropTypes.string,
    data: PropTypes.object,
    disabled: PropTypes.bool,
    events: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    messages: PropTypes.array,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    required: PropTypes.bool,
    type: PropTypes.string.isRequired
  }

  static defaultProps = {
    attrs: {},
    className: '',
    data: {},
    disabled: false,
    events: {},
    label: undefined,
    messages: [],
    placeholder: undefined,
    required: false,
    type: 'text'
  }


  /**
   * Get the placeholder text that should be
   * used for the component. If the prop
   * disables the placeholder an empty string
   * is returned, otherwise it is derived from
   * the name or explicitly by the prop
   *
   * @return {String}
   */
  getPlaceholder() {
    const { label, name, placeholder } = this.props

    switch (placeholder) {
      case false:
        return ''
      case undefined:
        return Utils.String.toTitleCase( label ? label : name )
      default:
        return placeholder
    }
  }


  /**
   * Gets the most severe message name from
   * the messages array
   *
   * @return {String}
   */
  getMessageLvl() {
    const { messages } = this.props
    if (messages.length === 0) return ''

    return Valid.getMessageLvl(Valid.utils.sortMessages(messages)[0][0])
  }


  /**
   * Render a simple label
   *
   * @params {String} label
   * @params {String} name
   * @return {null | JSX}
   */
  renderLabel(label, name) {
    if ( label === false ) return null
    const txt = label ? label : Utils.String.toTitleCase(name)

    return (<label className='input-label' htmlFor={name}>{txt}</label>)
  }


  /**
   * Render messages for a field
   *
   * @params {Array} messages list
   * @return {JSX}
   */
  renderMessages(messages=[]) {
    const errs = Valid.utils.groupMessages(messages)

    if ( errs.length === 0 ) return null

    const lvl = this.getMsgLvl()
    const err = errs[0].map( (e, idx) =>
      <span className={`message ${Valid.getErrLvl(e[0])}`} key={`err-${idx}`}>{e[1]}</span>
    )

    return <span className={`feedback feedback-${lvl}`}>{err}</span>
  }


  /**
   * Render a simple dropdown field
   *
   * @return {JSX}
   */
  renderDropdown() {
    const {attrs, data, events, disabled, name, placeholder, required, value} = this.props

    return (
      <select
          ref={(component) => {this.input = component}}
          disabled={disabled}
          id={name}
          name={name}
          required={required}
          value={value}
          {...attrs}
          {...events}>

        {placeholder !== false
          ? <option className="hide-option"
                  key="placeholder"
                  value="">{this.getPlaceholder()}</option>
          : null}

        {Object.keys(data).map((k) => {
          const key = Utils.String.toCleanSlug(k)
          // allow for the option of options being disabled
          return <option key={key} value={k} disabled={data[k].disabled}>{data[k].name || data[k]}</option>
        })}
      </select>
    )
  }


  /**
   * Render a simple text input
   *
   * @return {JSX}
   */
  renderInput() {
    const {attrs, events, disabled, type, name, required, value} = this.props

    return (
      <input
        ref={(component) => {this.input = component}}
        disabled={disabled}
        placeholder={this.getPlaceholder()}
        id={name}
        name={name}
        required={required}
        type={type}
        value={value}
        {...attrs}
        {...events} />
    )
  }

  /**
   * Render a simple text area
   *
   * @return {JSX}
   */
  renderTextArea() {
    const {attrs, events, disabled, type, name, required, value} = this.props

    return (
      <textarea
        ref={(component) => {this.input = component}}
        disabled={disabled}
        placeholder={this.getPlaceholder()}
        id={name}
        name={name}
        required={required}
        type={type}
        value={value}
        {...attrs}
        {...events} />
    )
  }


  /**
   * Render a simple field component...
   *
   * @return {JSX}
   */
  render() {
    const { className, type, label, name, messages } = this.props
    const classNames = [className, 'form-control', Utils.String.toClassName(name)]

    if (messages.length > 0) classNames.push(`has-${this.getMsgLvl()}`)

    return (
      <div className={classNames.join(' ')}>
        {this.renderLabel(label, name)}

        {(() => {
          switch(type) {
            case 'date':
            case 'month':
            case 'email':
            case 'number':
            case 'password':
            case 'tel':
            case 'text': 
            case 'checkbox':
              return this.renderInput()
            case 'textarea':
              return this.renderTextArea()
            case 'dropdown':
              return this.renderDropdown()

            default:
              return null
          }
        })()}

        {this.renderMessages(messages)}
      </div>
    )
  }
}

export default Field
