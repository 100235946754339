
export function getSponsorNameFromList (list) {
    if (list) {
        for (var i = 0; i < list.length; i++) {
            //7301 indicates sponsor level
            if(list[i].hierarchyType === 7301) {
                return list[i].name;
            }
        }
        return 'N/A';
    }
}

export function getSponsorIdFromList (list) {
    if (list) {
        for (var i = 0; i < list.length; i++) {
            //7301 indicates sponsor level
            if(list[i].hierarchyType === 7301) {
                return list[i].id;
            }
        }
        return 'N/A';
    }
}

export function getDistributorNameFromList (list) {
    if (list) {
        for (var i = 0; i < list.length; i++) {
            //7302 indicates distributor level
            if(list[i].hierarchyType === 7302) {
                return list[i].name;
            }
        }
        return 'N/A';
    }
}

export function getDistributorIdFromList (list) {
    if (list) {
        for (var i = 0; i < list.length; i++) {
            //7302 indicates distributor level
            if(list[i].hierarchyType === 7302) {
                return list[i].id;
            }
        }
        return 'N/A';
    }
}

export function getEmployerNameFromList (list) {
    if (list) {
        for (var i = 0; i < list.length; i++) {
            //7303 indicates Employer level
            if(list[i].hierarchyType === 7303) {
                return list[i].name;
            }
        }
        return 'N/A';
    }
}

export function getEmployerIdFromList (list) {
    if (list) {
        for (var i = 0; i < list.length; i++) {
            //7303 indicates Employer level
            if(list[i].hierarchyType === 7303) {
                return list[i].id;
            }
        }
        return 'N/A';
    }
}
