import Fetch from 'lib/fetch'

/**
 * GET request for notification records.
 * @param {*} form 
 * @returns list of notifications based on search parameters in form.
 */
export function getNotificationRequeue(form) {
    let queryParams = Object.keys(form).filter(key => form[key]).map(key => {
        return [key, form[key]].map(encodeURIComponent).join("=");
    }).join("&");
    return Fetch.get(`${process.env.REACT_APP_NOTIFICATION_DOMAIN}/notification/request/search?${queryParams}`, null)
          .catch(error => Promise.reject(error));
}

/**
 * GET request for requeuing single notification.
 * @param {*} requestId 
 * @returns Response
 */
export function requeueSingleNotification(requestId) {
    return Fetch.get(`${process.env.REACT_APP_NOTIFICATION_DOMAIN}/notification/send/requeue/${requestId}`, null)
                .then(data => {
                  return data
                })
                .catch(error => Promise.reject(error));
}

/**
 * POST request for requeuing multiple notifications.
 * @param {*} requestIdList 
 * @returns Response
 */
export function requeueMultipleNotifications(requestIdList) {
  return Fetch.post(`${process.env.REACT_APP_NOTIFICATION_DOMAIN}/notification/send/requeue`, requestIdList, null)
              .then(data =>{ return data})
              .catch(error => Promise.reject(error));
}