import React from "react";
import { hasRole } from "../../../../redux/utility/SessionUtil";
import hsaxsetup from "data/hsaxsetup";
import Field from "components/forms/Field/index";
import Card from "components/Card";
import actions from "actions";
import Loading from "components/Loading";
import Button from "components/Button";
import AutoCompleteField from "components/forms/AutoCompleteField";
import Modal from 'components/Modal';
import { showSnackbar } from "../../../../redux/utility/SnackbarUtil";
import { maskCurrency } from "lib/mask";

class HsaxSetupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initializeState();
  }

  componentWillMount() {
    if(this.verifyAccess()) {
      const {match: {params: {tokenString}}} = this.props
      this.setState({tokenString: tokenString})
      if(tokenString !== 'undefined'){
        this.getHsaxEmployer(tokenString)
      }
      this.getUIVersion();
    }
  }
  
  setCheckboxFields(employer) {
    this.setState({
      achFunding: employer.fundingConfigurations.includes("ACH"),
      debitFunding: employer.fundingConfigurations.includes("DEBIT_CARD"),
      debitForPaymentFunding: employer.fundingConfigurations.includes("DEBIT_CARD_FOR_PAYMENT"),
      form: {
        ...this.state.form,
        achFunding: employer.fundingConfigurations.includes("ACH"),
        debitFunding: employer.fundingConfigurations.includes("DEBIT_CARD"),
        debitForPaymentFunding: employer.fundingConfigurations.includes("DEBIT_CARD_FOR_PAYMENT"),
        minimumFundingAmount: employer.minimumFundingAmount
      }
    })
  }

  /*
  * Fetch hsax employer by id.
  */
 getHsaxEmployer(token) {
  this.setState({loading: true})
  actions.hsax.getHsaxEmployer(token).then(employer => {
    this.setCheckboxFields(employer)
    this.setState({
      version: employer.uiVersion,
      minimumFundingAmount: employer.minimumFundingAmount,
      fundingConfigurations: employer.fundingConfigurations,
      employer: employer.name,
      loading: false,
      isEditForm: true
    })
  }).catch(() => this.setState({loading: false}))
}

  setEmployerIds() {
    this.setState({ loadingEmployers: true });
    actions.hsax.getAllHsaxEmployers().then(employers => {
      const employerIds = employers.map(employer => {
        const { employerId } = employer;
        return {
          employerId
        };
      });
      this.setState({
        loadingEmployers: false,
        employerIds: employerIds.map((e) => e.employerId)
      });
    })
    .catch(() => this.setState({
      employerIds: [],
      loadingEmployers: false
    }));

  }

  /*
   * Initialize State
   */
  initializeState() {
    return {
      form: this.initializeFormData(),
      submitting: false,
      employerText: "",
      employerDataSource: [],
      showSuccessModal: false,
      readAccess: hasRole("HSAXERSetup"),
      writeAccess: hasRole("HSAXERSetup"),
      //Employers taken from HsaxSetup - We need to make sure that this page
      //is hit after coming in from HsaxSetup
      employerIds: this.props.location.state !== undefined ?
          this.props.location.state.employerIds : this.setEmployerIds(),
      fundingConfigurations: [],
      versionOptions: {},
      minimumFundingAmount: "",
      tokenString: ''.replace,
      employerName: this.props.location.state !== undefined ? this.props.location.state.employerName : '',
      isEditForm: false,
      employerId: this.props.location.state !== undefined ? this.props.location.state.employerId : ''
    };
  }

  /*
  * Verify that the user has access to account maintenance
  */
 verifyAccess () {
  if (!(this.state.readAccess || this.state.writeAccess)) {
    this.props.history.push('/nbtadmin/unauthorized')
    return false
  }

  return true
}

  /*
  * initialize all of the form fields
  */
  initializeFormData(data) {
    return {
      employerId: (this.props.location.state !== undefined && this.props.location.state.isEditForm === true) ? this.props.location.state.employerId : '',
      version: "",
      fundingConfigurations: [],
      minimumFundingAmount: "",
      debitFunding: false,
      debitForPaymentFunding: false,
      allowSkipFunding: false,
      achFunding: false
    };
  }

  /*
   * Fetch employers
   */
  getEmployers() {
    this.setState({ loadingEmployers: true });
    actions.employers
      .getEmployers({ employerName: this.state.employerText })
      .then(employers => {
        const mappedEmployers = employers.map(employer => {
          const { id, name } = employer;
          return {
            id,
            name
          };
        });

        this.setState({
          employerDataSource: mappedEmployers,
          loadingEmployers: false
        });
      })
      .catch(() => this.setState({ loadingEmployers: false }));
  }

  /*
  * Get UI Versions
  */
  getUIVersion() {
    this.setState({ loadingVersion: true });
    actions.hsax
    .getUIVersion()
    .then(versions => {
      const versionOptions = {};
      versions.map(version => {
        versionOptions[version] = version;
      });
      this.setState({
        loadingVersion: false,
        versionOptions: versionOptions
      });
    })
    .catch(() => this.setState({ loadingVersion: false }));
  }

  /*
   * Render row of autocomplete
   */
  renderAutoCompleteRow(data, index, resetState) {
    return (
      <div
        className="autocomplete-row"
        onClick={() => {
          this.onChange("employerId", data.id);
          this.setState({ employerText: data.name, employerDataSource: [] });
        }}
      >
        {data.name}
      </div>
    );
  }

  /*
   * Update form values
   *
   * @param [String] name. Field name of form to update.
   * @param [Any] value. Value.
   */
  onChange(name, value) {
    let form = { ...this.state.form };
    form[name] = value;
    this.setState({ form });
  }

  onCloseModal() {
    const { history } = this.props
    this.setState({ showSuccessModal: false });
    history.push(`/nbtadmin/hsax-setup`)
  }

  getFields() {
    const { fields } = hsaxsetup.us;
    const {
      form: { version, minimumFundingAmount, achFunding,
        debitFunding, debitForPaymentFunding }, versionOptions,
    } = this.state;
    let f = [
      {
        ...fields.version,
        required: true,
        events: {
          onChange: event => {
            this.setState({ version: event.target.value })
            this.onChange(event.target.name, event.target.value);
          }
        },
        data: versionOptions,
        value: this.state.isEditForm ? this.state.version : version
      },
      {
        ...fields.minimumFundingAmount,
        required: true,
        events: {
          onChange: event => {
            const newValue = maskCurrency(event.target.value);
            this.onChange(event.target.name, newValue);
          }
        },
        type: "number",
        value: minimumFundingAmount
      },
      {
        ...fields.achFunding,
        events: {
          onChange: event => {
            this.onChange(event.target.name, event.target.checked);
          },
          defaultChecked: this.state.achFunding
        },
        type: "checkbox",
        value: this.state.isEditForm ? null : achFunding
      },
      {
        ...fields.debitFunding,
        events: {
          onChange: event => {
            this.onChange(event.target.name, event.target.checked);
          },
          defaultChecked: this.state.debitFunding
        },
        type: "checkbox",
        value: this.state.isEditForm ? null : debitFunding
      },
      {
        ...fields.debitForPaymentFunding,
        events: {
          onChange: event => {
            this.onChange(event.target.name, event.target.checked);
          },
          defaultChecked: this.state.debitForPaymentFunding
        },
        type: "checkbox",
        value: this.state.isEditForm ? null : debitForPaymentFunding
      }
    ];

    return f;
  }

  handleEmployerCheck(value) {
    if(this.state.isEditForm === true) {
      return false
    } else {
      return (this.state.employerIds.indexOf(value) > -1)
    }
  }

  /**
   * On submit
   */
  handleSubmit(event) {
    event.preventDefault();
    this.setState({submitting: true})
    let fundingConfigurationCheck = false;
    if (this.state.form.debitForPaymentFunding !== true &&
        this.state.form.debitFunding !== true &&
        this.state.form.achFunding !== true) {
      fundingConfigurationCheck = true;
    }
    this.state.fundingConfigurations = []
    if(this.state.form.debitForPaymentFunding === true) this.state.fundingConfigurations.push("DEBIT_CARD_FOR_PAYMENT")
    if(this.state.form.debitFunding === true) this.state.fundingConfigurations.push("DEBIT_CARD")
    if(this.state.form.achFunding === true) this.state.fundingConfigurations.push("ACH")
    let form = {
      employerId: this.state.isEditForm ? this.state.employerId : this.state.form.employerId,
      uiVersion: Number(this.state.form.version),
      fundingConfiguration: 'ACH',
      fundingConfigurations: this.state.fundingConfigurations,
      minimumFundingAmount: Number(this.state.form.minimumFundingAmount)
    }
    if(this.state.isEditForm === true) form.token = this.state.tokenString
    if(this.handleEmployerCheck(form.employerId)) {
      this.setState({submitting: false})
      showSnackbar('Employer selected is already configured for HSAX')
    } else if(form.minimumFundingAmount < 0) {
      this.setState({submitting: false})
      showSnackbar('Individual Minimum Funding must be a valid amount and cannot go below $0')
    } else if (fundingConfigurationCheck) {
      this.setState({submitting: false})
      showSnackbar('Funding Configuration is required')
    } else{
      if (this.state.isEditForm === true) {
        actions.hsax
        .editHsaxEmployerConfiguration(form)
        .then(() => {
          this.setState({submitting: false, showSuccessModal: true});
        })
        .catch(error => {
          this.setState({submitting: false})
          showSnackbar(
              'There was an error processing your request')
        });
      } else {
        actions.hsax
        .postHsaxEmployerConfiguration(form)
        .then(() => {
          this.setState({submitting: false, showSuccessModal: true});
        })
        .catch(error => {
          this.setState({submitting: false})
          showSnackbar(
              'There was an error processing your request')
        });
      }
    }
  }

  goBack() {
    const {history} = this.props
    history.push(`/nbtadmin/hsax-setup`)
  }

  /*
   * Open success modal
   */
  renderSuccessModal() {
    const { showSuccessModal, isEditForm } = this.state
    if (showSuccessModal) {
      return (
        <Modal
          header={"Success"}
          onClose={this.onCloseModal.bind(this)}
          showClose={true}
        >
          <div className='modal-body'>
            <div className='margin-top margin-bottom'>
            {isEditForm ?
                <p>Employer successfully modified!</p> :
                <p>Employer successfully created!</p>
            }
            </div>
          </div>
          <div className='modal-footer'>
            <div className='modal-actions'>
              <button
                className='button primary-button'
                onClick={this.onCloseModal.bind(this)}
              >Proceed to HSAX Setup</button>
            </div>
          </div>
        </Modal>
      );
    }
  }

  render() {
    const { fields } = hsaxsetup.us;
    const isEditForm = this.state.isEditForm
    const employerField = {
      ...fields.employerId,
      required: true,
      name: "employerText",
      events: {
        onChange: (name, value) => {
          if (value.length < this.state.employerText.length)
            this.onChange("employerId", "");
          this.setState({ employerText: value });
        }
      },
      dataValue: this.state.form.employerId,
      value: this.state.employerText
    };
    if (this.state.loading || this.state.loadingVersion)
      return <div className='margin-top'><Loading/></div>
    return (
      <div className="page submit-hsax-er-configuration">
        {isEditForm && 
        <h4>Edit</h4>
        }
        <h4>HSAX Employer Configuration</h4>
        <i className="material-icons" onClick={this.goBack.bind(this)}>{"chevron_left"}</i>
        <form onSubmit={this.handleSubmit.bind(this)} className="fee-form">
          <Card className="padding">
          {this.state.isEditForm ? 
            <Field
              required={true}
              label='Employer'
              name='employerName'
              placeholder='Employer name'
              value={this.state.employerName}
              disabled  
            /> : 
            <AutoCompleteField
              {...employerField}
              dataSource={this.state.employerDataSource}
              limit={20}
              onSearch={this.getEmployers.bind(this)}
              renderRow={this.renderAutoCompleteRow.bind(this)}
            />
          }
            {this.getFields().map((f, idx) => {
              return (
                <Field className="margin-top" key={`${f.name}-${idx}`} {...f} />
              );
            })}
          </Card>
          <Button
            className="primary-button margin-top"
            disabled={this.state.submitting}
            type="submit"
          >
            <span>
              {this.state.submitting ? (
                <Loading containerClassName="inline" className="white small" />
              ) : (
                isEditForm ? "Edit" : "Submit"
              )}
            </span>
          </Button>
        </form>
        {this.renderSuccessModal()}
      </div>
    );
  }
}

export default HsaxSetupForm;
