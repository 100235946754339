const transferConsent = {
  us: {
    fields: {
      transferInKind: {
        name: 'transferInKind',
        label: 'Transfer In Kind',
        type: 'dropdown',
        data: {
          yes : {
            name: 'Yes',
            disabled: false
          },
          no : {
            name: 'No',
            disabled: false
          }
        }
      }
    }
  }
}

export default transferConsent