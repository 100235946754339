// import 'isomorphic-fetch'
import jwtDecode from 'jwt-decode'
import { getSession, updateAuth } from '../redux/utility/SessionUtil'
import { showSnackbar } from '../redux/utility/SnackbarUtil'

/*
* Centralize fetch library.
*/
class RequestLibrary {
  constructor () {
    this.parseResponse = this.parseResponse.bind(this)
  }

  getHeaders (token) {
    let header = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    }
    return header
  }

  getMultipartHeaders (token) {
    let header = {
      "Authorization": `Bearer ${token}`
    }
    return header
  }

  /*
  * Get request.
  *
  * @param [String] endpoint. Url endpoint.
  * @param [String] errMsg. Error message to show if request fails.
  */
  get (endpoint, errMsg) {
    return this.assertToken().then(token => {
      return fetch(`${endpoint}`, {
        method: 'GET',
        headers: this.getHeaders(token)
      })
        .then(response => this.parseResponse(response))
        .then((data) => data)
        .catch((error) => {
          if (error.status !== 401 && errMsg) showSnackbar(errMsg)
          return Promise.reject(error)
        })
    }).catch(error => Promise.reject(error))
  }

  /*
* Get File request.
*
* @param [String] endpoint. Url endpoint.
* @param [String] errMsg. Error message to show if request fails.
*/
  getFile (endpoint, errMsg) {
    return this.assertToken().then(token => {
      return fetch(`${endpoint}`, {
        method: 'GET',
        headers: this.getHeaders(token)
      })
      .then(response => response)
      .catch((error) => {
        if (error.status !== 401 && errMsg) showSnackbar(errMsg)
        return Promise.reject(error)
      })
    }).catch(error => Promise.reject(error))
  }

  /*
	* Post request.
	*
	* @param [String] endpoint. Url endpoint.
	* @param [Object] form. Body of request.
	* @param [String] errMsg. Error message to show if request fails.
	*/
  post (endpoint, form, errMsg) {
    return this.assertToken().then(token => {
      return fetch(`${endpoint}`, {
        method: 'POST',
        headers: this.getHeaders(token),
        body: JSON.stringify(form)
      })
        .then(response => this.parseResponse(response))
        .then((data) => data)
        .catch((error) => {
          if (error.status !== 401 && errMsg) showSnackbar(errMsg)
          return Promise.reject(error)
        })
    }).catch(error => Promise.reject(error))
  }

  /**
   * Enhanced version of `fetch#post` function.
   * This will return the parsed reponse object for both success/error response stream.
   *
   * @param {string} endpoint
   * @param {Object} form
   * @param {string} errMsg
   * @returns {*} - the response object
   */
  postV2(endpoint, form, errMsg) {
    return this.assertToken().then(token => {
      return fetch(`${endpoint}`, {
        method: 'POST',
        headers: this.getHeaders(token),
        body: JSON.stringify(form)
      })
        .then(response => this.parseResponseV2(response))
        .then(data => data)
        .catch((error) => {
          if (error.status !== 401 && errMsg) showSnackbar(errMsg)
          return Promise.reject(error)
        })
    }).catch(error => Promise.reject(error))
  }

  /*
  * Put request.
  *
  * @param [String] endpoint. Url endpoint.
  * @param [Object] form. Body of request.
  * @param [String] errMsg. Error message to show if request fails.
  */
  put (endpoint, form, errMsg) {

    return this.assertToken().then(token => {
      return fetch(`${endpoint}`, {
        method: 'PUT',
        headers: this.getHeaders(token),
        body: JSON.stringify(form)
      })
        .then(response => this.parseResponse(response))
        .then((data) => data)
        .catch((error) => {
          if (error.status !== 401 && errMsg) showSnackbar(errMsg)
          return Promise.reject(error)
        })
    }).catch(error => Promise.reject(error))

  }

  /*
	* Post request for upload batch transactions.
	*
	* @param [String] endpoint. Url endpoint.
	* @param [Object] form. Body of request.
	*/
  postMultipartBatch (endpoint, form) {
    const checkStatus = function(response) {
      const hasError = (response.status < 200 || response.status >= 300)
      if (hasError) {
        throw response.text()
      }
      return response
    }
    return this.assertToken().then(token => {
      return fetch(`${endpoint}`, {
        method: 'POST',
        headers: this.getMultipartHeaders(token),
        body: form
      })
      .then(checkStatus);
  })
}

  /*
	* Post request.
	*
	* @param [String] endpoint. Url endpoint.
	* @param [Object] form. Body of request.
	* @param [String] errMsg. Error message to show if request fails.
	*/
  postMultipart (endpoint, form, errMsg) {
    return this.assertToken().then(token => {
      return fetch(`${endpoint}`, {
        method: 'POST',
        headers: this.getMultipartHeaders(token),
        body: form
      })
        .then(response => this.parseResponse(response))
        .then((data) => data)
        .catch((error) => {
          if (error.status !== 401 && errMsg) showSnackbar(errMsg)
          return Promise.reject(error)
        })
    }).catch(error => Promise.reject(error))
  }

  /*
  * Delete request.
  *
  * @param [String] endpoint. Url endpoint.
  * @param [String] errMsg. Error message to show if request fails.
  */
  delete (endpoint, errMsg) {
    return this.assertToken().then(token => {
      return fetch(`${endpoint}`, {
        method: 'DELETE',
        headers: this.getHeaders(token)
      })
        .then(response => this.parseResponse(response))
        .catch((error) => {
          if (error.status !== 401 && errMsg) showSnackbar(errMsg)
          return Promise.reject(error)
        })
    }).catch(error => Promise.reject(error))
  }

  /*
  * Assert the auth token.
  * If the current auth token is expired, try to get a refresh token. Otherwise
  * reject call.
  *
  */
  assertToken () {
    return new Promise((resolve, reject) => {
      if (getSession().expires > Date.now()) {
        resolve(getSession().authToken)
      } else {
        this.refreshToken()
        .then(token => resolve(token))
        .catch((error) => reject(error))
      }
    })
  }

  /**
   * Decode JWT token and return data in Redux state format.
   * 
   * @param {*} userServiceResponse 
   * @returns Auth state to be updated.
   */
  decodeToken(authResponse) {
    const decodedJWT = jwtDecode(authResponse.access_token)
    return {
      authToken: authResponse.access_token,
      refreshToken: getSession().refreshToken,
      username: decodedJWT.sub,
      roles: decodedJWT.roles,
      expires: decodedJWT.exp * 1000
    }
  }

  /**
   * Currently in Prod, tokens are refreshed using refresh token fetched from cookies in client.
   * For local development, we are using username and password instead to fetch new tokens.
   * 
   */
  fetchJwtToken() {
    return fetch(`${process.env.REACT_APP_AUTH_DOMAIN}/users/authenticate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        username: process.env.REACT_APP_DEV_USERNAME,
        password: process.env.REACT_APP_DEV_PASSWORD
      })
    })
    .then(response => this.parseResponse(response))
    .then(data => {
      updateAuth(this.decodeToken(data))
      return data.access_token
    });
  }

  /*
  * Try and refresh token.
  */
  refreshToken() {
    if (!process.env.REACT_APP_DEV) {
      const form = {
        refresh_token: getSession().refreshToken
      }

      return fetch(`${process.env.REACT_APP_AUTH_DOMAIN}/jwt/refresh`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(form)
      })
      .then(response => this.parseResponse(response))
      .then(data => {
        updateAuth(this.decodeToken(data))
        return data.access_token
      })
      .catch((error) => {
        //TODO: logout?
        window.location = `${process.env.REACT_APP_PORTAL_DOMAIN}`
        return Promise.reject(error)
      })
    } else {
      // Currently used only for local development.
      return this.fetchJwtToken()
    }
  }

  /*
  * Function to check if http request was successful
  *
  * @param [Object] response. Http response from server.
  * @return [Object] response. Http response from server.
  */
  parseResponse (response) {
    if (response.status >= 200 && response.status < 300) {
      return response.json().then(res => {
        return res
      }).catch(() => {
        return response
      })
    } else if (response.status === 401) {
      throw response
    } else {
      return response.json().then(res => {
        if (res.message) {
          showSnackbar(res.message)
        } else {
          showSnackbar('There was an error connecting to our servers. Verify you are connected to the internet and try again.')
        }
        throw response
      }).catch(() => {
        throw response
      })
    }
  }

  /**
   * Gets the response object from response stream. Reject the promise for the error status.
   *
   * @param {Promise} response stream
   * @returns {*} response object
   */
   parseResponseV2 (response) {
    const json = response.json()
    if (response.status >= 200 && response.status < 300) {
      return json.then(res => res)
    } else {
      return json.then(error => Promise.reject(error))
    }
  }

}

const Fetch = new RequestLibrary()
export default Fetch
