import React from "react";
import TransferDateForm from './TransferDateForm'
import UrlExpirationDateForm from './UrlExpirationDateForm'
import EmployerNameForm from './EmployerNameForm'
import ConfirmGenerationForm from "./ConfirmGenerationForm";
import actions from "../../../../actions";
import Loading from "../../../../components/Loading";
import Card from "../../../../components/Card";
import TextField from "../../../../components/TextField";

class CustodianUrlGenerationForm extends React.Component {
  constructor(props){
    super(props)
    this.nextPage = this.nextPage.bind(this)
    this.getChildFormState = this.getChildFormState.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.state = {
      page: 1,
      transferDate: null,
      urlExpirationDate: null,
      employerName: null,
      employerId: null
    }
  }

  componentWillMount() {
    this.getStoredConsentForm()
  }

  getStoredConsentForm() {
    const {match: {params: {formId}}} = this.props
    this.setState({loading: true})
    actions.consentForms.getConsentFormsById(formId).then(consentForm => {
      this.setState({consentForm, loading: false})
    }).catch(() => {
      this.setState({loading: false})
    })
  }
  
  nextPage() {
    this.setState({page: this.state.page + 1})
  }
  
  getChildFormState(name, value){
    this.setState({[name]: value})
  }
  
  submitForm(event) {
    event.preventDefault()
    let consentUrl = {
      'employerName': this.state.employerName,
      'employerId': this.state.employerId,
      'transferDate': this.state.transferDate,
      'urlExpirationDate': this.state.urlExpirationDate,
      'consentFormId': this.state.consentForm.id
    }
    actions.consentForms.createConsentUrl(consentUrl).then((createdUrl) => {
      const {history} = this.props
      this.setState({submitting: false})
      history.push(`/nbtadmin/custodian-consent/generated-url/view/${createdUrl.id}`)
    }).catch(() => { this.setState({submitting: false}) })
  }

  renderPartialConsentForm() {
    const {custodianName, displayTransferInKind} = this.state.consentForm
    return (
        <div className='partial-consent-form'>
          <h5>Generate Consent URL</h5>
          <div className='row'>
            <div className='col'>
              <TextField
                  label='Custodian Name'
                  name='custodianName'
                  value={custodianName}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <TextField
                  label='Transfer In Kind'
                  name='displayTransferInKind'
                  value={displayTransferInKind}
              />
            </div>
          </div>
        </div>
    )
  }
  
  render() {
    if (this.state.loading) {
      return <div className='margin-top'><Loading/></div>
    }
    const{page} = this.state
    return(
        <Card className='padding'>
          <div>
            {this.renderPartialConsentForm()}
            {page === 1 && <TransferDateForm getChildFormState={this.getChildFormState} 
                                             onSubmit={this.nextPage}/>}
            {page === 2 && <UrlExpirationDateForm getChildFormState={this.getChildFormState}
                                               onSubmit={this.nextPage}
                                               transferDate={this.state.transferDate}/>}
            {page === 3 && <EmployerNameForm getChildFormState={this.getChildFormState}
                                             onSubmit={this.nextPage}
                                             transferDate={this.state.transferDate}
                                             urlExpirationDate={this.state.urlExpirationDate}/>}
            {page === 4 && <ConfirmGenerationForm employerName={this.state.employerName}
                                                  transferDate={this.state.transferDate}
                                                  urlExpirationDate={this.state.urlExpirationDate}
                                                  onSubmit={this.submitForm}/>
            }
          </div>
        </Card>
    )
  }
}

export default CustodianUrlGenerationForm