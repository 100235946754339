import React from 'react'
import Card from 'components/Card'
import Loading from 'components/Loading'
import TextField from 'components/TextField'
import actions from 'actions'
import { hasRole } from '../../../../redux/utility/SessionUtil'

class HsaxEditForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      employer: null,
      readAccess: hasRole('HSAXERSetup'),
      writeAccess: hasRole('HSAXERSetup')

    }
  }

  /*
  * Get email template by id .
  */
  componentWillMount () {
    if (this.verifyAccess()) {
      this.getHsaxEmployer()
    }
  }

  /*
  * Verify that the user has access to email templates
  */
  verifyAccess () {
    if (!(this.state.readAccess || this.state.writeAccess)) {
      this.props.history.push('/nbtadmin/unauthorized')
      return false
    }

    return true
  }

  /*
  * Fetch hsax employer by id.
  */
  getHsaxEmployer() {
    const {match: {params: {token}}} = this.props

    this.setState({loading: true})
    actions.hsax.getHsaxEmployer(token).then(employer => {
      this.setState({employer, loading: false})
    }).catch(() => this.setState({loading: false}))
  }

  /*
  * Render the actions menu
  */
  renderActionsMenu () {
    if (this.state.writeAccess) {
      return (
        <div className='text-right'>
          <a onClick={this.onEdit.bind(this)}>
            <i className='material-icons orange' style={{fontSize: '1.2em'}}>edit</i>
          </a>
        </div>
      )
    }
  }

  /*
  * On edit
  */
  onEdit () {
    const {history} = this.props
    history.push(`/nbtadmin/email-templates/${this.state.template.contentId}/edit`)
  }

  render () {
    if (this.state.loading) return <div className='margin-top'><Loading/></div>
    const {
      employerId,
      name,
      uiVersion
    } = this.state.employer

    return (
      <div className='page email-template-view'>
        <h4>{`${name}`}</h4>
        <Card className='padding'>
          {this.renderActionsMenu()}
          <h5>Details</h5>
          <div className='row'>
            <div className='col'>
              <TextField
                label='Subject'
                name='name'
                value={employerId}
              />
            </div>
            <div className='col'>
              <TextField
                label='Key'
                name='key'
                value={name}
              />
            </div>
          </div>
          <div className='row margin-top'>
            <div className='col'>
              <TextField
                label='Link'
                name='link'
                value={uiVersion}
              />
            </div>
          </div>
        </Card>
      </div>
    )
  }
}

export default HsaxEditForm
