import React from 'react'
import Field from "../../../../components/forms/Field";
import PropTypes from 'prop-types'

class EmployerSearch extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      form: this.initializeFormData()
    }
  }

  initializeFormData(){
    return {
      employerName: ''
    }
  }

  onChange(name, value){
    let form = {...this.state.form}
    form[name] = value
    this.setState({form})
  }

  handleEmployerSearch(event) {
    event.preventDefault()
    if (this.validateForm()) {
      this.props.onSubmit(this.state.form).then(() => {
      }).catch(() => false)
    }
  }

  validateForm(){
    const{employerName} = this.state.form
    return employerName
  }

  render(){
    const {form} = this.state
    return(
        <div>
          <form className='' onSubmit={this.handleEmployerSearch.bind(this)}>
            <Field
                name='employerName'
                required={true}
                label='Find Employer'
                placeholder='Name'
                value={form.employerName}
                events={
                  {
                    onChange: (event) => {this.onChange('employerName', event.target.value)}
                  }
                }
            />
            <button style={{width: 'auto', display: 'block', marginLeft: 'auto'}}
                    className='button margin-top primary-button'
                    type='submit'
            >Find Employer </button>
          </form>
        </div>
    )
  }
}

EmployerSearch.propTypes = {
  onSubmit: PropTypes.func
}

export default EmployerSearch