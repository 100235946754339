import React from "react";
import actions from "../../../../actions";
import Card from "../../../../components/Card";
import Field from "../../../../components/forms/Field";
import Button from "../../../../components/Button";
import Loading from "../../../../components/Loading";
import consentType from "../../../../data/consentType";
import PropTypes from "prop-types";

class ExportConsent extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      consentType: null,
      submitting: false,
    }
  }

  initializeFormData() {
    return {
      consentType: null
    }
  }

  handleSubmit(event) {
    event.preventDefault()
    if (this.validateForm()) {
      this.setState({submitting: true})
      const {match: {params: {employerId, employerName}}} = this.props
      let fileName
      actions.consentForms.getExportConsentData(employerId, employerName, this.state.consentType)
      .then((response) => {
        fileName = this.getFileName(response)
        return response.blob()
      })
      .then((blob) => {
        this.downloadFile(blob, fileName)
        this.setState({submitting: false})
        const {history} = this.props
        history.push(`/nbtadmin/custodian-consent/export-data`)
      }).catch(() => {
        this.setState({submitting: false})
      })
    }
  }

  validateForm(){
    const{consentType} = this.state
    return consentType
  }

  onChange(name, value){
    this.setState({[name]: value})
  }

  downloadFile(blob, fileName) {
    let link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(link.href)
    link.parentNode.removeChild(link)
  }
  
  getFileName(response){
    let cd = response.headers.get('content-disposition');
    let regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    let match = regex.exec(cd);
    const {match: {params: {employerName}}} = this.props
    return match[1] || employerName + '_export.csv';
  }

  renderForm() {
    const {match: {params: {employerName}}} = this.props
    return (
        <div className='page create-consent-form-verbiage-view'>
          <h4>Export Consent Data for {employerName}</h4>
          <form onSubmit={this.handleSubmit.bind(this)} className='create-custodian-form'>
            <Card className='padding'>
               <Field
                  name='consentType'
                  required={true}
                  label='Consent Type'
                  type='dropdown'
                  placeholder='Consent Type'
                  value={this.state.consentType || ''}
                  data={consentType.us.fields.consentType.data}
                  events={
                    {
                      onChange: (event) => {this.onChange('consentType', event.target.value)}
                    }
                  }
              />
            </Card>

            <Button
                className='primary-button margin-top'
                disabled={this.state.submitting}
                type="submit"
            >
            <span>
              {this.state.submitting ?
                  <Loading containerClassName='inline' className='white small' />
                  : 'Export Data'
              }
            </span>
            </Button>
          </form>
        </div>
    )
  }
  render() {
    return this.renderForm()
  }
}

ExportConsent.propTypes = {
  employerId: PropTypes.string,
  employerName: PropTypes.string
}

export default ExportConsent