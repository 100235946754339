import React from 'react'
import actions from '../../../../actions'
import Card from 'components/Card'
import EnrollmentRecordForm from '../EnrollmentForms/EnrollmentRecordForm'
import { hasRole } from '../../../../redux/utility/SessionUtil'
import { showSnackbar, hideSnackbar } from '../../../../redux/utility/SnackbarUtil'

class EnrollmentDisplay extends React.Component {
    constructor(props){
        super(props)
        const {match: {params: {enrollmentId}}} = this.props
        this.state = {
            enrollmentId: enrollmentId,
            enrollmentRecord: null,
            loading: true,
            readAccess: hasRole('AccountMaintView') || hasRole('AccountMaintEdit'),
            enrollmentReprocessingAccess: hasRole('AccountReactivation')
        }
    }

    componentDidMount() {
        if(this.verifyAccess()){
            this.searchEnrollmentRecord()
        }
    }

    verifyAccess() {
        if(!this.state.readAccess) {
            this.props.history.push('/nbtadmin/unauthorized')
            return false
        }
        return true
    }

    searchEnrollmentRecord() {
        this.setState({loading: true})
        actions.enrollments.getEnrollmentByEnrollmentId(this.state.enrollmentId).then(enrollmentRecord => {
            if(enrollmentRecord.enrollmentId == null) {
                this.setState({
                    enrollmentRecord: null,
                    loading: false
                })
            } else {
                this.setState({
                    enrollmentRecord: enrollmentRecord,
                    loading: false
                })
            }
        }).catch(() => { 
            this.setState({
                enrollmentRecord: null,
                loading: false
            })
        })
    }

    shouldAllowReprocessEnrollment() {
        return (!this.state.loading && this.state.enrollmentRecord!=null && this.state.enrollmentReprocessingAccess)
    }

    reprocessEnrollment() {
        this.setState({loading: true})
        actions.enrollments.reprocessEnrollment(this.state.enrollmentRecord.enrollmentId).then(enrollmentRecord => {
            if(enrollmentRecord.enrollmentId == null) {
                this.setState({loading: false})
                showSnackbar('Enrollment reprocessing failed.')
            } else {
                this.searchEnrollmentRecord()
                showSnackbar('Enrollment has been reprocessed.', 'info')
            }
        }).catch(() => {
            this.setState({loading: false})
            hideSnackbar()
            showSnackbar("Invalid Data on File.")
        })
    }

    render() {
        return(
            <div className='search-page enrollments-view'>
                <h4>Enrollment Record</h4>
                <div className='margin-top margin-bottom'>
                    <Card className='padding'>
                        <EnrollmentRecordForm
                            {...this.props}
                            enrollmentRecord={this.state.enrollmentRecord}
                            loading={this.state.loading}/>
                        <button style={{width: 'auto', marginLeft: 'auto', display: this.shouldAllowReprocessEnrollment() ? 'block' : 'none'}}
                            className='button margin-top primary-button'
                            onClick={this.reprocessEnrollment.bind(this)}>Reprocess Enrollment </button>
                    </Card>
                </div>
            </div>
        )
    }

}

export default EnrollmentDisplay