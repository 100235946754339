import React from 'react'
import Search from 'compositions/Search'
import TableDisplay from 'compositions/TableDisplay'
import actions from 'actions'
import { hasRole } from '../../../../redux/utility/SessionUtil'
import {filterDataSource} from 'lib/filter'
import {mapDateToForm} from 'lib/date'
import Loading from 'components/Loading'

let searchFilters = [
  {
    filterText: 'First name',
    header: () => 'First Name',
    key: 'firstName'
  },
  {
    filterText: 'Last name',
    header: () => 'Last Name',
    key: 'lastName'
  },
  {
    filterText: 'Account number',
    header: () => 'Account Number',
    key: 'accountNumber'
  },
  {
    filterText: 'SSN',
    header: () => 'SSN',
    key: 'ssn'
  },
  {
    filterText: 'Last Four SSN',
    header: () => 'Last Four SSN',
    key: 'ssnLastFour'
  },
  {
    filterText: 'Alternate ID',
    header: () => 'Alternate ID',
    key: 'employeeId'
  },
  {
    filterText: 'Ext. Account ID',
    header: () => 'Ext. Account ID',
    key: 'externalBankAccountId'
  },
  {
    filterText: 'Job ID',
    header: () => 'Job ID',
    key: 'jobId'
  }
]


let headers = [
  {
    filterText: 'First name',
    header: () => 'First Name',
    key: 'firstName'
  },
  {
    filterText: 'Last name',
    header: () => 'Last Name',
    key: 'lastName'
  },
  {
    filterText: 'ER ID',
    header: () => 'ER ID',
    key: 'employerId'
  },
  {
    filterText: 'ER Name',
    header: () => 'ER Name',
    key: 'employerName'
  },
  {
    filterText: 'Account number',
    header: () => 'Account Number',
    key: 'accountNumber'
  },
  {
    filterText: 'NBT Acct Status',
    header: () => 'NBT Acct Status',
    key: 'nbtAccountStatus'
  },
  {
    filterText: 'Ext. Account Id',
    header: () => 'Ext. Account Id',
    key: 'externalBankAccountId'
  },
  {
    filterText: 'SSN',
    header: () => 'SSN',
    key: 'ssn'
  },
  {
    filterText: 'Job ID',
    header: () => 'Job ID',
    key: 'jobId'
  },
  {
    filterText: 'Alternate ID',
    header: () => 'Alternate ID',
    key: 'employeeId'
  },
  {
    filterText: 'DOB',
    header: () => "DOB",
    key: 'birthDate'
  }
]

class Accounts extends React.Component {
  constructor (props) {
    super (props);
    this.state = {
      dataSource: [],
      filteredDataSource: [],
      hasSearched: false,
      showSearch: true,
      readAccess: hasRole('AccountMaintView'),
      writeAccess: hasRole('AccountMaintEdit')
    }

    this.actions = [
      {
        action: this.onView.bind(this),
        icon: 'visibility'
      }
    ]
  }

  /*
  * Verify access
  */
  componentWillMount () {
    if (this.verifyAccess()) {

    }
  }

  /*
  * Verify that the user has access to account maintenance
  */
  verifyAccess () {
    if (!(this.state.readAccess || this.state.writeAccess)) {
      this.props.history.push('/nbtadmin/unauthorized')
      return false
    }

    return true
  }


  /*
  * Fetch accounts
  */
  getAccounts (params) {
    this.setState({loading: true})
    actions.accounts.getAccounts(params).then(accounts => {
      const mappedAccounts = accounts.map(account => {
        const {
          bankAccount: {id, accountNumber, externalBankAccountId, employeeId, accountStatus},
          savingsAccountMinimal: {
            employeeMinimal: { firstName, lastName, ssn, ssnLastFour, birthDate},
            jobMinimal: {jobId, employerId},
            employerMinimal: {name}
          }
        } = account

        return {
          id,
          firstName,
          lastName,
          employerId,
          employerName: name,
          accountNumber,
          nbtAccountStatus: accountStatus,
          externalBankAccountId,
          ssn,
          jobId,
          ssnLastFour,
          birthDate: mapDateToForm(birthDate),
          employeeId
        }
      })
      this.setState({dataSource: mappedAccounts, filteredDataSource: mappedAccounts, loading: false, showSearch: false, hasSearched: true})
    }).catch(() => this.setState({loading: false}))
  }

  /*
  * Go to account form
  */
  onAdd () {
    const {history} = this.props
    history.push(`/nbtadmin/accounts/create/new`)
  }

  /*
  * View account
  */
  onRowClick (account) {
    const {history} = this.props
    history.push(`/nbtadmin/accounts/${account.id}`)
  }

  /*
  * View account
  */
  onView (account) {
    const {history} = this.props
    history.push(`/nbtadmin/accounts/${account.id}`)
  }

  /*
  * Filter data source.
  *
  * @param [Object] queryParams. Key value pair containing filters.
  */
  onFilter (queryParams) {
    const {dataSource} = this.state
    const newDataSource = filterDataSource(dataSource, queryParams)
    this.setState({filteredDataSource: newDataSource})
  }

  /*
  * Get all accounts
  */
  onSearch (params = {}) {
    params= Object.assign(params, {pageNumber: 0, pageSize: 50})
    this.getAccounts(params)
  }

  /*
  * Toggle if the search is showing
  *
  */
  toggleSearch () {
    if (this.state.hasSearched) {
      this.setState({showSearch: !this.state.showSearch})
    }
  }

  render () {
    if (this.state.loading) return <div className='margin-top'><Loading/></div>
    return (
      <div className='search-page accounts-view'>
        <h4>Account Maintenance</h4>
        <p>Manage accounts.</p>

        <i className="material-icons" onClick={this.toggleSearch.bind(this)}>{this.state.showSearch ? 'chevron_left' : 'chevron_right'}</i>
        <div style={{width: "100%", margin: "0 auto", display: this.state.showSearch ? 'block' : 'none'}}>
          <Search
            tableFilters={searchFilters}
            onSearch={this.onSearch.bind(this)}
          />
        </div>

        {this.state.hasSearched &&
          <TableDisplay
            dataActions={this.actions}
            dataSource={this.state.filteredDataSource}
            disableAdd={true}
            loading={this.state.loading}
            onAdd={this.onAdd.bind(this)}
            onFilter={this.onFilter.bind(this)}
            onRowClick={this.onRowClick.bind(this)}
            tableHeaders={headers}
          />
        }
      </div>
    )
  }
}

export default Accounts
