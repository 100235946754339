import Store from "../store/Store";
import { openSnackbar, closeSnackbar } from "../slices/SnackbarSlice";

/**
 * Display snackbar.
 * 
 * @params [String] message. Message to show
 * @params [String] type. Message type. info | warn | error
 */
export function showSnackbar(message, type = 'error') {
    Store.dispatch(openSnackbar({message, type}))
}

/**
 * Close snackbar.
 */
export function hideSnackbar() {
    Store.dispatch(closeSnackbar())
}