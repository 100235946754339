import React from 'react'
import Card from 'components/Card'
import Loading from 'components/Loading'
import TextField from 'components/TextField'
import actions from 'actions'
import { hasRole } from '../../../../redux/utility/SessionUtil'
import Field from 'components/forms/Field/index'
import dayjs from 'dayjs'

class MailTemplate extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      template: null,
      readAccess: hasRole('EmailMasterView'),
      writeAccess: hasRole('EmailMasterEdit')
    
    }
  }

  /*
  * Get mail template by id .
  */
  componentWillMount () {
    if (this.verifyAccess()) {
      this.getMailTemplate()
    }
  }

  /*
  * Verify that the user has access to email templates
  */
  verifyAccess () {
    if (!(this.state.readAccess || this.state.writeAccess)) {
      this.props.history.push('/nbtadmin/unauthorized')
      return false
    }

    return true
  }

  /*
  * Fetch mail templates by id.
  */
  getMailTemplate() {
    const {match: {params: {id}}} = this.props

    this.setState({loading: true})
    actions.mailTemplates.getMailTemplateById(id).then(template => {
      this.setState({template, loading: false})
    }).catch(() => this.setState({loading: false}))
  }

  /*
  * Render the actions menu
  */
  renderActionsMenu () {
    if (this.state.writeAccess && this.state.template.startDate > dayjs().format("YYYY-MM-DD")) {
      return (
        <div className='text-right'>
          <a onClick={this.onEdit.bind(this)}>
            <i className='material-icons orange' style={{fontSize: '1.2em'}}>edit</i>
          </a>
          <a onClick={this.onDelete.bind(this)}>
            <i className='material-icons orange' style={{fontSize: '1.2em'}}>delete</i>
          </a>
        </div>
      )
    }
  }

/*
 *  edit the template
 */
 onEdit(template){
    const {history} = this.props
    history.push(`/nbtadmin/mail-templates/${this.state.template.contentId}/edit`)
 }
 
 /*
 * confirm then delete the template
 */
 onDelete(template){

 }
  /*
  * Update template
  *
  * @param [String] template. Update template.
  */
  onChange (template) {
    this.setState({template})
  }

render () {
    if (this.state.loading) return <div className='margin-top'><Loading/></div>
    const {
      contentTypeset,
      contentDocument,
      contentKey,
      isDefault,
      optumCorrespondenceId,
      welcomeKitNum,
      startDate,
      endDate
    } = this.state.template
  const checkBoxAttr = {
                       attrs: { 
                         checked: isDefault 
                        }
                      }
  const formattedStartDate = startDate ? dayjs(startDate).format("MM/DD/YYYY") : ''
  const formattedEndDate = endDate ? dayjs(endDate).format("MM/DD/YYYY") : ''


    return (
      <div className='page mail-template-view'>
        <h4>{`${contentKey}'s Mail Template`}</h4>
        <Card className='padding'>
          {this.renderActionsMenu()}
          <h5>Details</h5>
          <div className='row'>
            <div className='col'>
              <TextField
                label='Letter Type'
                name='name'
                value={contentTypeset}
              />
            </div>
            <div className='col'>
              <TextField
                label='Content Key'
                name='key'
                value={contentKey}
              />
            </div>
          </div>
          <div className='row'>
          <div className='col'>
              <Field
                disabled
                type="checkbox"
                name='Default'
                id='defaultId'
                className='defaultid_form'
               {...checkBoxAttr}
              />
            </div>
            <div className='col'>
              <TextField
                label='Optum ID'
                name='key'
                value={optumCorrespondenceId}
              />
            </div>
            <div className='col'>
              <TextField
                label='Welcome Kit #'
                name='key'
                value={welcomeKitNum}
              />
            </div>
            </div>
            <div className='row margin-top'>
            <div className='col'>
              <TextField
                label='Start Date'
                name='startDate'
                value={formattedStartDate}
              />
            </div>
            <div className='col'>
              <TextField
                label='End Date'
                name='endDate'
                value={formattedEndDate}
              />
            </div>
          </div>

        </Card>

        <Card className='padding'>
          <h5>Mail Template</h5>
          <div dangerouslySetInnerHTML={{ __html: contentDocument}} />
        </Card>
      </div>
    )
  }

}

export default MailTemplate
