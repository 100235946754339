import Fetch from 'lib/fetch'

export function getEmployerBillingReport(form) {
    let queryParams = Object.keys(form).filter(key => form[key]).map(key => {
        return [key, form[key]].map(encodeURIComponent).join("=");
    }).join("&");
    return Fetch.get(`${process.env.REACT_APP_BILLING_DOMAIN}/billing-report/employer?${queryParams}`, null)
    .catch(error => Promise.reject(error))
}

export function exportEmployerBillingReport(form) {
    let queryParams = Object.keys(form).filter(key => form[key]).map(key => {
        return [key, form[key]].map(encodeURIComponent).join("=");
    }).join("&");
    return Fetch.getFile(`${process.env.REACT_APP_BILLING_DOMAIN}/billing-report/employer-download?${queryParams}`, null)
    .then(response => {
        if(response.ok) {
            return response
        }
        return Promise.reject(response)
    })
    .catch(error => Promise.reject(error))
}