import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/Modal'

class AddTransConfirm extends React.Component {
  render () {
    return (
      <Modal
        header={'Are you sure you want to add this transaction?'}
        onClose={() => this.props.onClose()}
      >
        <div className='modal-footer'>
          <div className='modal-actions'>
            <button
              className='button primary-button'
              onClick={() => this.props.onConfirm()}
            >Yes</button>
            <button
              className='button secondary-button'
              onClick={() => this.props.onClose()}
            >No</button>
          </div>
        </div>
      </Modal>
    )
  }
}

AddTransConfirm.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func
}

export default AddTransConfirm