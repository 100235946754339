/*
* Map date to form
*/
export function mapDateToForm (date) {
  if (date) {
    const dateParts = date.substring(0, 10).split('-')
    return `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`
  }
}

/*
* Map form to date
*/
export function mapFormDateToDate (date) {
  if (date) {
    const dateParts = date.split('/')
    return `${dateParts[2]}-${dateParts[0]}-${dateParts[1]} 00:00:00`
  }
}

export function mapDateToSortable(date) {
  if (date) {
    const dateParts = date.substring(0, 10).split('-')
    return `${dateParts[0]}-${dateParts[1]}-${dateParts[2]}`
  }
}
