/**
 * Super straightforward localization and more!
 *
 * ```
 *  email: {
 *      name: 'email',
 *      type: 'email',
 *      label: 'E-mail',
 *      validator: 'email'
 *  },
 *  date_admission: {
 *      name: 'date_admission',
 *      label: 'Date of Admission',
 *      placeholder: 'Date of Admission',
 *      type: 'month'
 *  }
 * ```
 * TODO Separate the translations from the definitions
 */

const interestProfile = {
  us: {
    fields: {
      name: {
        name: 'name',
        label: "Name",
        placeholder: 'Profile name'
      },
      type: {
        name: 'type',
        type: 'dropdown',
        label: "Type",
        placeholder: 'Profile type',
        data: {
          Bonus: {
            name: 'Bonus',
            disabled: false
          },
          Regular: {
           name: 'Regular',
           disabled: false
         }
        }
      },
      activeDate: {
        name: 'activeDate',
        label: "Start date",
        placeholder: 'Profile start date',
      },
      description: {
        name: 'description',
        label: 'Description',
        placeholder: 'Profile description',
      },
      accrualBalanceMethod: {
        name: 'accrualBalanceMethod',
        type: 'dropdown',
        label: 'Balance method',
        placeholder: 'Profile balance method',
        data: {
          Daily: {
            name: 'Daily',
            disabled: false
          },
          Monthly: {
           name: 'Monthly',
           disabled: true
         },
          Quarterly: {
            name: 'Quarterly',
            disabled: true

          },
          Biannually: {
            name: 'Biannually',
            disabled: true
          },
          Annually: {
            name: 'Annually',
            disabled: true
          }
        }
      },
      accrualCompoundMethod: {
        name: 'accrualCompoundMethod',
        type: 'dropdown',
        label: 'Compound method',
        placeholder: 'Profile compound method',
        data: {
          Daily: {
            name: 'Daily',
            disabled: true
          },
          Monthly: {
           name: 'Monthly',
           disabled: false
         },
          Quarterly: {
            name: 'Quarterly',
            disabled: true

          },
          Biannually: {
            name: 'Biannually',
            disabled: true
          },
          Annually: {
            name: 'Annually',
            disabled: true
          }
        }
      },
      postingSchedule: {
        name: 'postingSchedule',
        type: 'dropdown',
        label: 'Posting Schedule',
        placeholder: 'Profile posting schedule',
        data: {
          Daily: {
            name: 'Daily',
            disabled: true
          },
          Monthly: {
           name: 'Monthly',
           disabled: false
         },
          Quarterly: {
            name: 'Quarterly',
            disabled: true

          },
          Biannually: {
            name: 'Biannually',
            disabled: true
          },
          Annually: {
            name: 'Annually',
            disabled: true
          }
        }
      }
    },
    hierarchyFields: {
      interestProfileId: {
        name: 'interestProfileId',
        label: "Interest Profile",
        placeholder: 'Interest profile',
        type: 'dropdown',
        data: {}
      },
      startDate: {
        name: 'startDate',
        label: "Start date",
        placeholder: 'Profile start date',
      },
      endDate: {
        name: 'endDate',
        label: "End date",
        placeholder: 'Profile end date',
      },
      assigned: {
        name: 'assigned',
        type: 'dropdown',
        label: 'Assigned',
        placeholder: 'Profile assigned',
        data: {
          true: 'True',
          false: 'False'
        }
      }
    }
  }
}

export default interestProfile
