import React from 'react'
import PropTypes from 'prop-types'
import Card from '../Card'
import './modal.scss'

class Modal extends React.Component {
  renderHeader () {
    if (this.props.header) {
      return (
        <div className='modal-header'>
          <h4>{this.props.header}</h4>
          {this.renderCloseButton()}
        </div>
      )
    }
  }

  renderCloseButton () {
    if (this.props.showClose) {
      return (
        <button className='close-button' onClick={() => this.props.onClose()}>
          X
        </button>
      )
    }
  }

  render () {
    return (
      <div onClick={() => this.props.onClose()} className="overlay-style">
        <Card className="modal" onClick={(event) => { event.stopPropagation() }}>
          {this.renderHeader()}
          {this.props.children}
        </Card>
      </div>
    )
  }
}

Modal.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func
}

export default Modal
