import React from "react";
import actions from "../../../../actions";
import Card from "../../../../components/Card";
import EmployerSearch from "../EmployerSearch";
import PropTypes from "prop-types";
import TextField from "../../../../components/TextField";
import EmployersTable from "../EmployerSearch/EmployersTable";

class EmployerNameForm extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      hasSearched: false
    }
  }

  onEmployerSearch(value){
    this.setState({loadingEmployerSearch: true})
    return actions.employers.getEmployers(value).then(employers => {
      const mappedEmployers = employers.map(employer => {
        const {id, name} = employer
        return {
          id,
          name
        }
      })
      this.setState({dataSource: mappedEmployers, loadingEmployerSearch: false, showSearch: false, hasSearched: true})
    }).catch(() => this.setState({loadingEmployerSearch: false}))
  }
  
  onRowClick(employer){
    this.props.getChildFormState('employerName', employer.name)
    this.props.getChildFormState('employerId', employer.id)
    this.props.onSubmit()
  }

  renderEmployerSearch(){
    return (
        <EmployersTable
            {...this.props}
            title=''
            dataSource={this.state.dataSource}
            loading={this.state.loadingEmployerSearch}
            onRowClick={this.onRowClick.bind(this)}
        />
    )
  }

  render() {
    return (
        <Card className='padding'>
          <div className='row'>
            <div className='col'>
              <TextField
                  label='Transfer Date'
                  name='transferDate'
                  value={this.props.transferDate}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <TextField
                  label='Url Expiration Date'
                  name='urlExpirationDate'
                  value={this.props.urlExpirationDate}
              />
            </div>
          </div>
          <EmployerSearch
              {...this.props}
              onSubmit={this.onEmployerSearch.bind(this)}
          />
          {this.state.hasSearched && this.renderEmployerSearch()}
        </Card>
    )
  }
}

EmployerNameForm.propTypes = {
  transferDate: PropTypes.string,
  urlExpirationDate: PropTypes.string
}

export default EmployerNameForm