import React from 'react'
import PropTypes from 'prop-types'
import Field from 'components/forms/Field'

import './comments.scss'

class Comments extends React.Component {
  constructor (props) {
    super (props)
    this.state = {
      comment: ''
    }
  }
  /*
  * Render the comments
  */
  renderComments () {
    const {comments} = this.props
    return comments.map(comment => {
      return this.renderComment(comment)
    })
  }

  /*
  * Render a comment
  */
  renderComment (comment) {
    const {author, body, date} = comment

    return (
      <div className='comment'>
        <div className='wrapper'>
          <div className='author-details'>
            <span><span className='author'>{author}</span> added a comment - {date}</span>
          </div>
          <p>{body}</p>
        </div>
      </div>
    )
  }

  /*
  * On submit comment
  */
  onSubmitComment () {
    this.props.onSubmitComment(this.state.comment).then(() => {
      this.setState({comment: ''})
    }).catch(() => false)
  }

  render () {
    return (
      <div className='comment-section'>
        {this.renderComments()}
        <div className='margin-top'/>

        {!this.props.hideInput &&
          <div>
            <Field
              name='comment-text'
              label='Comment'
              type='textarea'
              placeholder='Leave a comment.'
              events= {{
                onChange: (event) => { this.setState({comment: event.target.value}) }
              }}
              value={this.state.comment}
            />


            <button
              className='button primary-button'
              onClick={this.onSubmitComment.bind(this)}
              style={{width: 'auto', display: 'block', marginLeft: 'auto'}}
            >Submit Comment</button>
          </div>
        }
      </div>
    )
  }
}

Comments.propTypes = {
  comments: PropTypes.array
}

export default Comments
