import Fetch from 'lib/fetch'
import { showSnackbar } from '../redux/utility/SnackbarUtil'

export function getConsentFormsByCustodian (params) {
  return Fetch.post(`${process.env.REACT_APP_NBT_ADMIN_DOMAIN}/portal-data/consent/retrieve/list/consent-by-custodian`,params, null)
  .then(data => data)
  .catch(error => {
    return Promise.reject(error)
  })
}

export function getConsentFormsById (id) {
  return Fetch.get(`${process.env.REACT_APP_NBT_ADMIN_DOMAIN}/portal-data/consent/retrieve/list/consent-by-id/${id}`, null)
  .then(data => data)
  .catch(error => {
    return Promise.reject(error)
  })
}

export function createConsentForm (form) {
  return Fetch.post(`${process.env.REACT_APP_NBT_ADMIN_DOMAIN}/portal-data/consent/create`, form, null)
  .then(data => {
    showSnackbar('Custodian consent form successfully created', 'info')
    return data
  })
  .catch(error => Promise.reject(error))
}

export function getConsentUrlsByConsentFormId (id) {
  return Fetch.get(`${process.env.REACT_APP_NBT_ADMIN_DOMAIN}/portal-data/consent/retrieve/list/url-by-form-id/${id}`, null)
  .then(data => data)
  .catch(error => {
    return Promise.reject(error)
  })
}

export function getConsentUrlById (id) {
  return Fetch.get(`${process.env.REACT_APP_NBT_ADMIN_DOMAIN}/portal-data/consent/retrieve/url-by-id/${id}`, null)
  .then(data => data)
  .catch(error => {
    return Promise.reject(error)
  })
}

export function createConsentUrl (form) {
  return Fetch.post(`${process.env.REACT_APP_NBT_ADMIN_DOMAIN}/portal-data/consent/url/create`, form, null)
  .then(data => {
    showSnackbar('Transfer consent URL successfully created', 'info')
    return data
  })
  .catch(error => Promise.reject(error))
}

export function updateConsentUrl (form) {
  return Fetch.put(`${process.env.REACT_APP_NBT_ADMIN_DOMAIN}/portal-data/consent/url/update`, form, null)
  .then(data => {
    showSnackbar('Consent URL successfully updated', 'info');
    return data
  })
  .catch(error => Promise.reject(error))
}

export function uploadConsentData(employerId, employerName, file) {
  let form = new FormData()
  form.append(`file`, file)
  return Fetch.postMultipart(`${process.env.REACT_APP_NBT_ADMIN_DOMAIN}/portal-data/consent/upload-consent-data/${employerId}/${employerName}`, form, null)
  .then(data => {
    showSnackbar(data, 'info')
    return data
   })
  .catch(error => Promise.reject(error))
}

export function getUploadedConsentFilesByDateRange(startDate, endDate) {
  return Fetch.get(`${process.env.REACT_APP_NBT_ADMIN_DOMAIN}/portal-data/consent/consent-data/forms/search/${startDate}/${endDate}`, null)
  .then(data => data)
  .catch(error => {

    return Promise.reject(error)
  })
}

export function getExportConsentData (employerId, employerName, consentType) {
  return Fetch.getFile(`${process.env.REACT_APP_NBT_ADMIN_DOMAIN}/portal-data/consent/consent-data/export/${employerId}/${employerName}/${consentType}`, null)
  .then(response => response)
  .catch(error => {
    console.log(error)
    showSnackbar('Failed to get export file', 'info')
    return Promise.reject(error)
  })
}