import React from 'react'
import Field from 'components/forms/Field/index'
import actions from 'actions'
import {maskDate} from 'lib/mask'
import {mapDateToForm, mapFormDateToDate} from 'lib/date'
import interestProfile from 'data/interestProfile'

class InterestProfileHierarchyForm extends React.Component {
  constructor (props) {
    super (props)
    this.state = {
      interestProfiles: {},
      form: this.initializeFormData(props.interestProfileHierarchy)
    }
  }

  componentWillMount () {
    this.getInterestProfiles()
  }

  /*
  * Fetch interest profiles and map them to dropdown format.
  */
  getInterestProfiles () {
    actions.interestProfiles.getInterestProfiles().then((interestProfiles) => {
      let mappedInterestProfiles = {}
      interestProfiles.forEach(interestProfile => {
        if (interestProfile.activeDate) {
          mappedInterestProfiles[interestProfile.id] = interestProfile.name
        }
      })

      this.setState({interestProfiles: mappedInterestProfiles})
    })
  }


  /*
  * Initialize form data
  * Interest profile hierarchy
  */
  initializeFormData (data) {
    const {id, hierarchyId, interestProfileId, startDate, endDate, assigned} = data || {}

    return {
      id: id || '',
      hierarchyId: hierarchyId || this.props.hierarchyId || '',
      interestProfileId: interestProfileId || '',
      startDate: startDate ? mapDateToForm(startDate) : '',
      endDate: endDate ? mapDateToForm(endDate) : '',
      assigned: assigned || ''
    }
  }

  /*
  * Render the fields
  */
  getFields () {
    const {form: {
      interestProfileId,
      startDate,
      endDate,
      assigned
    }} = this.state

    const {hierarchyFields} = interestProfile.us
    const fields = hierarchyFields
    const {formIndex} = this.props

    return [
        {
          ...fields.interestProfileId,
          name: `${fields.interestProfileId.name}-${formIndex}`,
          required: true,
          events: {
            onChange: (event) => {
              this.onChange(fields.interestProfileId.name, event.target.value)
            }
          },
          value: interestProfileId
        },
        {
          ...fields.startDate,
          name: `${fields.startDate.name}-${formIndex}`,
          required: true,
          events: {
            onChange: (event) => {
              const value = maskDate(this.state.form[fields.startDate.name], event.target.value)
              this.onChange(fields.startDate.name, value)
            }
          },
          value: startDate
        },
        {
          ...fields.endDate,
          name: `${fields.endDate.name}-${formIndex}`,
          required: false,
          disabled: true,
          events: {
            onChange: (event) => {
              const value = maskDate(this.state.form[fields.endDate.name], event.target.value)
              this.onChange(fields.endDate.name, value)
            }
          },
          value: endDate
        },
        {
          ...fields.assigned,
          name: `${fields.assigned.name}-${formIndex}`,
          required: false,
          events: {
            onChange: (event) => {
              this.onChange(fields.assigned.name, event.target.value)
            }
          },
          value: assigned
        }
      ]
  }

  /*
  * Update form values
  *
  * @param [String] name. Field name of form to update.
  * @param [Any] value. Value.
  */
  onChange (name, value) {
    let form = {...this.state.form}
    form[name] = value
    this.setState({form})
  }

  /*
  * Expose method to return form
  */
  getData () {
    return this.state.form
  }

  /*
  * Determine whether the user is submiting updated interest profile hierarchy or
  * a new interest profile hierarchy
  *
  */
  onSubmit (event) {
    event.preventDefault()
    const form = this.mapForm(this.state.form)
    !form.id ?
      this.onCreateInterestProfileHierarchy(form) : this.onUpdateInterestProfileHierarchy(form)
  }

  /*
  * Map form.
  */
  mapForm (f) {
    let form = {...f}
    form.startDate = mapFormDateToDate(form.startDate)
    form.endDate = mapFormDateToDate(form.endDate)
    return form
  }

  /*
  * Create a new interest profile for a hierarchy
  */
  onCreateInterestProfileHierarchy (form) {
    actions.interestProfiles.createInterestProfileHierarchy(form).then((interestProfile) => {
      this.props.onSubmit(interestProfile)
      this.props.onClose()
    }).catch(() => false)
  }

  /*
  * Update an existing interest profile hierarchy for a hierarchy
  */
  onUpdateInterestProfileHierarchy (form) {
    actions.interestProfiles.updateInterestProfileHierarchy(form).then((interestProfile) => {
      this.props.onSubmit(interestProfile)
      this.props.onClose()
    }).catch(() => false)
  }

  /*
  * Delete interest profile hierarchy.
  */
  onDeleteInterestProfileHierarchy () {
    // actions.interestProfiles.onDeleteInterestProfileHierarchy(this.state.form).then(() => {
    //   this.props.onClose()
    // }).catch(() => false)
  }

  render () {
    const {interestProfiles} = this.state
    const fields = this.getFields()
    return (
      <div>
        <div className='modal-body'>
          <div className='row margin-top'>
            <div className='col'>
              <Field key={`${fields[0].name}`} {...fields[0]} data={interestProfiles} />
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <Field key={`${fields[1].name}`} {...fields[1]}  />
            </div>
            <div className='col'>
              <Field key={`${fields[2].name}`} {...fields[2]}  />
            </div>
          </div>
          <div className='row margin-bottom'>
            <div className='col'>
              <Field key={`${fields[3].name}`} {...fields[3]}  />
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          <div className='modal-actions'>
            <button
              className='button primary-button'
              onClick={this.onSubmit.bind(this)}
            >SUBMIT</button>
          </div>
        </div>
      </div>
    )
  }
}

export default InterestProfileHierarchyForm
