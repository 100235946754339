import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from "../../../../components/forms/DatePicker";

class ConsentFileSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: this.initializeFormData()
    }
  }

  initializeFormData() {
    return {
      startDate: '',
      endDate: ''
    }
  }

  onChange(name, value) {
    let form = {...this.state.form}
    form[name] = value
    this.setState({form})
  }

  handleConsentFileSearch(event) {
    event.preventDefault()
    if (this.validateForm()) {
      this.props.onSubmit(this.state.form).then(() => {
      }).catch(() => false)
    }
  }

  validateForm() {
    const {startDate, endDate} = this.state.form
    return startDate && endDate
  }

  render() {
    const {form} = this.state
    return (
        <div>
            <form className=''
                  onSubmit={this.handleConsentFileSearch.bind(this)}>
              <div className="row">
                <div className="col">
                  <DatePicker
                      disabled={false}
                      label="Start Date"
                      name="startDate"
                      required={true}
                      onChange={(name, value) => {
                        this.onChange(name, value)
                      }}
                      placeholder="Start Date"
                      value={form.startDate}
                  />
                </div>
                <div className="col">
                  <DatePicker
                      disabled={false}
                      label="End Date"
                      name="endDate"
                      required={true}
                      onChange={(name, value) => {
                        this.onChange(name, value)
                      }}
                      placeholder="End Date"
                      value={form.endDate}
                  />
                </div>
              </div>
              <button
                  style={{
                    width: 'auto',
                    display: 'block',
                    marginLeft: 'auto'
                  }}
                  className='button margin-top primary-button'
                  type='submit'
              >Find Uploaded Consent Files
              </button>
            </form>
        </div>
    )
  }
}

ConsentFileSearch.propTypes = {
  onSubmit: PropTypes.func
}

export default ConsentFileSearch