import React from "react";
import Card from "../../../../components/Card";
import TextField from "../../../../components/TextField";
import actions from "../../../../actions";
import Loading from "../../../../components/Loading";

class GeneratedUrlDisplay extends React.Component{
  
  componentWillMount(){
    this.getConsentUrlById()
  }
  
  getConsentUrlById(){
    const {match: {params: {id}}} = this.props
    this.setState({loading: true})
    actions.consentForms.getConsentUrlById(id).then(consentUrl => 
        {
          this.setState({consentUrl, loading: false})
        }
    ).catch(() => {
      this.setState({loading: false})
    })
  }
  
  render() {
    if (this.state.loading) {
      return <div className='margin-top'><Loading/></div>
    }
    return (
        <div className='page view-generated-url'>
          <Card className='padding'>
          <h5>Employer Transfer Consent Details</h5>
          <div className='row'>
            <div className='col'>
              <TextField
                  label='URL'
                  name='fullUrl'
                  value={this.state.consentUrl.url}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <TextField
                  label='Employer Name'
                  name='employerName'
                  value={this.state.consentUrl.employerName}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <TextField
                  label='Transfer Date'
                  name='transferDateDisplay'
                  value={this.state.consentUrl.transferDateDisplay}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <TextField
                  label='Url Expiration Date'
                  name='urlExpirationDateDisplay'
                  value={this.state.consentUrl.urlExpirationDateDisplay}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <TextField
                  label='Date Generated'
                  name='createDateDisplay'
                  value={this.state.consentUrl.createDateDisplay}
              />
            </div>
          </div>      
          </Card>
        </div>
    )
  }
}

export default GeneratedUrlDisplay