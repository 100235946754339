import Fetch from 'lib/fetch'
import { showSnackbar } from '../redux/utility/SnackbarUtil'
  
/*
* Get account escheatment report
*/
export function getAccountEscheatmentReport () {
  return Fetch.get(`${process.env.REACT_APP_ACCOUNTS_DOMAIN}/accounts/escheatment/job`, null)
  .then(data => {
    return data
  })
  .catch(error => Promise.reject(error))
}

/*
* Get list of escheatment data from response sent from upload page
*/
export function getUploadVerificationResponse (data1) {
return Fetch.get(``, null)
 .then(data => {
  return data1.escheatmentAccounts
 })
 .catch(error => Promise.reject(error))
}

/*
* Get account escheatment report
*/
export function getAccountEscheatmentJobDetails (jobId) {
  return Fetch.get(`${process.env.REACT_APP_ACCOUNTS_DOMAIN}/accounts/escheatment/job/${jobId}`, null)
  .then(data => {
    return data
  })
  .catch(error => Promise.reject(error))
}

/*
* Insert escheatment records to escheatment_job_accounts table
*/
export function insertEscheatmentRecords (insertData) {
  return Fetch.post(`${process.env.REACT_APP_ACCOUNTS_DOMAIN}/accounts/escheatment/job`, insertData, null)
  .then(data => data)
  .catch(error => Promise.reject(error))
}

/*
* Validate uploaded file to show verification page
*/
  export function uploadAccountEscheatment (file) {
    let form = new FormData()
    form.append(`file`, file)    
    return Fetch.postMultipartBatch(`${process.env.REACT_APP_ACCOUNTS_DOMAIN}/accounts/escheatment/validate`, form)
    .then(data => {
        return data
       })
      .catch(error => 
        Promise.resolve(error).then((e) => {
          if(!JSON.parse(e).message){
            showSnackbar('Unable to Process the file. Something went wrong', 'error')
            return JSON.parse(e).message
          }
          showSnackbar(JSON.parse(e).message, 'error')
          return JSON.parse(e).message
        })
      )
  }
