import React from 'react'
import Card from '../../../../components/Card'
import EmployerBillingForm from '../EmployerBillingReport/EmployerBillingForm'
import TableDisplay from 'compositions/TableDisplay'
import Paginate from '../../../../components/Paginate'
import Field from '../../../../components/forms/Field'
import Button from '../../../../components/Button'
import Loading from '../../../../components/Loading'
import actions from '../../../../actions'
import { showSnackbar } from '../../../../redux/utility/SnackbarUtil'
import { hasRole } from '../../../../redux/utility/SessionUtil'
import Utils from '../../../../lib/utils'

const headers = [
    {
        filterText: 'Sponsor Name',
        header: () => 'Sponsor Name',
        key: 'sponsorName',
        disableFilter: true
    },
    {
        filterText: 'Sponsor ID',
        header: () => 'Sponsor ID',
        key: 'sponsorId',
        disableFilter: true
    },
    {
        filterText: 'Distributor Name',
        header: () => 'Distributor Name',
        key: 'distributorName',
        disableFilter: true
    },
    {
        filterText: 'Distributor ID',
        header: () => 'Distributor ID',
        key: 'distributorId',
        disableFilter: true
    },
    {
        filterText: 'Employer Name',
        header: () => 'Employer Name',
        key: 'employerName',
        disableFilter: true
    },
    {
        filterText: 'Employer ID',
        header: () => 'Employer ID',
        key: 'employerId',
        disableFilter: true
    },
    {
        filterText: 'Month-Year',
        header: () => 'Month-Year',
        key: 'yearMonth',
        disableFilter: true
    },
    {
        filterText: 'Status',
        header: () => 'Status',
        key: 'statusString',
        enableSort: true
    },
    {
        filterText: 'Status Message',
        header: () => 'Status Message',
        key: 'statusMessage',
        enableSort: true
    },
    {
        filterText: 'Count of Members',
        header: () => 'Count of Members',
        key: 'membersCount',
        disableFilter: true
    }
]

const exportDropDownData = {
    csv : {
      name: 'CSV',
      disabled: false
    },
    xlsx : {
      name: 'Excel',
      disabled: false
    }
  }

const COUNT_PER_PAGE = 50

class ErSummaryBillingReport extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            dataSource: [],
            exportType: 'csv',
            downloading: false,
            loading: false,
            total: 0,
            readAccess: hasRole('AccountMaintView') || hasRole('AccountMaintEdit'),
            loadPagination: true
        }
        this.form = {}
        this.initTableData()
        
    }

    componentDidMount() {
        this.verifyAccess()
    }

    verifyAccess() {
        if(!this.state.readAccess) {
            this.props.history.push('/nbtadmin/unauthorized')
        }
    }

    initTableData() {
        this.queryParams = {}
        this.sortDirection = 'asc'
        this.sortColumn = 'statusString'
        this.pageNumber = 0
    }

    onSearch(form) {
        this.form = form
        this.pageNumber = 0
        this.setState({ loading: true , dataSource: []})
        this.loadData()
    }

    onFormUpdate(form) {
        this.form = form
    }

    updatePage(current) {
        // zero based index for page number
        this.pageNumber = current - 1
        this.setState({ loading: true })
        this.loadData()
    }

    onFilterTable(queryParams) {
        this.pageNumber = 0
        this.setState({loadPagination : false})
        this.queryParams = queryParams
        this.loadData()
    }

    onSortTable(queryParams,sortableRefs) {
        this.pageNumber = 0
        this.setState({loadPagination : false})
        this.sortDirection = sortableRefs.filter(row => row.props.sortableItem.key === queryParams.key)
        .map(row => row.state.sortDirection)
        this.sortColumn = queryParams.key
        this.loadData()
    }

    loadData() {
        if(!this.isValid()){
            return
        }
        const params  = { 
            ...this.form,
            ...this.queryParams,
            pageNumber: this.pageNumber,
            sortDirection: this.sortDirection,
            sortColumn: this.sortColumn
        }
        actions.erSummaryBillingReport.getSummaryBillingReport(params)
        .then(data => {
            this.setState({dataSource: data.reportList, total: data.total, loading: false, loadPagination : true})
        }).catch(() => {
            this.setState({dataSource: [], loading: false, total: 0, loadPagination : false})
            showSnackbar('Failed to retrieve data')
        })
    }

    isValid() {
        if(!this.form.employerName) {
            showSnackbar('Enter Employer Name','warn')
            this.setState({ loading: false , dataSource: []})
            return false
        }
        if(!this.form.yearMonth) {
            showSnackbar('Enter Year And Month', 'warn')
            this.setState({ loading: false , dataSource: []})
            return false
        }
        if(this.form.yearMonth === 'Invalid date') {
            showSnackbar('Enter Valid Year And Month', 'warn')
            this.setState({ loading: false , dataSource: []})
            return false
        }
        return true
    }

    exportAll() {
        if(!this.isValid()){
            return
        }
        const params  = { ...this.form, fileType: this.state.exportType}
        this.setState({ downloading: true })
        actions.erSummaryBillingReport.exportSummaryBillingReport(params)
        .then(data => data.blob())
        .then(response => {
            this.setState({ downloading: false })
            const url = window.URL.createObjectURL(response)
            const a = document.createElement('a')
            a.style.display = 'none'
            a.href = url
            a.download = 'ErSummaryBillingReport-' + Utils.getCurrentDateTime() + '.' + this.state.exportType
            a.click()
            window.URL.revokeObjectURL(url)
        }).catch(() => {
            this.setState({ downloading: false })
            showSnackbar('Failed to download the file')
        })

    }

    render() {
        return (
            <div className='search-page'>
                <h4>ER Summary Level Billing Report</h4>
                <Card className='padding'>
                    <EmployerBillingForm 
                        onSubmit={this.onSearch.bind(this)}
                        onChange={this.onFormUpdate.bind(this)}
                    />
                </Card>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginBottom: '1em'
                    }}
                >
                <Field
                    label={false}
                    name='exportType'
                    type='dropdown'
                    value={this.state.exportType}
                    data={exportDropDownData}
                    className='width-auto'
                    events={
                    {
                      onChange: (event) => {
                          this.setState({exportType: event.target.value})
                      }
                    }
                  }
                />
                <Button
                    className='margin-left margin-right width-auto'
                    disabled={this.state.downloading}
                    events={
                        {
                          onClick: (event) => {
                              if(this.state.downloading) {
                                return;
                              }
                              this.exportAll();
                          }
                        }
                      }
                >
                    <span>
                        {this.state.downloading ? <Loading containerClassName='inline' className='white small' />
                        : 'Export All'}
                    </span>
                </Button>
                </div>
                <div className="fixed-height-scroll">
                <TableDisplay
                    dataActions={[]}
                    dataSource={this.state.dataSource}
                    disableAdd={true}
                    loading={this.state.loading}
                    onAdd={() => false}
                    onFilter={this.onFilterTable.bind(this)}
                    onSort={this.onSortTable.bind(this)}
                    singleColumnSort={true}
                    tableHeaders={headers}
                    tableDataClassName='align-items-start overflow-wrap'
                    initialSortLabel='Status'
                    initialSortDirection='asc'
                />
                </div>
                {(this.state.loadPagination && this.state.dataSource.length > 0) && (<Paginate
                    total={this.state.total}
                    countPerPage={COUNT_PER_PAGE}
                    updatePage={this.updatePage.bind(this)}
                    style={{
                        marginTop: '1em'
                    }}
                />)}
            </div>
        );
    }
}

export default ErSummaryBillingReport