import React from "react";
import Field from "../../../../components/forms/Field";
import AddTransConfirm from 'components/AddTransConfirm'
import { showSnackbar } from "../../../../redux/utility/SnackbarUtil";
import { getSession } from "../../../../redux/utility/SessionUtil";

class AddTransaction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          form: this.initializeFormData(),
          submitting: false,
          openConfirmDialog: false,
        }
      }

  initializeFormData() {
    return {
      amount: "",
      nbtTransactionCode: "",
      bankAccountId: this.props.savingsAccountId,
      postingStatus: "Transaction Initiated by NBT",
      description: "",
      enteredBy: getSession().username === null ? "NBT Admin Transaction" : "NBT Admin Transaction – " + getSession().username
    }
  }

  /*
  * Takes values on form and assigns them into the right form values in state
  */
  onChange(name, value){
    let form = {...this.state.form}
    form[name] = value
    this.setState({form})
  }
  
  /*
  * Seperate for dropdown since we will need to add the description logic as well
  */
  onDropDownChange(name, value){
    let form = {...this.state.form}
    form[name] = value
    form['description'] = this.props.transactionTypes[value]
    this.setState({form})
  }

  /*
  * Render confim dialogue 
  */
  renderConfirmDialog () {
    if (this.state.openConfirmDialog) {
      return (
        <AddTransConfirm
          onClose={() => {this.setState({openConfirmDialog: false})}}
          onConfirm={this.handleSubmit.bind(this)}
        />
      )
    }
  }

  openConfirm() {
    this.setState({ openConfirmDialog: true  })
    
  }

  checkAmount = form => {
    if(form.amount <= 0){
      this.setState({openConfirmDialog: false})
      return false
    } else {
      return true
    }
  }

  handleSubmit() {
    let form = {...this.state.form}
    let newForm ={}
    newForm["amount"] = form.amount
    newForm["nbtTransactionCode"] = form.nbtTransactionCode.substring(1, form.nbtTransactionCode.length)
    newForm["bankAccountId"] = form.bankAccountId
    newForm["postingStatus"] = form.postingStatus
    newForm["description"] = form.description
    newForm["enteredBy"] = form.enteredBy
    if(this.checkAmount(newForm)) {
      this.props.onSubmit(newForm)
      this.setState({submitting: true, openConfirmDialog: false})
    } else {
      return showSnackbar('Please enter a positive amount')
    }
  }

  render() {
    const {form} = this.state
    return (
      <div>
        <div className="modal-body">
          <div className="row">
            <div className="col">
              <Field
              name="amount"
              label="Amount"
              required={true}
              value={form.amount}
              events={
                {
                  onChange: (event) => {this.onChange('amount', event.target.value)}
                }
              }
              />
            </div>
            <div className="col">
              <Field
              name="description"
              label="Description"
              type='dropdown'
              required={true}
              value={form.nbtTransactionCode}
              data={this.props.transactionTypes}
                  events={
                    {
                      onChange: (event) => {this.onDropDownChange('nbtTransactionCode', event.target.value)}
                    }
                  }
              />
            </div>
          </div>
          <div className="margin-top">
            <div className="modal-actions">
              <button
                className="button primary-button"
                onClick={this.openConfirm.bind(this)}
              >
                SUBMIT
              </button>
            </div>
          </div>
        </div>
        {this.renderConfirmDialog()}
      </div>
    );
  }
}

export default AddTransaction;
