export function sortDataSource(dataSource, sortColumn, sortableRefs) {
    for (let i = 0; i < sortableRefs.length; i++) {
        const sortable = sortableRefs[i]
        const key = sortable.props.sortableItem.key
        const direction = sortable.state.sortDirection

        if (direction === 'asc') {
            dataSource.sort((a, b) => {
                if (a[key] < b[key])
                    return -1
                if (a[key] > b[key])
                    return 1
                return 0
            })
        } else if (direction === 'desc') {
            dataSource.sort((a, b) => {
                if (a[key] < b[key])
                    return 1
                if (a[key] > b[key])
                    return -1
                return 0
            })
        }
    }

    return dataSource
}