/**
 * Super straightforward localization and more!
 *
 * ```
 *  email: {
 *      name: 'email',
 *      type: 'email',
 *      label: 'E-mail',
 *      validator: 'email'
 *  },
 *  date_admission: {
 *      name: 'date_admission',
 *      label: 'Date of Admission',
 *      placeholder: 'Date of Admission',
 *      type: 'month'
 *  }
 * ```
 * TODO Separate the translations from the definitions
 */

export const cipSettings = {
  us: {
    fields: {
      configuredDaysUntilCipDeclined: {
        name: 'configuredDaysUntilCipDeclined',
        label: 'Days until Account Decline',
        placeholder: 'Days CIP can remain unresolved until moved to Decline status.',
        type: "number",
        validator: null
      },
      configuredCipEnabledAccountTypeIds: {
        name: 'configuredCipEnabledAccountTypeIds',
        label: 'Product that requires CIP processing',
        placeholder: 'Product that requires CIP processing.',
        type: 'dropdown',
        data: {
        },
        validator: null
      },
       configuredCipEnabledHsaCustodianIds: {
        name: 'configuredCipEnabledHsaCustodianIds',
        label: 'HSA Custodian',
        type: 'dropdown',
        data: {
        },
        validator: null
      }
    }
  }
}

export const notificationSettings = {
  us: {
    fields: {
       configuredDaysBeforeCipDeclinedNotification: {
        name: 'configuredDaysBeforeCipDeclinedNotification',
        label: 'Days prior to Decline for ER Reminder',
        placeholder: 'Days prior to Decline for ER Reminder',
        type: "number",
        validator: null
      },
      configuredDaysForEsignReminderInterval: {
        name: 'configuredDaysForEsignReminderInterval',
        label: 'E-Sign Reminder',
        placeholder: 'Day interval to remind user of E-Sign. ',
        type: "number",
        validator: null
      },
      configuredDaysToHoldReleaseForChecks: {
        name: 'configuredDaysToHoldReleaseForChecks',
        label: 'Days to hold deposited check.',
        placeholder: 'Days to hold deposited check.',
        type: "number",
        validator: null
      },
       configuredDaysToHoldReleaseForCardSwipes: {
        name: 'configuredDaysToHoldReleaseForCardSwipes',
        label: 'Days to hold card.',
        placeholder: 'Days to hold card.',
        type: "number",
        validator: null
      },
      configuredDaysForCipReminder1: {
        name: 'configuredDaysForCipReminder1',
        label: 'CIP Participant Reminder 1',
        placeholder: 'Number of days before first reminder of pending CIP.',
        type: "number",
        validator: null
      },
      configuredDaysForCipReminder2: {
        name: 'configuredDaysForCipReminder2',
        label: 'CIP Participant Reminder 2',
        placeholder: 'Number of days before second reminder of pending CIP.',
        type: "number",
        validator: null
      },
      configuredDaysForCipReminder3: {
        name: 'configuredDaysForCipReminder3',
        label: 'CIP Participant Reminder 3',
        placeholder: 'Number of days before third reminder of pending CIP.',
        type: "number",
        validator: null
      },
    }
  }
}
