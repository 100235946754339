import React from 'react'
import TableView from "compositions/TableDisplay/TableView";
import ConfirmDialog from 'components/ConfirmDialog'
import {filterDataSource} from "lib/filter";
import {sortDataSource} from "lib/sort";
import Button from 'components/Button'
import actions from 'actions'
import Modal from "components/Modal";
import Loading from "components/Loading";
import ViewSamples from "../ViewSamples";
import ApprovalTracking from "../ApprovalTracking";
import { hasRole } from '../../../../redux/utility/SessionUtil';
import { showSnackbar } from '../../../../redux/utility/SnackbarUtil';

const headers = [
    {
        filterText: 'Year-Month',
        header: () => 'Year-Month',
        key: 'monthYear',
        enableSort: true
    },
    {
        filterText: 'Type',
        header: () => 'Type',
        key: 'type',
        enableSort: true
    },
    {
        filterText: 'Send Status',
        header: () => 'Send Status',
        key: 'sendStatus',
        enableSort: true
    },
    {
        filterText: 'Ready for Send Date',
        header: () => 'Ready for Send Date',
        key: 'readyForSendDate',
        enableSort: true
    },
    {
        filterText: 'Send Date',
        header: () => 'Send Date',
        key: 'sendDate',
        enableSort: true
    },
    {
        header: () => 'viewSamples',
        key: 'viewSamples',
        disableFilter: true
    },
    {
        header: () => 'approve',
        key: 'approve',
        disableFilter: true
    },
    {
        header: () => 'approvalTracking',
        key: 'approvalTracking',
        disableFilter: true
    },
    {
        header: () => 'generate',
        key: 'generate',
        disableFilter: true
    }
]

class StatementApproval extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dataSource: [],
            filteredDataSource: [],
            openViewSampleModal: false,
            openApprovalTrackingModal: false,
            openGernerateModal: false,
            initialSortLabel: "sendDate",
            initialSortDirection: "desc",
            statementId:"",
            monthYear:"",
            openApprovalConfirmationDialog: false,
            openGenerateConfirmationDialog: false,
            approvalAccess: hasRole('NBTStatementApprover'),
            generateAccess: hasRole('GenerateStatements')
        };
        this.actions = [];
    }

    componentDidMount () {
        if(this.verifyAccess()){
            this.getStatementsList();
        }
    }
    
    /*
    * Verify that the user has access to upload transaction
    */
    verifyAccess () {
        if (!(this.state.approvalAccess || this.state.generateAccess)) {
            this.props.history.push('/nbtadmin/unauthorized')
            return false
        }
        return true
    }

    onCloseViewSampleModal() {
        this.setState({openViewSampleModal: false});
    }

    onCloseApprovalTrackingModal() {
        this.setState({openApprovalTrackingModal: false});
    }

    onCloseCipUpdateModal() {
        this.setState({openCipUpdateModal: false});
    }

    onCloseGenerateModal() {
        this.setState({openGernerateModal: false});
    }

    /*
    * Update form values
    *
    * @param [String] name. Field name of form to update.
    * @param [Any] value. Value.
    */
    onChange (name, value) {
        let form = {...this.state.form}
        form[name] = value
        this.setState({form})
    }

    /*
    * Filter data source.
    *
    * @param [Object] queryParams. Key value pair containing filters.
    */
    onFilter(queryParams, sortableRefs) {
        const {dataSource} = this.state;
        const newDataSource = filterDataSource(dataSource, queryParams);
        const sortedDataSource = sortDataSource(newDataSource, queryParams, sortableRefs)
        this.setState({filteredDataSource: sortedDataSource});
    }

    onSort(queryParams, sortableRefs) {
        const {filteredDataSource} = this.state
        const sortedDataSource = sortDataSource(filteredDataSource, queryParams, sortableRefs)
        this.setState({filteredDataSource: sortedDataSource})
    }

    getStatementsList() {
        this.setState({loading: true})
        return actions.statementApproval.getStatementsList().then(
            statementsData => {
                const statements = statementsData.map(statement => {
                    const {
                        id, monthYear, type, status, readyForSendDate, sendDate
                    } = statement
                        return {
                            statementId: id,
                            monthYear,
                            type,
                            sendStatus: status,
                            readyForSendDate: readyForSendDate==null? "":readyForSendDate,
                            sendDate: sendDate==null? "":sendDate,
                            viewSamples: (<div style={{ width: '100%'}}><Button
                                            className={"primary-button margin-top"}
                                            events={
                                                {
                                                    onClick: this.handleViewSampleClick.bind(this, id, monthYear)
                                                }
                                            }
                                            >
                                            <span style={{fontSize : '15px'}}> 
                                                View Samples
                                            </span>
                                            </Button>
                                            </div>),
                            approve: (<div style={{ width: '100%'}}><Button
                                        className={`primary-button margin-top`}
                                        disabled={this.state.approvalAccess && (status==="In Progress" || status==="Complete") ? false : true}
                                        events={
                                        {
                                            onClick: this.handleApprovalClick.bind(this, id)
                                        }
                                        }
                                        >
                                        <span style={{fontSize : '15px'}}> 
                                            Approve
                                        </span>
                                    </Button></div>),
                            approvalTracking: (<div style={{ width: '100%'}}><Button
                                        className={`primary-button margin-top`}
                                        events={
                                        {
                                            onClick: this.handleApprovalTrackingClick.bind(this, id)
                                        }
                                        }
                                        >
                                        <span style={{fontSize : '15px'}}> 
                                            Approval Tracking
                                        </span>
                                    </Button></div>),
                            generate: (<div style={{ width: '100%'}}><Button
                                        className={`primary-button margin-top`}
                                        disabled={this.state.generateAccess && (status==="Complete") ? false : true}
                                        events={
                                        {
                                            onClick: this.handleGenerateClick.bind(this, id)
                                        }
                                        }
                                        >
                                        <span style={{fontSize : '15px'}}> 
                                            Generate
                                        </span>
                                    </Button></div>)
                        }
                })
                this.setState({
                    dataSource: statements,
                    filteredDataSource: statements,
                    loading: false
                })
            }).catch(() => {
                this.setState({loading: false});
                showSnackbar('Unable to fetch statments list.')
            });
    }

    handleViewSampleClick(statementId, monthYear){
        this.setState({openViewSampleModal: true, statementId: statementId, monthYear: monthYear});
    }

    handleApprovalClick(statementId){
        this.setState({ openApprovalConfirmationDialog: true, statementId:statementId});
    }

    handleApprovalTrackingClick(statementId){
        this.setState({openApprovalTrackingModal: true, statementId: statementId})
    }

    handleGenerateClick(statementId){
        this.setState({ openGenerateConfirmationDialog: true, statementId:statementId});
    }

    renderConfirmDialog () {
        if (this.state.openApprovalConfirmationDialog) {
            return (
                <ConfirmDialog
                description='Are you sure you want to approve this statement?'
                onClose={() => { this.setState({openApprovalConfirmationDialog: false}) }}
                onConfirm={() => { this.approveStatement() }}
                />
            )
        }
        else if (this.state.openGenerateConfirmationDialog) {
            return (
                <ConfirmDialog
                description='Are you sure you want to generate the statement?'
                onClose={() => { this.setState({openGenerateConfirmationDialog: false}) }}
                onConfirm={() => { this.generateStatement() }}
                />
            )
        }
    }

    approveStatement() {
        this.setState({openApprovalConfirmationDialog: false})
        this.setState({loading: true})
        actions.statementApproval.approveStatement(this.state.statementId).then(() => {
            this.getStatementsList()
        }).catch(err => {
            this.setState({loading: false})
        })
    }

    generateStatement() {
        this.setState({openGenerateConfirmationDialog: false})
        this.setState({loading: true})
        actions.statementApproval.generateStatement(this.state.statementId).then(() => {
            this.getStatementsList()
        }).catch(err => {
            this.setState({loading: false})
        })
    }

    renderViewSample() {
        const {openViewSampleModal} = this.state;
        if (openViewSampleModal) {
            return (
                <Modal
                    onClose={this.onCloseViewSampleModal.bind(this)}
                    showClose={true}
                >
                    <ViewSamples
                        statementId = {this.state.statementId}
                        monthYear = {this.state.monthYear}
                    />
                </Modal>
            );
        }
    }

    renderApprovalTracking() {
        const {openApprovalTrackingModal} = this.state;
        if (openApprovalTrackingModal) {
            return (
                <Modal
                    onClose={this.onCloseApprovalTrackingModal.bind(this)}
                    showClose={true}
                >
                    <ApprovalTracking
                        statementId = {this.state.statementId}
                    />
                </Modal>
            );
        }
    }   

    render () {
        if (this.state.loading)
            return (
                <div>
                    <Loading/>
                </div>
            );
        const {filteredDataSource} = this.state;
        return (  
            <div className='search-page'>
                <h4>Statement Approval</h4>
                    <TableView
                        dataActions={this.actions}
                        dataSource={filteredDataSource}
                        tableHeaders={headers}
                        tableFilters={headers}
                        onFilter={this.onFilter.bind(this)}
                        onSort={this.onSort.bind(this)}
                        initialSortLabel={this.state.initialSortLabel}
                        initialSortDirection={this.state.initialSortDirection}
                    />
                    {this.renderViewSample()}
                    {this.renderApprovalTracking()}
                    {this.renderConfirmDialog()}
            </div>
        );
    }
}

export default StatementApproval
