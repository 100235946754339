import {createAccountClosure, createAccountClosureDocument, deleteAccountClosureDocument,
  createAccountClosureNote, getAccountClosure, getAccounts,
  getAccountById, updateAccountClosure, changeAccountNumber, getHsaAdvanceAvailableBalance, getDirectDepositByEmployeeId,
  getDirectDepositConfirmedByEmployeeId, getHsaProcessorById, reactivateAccount, getReactivationAudit} from './accounts'
import { getBatchWireRecords, sendBatchWireFileIdForFileResend } from './batch-wires'
import {
  getConsentFormsByCustodian,
  getConsentFormsById,
  createConsentForm,
  getConsentUrlsByConsentFormId,
  getConsentUrlById,
  createConsentUrl,
  uploadConsentData,
  getExportConsentData,
  getUploadedConsentFilesByDateRange,
  updateConsentUrl
} from './consent-form'
import {getEmployerById, getEmployers} from './employers'
import {getEmailTemplates, getEmailTemplateById, deleteEmailHierarchyTemplate,
  createEmailTemplate, updateEmailTemplate, createEmailHierarchy, getEmailHierarchyTemplatesById } from './email-templates'
import {expeditedUpload} from "./expeditedUpload";
import {getFees, getFeeById, updateFee} from './fees'
import {createFeeProfile, createFeeProfileHierarchy, deactivateFeeProfile,
  getFeeProfiles, getFeeProfileById, getFeeProfilesForHierarchy,
  updateFeeProfile, updateFeeProfileHierarchy, getFeeProfileCountById, getInstancesById} from './fee-profiles'
import {createFraudRule, deactivateFraudRule, getFraudRules, getFraudRuleById,
  updateFraudRule} from './fraud-rules'
import {getHierarchies, getHierarchyById, getHierarchyMinimalListByHierarchyIdAndHierarchyType, initializeSearchParams} from './hierarchies'
import {getAllHsaxEmployers, postHsaxEmployerConfiguration, editHsaxEmployerConfiguration, getUIVersion, getHsaxEmployer} from './hsax'
import {createInterestProfile, createInterestProfileHierarchy, deactivateInterestProfile,
  getInterestProfiles, getInterestProfileById, getInterestProfilesForHierarchy, updateInterestProfile, updateInterestProfileHierarchy, getInterestProfileCountById, getInterestInstancesById} from './interest-profiles'
import {getSponsorsByCustodian, getDistributorsBySponsors,
  getEmployersByDistributors, createBulkTransfer} from './bulk-transfers'
import {getMailTemplates, getMailTemplateById, deleteMailHierarchyTemplate,
  createMailTemplate, updateMailTemplate, getLetterTypes, createMailHierarchy, getMailHierarchyTemplatesById } from './mail-templates'
import {getSettings, updateSettings} from './settings'
import {getCustodians, uploadTOA, getTOAPostingExceptions, reprocessTOAPosting, getOmnibusPartnerBanks,
  getUnenrolledTransfers, changeTransferStatusToTransfer, changeTransferStatusToDoNotTransfer} from './toa'
import {getTransactionsByAccountId, addTransactionByAccountId, getTransactionTypes,updatePostingStatusByTransactionId} from './transactions'
import {getImageUrlByLockboxDepositId} from './lockbox-image'
import {transactionUpload} from './transaction-upload'
import {uploadFile, getFileTrackingRecords, getFileDetailRecords, getFileDetailDownload} from './beneficiary'
import {getTermsAndConditions, getActiveTermsAndConditions, getFile, uploadTermsAndConditions, editTermsAndConditions,
  setActive, getByHierarchyId, getDefault, saveDefault, saveConfig} from "./terms-and-conditions";
import {getAccountEscheatmentReport, getAccountEscheatmentJobDetails, uploadAccountEscheatment, getUploadVerificationResponse, insertEscheatmentRecords} from './account-escheatment'
import {getDisclaimersByCustodians, getDisclaimersByMaster, updateDisclaimerByCustodianIdAndType, updateDisclaimerByMaster} from "./disclaimers";
import {getDeadLetterReport} from './dead-letter'
import {getEnrollmentsByEmployeeAndEmployerIds, getEnrollmentByEnrollmentId, reprocessEnrollment} from './enrollments'
import { getEmployerBillingReport, exportEmployerBillingReport } from './employer-billing-report'
import { getEmployeeBillingReport, exportEmployeeBillingReport } from './employee-billing-report'
import { getSummaryBillingReport, exportSummaryBillingReport } from './er-summary-billing-report'
import { getMailCorrespondenceReport, exportMailCorrespondenceReport } from './mail-correspondence-report'
import { getBAIReconFiles, submitReconFile } from './bai-recon'
import { getCIPOrdersByEmployeeIdAndEmployerId, updateAndRetryCipOrder, retryCipProcessing} from './cip-order'
import { getStatementsList, getStatementSampleAccounts, approveStatement, generateStatement,
  getSampleAccountStatementDownload, getGeneratedAccountStatementDownload, getStatementApprovers} from './statement-approval'
import { getNotificationRequeue, requeueSingleNotification, requeueMultipleNotifications } from './notification-requeue'

var actions = {
  accounts: {
    createAccountClosure,
    createAccountClosureDocument,
    deleteAccountClosureDocument,
    createAccountClosureNote,
    getAccountClosure,
    getAccounts,
    getAccountById,
    changeAccountNumber,
    updateAccountClosure,
    getHsaAdvanceAvailableBalance,
    getDirectDepositByEmployeeId,
    getDirectDepositConfirmedByEmployeeId,
    getHsaProcessorById,
    reactivateAccount,
    getReactivationAudit
  },
  batchWireRecords: {
    getBatchWireRecords,
    sendBatchWireFileIdForFileResend
  },
  enrollments: {
    getEnrollmentsByEmployeeAndEmployerIds,
    getEnrollmentByEnrollmentId,
    reprocessEnrollment
  },
  bulkTransfer: {
    createBulkTransfer,
    getDistributorsBySponsors,
    getEmployersByDistributors,
    getSponsorsByCustodian
  },
  consentForms: {
    getConsentFormsByCustodian,
    getConsentFormsById,
    createConsentForm,
    getConsentUrlsByConsentFormId,
    getConsentUrlById,
    createConsentUrl,
    uploadConsentData,
    getExportConsentData,
    getUploadedConsentFilesByDateRange,
    updateConsentUrl
  },
  emailTemplates: {
    createEmailTemplate,
    getEmailTemplates,
    getEmailTemplateById,
    updateEmailTemplate,
    createEmailHierarchy,
    getEmailHierarchyTemplatesById,
    deleteEmailHierarchyTemplate
  },
  employers: {
    getEmployerById,
    getEmployers
  },
  expeditedUpload: {
    expeditedUpload
  },
  fees: {
    getFees,
    getFeeById,
    updateFee
  },
  feeProfiles: {
    createFeeProfile,
    createFeeProfileHierarchy,
    deactivateFeeProfile,
    getFeeProfiles,
    getFeeProfileById,
    getFeeProfilesForHierarchy,
    updateFeeProfile,
    updateFeeProfileHierarchy,
    getFeeProfileCountById,
    getInstancesById
  },
  fraudRules: {
    createFraudRule,
    deactivateFraudRule,
    getFraudRules,
    getFraudRuleById,
    updateFraudRule
  },
  hierarchies: {
    getHierarchies,
    getHierarchyById,
    getHierarchyMinimalListByHierarchyIdAndHierarchyType,
    getDistributorsBySponsors,
    initializeSearchParams
  },
  hsax: {
    getAllHsaxEmployers,
    postHsaxEmployerConfiguration,
    editHsaxEmployerConfiguration,
    getUIVersion,
    getHsaxEmployer
  },
  interestProfiles: {
    createInterestProfile,
    createInterestProfileHierarchy,
    deactivateInterestProfile,
    getInterestProfiles,
    getInterestProfileById,
    getInterestProfilesForHierarchy,
    updateInterestProfile,
    updateInterestProfileHierarchy,
    getInterestProfileCountById,
    getInterestInstancesById
  },
  mailTemplates: {
    createMailTemplate,
    getMailTemplates,
    getMailTemplateById,
    getLetterTypes,
    updateMailTemplate,
    createMailHierarchy,
    getMailHierarchyTemplatesById,
    deleteMailHierarchyTemplate
  },
  settings: {
    getSettings,
    updateSettings
  },
  toa: {
    getCustodians,
    uploadTOA,
    getTOAPostingExceptions,
    reprocessTOAPosting,
    getOmnibusPartnerBanks,
    getUnenrolledTransfers,
    changeTransferStatusToTransfer,
    changeTransferStatusToDoNotTransfer
  },
  transactions: {
    getTransactionsByAccountId,
    getTransactionTypes,
    addTransactionByAccountId,
    updatePostingStatusByTransactionId
  },
  lockboxImage: {
	  getImageUrlByLockboxDepositId
  },	
  transactionUpload: {
    transactionUpload
  },
  beneficiary: {
    uploadFile,
    getFileTrackingRecords,
    getFileDetailRecords,
    getFileDetailDownload
  },
  termsAndConditions: {
    getTermsAndConditions,
    getActiveTermsAndConditions,
    getFile,
    uploadTermsAndConditions,
    editTermsAndConditions,
    setActive,
    getByHierarchyId,
    getDefault,
    saveDefault,
    saveConfig
  },
  deadLetter: {
    getDeadLetterReport
  },
  accountEscheatment: {
    getAccountEscheatmentReport,
    getAccountEscheatmentJobDetails,
    uploadAccountEscheatment,
    getUploadVerificationResponse,
    insertEscheatmentRecords
  },
  disclaimers: {
    getDisclaimersByCustodians,
    getDisclaimersByMaster,
    updateDisclaimerByCustodianIdAndType,
    updateDisclaimerByMaster
  },
  employerBillingReport: {
    getEmployerBillingReport,
    exportEmployerBillingReport
  },
  employeeBillingReport: {
    getEmployeeBillingReport,
    exportEmployeeBillingReport
  },
  erSummaryBillingReport: {
    getSummaryBillingReport,
    exportSummaryBillingReport
  },
  mailCorrespondenceReport: {
   getMailCorrespondenceReport,
   exportMailCorrespondenceReport
  },
  baiReconFile: {
    getBAIReconFiles,
    submitReconFile
  },
  cipOrder: {
    getCIPOrdersByEmployeeIdAndEmployerId,
    updateAndRetryCipOrder,
    retryCipProcessing
  },
  statementApproval: {
   getStatementsList,
   getStatementSampleAccounts,
   approveStatement,
   generateStatement,
   getSampleAccountStatementDownload,
   getGeneratedAccountStatementDownload,
   getStatementApprovers
  },
  notificationRequeue: {
   getNotificationRequeue,
   requeueSingleNotification,
   requeueMultipleNotifications
  }
};

export default actions;
