import React from 'react'
import PropTypes from 'prop-types'
import Loading from 'components/Loading'
import TableView from './TableView'

class TableDisplay extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      tableFilters: {}
    }
  }

  /*
  * Render the menu for the table
  */
  renderTableMenu () {
    if (!this.props.disableAdd) {
      return (
        <div className='text-right'>
          <a onClick={this.onAdd.bind(this)}>
            <i className='material-icons orange'>add_circle_outline</i>
          </a>
        </div>
      )
    }
  }

  /*
  * Set table filters
  */
  onFilter (tableFilters, sortableRefs) {
    this.setState({tableFilters})
    this.props.onFilter(tableFilters, sortableRefs)
  }

  /*
  * On add item
  */
  onAdd () {
    this.props.onAdd()
  }


  render () {
    const {loading, pageDescription, pageTitle} = this.props

    return (
      <div>


        {pageTitle && <h4>{pageTitle}</h4>}
        {pageDescription && <p>{pageDescription}</p>}

        {loading ?
          <Loading /> :
          <div>
            {this.renderTableMenu()}
            <TableView
              {...this.props}
              onFilter={this.onFilter.bind(this)}
              tableFilters={this.state.tableFilters}
            />
          </div>
        }
      </div>
    )
  }
}

TableDisplay.propTypes = {
  loading: PropTypes.bool,
  pageDescription: PropTypes.string,
  pageTitle: PropTypes.string,
}

export default TableDisplay
