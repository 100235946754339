import React from 'react'
import Card from 'components/Card'
import ConfirmDialog from 'components/ConfirmDialog'
import Loading from 'components/Loading'
import TextField from 'components/TextField'
import actions from 'actions'

const rule = {
  id: 1,
  name: 'Fraud Rule 1',
  category: 'Risk',
  description: 'Minimize risk',
  active: true
}

class FraudRule extends React.Component {
  constructor (props) {
    super (props)
    this.state = {
      rule: rule || null,
      openConfirmationDialog: false
    }
  }

  /*
  * Get rule by id.
  */
  componentWillMount () {
    // this.getRule()
  }

  /*
  * Fetch rule by id.
  */
  getRule () {
    const {match: {params: {id}}} = this.props

    this.setState({loading: true})
    actions.fraudRules.getRuleById(id).then(rule => {
      this.setState({rule, loading: false})
    }).catch(() => this.setState({loading: false}))
  }

  /*
  * Render the rule meta data
  */
  renderDetails () {
    const {id, name, description, category, active} = this.state.rule

    return (
      <Card className='padding'>
        {this.renderActionsMenu()}
        <h5>Details</h5>

        <div className='row'>
          <div className='col'>
            <TextField
              label='ID'
              name='id'
              value={id}
            />
          </div>
          <div className='col'>
            <TextField
              label='Name'
              name='name'
              value={name}
            />
          </div>
        </div>
        <div className='row margin-top'>
          <div className='col'>
            <TextField
              label='Category'
              name='category'
              value={category}
            />
          </div>
          <div className='col'>
            <TextField
              label='Active'
              name='active'
              value={active === true ? 'Yes' : 'No'}
            />
          </div>
        </div>
        <div className='row margin-top'>
          <div className='col'>
            <TextField
              label='Description'
              name='description'
              value={description}
            />
          </div>
        </div>
      </Card>
    )
  }

  /*
  * Render the actions menu
  */
  renderActionsMenu () {
    return (
      <div className='text-right'>
        <a onClick={this.onEdit.bind(this)}>
          <i className='material-icons orange' style={{fontSize: '1.2em'}}>edit</i>
        </a>
        <a style={{marginLeft: '8px'}} onClick={this.onDelete.bind(this)}>
          <i className='material-icons orange' style={{fontSize: '1.2em'}}>delete</i>
        </a>
      </div>
    )
  }

  /*
  * Render the confirm delete dialog
  */
  renderConfirmDialog () {
    if (this.state.openConfirmationDialog) {
      return (
        <ConfirmDialog
          description='Are you sure you want to deactivate this rule?'
          onClose={() => { this.setState({openConfirmationDialog: false}) }}
          onConfirm={() => { this.deleteRule() }}
        />
      )
    }
  }

  /*
  * On edit
  */
  onEdit () {
    const {history} = this.props
    history.push(`/nbtadmin/fraud-rules/${this.state.rule.id}/edit`)
  }

  /*
  * On delete, open confirmation dialog
  */
  onDelete () {
    this.setState({openConfirmationDialog: true})
  }

  /*
  * Delete rule
  */
  deleteRule () {
    actions.fraudRules.deactivateRule(this.state.rule).then((updatedRule) => {
      this.setState({rule: updatedRule, openConfirmationDialog: false})
    }).catch(() => false)
  }

  render () {
    if (this.state.loading) return <div className='margin-top'><Loading/></div>

    const {name} = this.state.rule

    return (
      <div className='page rule-view'>
        <h4>{`${name}'s Rule`}</h4>
        {this.renderDetails()}
        {this.renderConfirmDialog()}
      </div>
    )
  }
}

export default FraudRule
