import React from "react"
import Loading from "components/Loading"
import actions from "actions"
import { hasRole } from '../../../../redux/utility/SessionUtil'
import TableDisplay from "../../../../compositions/TableDisplay";
import {filterDataSource} from "../../../../lib/filter";
import ConfirmDialog from "../../../../components/ConfirmDialog";

let headers = [
  {
    filterText: 'Employer',
    header: () => 'Employer',
    key: 'employer'
  },
  {
    filterText: 'First Name',
    header: () => 'First Name',
    key: 'firstName'
  },
  {
    filterText: 'Last Name ',
    header: () => 'Last Name ',
    key: 'lastName'
  },
  {
    filterText: ' Account Number',
    header: () => ' Account Number',
    key: 'accountNumber'
  },
  {
    filterText: 'External Account Id',
    header: () => 'External Account Id',
    key: 'extAccountId'
  },
  {
    filterText: 'SSN',
    header: () => 'SSN',
    key: 'ssn'
  },
  {
    filterText: 'Account Status',
    header: () => 'Account Status',
    key: 'accountStatus'
  }
];

class TOAPostingExceptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initializeState();

    this.actions = [
      {
        action: this.onView.bind(this),
        icon: 'refresh'
      }
    ]
  }

  componentDidMount() {
    if (this.verifyAccess()) {
      this.getTOAPostingExceptions()
    }
  }

  onView(exception) {
    this.setState({txId: exception.txId});
    this.setState({openReprocessDialog: true});
  }

  onRowClick(exception) {
    this.setState({txId: exception.txId});
    this.setState({openReprocessDialog: true});
  }

  onFilter(queryParams) {
    const {dataSource} = this.state;
    const newDataSource = filterDataSource(dataSource, queryParams);
    this.setState({filteredDataSource: newDataSource})
  }

  /*
  * Render the reprocess tx dialog
  */
  renderConfirmDialog() {
    if (this.state.openReprocessDialog) {
      console.log('reprocess dialog.');
      return (
        <ConfirmDialog
          description='Are you sure you want to reprocess this transaction?'
          onClose={() => {
            this.setState({openReprocessDialog: false})
          }}
          onConfirm={() => {
            actions.toa.reprocessTOAPosting(this.state.txId)
              .then(() => {
                console.log('success reprocess tx');
                this.setState({openReprocessDialog: false})
              })
              .catch(() => {
                  console.log('error reprocess tx');
                  this.setState({openReprocessDialog: false})
                }
              )
          }
          }
        />
      )
    }
  }

  initializeState() {
    return {
      dataSource: [],
      filteredDataSource: [],
      txId: null,
      readAccess: hasRole('BulkTransferEdit') && hasRole('TOAExceptionsEdit'),
      writeAccess: hasRole('BulkTransferEdit') && hasRole('TOAExceptionsEdit'),
      loadingExceptions: false,
      openReprocessDialog: false
    }
  }

  verifyAccess() {
    if (!(this.state.readAccess || this.state.writeAccess)) {
      this.props.history.push('/nbtadmin/unauthorized')
      return false
    }

    return true
  }


  getTOAPostingExceptions() {
    this.setState({loadingExceptions: true});
    actions.toa.getTOAPostingExceptions().then(exceptions => {
      const mappedExceptions = exceptions.map(exception => {
        const {
          txId,
          employer,
          firstName,
          lastName,
          extAccountId,
          ssn,
          accountStatus,
          accountNumber} = exception;
        return {
          txId,
          employer,
          firstName,
          lastName,
          extAccountId,
          ssn,
          accountStatus,
          accountNumber
        }
      });
      this.setState({
        dataSource: mappedExceptions,
        filteredDataSource: mappedExceptions,
        loadingExceptions: false,
      })
    }).catch(() => this.setState({loadingExceptions: false}))
  }

  render() {
    if (this.state.loadingExceptions) return <div className='margin-top'><Loading/></div>
    return (
      <div className='search-page toa-exceptions-view'>
        <h4>Transfer of Assets Posting Exceptions</h4>

        <TableDisplay
          dataActions={this.actions}
          dataSource={this.state.filteredDataSource}
          disableAdd={true}
          loading={this.state.loadingExceptions}
          onFilter={this.onFilter.bind(this)}
          onRowClick={this.onRowClick.bind(this)}
          tableHeaders={headers}
        />
        {this.renderConfirmDialog()}
      </div>
    )


  }
}

export default TOAPostingExceptions