import React from 'react'
import Card from 'components/Card'
import Loading from 'components/Loading'
import TextField from 'components/TextField'
import actions from 'actions'
import { hasRole } from '../../../../redux/utility/SessionUtil'

class Fee extends React.Component {
  constructor (props) {
    super (props)
    this.state = {
      fee: null,
      openConfirmationDialog: false,
      readAccess: hasRole('FeesMasterView'),
      writeAccess: hasRole('FeesMasterEdit')
    }
  }

  /*
  * Get fee by id
  */
  componentWillMount () {
    if (this.verifyAccess()) {
      this.getFee()
    }
  }

  /*
  * Verify that the user has access to fees
  */
  verifyAccess () {
    if (!(this.state.readAccess || this.state.writeAccess)) {
      this.props.history.push('/nbtadmin/unauthorized')
      return false
    }

    return true
  }

  /*
  * Fetch fee by id.
  */
  getFee () {
    const {match: {params: {id}}} = this.props

    this.setState({loading: true})
    actions.fees.getFeeById(id).then(fee => {
      this.setState({fee, loading: false})
    }).catch(() => this.setState({loading: false}))
  }

  /*
  * Render the fee meta data
  */
  renderDetails () {
    const {name, chargeFrequency, waivable,
      type, glCode, transactionCode, description} = this.state.fee

    return (
      <Card className='padding'>
        {this.renderActionsMenu()}
        <h5>Details</h5>

        <div className='row'>
          <div className='col'>
            <TextField
              label='Name'
              name='name'
              value={name}
            />
          </div>
        </div>
        <div className='row margin-top'>
          <div className='col'>
            <TextField
              label='Charge Frequency'
              name='chargeFrequency'
              value={chargeFrequency}
            />
          </div>
          <div className='col'>
            <TextField
              label='Type'
              name='type'
              value={type}
            />
          </div>
          <div className='col'>
            <TextField
              label='Waivable'
              name='waivable'
              value={waivable !== null ? waivable ? 'Yes' : 'No' : ''}
            />
          </div>
        </div>
        <div className='row margin-top'>
          <div className='col'>
            <TextField
              label='GL Code'
              name='glCode'
              value={glCode}
            />
          </div>
          <div className='col'>
            <TextField
              label='Transaction Code'
              name='transactionCode'
              value={transactionCode}
            />
          </div>
        </div>
        <div className='row margin-top'>
          <div className='col'>
            <TextField
              label='Description'
              name='description'
              value={description}
            />
          </div>
        </div>
      </Card>
    )
  }

  /*
  * Render the actions menu
  */
  renderActionsMenu () {
    if (this.state.writeAccess) {
      return (
        <div className='text-right'>
          <a onClick={this.onEdit.bind(this)}>
            <i className='material-icons orange' style={{fontSize: '1.2em'}}>edit</i>
          </a>
        </div>
      )
    }
  }

  /*
  * On edit
  */
  onEdit () {
    const {history} = this.props
    history.push(`/nbtadmin/fees/${this.state.fee.id}/edit`)
  }

  render () {
    if (this.state.loading) return <div className='margin-top'><Loading/></div>

    const {name} = this.state.fee

    return (
      <div className='page fee-view'>
        <h4>{name} Fee</h4>
        {this.renderDetails()}
      </div>
    )
  }
}

export default Fee
