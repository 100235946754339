import Fetch from 'lib/fetch'

/*
* Get image for lockbox by id
*
* @param [Number] id. Id of lockbox deposit to get image for.
*/
export function getImageUrlByLockboxDepositId (id) {
	  return Fetch.get(`${process.env.REACT_APP_LOCKBOX_DOMAIN}/lockbox/getImageUrlByLockboxDepositId/${id}`, null)
	    .then(data => data)
	    .catch(error => Promise.reject(error))
	}
