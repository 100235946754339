import React from 'react'
import {mapDateToForm} from 'lib/date'
import actions from "../../../../actions";
import Loading from 'components/Loading'
import { hasRole } from '../../../../redux/utility/SessionUtil'
import EnrollmentSearchForm from '../EnrollmentForms/EnrollmentSearchForm'
import TableDisplay from 'compositions/TableDisplay'
import {filterDataSource} from 'lib/filter'

let headers = [
    {
      filterText: 'CYC Account ID',
      header: () => 'CYC Account ID',
      key: 'cycAccountId'
    },
    {
        filterText: 'CIP Order ID',
        header: () => 'Cip Order ID',
        key: 'cipOrderId'
    },
    {
      filterText: 'Enrollment ID',
      header: () => 'Enrollment ID',
      key: 'enrollmentId'
    },
    {
      filterText: 'Enrollment Status',
      header: () => 'Enrollment Status',
      key: 'enrollmentStatus'
    },
    {
      filterText: 'Created Date',
      header: () => 'Created Date',
      key: 'created'
    },
    {
      filterText: 'Modified Date',
      header: () => 'Modified Date',
      key: 'modified'
    }
]

class EnrollmentsTableDisplay extends React.Component {
    constructor (props) {
        super (props);
        this.state = {
          form: this.initializeFormData(),
          dataSource: [],
          filteredDataSource: [],
          hasSearched: false,
          showSearch: true,
          loading: true,
          readAccess: hasRole('AccountMaintView') || hasRole('AccountMaintEdit')
        }

        this.actions = [
          {
            action: this.onView.bind(this),
            icon: 'visibility'
          }
        ]
    }

    componentWillMount() {
        if(this.verifyAccess()) {
          this.getEnrollmentsByEmployeeIdAndEnrollmentIds()
        }
    }

    verifyAccess() {
      if(!this.state.readAccess) {
          this.props.history.push('/nbtadmin/unauthorized')
          return false
      }
      return true
    }

    initializeFormData(){
      const {match: {params: {employeeId, employerId}}} = this.props
      return {
        employeeId: employeeId,
        employerId: employerId
      }
    }

    onSearch(form){
      this.setState({form: form}, () => this.getEnrollmentsByEmployeeIdAndEnrollmentIds())
      this.props.history.push(`/nbtadmin/enrollments/${form.employeeId}/${form.employerId}`)
    }

    onRowClick (enrollment) {
      this.props.history.push(`/nbtadmin/enrollments/${enrollment.enrollmentId}`)
    }
  
    onView (enrollment) {
      this.props.history.push(`/nbtadmin/enrollments/${enrollment.enrollmentId}`)
    }

    onFilter (queryParams) {
      const {dataSource} = this.state
      const newDataSource = filterDataSource(dataSource, queryParams)
      this.setState({filteredDataSource: newDataSource})
    }

    getEnrollmentsByEmployeeIdAndEnrollmentIds() {
      this.setState({loading: true})
      actions.enrollments.getEnrollmentsByEmployeeAndEmployerIds(this.state.form.employeeId, 
        this.state.form.employerId).then(enrollments => {
          const mappedEnrollments = enrollments.map(enrollment => {
            const {enrollmentId, cycAccountId, cipOrderId, enrollmentStatus,
                    created, modified} = enrollment
            return {
              enrollmentId,
              cycAccountId,
              cipOrderId,
              enrollmentStatus,
              created : mapDateToForm(created),
              modified : mapDateToForm(modified)
            }
          })
          this.setState({dataSource: mappedEnrollments, filteredDataSource: mappedEnrollments, loading: false, showSearch: false, hasSearched: true})
      }).catch(() => {
          this.setState({
            dataSource: [],
            filteredDataSource: [],
            loading: false
          })
        })
    }

    render() {
      if (this.state.loading) return <div className='margin-top'><Loading/></div>
      return (
        <div className='search-page enrollments-view'>
          <h4>Enrollments Search</h4>
          <div style={{width: "100%", margin: "0 auto"}}>
              <EnrollmentSearchForm
                  {...this.props}
                  onSubmit={this.onSearch.bind(this)}/>
          </div>
          <TableDisplay
            dataActions={this.actions}
            dataSource={this.state.filteredDataSource}
            disableAdd={true}
            loading={this.state.loading}
            onFilter={this.onFilter.bind(this)}
            onRowClick={this.onRowClick.bind(this)}
            tableHeaders={headers}
          />
        </div>
      )
    }
}

export default EnrollmentsTableDisplay