import React from 'react'
import PropTypes from 'prop-types'
import Card from 'components/Card'
import Field from 'components/forms/Field/index'
import actions from 'actions'
import {maskDate} from 'lib/mask'
import {mapDateToForm, mapFormDateToDate} from 'lib/date'
import feeProfile from 'data/feeProfile'

class FeeProfileHierarchyForm extends React.Component {
  constructor (props) {
    super (props)
    this.state = {
      feeProfiles: {},
      form: this.initializeFormData(props.feeProfileHierarchy)
    }
  }

  componentWillMount () {
    this.getFeeProfiles()
  }

  /*
  * Fetch fee profiles and map them to dropdown format.
  */
  getFeeProfiles () {
    actions.feeProfiles.getFeeProfiles().then((feeProfiles) => {
      let mappedFeeProfiles = {}
      //Only show active profiles or current profile even if it is not active
      feeProfiles.filter(p => p.deactivatedDate === null || p.id === this.state.form.feeProfileId).forEach(feeProfile => {
        mappedFeeProfiles[feeProfile.id] = feeProfile.name
      })

      this.setState({feeProfiles: mappedFeeProfiles})
    })
  }


  /*
  * Initialize form data
  * Fee profile hierarchy
  */
  initializeFormData (data) {
    const {id, hierarchyId, feeProfileId, startDate, endDate, active} = data || {}

    return {
      id: id || '',
      hierarchyId: hierarchyId || this.props.hierarchyId || '',
      feeProfileId: feeProfileId || '',
      startDate: startDate ? mapDateToForm(startDate) : '',
      endDate: endDate ? mapDateToForm(endDate) : '',
      active: active || ''
    }
  }

  /*
  * Render the fields
  */
  getFields () {
    const {form: {
      feeProfileId,
      startDate,
      endDate,
      active
    }} = this.state

    const {hierarchyFields} = feeProfile.us
    const fields = hierarchyFields
    const {formIndex} = this.props

    return [
        {
          ...fields.feeProfileId,
          name: `${fields.feeProfileId.name}-${formIndex}`,
          required: true,
          events: {
            onChange: (event) => {
              this.onChange(fields.feeProfileId.name, event.target.value)
            }
          },
          value: feeProfileId
        },
        {
          ...fields.startDate,
          name: `${fields.startDate.name}-${formIndex}`,
          required: true,
          events: {
            onChange: (event) => {
              const value = maskDate(this.state.form[fields.startDate.name], event.target.value)
              this.onChange(fields.startDate.name, value)
            }
          },
          value: startDate
        },
        {
          ...fields.endDate,
          name: `${fields.endDate.name}-${formIndex}`,
          required: false,
          events: {
            onChange: (event) => {
              const value = maskDate(this.state.form[fields.endDate.name], event.target.value)
              this.onChange(fields.endDate.name, value)
            }
          },
          value: endDate
        },
        {
          ...fields.active,
          name: `${fields.active.name}-${formIndex}`,
          required: false,
          events: {
            onChange: (event) => {
              this.onChange(fields.active.name, event.target.value)
            }
          },
          value: active
        }
      ]
  }

  /*
  * Update form values
  *
  * @param [String] name. Field name of form to update.
  * @param [Any] value. Value.
  */
  onChange (name, value) {
    let form = {...this.state.form}
    form[name] = value
    this.setState({form})
  }

  /*
  * Expose method to return form
  */
  getData () {
    return this.state.form
  }

  /*
  * Determine whether the user is submiting updated fee profile hierarchy or
  * a new fee profile hierarchy
  *
  */
  onSubmit (event) {
    event.preventDefault()
    const form = this.mapForm(this.state.form)
    !form.id ?
      this.onCreateFeeProfileHierarchy(form) : this.onUpdateFeeProfileHierarchy(form)
  }

  /*
  * Map form.
  */
  mapForm (f) {
    let form = {...f}
    form.startDate = mapFormDateToDate(form.startDate)
    form.endDate = mapFormDateToDate(form.endDate)
    return form
  }

  /*
  * Create a new fee profile for a hierarchy
  */
  onCreateFeeProfileHierarchy (form) {
    actions.feeProfiles.createFeeProfileHierarchy(form).then((feeProfile) => {
      this.props.onSubmit(feeProfile)
      this.props.onClose()
    }).catch(() => false)
  }

  /*
  * Update an existing fee profile hierarchy for a hierarchy
  */
  onUpdateFeeProfileHierarchy (form) {
    actions.feeProfiles.updateFeeProfileHierarchy(form).then((feeProfile) => {
      this.props.onSubmit(feeProfile)
      this.props.onClose()
    }).catch(() => false)
  }

  /*
  * Delete fee profile hierarchy.
  */
  onDeleteFeeProfileHierarchy () {
    // actions.feeProfiles.onDeleteFeeProfileHierarchy(this.state.form).then(() => {
    //  this.props.onRemove()
    //   this.props.onClose()
    // }).catch(() => false)
  }

  render () {
    const {feeProfiles} = this.state
    const fields = this.getFields()
    return (
      <div>
        <div className='modal-body'>
          <div className='row margin-top'>
            <div className='col'>
              <Field key={`${fields[0].name}`} {...fields[0]} data={feeProfiles} />
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <Field key={`${fields[1].name}`} {...fields[1]}  />
            </div>
            <div className='col'>
              <Field key={`${fields[2].name}`} {...fields[2]}  />
            </div>
          </div>
          <div className='row margin-bottom'>
            <div className='col'>
              <Field key={`${fields[3].name}`} {...fields[3]}  />
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          <div className='modal-actions'>
            <button
              className='button primary-button'
              onClick={this.onSubmit.bind(this)}
            >SUBMIT</button>
          </div>
        </div>
      </div>
    )
  }
}

export default FeeProfileHierarchyForm
