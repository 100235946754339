import React from 'react'
import TableDisplay from 'compositions/TableDisplay'
import Paginate from '../../../components/Paginate'
import { showSnackbar } from '../../../redux/utility/SnackbarUtil'
import actions from 'actions'
import Utils from '../../../lib/utils'
import Button from '../../../components/Button'
import Loading from '../../../components/Loading'

const COUNT_PER_PAGE = 50

const BENEFICIARY_HEADERS = [
    {
        filterText: 'File Name',
        header: () => 'File Name',
        key: 'filename',
        enableSort: true
    },
    {
        filterText: 'Status',
        header: () => 'Status',
        key: 'status',
        enableSort: true
    },
    {
        filterText: 'Uploaded By',
        header: () => 'Uploaded By',
        key: 'createdBy',
        enableSort: true
    },
    {
        filterText: 'Date',
        header: () => 'Date',
        key: 'created',
        disableFilter: true,
        enableSort: true
    }
]

const BENEFICIARY_DETAIL_HEADERS = [
    {
        filterText: 'Record Status',
        header: () => 'Record Status',
        key: 'recordStatus',
        enableSort: true
    },
    {
        filterText: 'Error Reason',
        header: () => 'Error Reason',
        key: 'errorReason',
        enableSort: true
    },
    {
        filterText: 'Created',
        header: () => 'Created',
        key: 'created',
        disableFilter: true,
        enableSort: true
    },
    {
        filterText: 'HSA FNAME',
        header: () => 'HSA FNAME',
        key: 'hsaFirstName',
        enableSort: true
    },
    {
        filterText: 'HSA LNAME',
        header: () => 'HSA LNAME',
        key: 'hsaLastName',
        enableSort: true
    },
    {
        filterText: 'Partial SSN',
        header: () => 'Partial SSN',
        key: 'partialSsn',
        enableSort: true
    },
    {
        filterText: 'EE ID',
        header: () => 'EE ID',
        key: 'employeeId',
        enableSort: true
    },
    {
        filterText: 'Type',
        header: () => 'Type',
        key: 'beneficiaryType',
        enableSort: true
    },
    {
        filterText: 'Name',
        header: () => 'Name',
        key: 'name',
        enableSort: true
    },
    {
        filterText: 'Address1',
        header: () => 'Address1',
        key: 'address1',
        enableSort: true
    },
    {
        filterText: 'SSN',
        header: () => 'SSN',
        key: 'ssn',
        enableSort: true
    },
    {
        filterText: 'DOB',
        header: () => 'DOB',
        key: 'dateOfBirth',
        disableFilter: true,
        enableSort: true
    },
    {
        filterText: 'Job ID',
        header: () => 'Job ID',
        key: 'jobId',
        enableSort: true
    },
    {
        filterText: 'Percentage',
        header: () => 'Percentage',
        key: 'percentage',
        enableSort: true
    },
    {
        filterText: 'REL ID',
        header: () => 'REL ID',
        key: 'relationshipId',
        enableSort: true
    },
    {
        filterText: 'Address 2',
        header: () => 'Address 2',
        key: 'address2',
        enableSort: true
    },
    {
        filterText: 'City',
        header: () => 'City',
        key: 'city',
        enableSort: true
    },
    {
        filterText: 'State',
        header: () => 'State',
        key: 'state',
        enableSort: true
    },
    {
        filterText: 'ZIP Code',
        header: () => 'ZIP Code',
        key: 'zipCode',
        enableSort: true
    },
    {
        filterText: 'Parent',
        header: () => 'Parent',
        key: 'parent',
        enableSort: true
    },
    {
        filterText: 'Parent Address',
        header: () => 'Parent Address',
        key: 'parentAddress',
        enableSort: true
    },
    {
        filterText: 'Parent SSN',
        header: () => 'Parent SSN',
        key: 'parentSsn',
        enableSort: true
    }
]

class BeneficiaryReport extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            reportDataSource: [],
            reportTotal: 0,
            reportLoading: false,
            reportPagination: false,
            detailDataSource: [],
            detailTotal: 0,
            detailLoading: false,
            detailPagination: false,
            showDetail: false,
            currentData: {},
            downloading: false
        }
        this.initTableData()
    }

    initTableData() {
        this.reportSortDirection = 'desc'
        this.reportSortColumn = 'created'
        this.reportPageNumber = 0
        this.detailSortDirection = 'asc'
        this.detailSortColumn = 'jobId'
        this.detailPageNumber = 0
    }

    componentDidMount() {
        this.setState({ reportLoading: true })
        this.onLoadReport()
    }

    exportAll() {
        const param = { ...this.state.currentData }
        this.setState({ downloading: true })
        actions.beneficiary.getFileDetailDownload(param.id)
            .then(data => data.blob())
            .then(response => {
                this.setState({ downloading: false })
                const url = window.URL.createObjectURL(response)
                const a = document.createElement('a')
                a.style.display = 'none'
                a.href = url
                a.download = 'BeneficiaryUploadReport-' + Utils.getCurrentDateTime() + '.csv'
                a.click()
                window.URL.revokeObjectURL(url)
            }).catch(() => {
                this.setState({ downloading: false })
                showSnackbar('Failed to download the file')
            })

    }

    updateReportPage(current) {
        // zero based index for page number
        this.reportPageNumber = current - 1
        this.setState({ reportLoading: true })
        this.onLoadReport()
    }

    updateDetailPage(current) {
        // zero based index for page number
        this.detailPageNumber = current - 1
        this.setState({ detailLoading: true })
        this.onLoadDetailReport()
    }

    onFilterTable(queryParams) {
        this.reportPageNumber = 0
        this.reportQueryParams = queryParams
        this.onLoadReport()
    }

    onSortTable(queryParams, sortableRefs) {
        this.reportPageNumber = 0
        this.reportSortDirection = sortableRefs.filter(row => row.props.sortableItem.key === queryParams.key)
            .map(row => row.state.sortDirection)
        this.reportSortColumn = queryParams.key
        this.onLoadReport()
    }

    onLoadReport() {
        let requestParams = {
            ...this.reportQueryParams,
            pageNumber: this.reportPageNumber,
            sortDirection: this.reportSortDirection,
            sortColumn: this.reportSortColumn
        }
        actions.beneficiary.getFileTrackingRecords(requestParams)
            .then(data => {
                this.setState({ reportDataSource: data.beneficiaryUploadFileTrackings, reportTotal: data.beneficiaryUploadFileTrackingsTotal, reportLoading: false, reportPagination: true })
            }).catch(() => {
                this.setState({ dataSource: [], reportLoading: false, reportTotal: 0, reportPagination: false })
                showSnackbar('Failed to retrieve data')
            })

    }

    onDetailFilterTable(queryParams) {
        this.detailPageNumber = 0
        this.setState({ detailPagination: false })
        this.detailQueryParams = queryParams
        this.onLoadDetailReport()
    }

    onDetailSortTable(queryParams, sortableRefs) {
        this.detailPageNumber = 0
        this.setState({ detailPagination: false })
        this.detailSortDirection = sortableRefs.filter(row => row.props.sortableItem.key === queryParams.key)
            .map(row => row.state.sortDirection)
        this.detailSortColumn = queryParams.key
        this.onLoadDetailReport()
    }

    onLoadDetailReport() {
        let requestParams = {
            trackingId: this.state.currentData.id,
            ...this.detailQueryParams,
            pageNumber: this.detailPageNumber,
            sortDirection: this.detailSortDirection,
            sortColumn: this.detailSortColumn
        }
        actions.beneficiary.getFileDetailRecords(requestParams)
            .then(data => {
                this.setState({
                    detailDataSource: data.beneficiaryUploadFileDetails, detailTotal: data.totalBeneficiaryUploadFileDetails,
                    detailLoading: false, detailPagination: true
                })
            }).catch(() => {
                this.setState({ detailDataSource: [], detailLoading: false, detailTotal: 0, detailPagination: false })
                showSnackbar('Failed to retrieve data')
            })
    }

    toggleTable(data) {
        this.setState({ currentData: data })
        if (this.state.showDetail) {
            this.setState({ showDetail: false })
        } else {
            this.setState({ showDetail: true, detailLoading: true })
            this.detailQueryParams = { trackingId: data.id }
            this.onLoadDetailReport()
        }
    }

    getTable() {
        return this.state.showDetail ? this.getDetailTable() : this.getMainTable()
    }

    getDetailTable() {
        return (<div>
            <i className='material-icons' onClick={this.toggleTable.bind(this)}>chevron_left</i>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}
            >
                {this.state.detailDataSource.length > 0 && <Button
                    className='width-auto margin-bottom'
                    disabled={this.state.downloading}
                    events={
                        {
                            onClick: (event) => {
                                if (this.state.downloading) {
                                    return;
                                }
                                this.exportAll();
                            }
                        }
                    }
                >
                    <span>
                        {this.state.downloading ? <Loading containerClassName='inline' className='white small' />
                            : 'Export All'}
                    </span>
                </Button>}
            </div>
            <div className="fixed-height-scroll">
                <div style={{
                    width: this.state.detailLoading ? '' : 'max-content'
                }}>
                    <TableDisplay
                        dataActions={[]}
                        dataSource={this.state.detailDataSource}
                        disableAdd={true}
                        loading={this.state.detailLoading}
                        onAdd={() => false}
                        onFilter={this.onDetailFilterTable.bind(this)}
                        onSort={this.onDetailSortTable.bind(this)}
                        singleColumnSort={true}
                        tableHeaders={BENEFICIARY_DETAIL_HEADERS}
                        pageTitle={'FILE NAME : ' + this.state.currentData.filename}
                        tableDataClassName='align-items-start overflow-wrap'
                        initialSortLabel='Job ID'
                        initialSortDirection='asc'
                    /></div>
                {(this.state.detailPagination && this.state.detailDataSource.length > 0) && (<Paginate
                    total={this.state.detailTotal}
                    countPerPage={COUNT_PER_PAGE}
                    updatePage={this.updateDetailPage.bind(this)}
                    style={{
                        marginTop: '1em'
                    }}
                />)}</div>
        </div>)
    }

    getMainTable() {
        return (<div><TableDisplay
            dataActions={[]}
            dataSource={this.state.reportDataSource}
            disableAdd={true}
            loading={this.state.reportLoading}
            onAdd={() => false}
            onFilter={this.onFilterTable.bind(this)}
            onSort={this.onSortTable.bind(this)}
            singleColumnSort={true}
            tableHeaders={BENEFICIARY_HEADERS}
            onRowClick={this.toggleTable.bind(this)}
            tableDataClassName='align-items-start overflow-wrap'
            initialSortLabel='Date'
            initialSortDirection='desc'
        />
            {(this.state.reportPagination && this.state.reportDataSource.length > 0) && (<Paginate
                total={this.state.reportTotal}
                countPerPage={COUNT_PER_PAGE}
                updatePage={this.updateReportPage.bind(this)}
                style={{
                    marginTop: '1em'
                }}
            />)}</div>)
    }


    render() {
        return (
            <div className='search-page'>
                {this.getTable()}
            </div>
        )
    }
}

export default BeneficiaryReport