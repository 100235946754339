import React from 'react'
import Button from 'components/Button'
import Card from 'components/Card'
import Loading from "components/Loading"
import actions from 'actions'
import { showSnackbar } from '../../../redux/utility/SnackbarUtil'
import Papa from 'papaparse'

class UploadEscheatment extends React.Component {
    constructor (props) {
        super (props);
        this.state = {
            loading: false
        };
    }

    // handle file upload
    handleFileUpload = e => {
        e.preventDefault();

        if(!this.props.uploadAccess){
            this.showDisabledPopUp()
            return
        }

        this.setState({loading: true})

        actions.accountEscheatment.uploadAccountEscheatment(this.fileInput.files[0])
            .then((response) => {
                return response.json();
            }).then((data) => {
                this.props.uploadResponse(data);
                this.props.showVerifyPageFlag(true);
            }).catch(() => {
                this.props.showVerifyPageFlag(false);
                this.setState({loading: false});
            });
    }

    async generateSampleTemplateFile() {
        const templateData = [
            {
                "NBT Account ID/Nos": 52345234,
                "First Name": "John",
                "Last Name": "Peter",
                "SSN": "123456789"
            },
            {
                "NBT Account ID/Nos": 68978782,
                "First Name": "John",
                "Last Name": "Smith",
                "SSN": "987654321"
            }
        ]

        const parsedCsv = Papa.unparse(templateData, { newline: '\n' });
        const blob = new Blob([parsedCsv], { type: 'text/csv'});

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', "Escheatment Template.csv");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    showDisabledPopUp() {
        showSnackbar('Unauthorized. You are not authorized to upload the file.')
    }

    render () {
        if (this.state.loading)
            return (
                <div>
                    <Loading />
                </div>
            );
        return (
            <div>
                <form onSubmit={this.handleFileUpload.bind(this)} className="account-escheatment-form">
                    <Card className='padding'>
                    <div className='form-control margin-top'>
                        <label className='input-label' htmlFor={'file-input'}>
                        Select File To Upload. <i>[Download the <b><u><a href='#/nbtadmin/account-escheatment' onClick={()=>this.generateSampleTemplateFile()}>Template</a></u></b>]</i>
                        </label>
                        <div>
                            <input
                            id='file-input'
                            name='file-input'
                            type='file'
                            accept=".csv"
                            required={this.props.uploadAccess? true: false}
                            ref={input => {this.fileInput = input;}}
                            />
                        </div>
                    </div>
                    </Card>
                    {this.props.uploadAccess ?
                        <Button
                            className='primary-button margin-top'
                            type="submit">
                            <span> 
                                Submit
                            </span>
                        </Button>
                        :<Button 
                            className='disabled-button' 
                            type="submit">
                            <span> 
                                Submit
                            </span>
                        </Button>
                    }
                </form>
            </div>
        );
    }
}

export default UploadEscheatment;