import React from "react";
import Button from "components/Button";
import Card from "components/Card";
import Field from "components/forms/Field/index";
import Loading from "components/Loading";
import { hasRole } from "../../../../redux/utility/SessionUtil";
import toa from "data/toa";
import UploadSingleTOA from "./UploadSingleOrMultipleTOA";

class UploadTOA extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initializeState();

    this.onChange.bind(this);
  }

  initializeFormData() {
    return {
      employerChoice: null
    };
  }

  componentWillMount() {
    if (this.verifyAccess()) {
    }
  }

  /*
   * Verify that the user has access to email templates
   */
  verifyAccess() {
    if (!(this.state.readAccess || this.state.writeAccess)) {
      this.props.history.push("/nbtadmin/unauthorized");
      return false;
    }

    return true;
  }

  /*
   * Initialize State
   */
  initializeState() {
    return {
      form: this.initializeFormData(),
      readAccess: hasRole("CipOpsView"),
      writeAccess: false,
      isMultiple: null
    };
  }

  /*
   * initialize all of the form fields
   */
  initializeFormData(data) {
    const { custodianId } = data || {};

    return {
      custodianId: custodianId || "",
      otherCustodian: "",
      employerId: "",
      totalCash: "",
      hasConsent: 0
    };
  }

  /*
   * Update form values
   *
   * @param [String] name. Field name of form to update.
   * @param [Any] value. Value.
   */
  onChange(name, value) {
    let form = { ...this.state.form };
    form[name] = value;
    this.setState({ form });
  }

  /*
   * Handle file drop.
   *
   * @param [Event] event. Drop event.
   */
  onDrop(event) {
    // this.onCreateAccountClosureDocument(event.target.files[0])
    // this.fileInput.value = ''
  }

  goBack() {
    this.setState({ isMultiple: null })
  }

  /**
   * On submit
   */
  handleSubmit(event) {
    event.preventDefault();
    if (this.validateForm()) {
      let form = { ...this.state.form };
      if (form.isMultiple === "true") {
        this.setState({ isMultiple: "true" });
      } else {
        this.setState({ isMultiple: "false" });
      }
      this.setState({ submitting: false });
    }
  }

  validateForm() {
    const { isMultiple } = this.state.form;
    return isMultiple;
  }

  renderLandingPage() {
    const { form } = this.state;
    return (
      <form onSubmit={this.handleSubmit.bind(this)} className="fee-form">
        <Card className="padding">
          <Field
            name="isMultiple"
            required={true}
            label="Please choose whether you will be uploading a single employer or multiple employers to continue."
            type="dropdown"
            placeholder="Choose employer type"
            value={form.isMultiple || ""}
            data={toa.us.employerIsMultiple.data}
            events={{
              onChange: event => {
                this.onChange("isMultiple", event.target.value);
              }
            }}
          />
        </Card>

        <Button
          className="primary-button margin-top"
          disabled={this.state.submitting}
          type="submit"
        >
          <span>
            {this.state.submitting ? (
              <Loading containerClassName="inline" className="white small" />
            ) : (
              "Submit"
            )}
          </span>
        </Button>
      </form>
    );
  }

  renderToaUploadComponent() {
    return (
      <UploadSingleTOA
        verifyAccess={this.verifyAccess.bind(this)}
        isMultiple={this.state.isMultiple}
      />
    );
  }

  render() {
    return (
      <div className="page upload-toa-view">
        <h4>Upload Transfer of Assets Data</h4>
        <i className="material-icons" onClick={this.goBack.bind(this)}>{"chevron_left"}</i>
        {this.state.isMultiple === null && this.renderLandingPage()}
        {this.state.isMultiple !== null && this.renderToaUploadComponent()}
      </div>
    );
  }
}

export default UploadTOA;
