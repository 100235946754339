import React from 'react'
import TableDisplay from 'compositions/TableDisplay'
import actions from 'actions'
import { showSnackbar } from '../../../../redux/utility/SnackbarUtil'
import { hasRole } from '../../../../redux/utility/SessionUtil'
import {filterDataSource} from 'lib/filter'
import dayjs from 'dayjs'



const headers = [
  {
    filterText: 'ContentKey',
    header: () => 'Content Key',
    key: 'contentKey'
  },
  {
    filterText: 'Letter Type',
    header: () => 'Letter Type',
    key: 'contentTypeset'
  },
  {
    filterText: 'Default',
    header: () => 'Default ',
    key: 'isDefault'
  },
  {
    filterText: 'optumCorrespondenceId',
    header: () => 'Optum ID',
    key: 'optumCorrespondenceId'
  },
  {
    filterText: 'welcomeKitId',
    header: () => 'Welcome Kit #',
    key: 'welcomeKitNum'
  },
  {
    filterText: 'Start Date',
    header: () => 'Start Date',
    key: 'startDate'
  },
  {
    filterText: 'End Date',
    header: () => 'End Date',
    key: 'endDate'
  },
  {
    filterText: 'Template',
    header: () => 'Template',
    key: 'contentDocument'
  }
]

 class MailTemplates extends React.Component {
  constructor (props) {
    super (props);
    this.state = {
      dataSource: [],
      filteredDataSource: [],
      readAccess: hasRole('EmailMasterView'),
      writeAccess: hasRole('EmailMasterEdit')
    }
    this.actions = [
      {
        action: this.onCopy.bind(this),
        icon: 'content_copy'
      }
    ]
    if (this.state.writeAccess) {
      this.actions.push(
        {
          action: this.onEdit.bind(this),
          icon: 'edit'
        }
      )
    }
  }

  /*
  * Get all templates
  */
  componentWillMount () {
    if (this.verifyAccess()) {
      this.getTemplates()
    }
  }


 /*
  * Verify that the user has access to mail templates
  */
  verifyAccess () {
    if (!(this.state.readAccess || this.state.writeAccess)) {
      this.props.history.push('/nbtadmin/unauthorized')
      return false
    }

    return true
  }

  /*
  * Fetch templates
  */
  getTemplates () {
    this.setState({loading: true})
    actions.mailTemplates.getMailTemplates().then(templates => {
      const mappedTemplates = templates.map(template => {
        const {contentId, contentDocument, contentTypeset, contentKey,isDefault,optumCorrespondenceId,welcomeKitNum,startDate,endDate} = template
        return {
          id: contentId,
          contentKey,
          contentTypeset,
          contentDocument: <div style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '25em'}}>
            {contentDocument}
          </div>,
          contentDocumentString: contentDocument,
          isDefault: isDefault ? 'Default' : '',
          optumCorrespondenceId: optumCorrespondenceId,
          welcomeKitNum : welcomeKitNum ? welcomeKitNum : '',
          startDate:startDate,
          endDate:endDate
        }
      });
      this.setState({dataSource: mappedTemplates, filteredDataSource: mappedTemplates, loading: false})
    }).catch(() => this.setState({loading: false}))
  }



  /*
  * Go to mail template form
  */
  onAdd () {
    const {history} = this.props
    history.push(`/nbtadmin/mail-templates/create/new`)
  }

  onCopy (template) {
      const {history} = this.props;
      localStorage.setItem('copyTemplateId', template.id);
      history.push(`/nbtadmin/mail-templates/create/new`);
  }  

  onRowClick (template) {
    //TODO: Navigate to view page
    const {history} = this.props
    history.push(`/nbtadmin/mail-templates/${template.id}`)
  }

   onEdit(template) {
     if (template.startDate > dayjs().format("YYYY-MM-DD")) {
       const { history } = this.props
       history.push(`/nbtadmin/mail-templates/${template.id}/edit`)
     } else {
      showSnackbar('Cannot edit an active template', 'error');
     }
   }

  onDelete (template) {
    //TODO: Api request to delete item
  }

  onFilter (queryParams) {
    const {dataSource} = this.state

    // contentDocument is a react element that's necessary for rendering but
    // we want to filter on the HTML string itself so we replace the filter
    // on contentDocument with a filter on contentDocumentString
    if (queryParams["contentDocument"]) {
      let contentDocumentQuery = queryParams["contentDocument"];
      queryParams["contentDocument"] = "";
      queryParams["contentDocumentString"] = contentDocumentQuery
    }
    const newDataSource = filterDataSource(dataSource, queryParams)
    this.setState({filteredDataSource: newDataSource})
  }

  render () {
    return (
      <div className='page mail-template-view'>
        <TableDisplay
          dataActions={this.actions}
          dataSource={this.state.filteredDataSource}
          loading={this.state.loading}
          onAdd={this.onAdd.bind(this)}
          onFilter={this.onFilter.bind(this)}
          onRowClick={this.onRowClick.bind(this)}
          pageDescription='Manage Letter templates.'
          pageTitle='Letter Templates'
          tableHeaders={headers}
        />
      </div>
    )
  }
}

export default MailTemplates
