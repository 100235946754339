import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Styles ------------
import './card.scss'

class Card extends Component {
  render () {
    const {children, className, onClick, style} = this.props

    const classes = ['card']
    if (className) classes.push(className)

    return (
      <div className={classes.join(' ')} style={style} onClick={onClick}>
        {children}
      </div>
    )
  }
}

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export default Card
