import React from 'react'
import PropTypes from 'prop-types'

// Styles --------------------------------
import 'components/TextField/text-field.scss'

class TextField extends React.Component {
  render () {
    const {label, name, value} = this.props
    return (
      <div className='text-field'>
        <label className='input-label' htmlFor={name}>{label}</label>
        <div className='text-container' id={name}>
          <span>{value}</span>
        </div>
      </div>
    )
  }
}

TextField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string
}

export default TextField
