import React from 'react'
import Card from 'components/Card'
import Filter from 'compositions/Search/Filter'

class Search extends React.Component {
  constructor (props) {
    super(props)
    this.filterRefs = []
  }

  renderFilters () {
    const {tableFilters} = this.props
    return tableFilters.map((filter, index) => {
      return (
        <Filter
          key={`filter-${index}`}
          ref={(component) => { if (component) this.filterRefs.push(component) }}
          {...this.props}
          filterItem={filter}
          onFilter={() => false}
        />
      )
    })
  }

  /*
  * On fsearch press, combine filter params.
  */
  onSearch (event) {
    event.preventDefault()
    const filters = {}
    this.filterRefs.forEach(r => {
      const filter = r.getFilterValue()
      if (filter) {
        const filterArray = filter.split('=')
        filters[filterArray[0]] = filterArray[1]
      }
    })

    this.props.onSearch(filters)
  }



  render () {
    return (
      <div>
        <form onSubmit={this.onSearch.bind(this)}>
        <Card className='padding'>
          {this.renderFilters()}
          <button
            className='button primary-button margin-top'
            style={{width: 'auto', marginLeft: 'auto', display: 'block'}}
          >Search</button>
        </Card>
        </form>
      </div>
    )
  }
}

Search.propTypes = {

}

export default Search
