import Fetch from 'lib/fetch'
import { showSnackbar } from '../redux/utility/SnackbarUtil'

/*
* Get other configuration settings
*/
export function getSettings() {
  return Fetch.get(`${process.env.REACT_APP_NBT_ADMIN_DOMAIN}/nbt-admin/other-configuration`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

/*
* Update other configuration settings
*/
export function updateSettings(form) {
  return Fetch.put(`${process.env.REACT_APP_NBT_ADMIN_DOMAIN}/nbt-admin/other-configuration`, form, null)
    .then(data => {
      showSnackbar('Settings successfully updated', 'info')
      return data
    })
    .catch(error => Promise.reject(error))
}
