import React from 'react'
import Card from "../../../../components/Card";
import Field from "../../../../components/forms/Field";
import PropTypes from 'prop-types'

class CustodianFormSearch extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      form: this.initializeFormData()
    }
  }
  
  initializeFormData(){
    return {
      custodianName: ''
    }
  }
  
  onChange(name, value){
    let form = {...this.state.form}
    form[name] = value
    this.setState({form})
  }

  handleSubmit(event) {
    event.preventDefault()
    if (this.validateForm()) {
      this.props.onSubmit(this.state.form).then(() => {
      }).catch(() => false)
    }
  }
  
  validateForm(){
    const{custodianName} = this.state.form
    return custodianName
  }
  
  render(){
    const {form} = this.state
    return(
    <Card className='padding'>
      <form className='' onSubmit={this.handleSubmit.bind(this)}>
        <Field 
            name='custodianName'
            required={true}
            label='Find Consent Forms by Custodian'
            placeholder='Custodian Name'
            value={form.custodianName}
            events={
              {
                onChange: (event) => {this.onChange('custodianName', event.target.value)}
              }
            }
        />
        <button style={{width: 'auto', display: 'block', marginLeft: 'auto'}}
                className='button margin-top primary-button'
                type='submit'
        >Search </button>
      </form>
    </Card>
    )
  }
}

CustodianFormSearch.propTypes = {
  onSubmit: PropTypes.func
}

export default CustodianFormSearch