import React from "react";
import TableView from "compositions/TableDisplay/TableView";
import actions from "actions";
import {filterDataSource} from "lib/filter";
import {sortDataSource} from "lib/sort";
import Loading from "components/Loading";
import Modal from "components/Modal";
import UpdateCipOrder from "./UpdateCipOrder";

const headers = [
    {
        filterText: "CIP Order ID",
        header: () => "CIP Order ID",
        key: "cipOrderId"
    },
    {
        filterText: "CIP Status",
        header: () => "CIP Status",
        key: "cipStatus"
    },
    {
        filterText: "Created",
        header: () => "Created",
        key: "created"
    },
    {
        filterText: "Modified",
        header: () => "Modified",
        key: "modified"
    },
    {
        filterText: "First Name",
        header: () => "First Name",
        key: "firstName"
    },
    {
        filterText: "Last Name",
        header: () => "Last Name",
        key: "lastName"
    },
    {
        filterText: "SSN",
        header: () => "SSN",
        key: "ssn"
    },
    {
        filterText: 'Date of Birth',
        header: () => 'Date of Birth',
        key: 'dateOfBirth'
    },
    {
        filterText: 'Address 1',
        header: () => 'Address 1',
        key: 'streetAddress1'
    },
    {
        filterText: 'Address 2',
        header: () => 'Address 2',
        key: 'streetAddress2'
    },
    {
        filterText: 'City',
        header: () => 'City',
        key: 'city'
    },
    {
        filterText: 'State',
        header: () => 'State',
        key: 'state'
    },
    {
        filterText: 'ZIP Code',
        header: () => 'ZIP Code',
        key: 'zipcode'
    },
    {
        // filterText: 'Update',
        header: () => 'Update',
        key: 'update'
    },
    {
        // filterText: 'Retry',
        header: () => 'Retry',
        key: 'retry'
    }
]

class CIPOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            filteredDataSource: [],
            openCipUpdateModal: false,
            initialSortLabel: "Modified Date",
            initialSortDirection: "desc",
            cipOrderId: "",
            dateOfBirth: "",
            streetAddress1: "",
            streetAddress2: "",
            city: "",
            state: "",
            zipcode: ""
        };

        this.actions = [];
    }

    /*
    * Get all CIP Orders
    */
    componentDidMount() {
        this.getCIPOrders()
    }

    /*
    * Fetch CIP Orders
    */
    getCIPOrders() {
        this.setState({loading: true})
        return actions.cipOrder.getCIPOrdersByEmployeeIdAndEmployerId(this.props.account.employeeId, this.props.employerId).then(
            cipOrders => {
                const mappedCipOrders = cipOrders.map(cipOrder => {
                    const {
                        databaseId,
                        cipStatus,
                        cipOrderCreated,
                        cipOrderModified,
                        firstName,
                        lastName,
                        ssn,
                        dateOfBirth,
                        streetAddress1,
                        streetAddress2,
                        city,
                        state,
                        zipcode
                    } = cipOrder
                        return {
                            cipOrderId: databaseId,
                            cipStatus,
                            created: cipOrderCreated,
                            modified: cipOrderModified,
                            firstName,
                            lastName,
                            ssn,
                            dateOfBirth,
                            streetAddress1,
                            streetAddress2,
                            city,
                            state,
                            zipcode,
                            update: this.props.cipAccess && (cipStatus === 'Pending' || cipStatus === 'Error') ? this.updateCipOrder(databaseId, dateOfBirth, streetAddress1, streetAddress2, city, state, zipcode) : this.disableEdit(),
                            retry: this.props.cipAccess && (cipStatus === 'Pending' || cipStatus === 'Error') ? this.retryCipOrder(databaseId) : this.disableRetry()
                        }
                })
                this.setState({
                    dataSource: mappedCipOrders,
                    filteredDataSource: mappedCipOrders,
                    loading: false,
                })
            }).catch(err => console.log(err))
    }

    /*
    * Filter data source.
    *
    * @param [Object] queryParams. Key value pair containing filters.
    */
    onFilter(queryParams, sortableRefs) {
        const {dataSource} = this.state;
        const newDataSource = filterDataSource(dataSource, queryParams);
        const sortedDataSource = sortDataSource(newDataSource, queryParams, sortableRefs)
        this.setState({filteredDataSource: sortedDataSource});
    }

    onSort(queryParams, sortableRefs) {
        const {filteredDataSource} = this.state
        const sortedDataSource = sortDataSource(filteredDataSource, queryParams, sortableRefs)
        this.setState({filteredDataSource: sortedDataSource})
    }

    onCloseCipUpdateModal() {
        this.setState({openCipUpdateModal: false});
    }

    onSubmit(form) {
        this.setState({loading: true})
        actions.cipOrder.updateAndRetryCipOrder(form).then(() => {
            this.getCIPOrders()
            this.setState({submitting: false})
        }).catch(err => {
            this.setState({loading: false})
        })
        this.onCloseCipUpdateModal()
    }

    disableEdit() {
        return (<div>
            <button className="button disabled-button">
                Edit
            </button>
        </div>)
    }

    disableRetry() {
        return (<div>
            <button className="button disabled-button">
                Retry
            </button>
        </div>)
    }

    updateCipOrder(databaseId, dateOfBirth, streetAddress1, streetAddress2, city, state, zipcode) {
        if (this.props.cipAccess === false) {
            this.props.history.push('/nbtadmin/unauthorized')
        } else {
            return (<div>
                <button className="button primary-button" onClick={() => {
                    this.setState({openCipUpdateModal: true, cipOrderId: databaseId, dateOfBirth: dateOfBirth, streetAddress1: streetAddress1, streetAddress2: streetAddress2, city: city, state: state, zipcode: zipcode})
                }}>
                    Edit
                </button>
            </div>)
        }
    }

    retryCipOrder(databaseId) {
        return (<div>
            <button className="button primary-button" onClick={() => {
                this.setState({loading: true})
                actions.cipOrder.retryCipProcessing(databaseId).then(() => {
                    this.getCIPOrders()
                }).catch(err => {
                    this.setState({loading: false})
                })
            }}>
                Retry
            </button>
        </div>)
    }

    /*
      * Open CIP Update modal
      */
    renderCipUpdateModal() {
        const {openCipUpdateModal} = this.state;
        if (openCipUpdateModal) {
            return (
                <Modal
                    header={"CIP Order"}
                    onClose={this.onCloseCipUpdateModal.bind(this)}
                    showClose={true}
                    account={this.state.dataSource}
                >
                    <UpdateCipOrder
                        onSubmit={this.onSubmit.bind(this)}
                        cipOrderId = {this.state.cipOrderId}
                        dateOfBirth = {this.state.dateOfBirth}
                        streetAddress1 = {this.state.streetAddress1}
                        streetAddress2 = {this.state.streetAddress2}
                        city = {this.state.city}
                        state = {this.state.state}
                        zipcode = {this.state.zipcode}
                        {...this.props}
                        />
                </Modal>
            );
        }
    }

    render() {
        if (this.state.loading)
            return (
                <div>
                    <Loading/>
                </div>
            );

        const {filteredDataSource} = this.state;
        return (
            <div>
                <div className="padding">
                    <div className="row">
                        <div className="col">
                            <h5>CIP Order</h5>
                        </div>
                    </div>
                    <div className="margin-top"/>
                    <TableView
                        dataActions={this.actions}
                        dataSource={filteredDataSource}
                        disableFilters={true}
                        tableHeaders={headers}
                        tableFilters={headers}
                        onFilter={this.onFilter.bind(this)}
                        onSort={this.onSort.bind(this)}
                        initialSortLabel={this.state.initialSortLabel}
                        initialSortDirection={this.state.initialSortDirection}
                    />
                </div>
                {this.renderCipUpdateModal()}
            </div>
        );
    }
}

export default CIPOrder;