import Fetch from 'lib/fetch'

export function getEmployerById(id){
  return Fetch.get(`${process.env.REACT_APP_NBT_ADMIN_DOMAIN}/portal-data/employers/${id}`, null)
  .then(data => data)
  .catch(error => Promise.reject(error))
}

export function getEmployers(params){
  return Fetch.post(`${process.env.REACT_APP_NBT_ADMIN_DOMAIN}/portal-data/employers/search`, params, null)
  .then(data => data)
  .catch(error => Promise.reject(error))
}
