import React from 'react'
import './loading.scss'

class Loading extends React.Component {
  render () {
    const {className, containerClassName} = this.props
    const containerClasses = ['loading-container']
    const classes = ['loading-spinner']

    if (containerClassName) containerClasses.push(containerClassName)
    if (className) classes.push(className)
    return (
      <div className={containerClasses.join(' ')}>
        <div className={classes.join(' ')} />
      </div>
    )
  }
}

export default Loading
