/**
 * Super straightforward localization and more!
 *
 * ```
 *  email: {
 *      name: 'email',
 *      type: 'email',
 *      label: 'E-mail',
 *      validator: 'email'
 *  },
 *  date_admission: {
 *      name: 'date_admission',
 *      label: 'Date of Admission',
 *      placeholder: 'Date of Admission',
 *      type: 'month'
 *  }
 * ```
 * TODO Separate the translations from the definitions
 */

const fee = {
  us: {
    fields: {
      name: {
        name: 'name',
        label: "Name",
        placeholder: 'Fee name',
      },
      chargeFrequency: {
        name: 'chargeFrequency',
        label: 'Charge frequency',
        placeholder: 'Charge Frequency',
      },
      waivable: {
        name: 'waivable',
        type: 'dropdown',
        label: 'Waivable',
        placeholder: 'Waivable',
        data: {
          Yes: 'Yes',
          No: 'No'
        }
      },
      type: {
        name: 'type',
        type: 'dropdown',
        label: 'Type',
        placeholder: 'Type',
        data: {
          Fixed: 'Fixed',
          Percentage: 'Percentage'
        }
      },
      glCode: {
        name: 'glCode',
        label: "GL Code",
        placeholder: 'GL code',
      },
      transactionCode: {
        name: 'transactionCode',
        label: "Transaction Code",
        placeholder: 'Transaction code',
      },
      description: {
        name: 'description',
        label: 'Description',
        placeholder: 'Fee description',
      }
    }
  }
}

export default fee
