import React from 'react';
import TableDisplay from 'compositions/TableDisplay';
import actions from '../../../actions';
import { hasRole } from '../../../redux/utility/SessionUtil';
import Modal from "components/Modal";
import { showSnackbar } from '../../../redux/utility/SnackbarUtil';
import ValidationForm from './ValidationForm';
import {mapDateToForm} from 'lib/date'
import Paginate from '../../../components/Paginate';
import Button from 'components/Button'

const headers = [
    {
        filterText: 'Wire Transfer ID',
        header: () => 'Wire Transfer ID',
        key: 'wireTransferId',
        enableSort: true
    },
    {
        filterText: 'Wire Transfer Status',
        header: () => 'Wire Transfer Status',
        key: 'wireTransferStatus',
        enableSort: true
    },
    {
        filterText: 'Source Account Name',
        header: () => 'Source Account Name',
        key: 'wireTransferSourceAccountName',
        enableSort: true
    },
    {
        filterText: 'Destination Account Name',
        header: () => 'Destination Account Name',
        key: 'wireTransferDestinationAccountName',
        enableSort: true
    },
    {
        filterText: 'Wire Transfer Amount',
        header: () => 'Wire Transfer Amount',
        key: 'wireTransferAmount',
        enableSort: true
    },
    {
        filterText: 'Wire Transfer Filename',
        header: () => 'Wire Transfer Filename',
        key: 'wireTransferFileName',
        enableSort: true
    },
    {
        filterText: 'Wire Transfer File Status',
        header: () => 'Wire Transfer File Status',
        key: 'wireTransferFileStatus',
        enableSort: true
    },
    {
        filterText: 'Omnibus Partner Bank ID',
        header: () => 'Omnibus Partner Bank ID',
        key: 'wireTransferFileOmnibusPartnerBankId',
        enableSort: true
    },
    {
        filterText: 'Created Date',
        header: () => 'Created Date',
        key: 'wireTransferCreatedDate',
        enableSort: true,
        disableFilter: true
    },
    {
        filterText: 'Modified Date',
        header: () => 'Modified Date',
        key: 'wireTransferModifiedDate',
        enableSort: true,
        disableFilter: true
    },
    {
        filterText: 'Wire Transfer File Last Resent Date',
        header: () => 'Wire Transfer File Last Resent Date',
        key: 'wireTransferFileLastResentDate',
        enableSort: true,
        disableFilter: true
    },
    {
        filterText: '',
        header: () => '',
        key: 'resendButton',
        enableSort: false,
        disableFilter: true
    }

]

const COUNT_PER_PAGE = 25;

const amountFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
});

class BatchWires extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            datasource: [],
            total: 0,
            loading: true,
            showConfirmationModalBoolean:false,
            readAccess: hasRole('BatchWireView') || hasRole('BatchWireEdit'),
            writeAccess: hasRole('BatchWireEdit')
        }
        this.initTableData()
    }

    componentDidMount() {
        if(this.verifyAccess()){
            this.getDataForBatchWires();
        }
    }

    verifyAccess() {
        if(!this.state.readAccess) {
            this.props.history.push('/nbtadmin/unauthorized')
            return false
        }
        return true
    }

    initTableData() {
        this.queryParams = {}
        this.sortDirection = 'desc'
        this.sortColumn = 'wireTransferCreated'
        this.pageNumber = 0
    }

    updatePage(current) {
        // zero based index for page number
        this.pageNumber = current - 1
        this.getDataForBatchWires()
    }

    onFilterTable(queryParams) {
        this.pageNumber = 0
        this.queryParams = queryParams
        this.getDataForBatchWires()
    }

    onSortTable(queryParams,sortableRefs) {
        this.pageNumber = 0
        this.sortDirection = sortableRefs.filter(row => row.props.sortableItem.key === queryParams.key)
                                            .map(row => row.state.sortDirection)[0]
        this.sortColumn = queryParams.key
        this.getDataForBatchWires()
    }

    resendButton(resendEnabled, wireTransferFileId, wireTransferFileLastResent) {
        return (
            <div style={{ width: '100%'}}>
                <Button
                    className={`primary-button margin-top`}
                    disabled={this.state.writeAccess && resendEnabled ? false : true}
                    events={
                        {
                            onClick: this.handleResendClick.bind(this, wireTransferFileId, wireTransferFileLastResent)
                        }
                    }
                    >
                    <span style={{fontSize : '15px'}}> 
                        Resend
                    </span>
                </Button>
            </div>
        )
    }

    handleResendClick(wireTransferFileId, wireTransferFileLastResent){
        this.setState({showConfirmationModalBoolean: true, resendWireTransferFileId: wireTransferFileId, wireTransferFileLastResent: wireTransferFileLastResent});
    }

    getDataForBatchWires() {
        const params  = { 
            ...this.queryParams,
            page: this.pageNumber,
            limit: COUNT_PER_PAGE,
            sortOrder: this.sortDirection,
            sortColumn: this.sortColumn,
            ignoreReconciled: true
        }
        actions.batchWireRecords
            .getBatchWireRecords(params)
            .then(response => {
                const mappedBatchWires = response.content.map(record => {
                    const {wireTransferId, wireTransferStatus, wireTransferSourceAccountName, wireTransferDestinationAccountName,
                        wireTransferAmount, wireTransferFileId, wireTransferFileStatus, wireTransferFileName, 
                        wireTransferFileOmnibusPartnerBankId, wireTransferCreated,
                        wireTransferModified, wireTransferFileLastResent, resendEnabled} = record
                    return {
                        wireTransferId,
                        wireTransferStatus,
                        wireTransferSourceAccountName,
                        wireTransferDestinationAccountName,
                        wireTransferAmount : amountFormatter.format(wireTransferAmount),
                        wireTransferFileId,
                        wireTransferFileStatus,
                        wireTransferFileName,
                        wireTransferFileOmnibusPartnerBankId,
                        wireTransferCreatedDate : mapDateToForm(wireTransferCreated),
                        wireTransferModifiedDate : mapDateToForm(wireTransferModified),
                        wireTransferFileLastResentDate : mapDateToForm(wireTransferFileLastResent),
                        resendButton : this.resendButton(resendEnabled, wireTransferFileId, wireTransferFileLastResent)
                    }
                  })
                this.setState({
                    loading: false,
                    datasource: mappedBatchWires,
                    total: response.totalElements
                })
            })
            .catch(() => {
                this.setState({ 
                        loading: false,
                        datasource:[],
                        total: 0
                    })
                showSnackbar('Failed to retrieve data')
            })
    }

    closeConfirmationModal() {
        this.setState({
            showConfirmationModalBoolean:false
        })
    }

    onValidationSubmit(wasResendSuccess) {
        this.setState(
            {showConfirmationModalBoolean:false, loading:true},
            () => {
                this.getDataForBatchWires();
                if(wasResendSuccess) {
                    showSnackbar('The batch wire file was resent successfully.', 'info')
                } else {
                    showSnackbar('The batch wire file was not resent successfully.');
                }
            }
        )
    }

    showConfirmationModal() {
        const {showConfirmationModalBoolean} = this.state;
        if (showConfirmationModalBoolean) {
            return (
                <Modal
                    header={"Action Confirmation"}
                    onClose={this.closeConfirmationModal.bind(this)}
                    showClose={true}>
                    <ValidationForm
                        {...this.state}
                        onSubmit = {this.onValidationSubmit.bind(this)}
                    />
                </Modal>
            );
        }
    }

    render() {
        return (  
            <div className='search-page'>
                <h4>Batch Wires</h4>
                <TableDisplay
                    dataActions={[]}
                    dataSource={this.state.datasource}
                    disableAdd={true}
                    loading={this.state.loading}
                    tableDataClassName='align-items-start overflow-wrap'
                    onAdd={() => false}
                    onFilter={this.onFilterTable.bind(this)}
                    onSort={this.onSortTable.bind(this)}
                    singleColumnSort={true}
                    tableHeaders={headers}
                    initialSortLabel='Created Date'
                    initialSortDirection='desc'
                />
                {this.showConfirmationModal()}
                {this.state.datasource.length > 0 && (<Paginate
                    total={this.state.total}
                    countPerPage={COUNT_PER_PAGE}
                    updatePage={this.updatePage.bind(this)}
                    style={{
                        marginTop: '1em'
                    }}
                />)}
            </div>
        );
    }
}
 
export default BatchWires;