import React from 'react'
import Field from 'components/forms/Field/index'
import actions from 'actions'
import Loading from "components/Loading";
import termsAndConditionsSetup from "data/termsAndConditionsSetup";

class TermsAndConditionsHierarchyForm extends React.Component {
  constructor (props) {
    super (props)
    this.state = this.initializeState();
  }

  componentWillMount () {
    this.getActiveTermsAndConditions()
  }

  /*
   * Initialize State
   */
  initializeState() {
    return {
      form: this.initializeFormData(),
      dataSource: [],
      name: this.props.currentTermsAndConditions !== null ? this.props.currentTermsAndConditions.fileName : '',
      type: this.props.currentTermsAndConditions !== null ? this.props.currentTermsAndConditions.type : '',
      hierarchyId: this.props.hierarchyId,
      edit: this.props.edit,
      selectedIndexBankingAgreement: '',
      selectedIndexPolicyAgreement: '',
      selectedIndexFeeAgreement: '',
      selectedIndexEsignAgreement: '',
      bankingData: ['Null', 'Use Default'],
      bankingDataIds: [],
      policyData: ['Null', 'Use Default'],
      policyDataIds: [],
      feeData: ['Null', 'Use Default'],
      feeDataIds: [],
      esignData: ['Null', 'Use Default'],
      esignDataIds: []
    };
  }

  /*
   * Get Active Terms and Conditions
   */
  getActiveTermsAndConditions() {
    this.setState({ loadingTermsAndConditions: true });
    actions.termsAndConditions.
    getActiveTermsAndConditions().then(termsAndConditions => {
      const mappedTermsAndConditions = termsAndConditions.map(tc => {
        const { id, agreementType, fileName } = tc;
        return {
          id,
          agreementType,
          fileName,
        };
      });
      this.setState({
        loadingTermsAndConditions: false,
        dataSource: mappedTermsAndConditions,
      });
    })
    .catch(() => this.setState({ loadingTermsAndConditions: false }));
  }



  /*
  * Initialize form data
  * Fee profile hierarchy
  */
  initializeFormData () {
    return {
      bankingAgreement: '',
      policyAgreement: '',
      feeAgreement: '',
      esignAgreement: '',

    }
  }

  retrieveDropdownValues() {
    const{ dataSource } = this.state

    if(this.state.bankingDataIds.length === 0) {
      const bankingValues = dataSource.filter(x => x.agreementType === 'BANKING_AGREEMENT')
      bankingValues.map(tc => this.state.bankingData.push(tc.fileName))
      bankingValues.map(tc => this.state.bankingDataIds.push(tc.id))
    }

    if(this.state.policyDataIds.length === 0) {
      const policyValues = dataSource.filter(x => x.agreementType === 'PRIVACY_AGREEMENT')
      policyValues.map(tc => this.state.policyData.push(tc.fileName))
      policyValues.map(tc => this.state.policyDataIds.push(tc.id))
    }

    if(this.state.feeDataIds.length === 0) {
      const feeValues = dataSource.filter(x => x.agreementType === 'FEE_AGREEMENT')
      feeValues.map(tc => this.state.feeData.push(tc.fileName))
      feeValues.map(tc => this.state.feeDataIds.push(tc.id))
    }

    if(this.state.esignDataIds.length === 0) {
      const esignValues = dataSource.filter(x => x.agreementType === 'ESIGN_AGREEMENT')
      esignValues.map(tc => this.state.esignData.push(tc.fileName))
      esignValues.map(tc => this.state.esignDataIds.push(tc.id))
    }
  }

  /*
  * Render the fields
  */
  getFields () {
    const { hierarchy } = termsAndConditionsSetup

    const {form: {
      bankingAgreement,
      policyAgreement,
      feeAgreement,
      esignAgreement
    }} = this.state

    this.retrieveDropdownValues()

    return [
      {
        ...hierarchy.bankingAgreement,
        required: (this.state.edit && this.state.type === 'Custodial Agreement'),
        disabled: (this.state.edit && this.state.type !== 'Custodial Agreement'),
        events: {
          onChange: (event) => {
            this.setState({selectedIndexBankingAgreement: event.target.selectedIndex})
            this.onChange("bankingAgreement", event.target.value)
          }
        },
        data: this.state.bankingData,
        placeholder: (this.state.edit && this.state.type !== 'Custodial Agreement')
            ? (this.props.hierarchyTermsAndConditions.find(x => x.type === 'Custodial Agreement').pdfName === 'N/A' ? 'Null' :
                this.props.hierarchyTermsAndConditions.find(x => x.type === 'Custodial Agreement').pdfName)
            :'Null',
        value: bankingAgreement
      },
      {
        ...hierarchy.policyAgreement,
        required: (this.state.edit && this.state.type === 'Private Policy'),
        disabled: (this.state.edit && this.state.type !== 'Private Policy'),
        events: {
          onChange: (event) => {
            this.setState({selectedIndexPolicyAgreement: event.target.selectedIndex})
            this.onChange("policyAgreement", event.target.value)
          }
        },
        data: this.state.policyData,
        placeholder: (this.state.edit && this.state.type !== 'Private Policy')
            ? (this.props.hierarchyTermsAndConditions.find(x => x.type === 'Private Policy').pdfName === 'N/A' ? 'Null' :
                this.props.hierarchyTermsAndConditions.find(x => x.type === 'Private Policy').pdfName)
            :'Null',
        value: policyAgreement
      },
      {
        ...hierarchy.feeAgreement,
        required: (this.state.edit && this.state.type === 'Fee Schedule'),
        disabled: (this.state.edit && this.state.type !== 'Fee Schedule'),
        events: {
          onChange: (event) => {
            this.setState({selectedIndexFeeAgreement: event.target.selectedIndex})
            this.onChange("feeAgreement", event.target.value)
          }
        },
        data: this.state.feeData,
        placeholder: (this.state.edit && this.state.type !== 'Fee Schedule')
            ? (this.props.hierarchyTermsAndConditions.find(x => x.type === 'Fee Schedule').pdfName === 'N/A' ? 'Null' :
                this.props.hierarchyTermsAndConditions.find(x => x.type === 'Fee Schedule').pdfName)
            :'Null',
        value: feeAgreement
      },
      {
        ...hierarchy.esignAgreement,
        required: (this.state.edit && this.state.type === 'Esign'),
        disabled: (this.state.edit && this.state.type !== 'Esign'),
        events: {
          onChange: (event) => {
            this.setState({selectedIndexEsignAgreement: event.target.selectedIndex})
            this.onChange("esignAgreement", event.target.value)
          }
        },
        data: this.state.esignData,
        placeholder: (this.state.edit && this.state.type !== 'Esign')
            ? (this.props.hierarchyTermsAndConditions.find(x => x.type === 'Esign').pdfName === 'N/A' ? 'Null' :
                this.props.hierarchyTermsAndConditions.find(x => x.type === 'Esign').pdfName)
            :'Null',
        value: esignAgreement
      }
    ]
  }

  /*
  * Update form values
  *
  * @param [String] name. Field name of form to update.
  * @param [Any] value. Value.
  */
  onChange (name, value) {
    let form = {...this.state.form}
    form[name] = value
    this.setState({form})
  }

  /*
  * Retrieving Form Values
  *
  */
  retrieveFormValues(index, previousValue, currentValue) {
    if(index === 0) {
      return null
    } else if (index === 1) {
      return (previousValue === 'N/A' ? null : previousValue)
    } else {
      return currentValue
    }
  }

  /*
  * Submit terms and conditions
  *
  */
  onSubmit (event) {
    event.preventDefault()

    let form = {...this.state.form}

    let formData = {
      hierarchyId: this.state.hierarchyId
    }

    if (!this.state.edit || this.state.type === 'Custodial Agreement') {
      formData.bankingAgreementId = this.retrieveFormValues(
          form.bankingAgreement,
          this.props.hierarchyTermsAndConditions.find(
              x => x.type === 'Custodial Agreement').id,
          this.state.bankingDataIds[this.state.selectedIndexBankingAgreement
          - 3])
    } else {
      formData.bankingAgreementId =
          this.props.hierarchyTermsAndConditions.find(x => x.type === 'Custodial Agreement').id
    }

    if (!this.state.edit || this.state.type === 'Private Policy') {
      formData.privacyAgreementId = this.retrieveFormValues(
          form.policyAgreement,
          this.props.hierarchyTermsAndConditions.find(
              x => x.type === 'Private Policy').id,
          this.state.policyDataIds[this.state.selectedIndexPolicyAgreement - 3])
    } else {
      formData.privacyAgreementId =
          this.props.hierarchyTermsAndConditions.find(x => x.type === 'Private Policy').id
    }

    if (!this.state.edit || this.state.type === 'Fee Schedule') {
        formData.feeAgreementId = this.retrieveFormValues(form.feeAgreement,
            this.props.hierarchyTermsAndConditions.find(x => x.type === 'Fee Schedule').id,
            this.state.feeDataIds[this.state.selectedIndexFeeAgreement - 3])
    } else {
      formData.feeAgreementId =
          this.props.hierarchyTermsAndConditions.find(x => x.type === 'Fee Schedule').id
    }

    if (!this.state.edit || this.state.type === 'Esign') {
        formData.esignAgreementId = this.retrieveFormValues(form.esignAgreement,
            this.props.hierarchyTermsAndConditions.find(x => x.type === 'Esign').id,
            this.state.esignDataIds[this.state.selectedIndexEsignAgreement - 3])
    } else {
      formData.esignAgreementId =
          this.props.hierarchyTermsAndConditions.find(x => x.type === 'Esign').id
    }

    this.onSaveTermsAndConditions(formData)
  }

  /*
  * Save Terms And Conditions
  */
  onSaveTermsAndConditions (form) {
    actions.termsAndConditions.saveConfig(form).then((employerConfig) => {
      this.props.onSubmit(employerConfig)
      this.props.onClose()
    }).catch(() => false);
  }
  /*
    * Form helper to determine what to send over
    *
    */

  render () {
    const fields = this.getFields()
    if (this.state.loadingTermsAndConditions)
      return <div className='margin-top'><Loading/></div>
    return (
        <div>
          <div className='modal-body'>
            <div className='row margin-top'>
              <div className='col'>
                <Field{...fields[0]} />
              </div>
            </div>
            <div className='row margin-top'>
              <div className='col'>
                <Field {...fields[1]}  />
              </div>
            </div>
            <div className='row margin-top'>
              <div className ='col'>
                <Field {...fields[2]}  />
              </div>
            </div>
            <div className='row margin-top'>
              <div className='col'>
                <Field {...fields[3]}  />
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <div className='modal-actions'>
              <button
                  className='button primary-button'
                  onClick={this.onSubmit.bind(this)}
              >SUBMIT</button>
            </div>
          </div>
        </div>
    )
  }
}

export default TermsAndConditionsHierarchyForm
