import React from 'react';
import Field from "../../../../components/forms/Field";
import TextField from "../../../../components/TextField";

class EditCustodianUrlForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      form: this.initializeFormData(props.custodianUrl)
    }
  }

  initializeFormData (custodianUrl) {
    const {id, employerName, transferDateDisplay, urlExpirationDateDisplay, createDateDisplay} = custodianUrl || {};

    return {
      id: id || '',
      employerName: employerName || '',
      transferDateDisplay: transferDateDisplay || '',
      urlExpirationDateDisplay: urlExpirationDateDisplay || '',
      createDateDisplay: createDateDisplay || ''
    }
  }

  onChange (name, value) {
    let form = {...this.state.form};
    form[name] = value;
    this.setState({form})
  }

  onSubmit (event) {
    event.preventDefault();
    const form = this.state.form;
    this.props.onSubmit(form)
  }

  render() {

    const {
      employerName,
      transferDateDisplay,
      urlExpirationDateDisplay,
      createDateDisplay
    } = this.state.form;

    const employerNameField = {
      label: 'Employer Name',
      name: 'employerName',
      value: employerName
    };

    const createDateDisplayField = {
      name: 'createDateDisplay',
      label: 'Date Generated',
      value: createDateDisplay
    };

    const transferDateDisplayField = {
      name: 'transferDateDisplay',
      label: 'Transfer Date',
      value: transferDateDisplay,
      required: true,
      events: {
        onChange: (event) => {
          this.onChange('transferDateDisplay', event.target.value)
        }
      },
    };

    const urlExpirationDateDisplayField = {
      name: 'urlExpirationDateDisplay',
      label: 'URL Expiration Date',
      value: urlExpirationDateDisplay,
      required: true,
      events: {
        onChange: (event) => {
          this.onChange('urlExpirationDateDisplay', event.target.value)
        }
      },
    };

    return (
        <div>
          <div className='modal-body'>
            <div className='row margin-top'>
              <div className='col'>
                <TextField {...employerNameField}/>
              </div>
              <div className='col'>
                <TextField {...createDateDisplayField}/>
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <Field {...transferDateDisplayField}/>
              </div>
              <div className='col'>
                <Field {...urlExpirationDateDisplayField}/>
              </div>
            </div>
            <div className='margin-top'>
              <div className='modal-actions'>
                <button className='button primary-button' onClick={this.onSubmit.bind(this)}>
                  SUBMIT
                </button>
              </div>
            </div>
          </div>
        </div>
    )
  }


}

export default EditCustodianUrlForm;