import React from 'react'
import TableDisplay from 'compositions/TableDisplay'
import actions from 'actions'
import { hasRole } from '../../../../redux/utility/SessionUtil'
import {filterDataSource} from 'lib/filter'

const headers = [
  {
    filterText: 'Name',
    header: () => 'Name',
    key: 'name'
  },
  {
    filterText: 'Description',
    header: () => 'Description',
    key: 'description'
  },
  {
    filterText: 'Charge frequency',
    header: () => 'Charge Frequency',
    key: 'chargeFrequency'
  },
  {
    filterText: 'Type',
    header: () => 'Type',
    key: 'type'
  },
  {
    filterText: 'GL code',
    header: () => 'GL Code',
    key: 'glCode'
  },
  {
    filterText: 'Transaction code',
    header: () => 'Transaction Code',
    key: 'transactionCode'
  },
  {
    filterText: 'Waivable',
    header: () => 'Waivable',
    key: 'waivable'
  }
]

class Fees extends React.Component {
  constructor (props) {
    super (props)

    this.state = {
      currentFee: null,
      dataSource: [],
      filteredDataSource: [],
      originalSource: [],
      openConfirmationDialog: false,
      readAccess: hasRole('FeesMasterView'),
      writeAccess: hasRole('FeesMasterEdit')
    }



    this.actions = this.state.writeAccess ?
      [
        {
          action: this.onEdit.bind(this),
          icon: 'edit'
        }
      ] : []
  }

  /*
  * Get all fees
  */
  componentWillMount () {
    if (this.verifyAccess()) {
      this.getFees()
    }
  }

  /*
  * Verify that the user has access to fees
  */
  verifyAccess () {
    if (!(this.state.readAccess || this.state.writeAccess)) {
      this.props.history.push('/nbtadmin/unauthorized')
      return false
    }

    return true
  }

  /*
  * Fetch fees
  */
  getFees() {
    this.setState({loading: true})
    actions.fees.getFees().then(fees => {
      const mappedFees = fees.map(fee => {
        return this.mapFeeToDisplay(fee)
      })
      this.setState({dataSource: mappedFees, filteredDataSource: mappedFees, originalSource: fees, loading: false})
    }).catch(() => this.setState({loading: false}))
  }



  /*
  * Map fee to display
  */
  mapFeeToDisplay (fee) {
    const {id, name, chargeFrequency, eventRuleName, waivable, type, glCode,
      transactionCode, deactivatedDate, description} = fee
    return {
      id,
      name,
      chargeFrequency,
      waivable: waivable !== null ? waivable ? 'Yes' : 'No' : '',
      type,
      glCode,
      transactionCode,
      description: <div className='overflow'>{description}</div>
    }
  }

  /*
  * View fee
  */
  onRowClick (fee) {
    const {history} = this.props
    history.push(`/nbtadmin/fees/${fee.id}`)
  }

  /*
  * Edit fee
  */
  onEdit (fee) {
    const {history} = this.props
    history.push(`/nbtadmin/fees/${fee.id}/edit`)
  }

  /*
  * Filter data source.
  *
  * @param [Object] queryParams. Key value pair containing filters.
  */
  onFilter (queryParams) {
    const {dataSource} = this.state
    const newDataSource = filterDataSource(dataSource, queryParams)
    this.setState({filteredDataSource: newDataSource})
  }

  render () {
    return (
      <div className='search-page fee-view'>
        <TableDisplay
          dataActions={this.actions}
          dataSource={this.state.filteredDataSource}
          disableAdd={true}
          loading={this.state.loading}
          onFilter={this.onFilter.bind(this)}
          onRowClick={this.onRowClick.bind(this)}
          pageDescription='Manage fees.'
          pageTitle='Fees'
          tableHeaders={headers}
        />
      </div>
    )
  }
}

export default Fees
