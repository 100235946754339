import React from 'react';
import { showSnackbar } from '../../../../redux/utility/SnackbarUtil';
import Card from 'components/Card';
import TableView from "compositions/TableDisplay/TableView";
import Loading from 'components/Loading';
import actions from 'actions';

class AccountReactivation extends React.Component {

    headers = [{
        filterText: "Date Modified",
        header: () => "Date Modified",
        key: "modified"
      },
      {
        filterText: "Updated Account Status",
        header: () => "Updated Account Status",
        key: "accountStatus"
      },
      {
          filterText: "Entered By",
          header: () => "Entered By",
          key: "lastModifiedBy"
    }];

    constructor(props) {
        super(props)
        this.state = {
            enableReactivation: props.accountState == 'Closed' ? true : false,
            reactivationInProgress: false,
            reactivationAuditRecords: [],
            loadingAuditTable: false
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.accountState !== prevProps.accountState && this.props.accountState == 'Active') {
            this.setState({enableReactivation: false});
        }
    }

    componentWillMount() {
        this.getAccountReactivationAudit();
    }

    onReactivateAccount() {
        this.props.onReactivateAccount(this.props.accountId).then(() => this.getAccountReactivationAudit());
    }


    getAccountReactivationAudit() {
        this.setState({loadingAuditTable: true});
        actions.accounts.getReactivationAudit(this.props.accountId).then((data) => {
            const res = data.map((ele, index) => {
                ele["accountId"] = ele.id;
                delete(ele.id);
                ele = {...ele, id: index};
                return ele;
            })
            this.setState({reactivationAuditRecords: res, loadingAuditTable: false})
        }).catch((error) => {
            this.setState({loadingAuditTable: false});
            console.log(error);
            showSnackbar('Account audit logs could not be refreshed!');
        });
    }

    render() {
        const {reactivationInProgress, accountReactivationAccess} = this.props;
        if(reactivationInProgress) return <div className='margin-top'><Loading/></div>
        if(!accountReactivationAccess) return <div className='margin-top'>You don't have access to this feature!</div>
        return (
        <Card className="padding">
            <div className="reactivate-account">
                <button className={(this.state.enableReactivation ? "button primary-button": "disabled-button")} style={{width: '15%'}} disabled={!this.state.enableReactivation} onClick={this.onReactivateAccount.bind(this)} >Reactivate Account</button>
                <p style={{fontSize: '80%'}}><i>Note: by clicking on the above button, you will be reactivating a closed account.
                    This will set the NBT account and CYC account status to Active.</i></p>
            </div>
            <div>
                <span style={{fontWeight: 'bold', paddingBottom:'20px'}}>Audit History</span>
                {(this.state.loadingAuditTable)? 
                <Loading />
                :
                <TableView
                    dataActions={[]}
                    dataSource={this.state.reactivationAuditRecords}
                    disableFilters={true}
                    tableHeaders={this.headers}
                />
                }
            </div>
        </Card>
        )
    }

}

export default AccountReactivation