import React from 'react'
import ConfirmDialog from 'components/ConfirmDialog'
import TableDisplay from 'compositions/TableDisplay'
import actions from 'actions'
import {filterDataSource} from 'lib/filter'

const headers = [
  {
    filterText: 'Rule id',
    header: () => 'ID',
    key: 'id'
  },
  {
    filterText: 'Rule category',
    header: () => 'Category',
    key: 'category'
  },
  {
    filterText: 'Rule name',
    header: () => 'Name',
    key: 'name'
  },
  {
    filterText: 'Rule description',
    header: () => 'Description',
    key: 'description'
  },
  {
    filterText: 'Active',
    header: () => 'Active',
    key: 'isActive'
  }
]

const fraudRules = [
  {
    id: 1,
    name: 'Fraud Rule 1',
    category: 'Risk',
    description: 'Minimize risk',
    active: true
  }
]
//TODO Read/write access needs to be implemented for all Fraud Rule pages
class FraudRules extends React.Component {
  constructor (props) {
    super (props);
    const mappedRules = fraudRules.map(rule => {
      return this.mapRuleToDisplay(rule)
    })
    this.state = {
      dataSource: mappedRules || [],
      filteredDataSource: mappedRules || [],
      originalSource: fraudRules || []
    }
    this.actions = [
      {
        action: this.onEdit.bind(this),
        icon: 'edit'
      },
      {
        action: this.onDelete.bind(this),
        icon: 'delete'
      }
    ]
  }

  /*
  * Get all fraud rules
  */
  componentWillMount () {
    // this.getRules()
  }

  /*
  * Fetch rules.
  */
  getRules () {
    this.setState({loading: true})
    actions.fraudRules.getFraudRules().then(rules => {
      const mappedRules = rules.map(rule => {
        return this.mapRuleToDisplay(rule)
      })
      this.setState({dataSource: mappedRules, originalSource: rules, filteredDataSource: mappedRules, loading: false})
    }).catch(() => this.setState({loading: false}))
  }

  /*
  * Map rule to display
  */
  mapRuleToDisplay (rule) {
    const {id, name, description, category, active} = rule
    return {
      id,
      category,
      name,
      description,
      isActive: active === true ? 'Yes' : 'No'
    }
  }

  /*
  * Render the confirm delete dialog
  */
  renderConfirmDialog () {
    if (this.state.openConfirmationDialog) {
      return (
        <ConfirmDialog
          description='Are you sure you want to deactivate rule?'
          onClose={() => { this.setState({openConfirmationDialog: false, currentRule: null}) }}
          onConfirm={() => { this.deleteRule(this.state.currentRule) }}
        />
      )
    }
  }

  /*
  * Go to fraud rule form
  */
  onAdd () {
    const {history} = this.props
    history.push(`/nbtadmin/fraud-rules/create/new`)
  }

  /*
  * View fraud rule
  */
  onRowClick (rule) {
    const {history} = this.props
    history.push(`/nbtadmin/fraud-rules/${rule.id}`)
  }

  /*
  * Edit fraud rule
  */
  onEdit (rule) {
    const {history} = this.props
    history.push(`/nbtadmin/fraud-rules/${rule.id}/edit`)
  }

  /*
  * On delete, open confirmation dialog
  */
  onDelete (rule) {
    this.setState({openConfirmationDialog: true, currentRule: rule})
  }

  /*
  * Deactivate rule
  */
  deleteRule () {
    // TODO: Api should not update fields that don't exist in form. Right now they null out if not provided
    // so keep copy of origianl record.
    const originalRule = this.state.originalSource.find((ds) => ds.id === this.state.currentRule.id)
    actions.fraudRules.deactivateRule(originalRule).then((updatedRule) => {
      const index = this.state.dataSource.findIndex(ds => ds.id === this.state.currentRule.id)
      let newDataSource = this.state.dataSource
      let newOriginalSource = this.state.originalSource
      newDataSource[index] = this.mapRuleToDisplay(updatedRule)
      newOriginalSource[index] = updatedRule
      this.setState({dataSource: newDataSource, originalSource: newOriginalSource, openConfirmationDialog: false, currentRule: null})
    }).catch(() => false)
  }

  /*
  * Filter data source.
  *
  * @param [Object] queryParams. Key value pair containing filters.
  */
  onFilter (queryParams) {
    const {dataSource} = this.state
    const newDataSource = filterDataSource(dataSource, queryParams)
    this.setState({filteredDataSource: newDataSource})
  }

  render () {
    return (
      <div className='search-page fraud-rules-view'>
        <TableDisplay
          dataActions={this.actions}
          dataSource={this.state.filteredDataSource}
          loading={this.state.loading}
          onAdd={this.onAdd.bind(this)}
          onFilter={this.onFilter.bind(this)}
          onRowClick={this.onRowClick.bind(this)}
          pageDescription='Manage fraud and risk rules.'
          pageTitle='Fraud and Risk Rules'
          tableHeaders={headers}
        />
        {this.renderConfirmDialog()}
      </div>
    )
  }
}

FraudRules.propTypes = {

}

export default FraudRules
