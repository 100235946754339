import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Styles ------------
import './flex-table.scss'

class TableRow extends Component {
  render () {
    const {children, className, onClick} = this.props

    const classes = ['table-row']
    if (className) classes.push(className)

    return (
      <div className={classes.join(' ')} onClick={onClick}>
        {children}
      </div>
    )
  }
}

TableRow.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func
}

export default TableRow
