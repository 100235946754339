import React from 'react'
import PropTypes from 'prop-types'
import SortableField from '../../components/forms/SortableField'

class Sortable extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            isDirty: false,
            sortDirection: null
        }
    }

    componentWillMount () {
        if(this.props.label === this.props.initialSortLabel)
            this.setState({sortDirection: this.props.initialSortDirection})
    }

    onClick(event) {
        event.preventDefault()
        if(!this.state.sortDirection ||
            // Either asc or desc for single column sort
            this.props.singleColumnSort && this.state.sortDirection === 'desc') {
            this.setState({sortDirection: 'asc'})
        } else if(this.state.sortDirection === 'asc') {
            this.setState({sortDirection: 'desc'})
        } else {
            this.setState({sortDirection: null})
        }
        this.props.onSort(this.props.sortableItem)
    }

    render() {
        const {label,key} = this.props
        let formattedLabel = label
        if(this.state.sortDirection !== null) {
            formattedLabel = label + ' (' + this.state.sortDirection + ')'
        }
        return (
            <SortableField
                ref={(component) => { this.field = component }}
                label={formattedLabel}
                key={key}
                sortDirection={this.state.sortDirection}
                events={
                    {
                        onClick: (event) =>  { this.onClick(event) }
                    }
                }
            />
        )
    }
}

Sortable.propTypes = {
    sortableItem: PropTypes.object,
    key: PropTypes.string,
    sortDirection: PropTypes.string,
    initialSortLabel: PropTypes.string,
    initialSortDirection: PropTypes.string,
    singleColumnSort: PropTypes.bool
}

export default Sortable