const transferType = {
  us: {
    transferType : {
      name: 'transferType',
      label: 'Transfer Type',
      type: 'dropdown',
      data: {
        employers : {
          name: 'Transfer Employers',
          disabled: false
        },
        members : {
          name: 'Transfer Members',
          disabled: false
        }
      }
    }
  }
}

export default transferType
