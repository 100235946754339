import React from 'react'
import Search from 'compositions/Search'
import TableDisplay from 'compositions/TableDisplay'
import Paginate from '../../../../components/Paginate'
import Field from '../../../../components/forms/Field'
import Button from '../../../../components/Button'
import Loading from '../../../../components/Loading'
import actions from '../../../../actions'
import { showSnackbar } from '../../../../redux/utility/SnackbarUtil'
import { hasRole } from '../../../../redux/utility/SessionUtil'
import Utils from '../../../../lib/utils'

const headers = [
    {
        filterText: 'Employer Name',
        header: () => 'Employer Name',
        key: 'employerName',
        enableSort: true
    },
    {
        filterText: 'Member First Name',
        header: () => 'Member First Name',
        key: 'firstName',
        disableFilter: true,
        enableSort: true
    },
    {
        filterText: 'Member Last Name',
        header: () => 'Member Last Name',
        key: 'lastName',
        disableFilter: true,
        enableSort: true
    },
    {
        filterText: 'SSN',
        header: () => 'SSN',
        key: 'ssn',
        disableFilter: true,
        enableSort: true
    },
    {
        filterText: 'Job ID',
        header: () => 'Job ID',
        key: 'jobId',
        disableFilter: true,
        enableSort: true
    },
    {
        filterText: 'Savings Account ID',
        header: () => 'Savings Account ID',
        key: 'cycAccountId',
        disableFilter: true,
        enableSort: true
    },
    {
        filterText: 'NBT Account ID',
        header: () => 'NBT Account ID',
        key: 'nbtAccountId',
        disableFilter: true,
        enableSort: true
    },
    {
        filterText: 'Month-Year',
        header: () => 'Month-Year',
        disableFilter: true,
        key: 'yearMonth',
        enableSort: true
    },
    {
        filterText: 'Status',
        header: () => 'Status',
        key: 'statusString',
        enableSort: true
    },
    {
        filterText: 'Status Message',
        header: () => 'Status Message',
        key: 'statusMessage',
        enableSort: true
    }
]

let searchFilters = [
    {
      filterText: 'First name', 
      header: () => 'First Name',
      key: 'firstName'
    },
    {
      filterText: 'Last name',
      header: () => 'Last Name',
      key: 'lastName'
    },
    {
      filterText: 'CYC Account ID',
      header: () => 'CYC Account ID',
      key: 'cycAccountId'
    },
    {
        filterText: 'NBT Account ID',
        header: () => 'NBT Account ID',
        key: 'nbtAccountId'
    },
    {
      filterText: 'SSN',
      header: () => 'SSN',
      key: 'ssn'
    },
    {
      filterText: 'Last Four SSN',
      header: () => 'Last Four SSN',
      key: 'lastFourSsn'
    },
    {
      filterText: 'Job ID',
      header: () => 'Job ID',
      key: 'jobId'
    }
  ]

const exportDropDownData = {
    csv : {
      name: 'CSV',
      disabled: false
    },
    xlsx : {
      name: 'Excel',
      disabled: false
    }
  }

const COUNT_PER_PAGE = 50

class EmployeeBillingReport extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            dataSource: [],
            exportType: 'csv',
            downloading: false,
            loading: false,
            hasSearched: false,
            total: 0,
            readAccess: hasRole('AccountMaintView') || hasRole('AccountMaintEdit'),
            showSearch: true,
            loadPagination: true
        }
        this.form = {}
        this.initTableData()
        
    }

    componentDidMount() {
        this.verifyAccess()
    }

    verifyAccess() {
        if(!this.state.readAccess) {
            this.props.history.push('/nbtadmin/unauthorized')
        }
    }

    initTableData() {
        this.queryParams = {}
        this.sortDirection = 'asc'
        this.sortColumn = 'firstName'
        this.pageNumber = 0
    }

    onSearch(form) {
        this.form = form
        if(!form || Object.keys(form).length === 0) {
            this.setState({dataSource: [], total: 0})
            showSnackbar('At least one search required!')
            return
        }
        this.pageNumber = 0
        this.setState({ loading: true , dataSource: [], total: 0})
        this.loadData()
    }

    onFormUpdate(form) {
        this.form = form
    }

    updatePage(current) {
        // zero based index for page number
        this.pageNumber = current - 1
        this.setState({ loading: true })
        this.loadData()
    }

    onFilterTable(queryParams) {
        this.pageNumber = 0
        this.setState({loadPagination : false})
        this.queryParams = queryParams
        this.loadData()
    }

    onSortTable(queryParams,sortableRefs) {
        this.pageNumber = 0
        this.setState({loadPagination : false})
        this.sortDirection = sortableRefs.filter(row => row.props.sortableItem.key === queryParams.key)
        .map(row => row.state.sortDirection)
        this.sortColumn = queryParams.key
        this.loadData()
    }

    loadData() {
        const params  = { 
            ...this.form,
            ...this.queryParams,
            pageNumber: this.pageNumber,
            sortDirection: this.sortDirection,
            sortColumn: this.sortColumn
        }
        actions.employeeBillingReport.getEmployeeBillingReport(params)
        .then(data => {
            this.setState({dataSource: data.reportList, total: data.total, loading: false, 
                showSearch: false, hasSearched: true, loadPagination : true})
        }).catch(() => {
            this.setState({dataSource: [], loading: false, showSearch: true, total: 0, loadPagination : false})
            showSnackbar('Failed to retrieve data')
        })
    }

    exportAll() {
        if(!this.form || Object.keys(this.form).length === 0) {
            showSnackbar('At least one search required!')
            return
        }
        const params  = { ...this.form, fileType: this.state.exportType}
        this.setState({ downloading: true })
        actions.employeeBillingReport.exportEmployeeBillingReport(params)
        .then(data => data.blob())
        .then(response => {
            this.setState({ downloading: false })
            const url = window.URL.createObjectURL(response)
            const a = document.createElement('a')
            a.style.display = 'none'
            a.href = url
            a.download = 'EmployeeBillingReport-' + Utils.getCurrentDateTime() + '.' + this.state.exportType
            a.click()
            window.URL.revokeObjectURL(url)
        }).catch(() => {
            this.setState({ downloading: false })
            showSnackbar('Failed to download the file')
        })

    }

    /*
    * Toggle if the search is showing
    *
    */
    toggleSearch () {
        this.setState({showSearch: !this.state.showSearch})
    }

    renderTable() {
        if (this.state.loading) return <div className='margin-top'><Loading/></div>

        return (this.state.hasSearched &&  <div> <div
            style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '1em'
            }}
        >
            <Field
                label={false}
                name='exportType'
                type='dropdown'
                value={this.state.exportType}
                data={exportDropDownData}
                className='width-auto'
                events={
                    {
                        onChange: (event) => {
                            this.setState({ exportType: event.target.value })
                        }
                    }
                }
            />
            <Button
                className='margin-left margin-right width-auto'
                disabled={this.state.downloading}
                events={
                    {
                        onClick: (event) => {
                            if (this.state.downloading) {
                                return;
                            }
                            this.exportAll();
                        }
                    }
                }
            >
                <span>
                    {this.state.downloading ? <Loading containerClassName='inline' className='white small' />
                        : 'Export All'}
                </span>
            </Button>
        </div>
            <div className="fixed-height-scroll">
                <TableDisplay
                    dataActions={[]}
                    dataSource={this.state.dataSource}
                    disableAdd={true}
                    onAdd={() => false}
                    onFilter={this.onFilterTable.bind(this)}
                    onSort={this.onSortTable.bind(this)}
                    singleColumnSort={true}
                    tableHeaders={headers}
                    tableDataClassName='align-items-start overflow-wrap'
                    initialSortLabel='Member First Name'
                    initialSortDirection='asc'
                />
            </div>
        </div>)
    }

    render() {
        return (
            <div className='search-page'>
                <h4>Employee Detail Billing Report</h4>
                <i className="material-icons" onClick={this.toggleSearch.bind(this)}>{this.state.showSearch ? 'chevron_left' : 'chevron_right'}</i>
                <div style={{ width: "100%", margin: "0 auto", display: this.state.showSearch ? 'block' : 'none' }}>
                    <Search
                        tableFilters={searchFilters}
                        onSearch={this.onSearch.bind(this)}
                    />
                </div>
                {this.renderTable()}
                { (this.state.loadPagination && this.state.dataSource.length > 0) && (<Paginate
                total={this.state.total}
                countPerPage={COUNT_PER_PAGE}
                updatePage={this.updatePage.bind(this)}
                style={{
                    marginTop: '1em'
                }}
            />)}
            </div>
        )
    }
}

export default EmployeeBillingReport