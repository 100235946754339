import Fetch from 'lib/fetch'
import { showSnackbar } from '../redux/utility/SnackbarUtil'

/*
* Get the disclaimers based on custodians.
*/

export function getDisclaimersByCustodians () {
  return Fetch.get(`${process.env.REACT_APP_EMPLOYER_CONFIG_DOMAIN}/employer-config/disclaimers/custodians`, null)
  .then(data => data)
  .catch(error => Promise.reject(error))
}

/*
* Get the disclaimers based on master.
*/

export function getDisclaimersByMaster () {
  return Fetch.get(`${process.env.REACT_APP_EMPLOYER_CONFIG_DOMAIN}/employer-config/disclaimers/master`, null)
  .then(data => data)
  .catch(error => Promise.reject(error))
}

/*
* Update disclaimer from Custodian.
*
* @param [Object] form. Disclaimer.
*/
export function updateDisclaimerByCustodianIdAndType (form) {
  return Fetch.post(`${process.env.REACT_APP_EMPLOYER_CONFIG_DOMAIN}/employer-config/disclaimers/custodians`, form, null)
    .then(data => {
      showSnackbar('Disclaimer successfully updated', 'info')
      return data
    })
    .catch(error => Promise.reject(error))
}

/*
* Update disclaimer from Master.
*
* @param [Object] form. Disclaimer.
*/
export function updateDisclaimerByMaster (form) {
  return Fetch.post(`${process.env.REACT_APP_EMPLOYER_CONFIG_DOMAIN}/employer-config/disclaimers/master`, form, null)
    .then(data => {
      showSnackbar('Disclaimer successfully updated', 'info')
      return data
    })
    .catch(error => Promise.reject(error))
}