import React from 'react'
import PropTypes from 'prop-types'
import TableDisplay from 'compositions/TableDisplay'
import {filterDataSource} from 'lib/filter'

const headers = [
  {
    filterText: 'Name',
    header: () => 'Name',
    key: 'name'
  }
]

class HierarchyTable extends React.Component {
  constructor (props) {
    super (props)

    let mappedHierarchies = []
    if (props.dataSource) {
      mappedHierarchies = props.dataSource.map(hierarchy => {
        return this.mapHierarchyToDisplay(hierarchy)
      })
    }

    this.state = {
      dataSource: mappedHierarchies,
      filteredDataSource: mappedHierarchies,
      originalSource: props.dataSource || []
    }

    this.actions = [
      {
        action: this.onRowClick.bind(this),
        icon: 'visibility'
      }
    ]
  }

  /*
  * Map the new datasource to state
  */
  componentWillReceiveProps (newProps) {
    const mappedHierarchies = newProps.dataSource.map(hierarchy => {
      return this.mapHierarchyToDisplay(hierarchy)
    })

    this.setState({
      originalSource: newProps.dataSource,
      dataSource: mappedHierarchies,
      filteredDataSource: mappedHierarchies
    })
  }


  /*
  * Map hierarchy to display
  */
  mapHierarchyToDisplay (hierarchy) {
    const {id, name} = hierarchy
    return {
      id,
      name
    }
  }


  /*
  * View hierarchy
  */
  onRowClick (hierarchy) {
    const {history} = this.props
    history.push(`/nbtadmin/hierarchies/${hierarchy.id}`)
  }

  /*
  * Filter data source.
  *
  * @param [Object] queryParams. Key value pair containing filters.
  */
  onFilter (queryParams) {
    const {dataSource} = this.state
    const newDataSource = filterDataSource(dataSource, queryParams)
    this.setState({filteredDataSource: newDataSource})
  }

  render () {
    return (
      <div className='hierarchy-view'>
        {this.props.title && <h5>{this.props.title}</h5>}
        <TableDisplay
          dataActions={this.actions}
          dataSource={this.state.filteredDataSource}
          disableAdd={true}
          loading={this.props.loading}
          onAdd={() => false}
          onFilter={this.onFilter.bind(this)}
          onRowClick={this.onRowClick.bind(this)}
          pageDescription=''
          pageTitle=''
          tableHeaders={headers}
        />
      </div>
    )
  }
}

HierarchyTable.propTypes = {
  dataSource: PropTypes.array,
  title: PropTypes.string
}

export default HierarchyTable
