/*
* Filter method for tables.
*
* @param [Array] dataSource. Datasource to filter.
* @param [Object] querParams. The queryParams that contain the key value pair \
* to filter.
* @return [Array] newDataSource. The filtered data source.
*/
export function filterDataSource(dataSource, queryParams) {
  const newDataSource = dataSource.filter(d => {
    let isValid = true

    const queryKeys = Object.keys(queryParams)
    for (let i=0; i < queryKeys.length; i++) {
      //if any formatting in text
      if(d[queryKeys[i]]['props'] &&
        d[queryKeys[i]]['props']['children'] &&
        d[queryKeys[i]]['props']['children'].toString().toLowerCase()
        .includes(queryParams[queryKeys[i]].toLowerCase()) ) {
          isValid = true
      }
      else if (!d[queryKeys[i]] || !d[queryKeys[i]].toString().toLowerCase()
        .includes(queryParams[queryKeys[i]].toLowerCase()) )
      {
        isValid = false
        i = queryKeys.length
      }
    }

    return isValid
  })

  return newDataSource
}
