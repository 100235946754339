import React from 'react'
import Button from 'components/Button'
import Card from 'components/Card'
import Field from 'components/forms/Field/index'
import Loading from 'components/Loading'
import ProfileSpecificFee from './ProfileSpecificFee'
import feeProfile from 'data/feeProfile'
import actions from 'actions'
import { hasRole } from '../../../../redux/utility/SessionUtil'
import {v1 as uuidv1} from 'uuid'
import dayjs from 'dayjs'

class FeeProfileForm extends React.Component {

  constructor (props) {
    super (props)

    this.state = {
      form: this.initializeFormData(),
      fees: {},
      loading: false,
      submitting: false,
      writeAccess: hasRole('FeeProfileMasterEdit')
    }

    this.feeFormRefs = []

    this.onChange.bind(this)
  }

  /*
  * Get fee profile by id.
  */
  componentWillMount () {
    if (this.verifyAccess()) {
      this.getFeeProfile()
      this.getFees()
    }
  }

  /*
  * Verify that the user has access to fee profile
  */
  verifyAccess () {
    if (!(this.state.writeAccess)) {
      this.props.history.push('/nbtadmin/unauthorized')
      return false
    }

    return true
  }

  /*
  * Fetch fee profile by id.
  */
  getFeeProfile () {
    const {match: {params: {id}}} = this.props

    if (id) {
      this.setState({loading: true})
      actions.feeProfiles.getFeeProfileById(id).then(profile => {
        this.setState({form: this.initializeFormData(profile), loading: false})
      }).catch(() => this.setState({loading: false}))
    }
  }

  /*
  * Fetch fees and map them to dropdown format.
  */
  getFees () {
    actions.fees.getFees().then((fees) => {
      let mappedFees = {}
      fees.forEach(fee => {
        mappedFees[fee.id] = fee.name
      })

      this.setState({fees: mappedFees})
    })
  }


  /*
  * Initialize form data
  * Fee.
  */
  initializeFormData (data) {
    const {id, name, description, deactivatedDate, profileSpecificFees} = data || {}
    if (deactivatedDate != null) {
      return {
        id: id || '',
        name: name || '',
        description: description || '',
        deactivatedDate: dayjs(deactivatedDate).format("YYYY-MM-DD") || '',
        profileSpecificFees: profileSpecificFees ? profileSpecificFees.map(fee => (
            this.initializeFeeForm(fee)
        )) : []
      }
    } else {
      return {
        id: id || '',
        name: name || '',
        description: description || '',
        deactivatedDate: deactivatedDate || '',
        profileSpecificFees: profileSpecificFees ? profileSpecificFees.map(
            fee => (
                this.initializeFeeForm(fee)
            )) : []
      }
    }
  }

  /*
  * Initialize profile specific form data
  * Profile specific fee.
  */
  initializeFeeForm(profileFee) {
    const {id, feeProfileId, fee, amount, paidBy, thresholdAmount, thresholdType, enrollmentStatus} = profileFee || {}
    return {
      id: id || '',
      feeProfileId: feeProfileId || '',
      feeId: (fee && fee.id) || '',
      amount: amount || '',
      paidBy: paidBy || '',
      thresholdAmount: thresholdAmount || '',
      thresholdType: thresholdType || '',
      enrollmentStatus: enrollmentStatus || ''
    }
  }

  /*
  * Render the fields
  */
  getFields () {
    const {form: {
      name,
      description,
      deactivatedDate
    }} = this.state

    const {fields} = feeProfile.us

    return [
        {
          ...fields.name,
          required: true,
          events: {
            onChange: (event) => {
              this.onChange(fields.name.name, event.target.value)
            }
          },
          value: name
        },
        {
          ...fields.description,
          required: false,
          events: {
            onChange: (event) => {
              this.onChange(fields.description.name, event.target.value)
            }
          },
          value: description
        },
        {
        ...fields.deactivatedDate,
        required: false,
        events: {
          onChange: (event) => {
            if (event.target.value != null) {
              this.onChange(fields.deactivatedDate.name, event.target.value)
            } else {
              this.onChange(fields.deactivatedDate.name, '')
            }
          }
        },
        value: deactivatedDate
      }

      ]
  }

  /*
  * Render profile specific fee forms.
  */
  renderProfileSpecificFeeForms() {
    this.feeFormRefs = []
    const {form: {profileSpecificFees}, fees} = this.state
    const feeForms = this.checkMutipleFee(profileSpecificFees)
    return feeForms.map((fee, index) => {
      return (
        <ProfileSpecificFee
          key={`fee-${uuidv1()}`}
          ref={(c) => { if (c) this.feeFormRefs[index] = c} }
          fee={fee}
          fees={fees}
          formIndex={index}
          onChangeChild={this.onChangeChild.bind(this)}
          onDelete={this.onDelete.bind(this)}
        />
      )
    })
  }

  /*
  * Update form values
  *
  * @param [String] name. Field name of form to update.
  * @param [Any] value. Value.
  */
  onChange (name, value) {
    let form = Object.assign({}, this.state.form)
    form[name] = value
    this.setState({form})
  }

  /*
  * Update form values
  *
  */
  onChangeChild () {
    let feeProfiles = this.getProfileSpecificForms()
    const feeForms = this.checkMutipleFee(feeProfiles)
    this.onChange('profileSpecificFees', feeForms)
  }

  checkMutipleFee(feeProfiles) {
    let feeForms = []
    feeProfiles.forEach(data => {
      let exsistingFee = feeForms.filter(fee => (fee.feeId === data.feeId));  
      if(exsistingFee && exsistingFee.length > 0){
        data.thresholdAmount = exsistingFee[0].thresholdAmount
        data.thresholdType = exsistingFee[0].thresholdType
        data.disableThreshold = true
      }else{
        data.disableThreshold = false
      }
      feeForms.push(data)
    })
    return feeForms
  }

  /**
   * On submit
   */
  handleSubmit(event) {
    event.preventDefault()
    const form = this.mapForm(this.state.form)
    if (form.deactivatedDate != '') {
      form.deactivatedDate = dayjs(form.deactivatedDate).format(
          'YYYY-MM-DD HH:mm:ss')
    }
    !form.id ?
      this.onCreateFeeProfile(form): this.onUpdateFeeProfile(form)
  }

  /*
  * Map form for submission
  */
  mapForm () {
    let form = {...this.state.form}
    form.profileSpecificFees = this.getProfileSpecificForms()
    return form
  }

  /*
  * Get the profile specific fee forms.
  */
  getProfileSpecificForms () {
    let feeForms = []

    this.feeFormRefs.forEach(c => {
      const data = c.getData()
      if (!Object.keys(data).every(d => !d)) {
        feeForms.push(data)
      }
    })
    feeForms = feeForms.map((feeForm) => {
      let newForm = feeForm
      if (!newForm.id) delete newForm.id
      newForm.feeId = parseInt(newForm.feeId)
      return newForm
    })

    return feeForms
  }

  /*
  * Create new fee profile
  */
  onCreateFeeProfile (form) {
    delete form.id
    delete form.profileSpecificFees
    this.setState({submitting: true})
    actions.feeProfiles.createFeeProfile(form).then((profile) => {
      const {history} = this.props
      history.push(`/nbtadmin/fee-profiles/${profile.id}/edit`)
      this.setState({form: this.initializeFormData(profile), submitting: false})
    }).catch(() => { this.setState({submitting: false}) })
  }

  /*
  * Update fee profile
  */
  onUpdateFeeProfile (form) {
    this.setState({submitting: true})
    actions.feeProfiles.updateFeeProfile(form).then((profile) => {
      const {history} = this.props
      history.push(`/nbtadmin/fee-profiles/${profile.id}`)
      this.setState({form: this.initializeFormData(profile), submitting: false})
    }).catch(() => { this.setState({submitting: false}) })
  }

  renderAddButton (form) {
    if (this.state.writeAccess && form.id) {
      return (
        <div>
          <div>
            <span style={{float: 'right'}}>
              <a onClick={this.onAddProfile.bind(this)} className='orange'>
                <span style={{verticalAlign: 'top'}}>Create New Fee Profile</span>
                <i className='material-icons'>add_circle_outline</i>
              </a>
            </span>
          </div>
          <div style={{clear: 'both'}}></div>
        </div>
      )
    }
  }

  onAddProfile () {
    const {history} = this.props
    history.push(`/nbtadmin/fee-profiles/create/new`)
  }

  /*
  * Add a new fee to profile
  */
  onAdd () {
    const newFees = this.getProfileSpecificForms()
    newFees.push(this.initializeFeeForm())
    this.onChange('profileSpecificFees', newFees)
  }

  /*
  * Delete fee profile
  */
  onDelete(index) {
    const newFees = this.getProfileSpecificForms().filter((fee, idx ) => (
      idx !== index
    ))
    const feeForms = this.checkMutipleFee(newFees)
    this.onChange('profileSpecificFees', feeForms)
  }

  render () {
    if (this.state.loading) return <div/>
    const {form} = this.state
    return (
      <div className='page fee-form'>
        <h4>{form.id ? 'Edit' : 'Create'} Fee Profile</h4>
        {this.renderAddButton(form)}
        <form onSubmit={this.handleSubmit.bind(this)} className="fee-form">
          <Card className='padding'>
            {this.getFields().map((f, idx) => {
              return <Field key={`${f.name}-${idx}`} {...f} />
            })}
          </Card>
          {form.id &&
            <div style={{marginLeft: '3em'}}>
              <h5> Profile Specific Fees
                <a style={{float: 'right'}} onClick={this.onAdd.bind(this)}>
                  <i className='material-icons orange'>add_circle_outline</i>
                </a>
              </h5>
              {this.renderProfileSpecificFeeForms()}
            </div>
          }

          <Button
            className='primary-button margin-top'
            disabled={this.state.submitting}
            type="submit"
          >
            <span>

              {this.state.submitting ?
                <Loading containerClassName='inline' className='white small' />
                : 'Submit'
              }
            </span>
          </Button>
        </form>
      </div>
    )
  }
}

FeeProfileForm.propTypes = {

}

export default FeeProfileForm
