import { configureStore } from "@reduxjs/toolkit";
import SessionSlice from "../slices/SessionSlice";
import SnackbarSlice from "../slices/SnackbarSlice";

const Store = configureStore({
    reducer: {
        session: SessionSlice,
        snackbar: SnackbarSlice
    },
    devTools: process.env.NODE_ENV === 'development'
});

export default Store;

