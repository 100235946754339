import React from "react";
import { hasRole } from "../../../../redux/utility/SessionUtil";
import toa from "data/toa";
import Field from "components/forms/Field/index";
import Card from "components/Card";
import actions from "actions";
import Loading from "components/Loading";
import Button from "components/Button";
import AutoCompleteField from "components/forms/AutoCompleteField";
import { maskCurrency } from "lib/mask";
import { showSnackbar } from "../../../../redux/utility/SnackbarUtil";

class UploadSingleOrMultipleTOA extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initializeState();
  }

  componentWillMount() {
    if (this.props.verifyAccess()) {
      this.getCustodians();
      this.getOmnibusPartnerBanks();
    }
  }

  /*
   * Initialize State
   */
  initializeState() {
    return {
      form: this.initializeFormData(),
      custodianOptions: {},
      omnibusOptions: {},
      submitting: false,
      employerText: "",
      employerDataSource: [],
      readAccess: hasRole("CipOpsView"),
      writeAccess: false,
      isMultiple: this.props.isMultiple
    };
  }

  /*
   * initialize all of the form fields
   */
  initializeFormData(data) {
    const { custodianId } = data || {};

    return {
      custodianId: custodianId || "",
      otherCustodian: "",
      employerId: "",
      totalCash: "",
      hasConsent: 0,
      sponsorBankName: ""
    };
  }

  /*
   * Fetch custodians
   */
  getCustodians() {
    this.setState({ loadingCustodians: true });
    actions.toa
      .getCustodians()
      .then(custodians => {
        let custodianOptions = {};
        custodians.forEach((c, i) => {
          if (c.name) {
            custodianOptions[i] = c.name;
          }
        });
        this.setState({ custodianOptions, loadingCustodians: false });
      })
      .catch(() => this.setState({ loadingCustodians: false }));
  }

  /*
   * Get Omnibus partner banks
   */
  getOmnibusPartnerBanks() {
    this.setState({ loadingOmnibus: true });
    actions.toa
      .getOmnibusPartnerBanks()
      .then(banks => {
        const omnibusOptions = {};
        banks.omnibusPartnerBanks.map(bank => {
          omnibusOptions[bank.name] = bank.name;
        });
        this.setState({ omnibusOptions: omnibusOptions });
      })
      .catch(() => this.setState({ loadingOmnibus: false }));
  }

  /*
   * Fetch employers
   */
  getEmployers() {
    this.setState({ loadingEmployers: true });
    actions.employers
      .getEmployers({ employerName: this.state.employerText })
      .then(employers => {
        const mappedEmployers = employers.map(employer => {
          const { id, name } = employer;
          return {
            id,
            name
          };
        });

        this.setState({
          employerDataSource: mappedEmployers,
          loadingEmployers: false
        });
      })
      .catch(() => this.setState({ loadingEmployers: false }));
  }

  /*
   * Render row of autocomplete
   */
  renderAutoCompleteRow(data, index, resetState) {
    return (
      <div
        className="autocomplete-row"
        onClick={() => {
          this.onChange("employerId", data.id);
          this.setState({ employerText: data.name, employerDataSource: [] });
        }}
      >
        {data.name}
      </div>
    );
  }

  /*
   * Handle file drop.
   *
   * @param [Event] event. Drop event.
   */
  onDrop(event) {
    // this.onCreateAccountClosureDocument(event.target.files[0])
    // this.fileInput.value = ''
  }

  /*
   * Update form values
   *
   * @param [String] name. Field name of form to update.
   * @param [Any] value. Value.
   */
  onChange(name, value) {
    let form = { ...this.state.form };
    form[name] = value;
    this.setState({ form });
  }

  getFields() {
    const { fields } = toa.us;

    const {
      form: { custodianId, otherCustodian, totalCash, hasConsent },
      custodianOptions
    } = this.state;

    let f = [
      {
        ...fields.custodianId,
        required: true,
        data: custodianOptions,
        events: {
          onChange: event => {
            this.onChange(event.target.name, event.target.value);
          }
        },
        value: custodianId
      },
      {
        ...fields.totalCash,
        required: true,
        events: {
          onChange: event => {
            const newValue = maskCurrency(event.target.value);
            this.onChange(event.target.name, newValue);
          }
        },
        type: "number",
        value: totalCash
      },
      {
        ...fields.hasConsent,
        events: {
          onChange: event => {
            this.onChange(event.target.name, event.target.checked);
          }
        },
        type: "checkbox",
        value: hasConsent
      }
    ];

    const otherCustodianField = {
      ...fields.otherCustodian,
      events: {
        onChange: event => {
          this.onChange(event.target.name, event.target.value);
        }
      },
      value: otherCustodian
    };

    if (custodianOptions[custodianId] === "Other")
      f.splice(1, 0, otherCustodianField);

    return f;
  }

  /**
   * On submit
   */
  handleSubmit(event) {
    event.preventDefault();

    if (this.validateSubmit()) {
      const {
        form: {
          employerId,
          custodianId,
          otherCustodian,
          totalCash,
          hasConsent,
          sponsorBankName
        }
      } = this.state;
      let formData = new FormData();
      formData.append("employerId", employerId);
      formData.append("custodianId", custodianId);
      formData.append("otherCustodian", otherCustodian);
      formData.append("totalCash", totalCash);
      formData.append("fileInput", this.fileInput.files[0]);
      formData.append("hasConsent", hasConsent);
      
      this.state.isMultiple === "true"
        ? formData.append("sponsorBankName", sponsorBankName)
        : formData.append("sponsorBankName", "");
      
      formData.append("isMultiple", this.state.isMultiple);

      actions.toa
        .uploadTOA(formData)
        .then(() => {
          this.setState(this.initializeState());
          this.fileInput.value = "";
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  validateSubmit() {
    const {
      form: {custodianId, otherCustodian},
      custodianOptions
    } = this.state;

    if (
      custodianId &&
      custodianOptions[custodianId] === "Other" &&
      !otherCustodian
    )
      showSnackbar("Other Custodian is required.");
    else if (!this.fileInput.value)
      showSnackbar("File selection is required.");
    else return true;

    return false;
  }

  render() {
    const { fields } = toa.us;

    const { sponsorBankName, omnibusOptions } = this.state;

    const employerField = {
      ...fields.employerId,
      required: true,
      name: "employerText",
      events: {
        onChange: (name, value) => {
          if (value.length < this.state.employerText.length)
            this.onChange("employerId", "");
          this.setState({ employerText: value });
        }
      },
      dataValue: this.state.form.employerId,
      value: this.state.employerText
    };

    const omnibusField = {
      ...fields.sponsorBankName,
      required: true,
      events: {
        onChange: event => {
          this.onChange(event.target.name, event.target.value);
        }
      },
      data: omnibusOptions,
      value: sponsorBankName
    };

    return (
      <div>
        <form onSubmit={this.handleSubmit.bind(this)} className="fee-form">
          <Card className="padding">
            {this.state.isMultiple === "false" && (
              <AutoCompleteField
                {...employerField}
                dataSource={this.state.employerDataSource}
                limit={20}
                onSearch={this.getEmployers.bind(this)}
                renderRow={this.renderAutoCompleteRow.bind(this)}
              />
            )}

            {this.getFields().map((f, idx) => {
              return (
                <Field className="margin-top" key={`${f.name}-${idx}`} {...f} />
              );
            })}
            {this.state.isMultiple === "true" && (
              <Field className="margin-top" {...omnibusField} />
            )}

            <div className="form-control margin-top">
              <label className="input-label" htmlFor={"file-input"}>
                Select File To Upload
              </label>
              <div>
                <input
                  name="file-input"
                  type="file"
                  ref={input => {
                    this.fileInput = input;
                  }}
                  onChange={this.onDrop.bind(this)}
                />
              </div>
            </div>
          </Card>
          <Button
            className="primary-button margin-top"
            disabled={this.state.submitting}
            type="submit"
          >
            <span>
              {this.state.submitting ? (
                <Loading containerClassName="inline" className="white small" />
              ) : (
                "Submit"
              )}
            </span>
          </Button>
        </form>
      </div>
    );
  }
}

export default UploadSingleOrMultipleTOA;
