import React from 'react'
import PropTypes from 'prop-types'
import TableDisplay from "../../../../compositions/TableDisplay";
import {filterDataSource} from 'lib/filter'

const headers = [
  {
    filterText: 'Custodian Name',
    header: () => 'Custodian Name',
    key: 'custodianName'
  },
  {
    filterText: 'Receiving Custodian',
    header: () => 'Receiving Custodian',
    key: 'receivingCustodian'
  },
  {
    filterText: 'Create Date',
    header: () => 'Create Date',
    key: 'createDateDisplay'
  },
  {
    filterText: 'Transfer In Kind',
    header: () => 'Transfer In Kind',
    key: 'displayTransferInKind'
  }
]

class CustodianFormTable extends React.Component {
  constructor(props) {
    super(props)
    
    let mappedCustodianForms = []
    if (props.dataSource) {
      mappedCustodianForms = props.dataSource.map(custodianForm => {
            return this.mapCustodianFormToDisplay(custodianForm)
      })
    }
    
    this.state = {
      dataSource: mappedCustodianForms,
      filteredDataSource: mappedCustodianForms,
      originalSource: props.dataSource || []
    }
    
    this.actions = [
      {
        action: this.onRowClick.bind(this),
        icon: 'visibility'
      }
    ]
  }

  componentWillReceiveProps (newProps) {
    const mappedCustodianForms = newProps.dataSource.map(custodianForm => {
      return this.mapCustodianFormToDisplay(custodianForm)
    })

    this.setState({
      originalSource: newProps.dataSource,
      dataSource: mappedCustodianForms,
      filteredDataSource: mappedCustodianForms
    })
  }

  mapCustodianFormToDisplay(custodianForm) {
    const {id, custodianName, receivingCustodian, createDateDisplay, custodianVerbiage, displayTransferInKind} = custodianForm
    return {
      id,
      custodianName,
      receivingCustodian,
      createDateDisplay,
      custodianVerbiage,
      displayTransferInKind
    }
  }

  onRowClick (custodianForm) {
    const {history} = this.props
    history.push(`/nbtadmin/custodian-consent-form/${custodianForm.id}`)
  }
  
  onFilter (queryParams) {
    const {dataSource} = this.state
    const newDataSource = filterDataSource(dataSource, queryParams)
    this.setState({filteredDataSource: newDataSource})
  }

  render() {
    return (
        <div className='search-page custodian-forms-view'>
          <TableDisplay
              dataActions={this.actions}
              dataSource={this.state.filteredDataSource}
              disableAdd={true}
              loading={this.props.loading}
              onAdd={() => false}
              onFilter={this.onFilter.bind(this)}
              onRowClick={this.onRowClick.bind(this)}
              pageDescription=''
              pageTitle=''
              tableHeaders={headers}
          />
        </div>
    )
  }
}

CustodianFormTable.propTypes = {
  dataSource: PropTypes.array,
  title: PropTypes.string
}

export default CustodianFormTable
