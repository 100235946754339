import React from "react";
import Field from "../../../../components/forms/Field";
import UpdateCipOrderConfirm from 'components/UpdateCipOrderConfirm'
import { showSnackbar } from "../../../../redux/utility/SnackbarUtil";
import DatePicker from '../../../../components/forms/DatePicker'
import stateTerritory from "data/stateTerritory"

const currentDate = new Date();
const currentYear = currentDate.getUTCFullYear();

class UpdateCipOrder extends React.Component {
  
    constructor(props) {
        super(props);
        this.state = {
          form: this.initializeFormData(),
          submitting: false,
          openConfirmDialog: false,
        }
      }

  initializeFormData() {
    return {
      cipOrderId: this.props.cipOrderId,
      dateOfBirth: this.props.dateOfBirth,
      streetAddress1: this.props.streetAddress1,
      streetAddress2: this.props.streetAddress2,
      city: this.props.city,
      state: this.props.state,
      zipcode: this.props.zipcode
    }
  }

  /*
  * Takes values on form and assigns them into the right form values in state
  */
  onChange(name, value){
    let form = {...this.state.form}
    form[name] = value
    this.setState({form})
  }
  
  /*
  * Seperate for dropdown since we will need to add the description logic as well
  */
  onDropDownChange(name, value){
    let form = {...this.state.form}
    form[name] = value
    this.setState({form})
  }

  /*
  * Render confim dialogue 
  */
  renderConfirmDialog () {
    if (this.state.openConfirmDialog) {
      return (
        <UpdateCipOrderConfirm
          onClose={() => {this.setState({openConfirmDialog: false})}}
          onChange={this.setState({displaySaveButton: true})}
          onConfirm={this.handleSubmit.bind(this)}
        />
      )
    }
  }

  openConfirm(event) {
    event.preventDefault()
    this.setState({ openConfirmDialog: true  })
  }

  isDateBetween18and100Years(dateOfBirth) {
    return new Date(dateOfBirth.getUTCFullYear()+100, dateOfBirth.getUTCMonth(), dateOfBirth.getUTCDate()) >= new Date() &&
    new Date(dateOfBirth.getUTCFullYear()+18, dateOfBirth.getUTCMonth()-1, dateOfBirth.getUTCDate()) <= new Date();
  }

  isValidDOB = dob => {
    if (dob === 'Invalid date')
      showSnackbar('Invalid date', 'error')
    else if (!this.isDateBetween18and100Years(new Date(dob)))
      showSnackbar('Age must be between 18 and 100 years ', 'error')
    else
      return true
    return false
  }

  isAnyChangeFound = form => {
    if (form.dateOfBirth === this.props.dateOfBirth && form.address.streetAddress1 === this.props.streetAddress1 
      && form.address.streetAddress2 === this.props.streetAddress2 && form.address.city === this.props.city 
      && form.address.state === this.props.state && form.address.zipcode === this.props.zipcode) {
      showSnackbar('No change found in DOB and Address', 'error')
      return false
    }
    return true
  }

  validateCipData = form => {
    return this.isValidDOB(form.dateOfBirth) && this.isAnyChangeFound(form)
  }

  handleSubmit() {
    let form = {...this.state.form}

    let address = {}
    address["streetAddress1"] = form.streetAddress1
    address["streetAddress2"] = form.streetAddress2
    address["city"] = form.city
    address["state"] = form.state
    address["zipcode"] = form.zipcode
    
    let newForm ={}
    newForm["cipOrderId"] = form.cipOrderId
    newForm["address"] = address
    newForm["dateOfBirth"] = form.dateOfBirth

    if(this.validateCipData(newForm)) {
      this.props.onSubmit(newForm)
      this.setState({submitting: true})
    }
    this.setState({openConfirmDialog: false})
  }

  getMinDate(){
    let minYearsAllowed = -100;
    let minYearDate = new Date(currentDate.setUTCFullYear(currentYear+minYearsAllowed));
    let minDate = new Date(minYearDate.setDate(minYearDate.getDate()+1));
    return minDate;
  }

  getMaxDate(){
    let maxYearsAllowed = -18;
    let maxYearDate = new Date(currentDate.setUTCFullYear(currentYear+maxYearsAllowed))
    let maxDate = new Date(maxYearDate.setDate(maxYearDate.getDate()-1))
    return maxDate
  }

  render() {
    const {form} = this.state
    return (
      <div>
        <form className='' onSubmit={this.openConfirm.bind(this)}>
          <div className="modal-body" style={{height: '350px'}}>
            <div className="row">
              <div className="col">
                <DatePicker
                  label='Date of Birth'
                  placeholder='MM/DD/YYYY'
                  name='dateOfBirth'
                  required={true}
                  onChange={(name, value) => {
                      this.onChange(name, value)
                  }}
                  value={this.state.form.dateOfBirth}
                  startMode='day'
                  displayFormat='MM/DD/YYYY'
                  returnFormat='YYYY-MM-DD'
                  className='grid-width-25'
                  minDate={this.getMinDate()}
                  maxDate={this.getMaxDate()}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Field
                name="streetAddress1"
                required={true}
                label="Street Address 1"
                value={form.streetAddress1}
                onClear={() => false}
                events={
                      {
                        onChange: (event) => {this.onChange('streetAddress1', event.target.value)}
                      }
                  }
                  attrs={
                    {
                      maxLength: 50
                    }
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Field
                name="streetAddress2"
                label="Street Address 2"
                value={form.streetAddress2}
                events={
                      {
                        onChange: (event) => {this.onChange('streetAddress2', event.target.value)}
                      }
                  }
                attrs={
                  {
                    maxLength: 50
                  }
                }
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Field
                name="city"
                required={true}
                label="City"
                value={form.city}
                onClear={() => false}
                events={
                      {
                        onChange: (event) => {this.onChange('city', event.target.value)}
                      }
                  }
                  attrs={
                    {
                      maxLength: 30
                    }
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Field
                name="state"
                required={true}
                label="State"
                type='dropdown'
                value={form.state}
                onClear={() => false}
                data={stateTerritory}
                    events={
                      {
                        onChange: (event) => {this.onDropDownChange('state', event.target.value)}
                      }
                    }
                />
              </div>
              <div className="col">
                <Field
                name="zipcode"
                required={true}
                label="ZIP Code"
                value={form.zipcode}
                onClear={() => false}
                events={
                      {
                        onChange: (event) => {this.onChange('zipcode', event.target.value)}
                      }
                  }
                  attrs={
                    {
                      maxLength: 9
                    }
                  }
                />
              </div>
            </div>
            <div className='modal-footer'>
              <div className='modal-actions'>
                <button
                  className='button primary-button'
                  type='submit'
                >Save</button>
              </div>
            </div>
          </div>
        </form>
        
        {this.renderConfirmDialog()}
      </div>
    );
  }
}

export default UpdateCipOrder;