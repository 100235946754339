import Fetch from 'lib/fetch'
import { showSnackbar } from '../redux/utility/SnackbarUtil'

/*
* Upload TOA data
*/
export function uploadTOA(form) {
  return Fetch.postMultipart(`${process.env.REACT_APP_TOA_DOMAIN}/asset-transfer/upload
`, form, null)
    .then(data => {
        if(data != null && data.message != null) {
            showSnackbar('TOA succesfully uploaded. '.concat(data.message), 'info')
        } else {
            showSnackbar('TOA succesfully uploaded.', 'info')
        }
      return data
    })
    .catch(error => Promise.reject(error))
}


export function getCustodians(){
  return Fetch.get(`${process.env.REACT_APP_NBT_ADMIN_DOMAIN}/portal-data/custodian/consent/find-all-custodians-with-other`, null)
  .then(data => data)
  .catch(error => Promise.reject(error))
}

export function getTOAPostingExceptions() {
  return Fetch.get(`${process.env.REACT_APP_NBT_ADMIN_DOMAIN}/nbt-admin/get-toa-posting-exceptions`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

export function reprocessTOAPosting(txId) {
  return Fetch.post(`${process.env.REACT_APP_ACCOUNTS_DOMAIN}/accounts/reprocess-single-tx/${txId}`, null)
    .then(data => {
      showSnackbar('ToA Transaction reprocessed', 'info');
      return data
    })
    .catch(error => {
      showSnackbar('Toa Transaction reprocessing unsuccessful', 'warn');
      Promise.reject(error)
    })
}

export function getOmnibusPartnerBanks(){
  return Fetch.get(`${process.env.REACT_APP_ACCOUNTS_DOMAIN}/omnibus-partner-banks`, null)
  .then(data => data)
  .catch(error => Promise.reject(error))
}

/*
* Get Unenrolled Reports.
*
* @param [String] employerId.
*/
export function getUnenrolledTransfers (employerId) {
  return Fetch.get(`${process.env.REACT_APP_TOA_DOMAIN}/asset-transfer/getUnenrolledReports/${employerId}`, null)
  .then(data => data)
  .catch(error => Promise.reject(error))
}

/*
* Change Transfer Status to Transfer
*
* @param [Long] assetTransferId.
*/
export function changeTransferStatusToTransfer (firstName, lastName, assetTransferId, employerId) {
  return Fetch.post(`${process.env.REACT_APP_TOA_DOMAIN}/asset-transfer/changeTransferStatus/transfer/${assetTransferId}/${employerId}`, null)
  .then(data =>{
    console.log(data)
    showSnackbar('Status changed to Transfer for ' + firstName + ' ' + lastName, 'info');
  })
  .catch(error => Promise.reject(error))
}

/*
* Change Transfer Status to Do Not Transfer
*
* @param [Long] assetTransferId.
*/
export function changeTransferStatusToDoNotTransfer (firstName, lastName, assetTransferId, employerId) {
  return Fetch.post(`${process.env.REACT_APP_TOA_DOMAIN}/asset-transfer/changeTransferStatus/notTransfer/${assetTransferId}/${employerId}`, null)
  .then(data =>{
    console.log(data)
      showSnackbar('Status changed to Do Not Transfer for ' + firstName + ' ' + lastName, 'info');
  })
  .catch(error => Promise.reject(error))
}
