import React from "react";
import actions from 'actions';
import TableDisplay from "../../../../compositions/TableDisplay";
import {filterDataSource} from "lib/filter";
import { hasRole } from "../../../../redux/utility/SessionUtil";
import Loading from 'components/Loading';
import TransferStatus from "./TransferStatus";
import TransferStatusCompletion from "./TransferStatusCompletion";

const headers = [
  {
    filterText: "Asset Transfer ID",
    header: () => "Asset Transfer ID",
    key: "assetTransferId"
  },
  {
    filterText: "First Name",
    header: () => "First Name",
    key: "firstName"
  },
  {
    filterText: "Last Name",
    header: () => "Last Name",
    key: "lastName"
  },
  {
    filterText: "Last 4 SSN",
    header: () => "Last 4 SSN",
    key: "lastFour"
  },
  {
    filterText: "Date Of Birth",
    header: () => "Date Of Birth",
    key: "dateOfBirth"
  },
  {
    filterText: "Transfer Amount",
    header: () => "Transfer Amount",
    key: "transferAmount"
  },
  {
    filterText: "Prior Custodian",
    header: () => "Prior Custodian",
    key: "priorCustodian"
  }
];

class EmployerUnenrolledTransferTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      filteredDataSource: [],
      originalSource: [],
      openTransferStatus: false,
      openCompletionDialog: false,
      employerId: null,
      employer: this.getEmployer(),
      currentTransfer:  null,
      transferStatus: null,
      readAccess: hasRole('BulkTransferEdit'),
      writeAccess: hasRole('BulkTransferEdit'),
    };

    this.actions = [
      {
        action: this.onView.bind(this),
        icon: 'visibility'
      }
    ]

  }

  componentWillMount() {
    if (this.verifyAccess()) {
      const {match: {params: {employerId}}} = this.props;
      this.state.employerId = employerId
      this.getUnenrolledTransfers()
    }
  }

  /*
   * Verify that the user has access to bulk transfers
   */
  verifyAccess () {
    if (!(this.state.readAccess || this.state.writeAccess)) {
      this.props.history.push('/nbtadmin/unauthorized')
      return false
    }

    return true
  }

  /*
   * Retrieve unenrolled transfers
   */
  getUnenrolledTransfers() {
    this.setState({loading: true})
    actions.toa.getUnenrolledTransfers(this.state.employerId).then(unenrolledTransfers => {
      const mappedTransfers = unenrolledTransfers.map(unenrolledTransfer => {
        return this.mapUnenrolledTransferToTable(unenrolledTransfer)
      })
      this.setState({
        dataSource: mappedTransfers,
        filteredDataSource: mappedTransfers,
        originalSource: unenrolledTransfers,
        loading: false,
        transferStatus: null,
        openCompletionDialog: false,
        currentTransfer: null,
        openTransferStatus: false
      })
    }).catch(() => this.setState({loading: false}))
  }

  /*
 * Change transfer status to Transfer
 */
  changeStatusToTransfer() {
    actions.toa.changeTransferStatusToTransfer(this.state.currentTransfer.firstName,
        this.state.currentTransfer.lastName, this.state.currentTransfer.assetTransferId, this.state.employerId).then(
        this.setState({openCompletionDialog: true, transferStatus: "Transfer"}))
    .catch(() => false)
  }

  /*
 * Change transfer status to Do Not Transfer
 */
  changeStatusToDoNotTransfer() {
    actions.toa.changeTransferStatusToDoNotTransfer(this.state.currentTransfer.firstName,
        this.state.currentTransfer.lastName, this.state.currentTransfer.assetTransferId, this.state.employerId).then(
        this.setState({openCompletionDialog: true, transferStatus: "Do Not Transfer"}))
    .catch(() => false)
  }

  /*
 * Map transfer to display
 */
  mapUnenrolledTransferToTable (unenrolledTransfer) {
    const {assetTransferId, firstName, lastName, lastFour, dateOfBirth, transferAmount,
      priorCustodian} = unenrolledTransfer
    return {
      assetTransferId,
      firstName,
      lastName,
      lastFour,
      dateOfBirth,
      transferAmount,
      priorCustodian: priorCustodian != null ? priorCustodian : ""
    }
  }

  /*
  * Fetch employer by id.
  */
  getEmployer () {
    const {match: {params: {employerId}}} = this.props
    actions.employers.getEmployerById(employerId).then(employer => {
      this.setState({employer})})
  }

  /*
  * View unenrolled transfer to edit
  */
  onView (unenrolledTransfer) {
    this.setState({openTransferStatus: true, currentTransfer: unenrolledTransfer})
  }

  /*
  * View unenrolled transfer to edit
  */
  onRowClick (unenrolledTransfer) {
    this.setState({openTransferStatus: true, currentTransfer: unenrolledTransfer})
  }


  /*
   * Filter data source.
   *
   * @param [Object] queryParams. Key value pair containing filters.
   */
  onFilter(queryParams) {
    const { dataSource } = this.state;
    const newDataSource = filterDataSource(dataSource, queryParams);
    this.setState({ filteredDataSource: newDataSource });
  }

  render() {
    if (this.state.loading) return <div className='margin-top'><Loading/></div>
    return (
        <div className='search-page unenrolled-transfer-table-view'>
          <h4>Unenrolled Report for: {this.state.employer.name}</h4>
            <TableDisplay
             dataActions={this.actions}
             dataSource={this.state.filteredDataSource}
             disableAdd={true}
             loading={this.props.loading}
             onAdd={() => false}
             onFilter={this.onFilter.bind(this)}
             onRowClick={this.onRowClick.bind(this)}
             pageDescription=''
             pageTitle=''
             tableHeaders={headers}
           />

          {this.renderTransferDialog()}
          {this.renderCompletionDialog()}
        </div>
    );
  }

  /*
  * Render the transfer dialog
  */
  renderTransferDialog () {
    if (this.state.openTransferStatus) {
      return (
          <TransferStatus
              firstName= {this.state.currentTransfer.firstName}
              lastName= {this.state.currentTransfer.lastName}
              onClose={() => { this.setState({openTransferStatus: false, currentTransfer: null}) }}
              onTransfer={this.changeStatusToTransfer.bind(this)}
              onDoNotTransfer ={this.changeStatusToDoNotTransfer.bind(this)}
          />
      )
    }
  }

  /*
  * Render the completion dialog
  */
  renderCompletionDialog () {
    if (this.state.openCompletionDialog) {
      return (
          <TransferStatusCompletion
              status= {this.state.transferStatus}
              firstName= {this.state.currentTransfer.firstName}
              lastName= {this.state.currentTransfer.lastName}
              onClose= {this.getUnenrolledTransfers.bind(this)}
          />
      )
    }
  }

}

export default EmployerUnenrolledTransferTable;
