import React from 'react'
import CustodianFormSearch from "./CustodianForm/CustodianFormSearch";
import actions from "../../../actions";
import CustodianFormTable from "./CustodianForm/CustodianFormTable";

class CustodianConsentDisplay extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      dataSource: [],
      hasSearched: false,
      showSearch: true,
      loading: false
    }
  }

  onSearch(value){
    this.setState({loading: true})
    return actions.consentForms.getConsentFormsByCustodian(value).then(storedConsentForms => {
      const mappedConsentForms = storedConsentForms.map(storedConsentForm => {
        const {id, custodianName, receivingCustodian, createDateDisplay, custodianVerbiage, displayTransferInKind} = storedConsentForm
        return {
          id,
          custodianName,
          receivingCustodian,
          createDateDisplay,
          custodianVerbiage,
          displayTransferInKind
        }
      })
      this.setState({dataSource: mappedConsentForms, loading: false, showSearch: false, hasSearched: true})
    }).catch(() => this.setState({loading: false}))
  }

  toggleSearch () {
    if (this.state.hasSearched) {
      this.setState({showSearch: !this.state.showSearch})
    }
  }

  onAdd () {
    const {history} = this.props
    history.push(`/nbtadmin/custodian-consent-form/create/new`)
  }
  
  render(){
    return(
        <div className='search-page transfer-consent-view'>
          <h4>Custodian Transfer Consent Forms</h4>
          <i className="material-icons" onClick={this.toggleSearch.bind(this)}>{this.state.showSearch ? 'chevron_left' : 'chevron_right'}</i>
          <div className='text-right'>
            <a onClick={this.onAdd.bind(this)}>
              <i className='material-icons orange'>add_circle_outline</i>
            </a>
          </div>
            <div style={{width: "100%", margin: "0 auto", display: this.state.showSearch ? 'block' : 'none'}}>
              <CustodianFormSearch
                  {...this.props}
                  onSubmit={this.onSearch.bind(this)}
              />
            </div>
            <div className='margin-top margin-bottom'/>
            {this.state.dataSource.length > 0 &&
            <CustodianFormTable
                {...this.props}
                title=''
                dataSource={this.state.dataSource}
                loading={this.state.loading}
            />
            }
        </div>
    )
  }
}

export default CustodianConsentDisplay
