import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { closeSnackbar } from '../../redux/slices/SnackbarSlice'
import './snackbar.scss'

class Snackbar extends React.Component {

  render () {

    const classes = ['snackbar card']

    if (this.props.open) {
      classes.push('show')
      classes.push('')
    }

    let icon = ''
    switch(this.props.type) {
      case 'info':
        icon = 'check_circle'
        break
      case 'error':
        icon = 'error'
        break
      case 'warn':
        icon = 'warning'
        break
      default:
        break
    }

    const iconClasses = ['material-icons icon']

    if (this.props.type) {
      iconClasses.push(this.props.type)
    }

    return (
      <div className={classes.join(' ')}>
        <div>
          <i className={iconClasses.join(' ')}>{icon}</i>
        </div>
        <div className='content overflow-wrap'>
          <button className='close-button' onClick={() => this.props.closeSnackbar()}>
            <i className='material-icons'>close</i>
          </button>
          <span>
            {this.props.message}
          </span>
        </div>
      </div>
    )
  }
}

Snackbar.propTypes = {
  message: PropTypes.string,
  open: PropTypes.bool,
  type: PropTypes.string
}

const mapStateToProps = state => ({
    open: state.snackbar.open,
    message: state.snackbar.message,
    type: state.snackbar.type
});

const mapDispatchToProps = {
  closeSnackbar
}

export default connect(mapStateToProps, mapDispatchToProps)(Snackbar)