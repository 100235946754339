import React from 'react'
import actions from "actions";
import Button from 'components/Button'
import Card from 'components/Card'
import Field from "components/forms/Field/index"
import Loading from "components/Loading"
import { hasRole } from '../../../../redux/utility/SessionUtil'
import transferType from "./transferType";
import { showSnackbar } from '../../../../redux/utility/SnackbarUtil';

class ExpeditedCipUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initializeState();

    this.onChange.bind(this);
  }


  componentWillMount() {
    if (this.verifyAccess()) {
    }
  }

  /*
   * Verify that the user has access Expedited Cip Upload
   */
  verifyAccess() {
    if (!(this.state.readAccess || this.state.writeAccess)) {
      this.props.history.push("/nbtadmin/unauthorized");
      return false;
    }

    return true;
  }

  initializeFormData() {
    return {
    };
  }


  /*
   * Initialize State
   */
  initializeState() {
    return {
      form: this.initializeFormData(),
      readAccess: hasRole("ExpeditedCIP"),
      writeAccess: false,
      fileType: null,
      loading: false
    };
  }

  /*
   * Update form values
   *
   * @param [String] name. Field name of form to update.
   * @param [Any] value. Value.
   */
  onChange(name, value) {
    let form = { ...this.state.form };
    form[name] = value;
    this.setState({ form });
  }

  /*
   * Handle file drop.
   *
   * @param [Event] event. Drop event.
   */
  onDrop(event) {

  }

  validateForm() {
    const { fileType } = this.state.form;
    return fileType;
  }

  render() {
    if (this.state.loading) {
      return <div className='margin-top'><Loading/></div>
    }
    const { form } = this.state;
    return (
      <div className="page upload-expedited-cip-view">
        <h4>Expedited CIP Upload</h4>
        <form onSubmit={this.handleSubmit.bind(this)} className="fee-form">
          <Card className="padding">
            <Field
              name="transferType"
              required={true}
              label="Please choose whether you will be uploading transfer members or transfer employers to continue."
              type="dropdown"
              placeholder="Choose Transfer Type"
              value={form.fileType || ""}
              data={transferType.us.transferType.data}
              events={{
                onChange: event => {
                  this.onChange("fileType", event.target.value);
                }
              }}
            />
            <label className="input-label" htmlFor={"file-input"}>
              Select File To Upload
              </label>
            <div>
              <input
                name="file-input"
                type="file"
                ref={input => {
                  this.fileInput = input;
                }}
                onChange={this.onDrop.bind(this)}
              />
            </div>
          </Card>
          <Button
            className="primary-button margin-top"
            disabled={this.state.submitting}
            type="submit"
          >
            <span>
              {this.state.submitting ? (
                <Loading containerClassName="inline" className="white small" />
              ) : (
                  "Submit"
                )}
            </span>
          </Button>
        </form>
      </div>
    );
  }
  /**
   * On submit
   */
  handleSubmit(event) {
    event.preventDefault();
    if (this.validateForm()) {
      let form = { ...this.state.form };
      if (form.fileType === "employers") {
        this.setState({ fileType: "employers" });
      } else if (form.fileType === "members") {
        this.setState({ fileType: "members" });
      }
      this.setState({ submitting: false });
    }

    if (this.validateSubmit()) {
      let formData = new FormData();
      const {
        form: {
          fileType
        }
      } = this.state;
      formData.append("fileType", fileType);
      formData.append("fileInput", this.fileInput.files[0]);
      this.setState({loading: true})
      actions.expeditedUpload
        .expeditedUpload(formData)
        .then(() => {
          this.setState(this.initializeState());
          this.setState({loading: false});
          this.fileInput.value = "";
        })
        .catch(error => {
          console.log(error);
          this.setState({loading: false})
        });
    }
  }

  validateSubmit() {
    if (!this.fileInput.value)
      showSnackbar("File selection is required.");
    else return true;

    return false;
  }

}



export default ExpeditedCipUpload
