import React from 'react'
import Field from 'components/forms/Field/index'
import actions from 'actions'
import emailTemplate from 'data/emailTemplate'
import { showSnackbar } from '../../../../redux/utility/SnackbarUtil'

class CommunicationsHierarchyForm extends React.Component {
  constructor (props) {
    super (props)
    this.state = {
      communications: {},
      form: this.initializeFormData(props.hierarchyId)
    }
  }

  /*
  * Initialize form data
  * Fee profile hierarchy
  */
  initializeFormData (data) {
    return {
      hierarchyId: data || '',
    }
  }

  componentWillMount () {
  }

  /*
  * Render the fields
  */
  getFields () {
    const {form: {
      communicationType,
      contentId
    }} = this.state

    const {hierarchyFields} = emailTemplate.us
    const fields = hierarchyFields
    const {formIndex} = this.props

    return [
        {
          ...fields.type,
          name: `${fields.type.name}-${formIndex}`,
          required: true,
          events: {
            onChange: (event) => {
              this.onChangeType(event.target.value,fields.type.name)
            }
          },
          value: communicationType
        },
        {
          ...fields.contentId,
          name: `${fields.contentId.name}-${formIndex}`,
          required: true,
          events: {
            onChange: (event) => {
              this.onChange(fields.contentId.name, event.target.value)
            }
          },
          value: contentId
        }
      ]
  }

  /*
  * Update form values
  *
  * @param [String] name. Field name of form to update.
  * @param [Any] value. Value.
  */
  onChangeType (value, name) {
    let form = {...this.state.form}
    form[name] = value
    form['contentId'] = null
    this.setState({form: form})
    this.setState({communications: []})
    if(value === 'EMAIL') {
      actions.emailTemplates.getEmailTemplates().then(templates => {
      let mappedEmails = {}
      templates.forEach(email => {
        mappedEmails[email.contentId] = email.contentKey 
                                        + ( email.optumCorrespondenceId ? ': ' + email.optumCorrespondenceId : '' ) 
                                        + ( email.endDate ? ' (expires on ' + email.endDate + ')' : '')
      })
      this.setState({communications: mappedEmails})
    }).catch(() => this.setState({loading: false}))
    } else { 
      actions.mailTemplates.getMailTemplates().then(templates => {
        let mappedMails = {}
        templates.forEach(mail => {
          mappedMails[mail.contentId] = mail.contentKey 
                                        + ( mail.optumCorrespondenceId ? ': ' + mail.optumCorrespondenceId : '' )
                                        + ( mail.welcomeKitNum ? ': ' + mail.welcomeKitNum : '' )
                                        + ( mail.endDate ? ' (expires on ' + mail.endDate + ')' : '')
        })
        this.setState({communications: mappedMails})
      }).catch(() => this.setState({loading: false}))
    }
  }


  /*
  * Update form values
  *
  * @param [String] name. Field name of form to update.
  * @param [Any] value. Value.
  */
  onChange (name, value) {
    let form = {...this.state.form}
    form[name] = value
    this.setState({form: form})
  }

  /*
  * Expose method to return form
  */
  getData () {
    return this.state.form
  }

  /*
  * Add new template to hierarchy based on letter / email
  *
  */
  onSubmit (event) {
    event.preventDefault()
    const form = this.mapForm(this.state.form)
    this.onCreateCommunicationsHierarchy(form) 
  }

  /*
  * Map form.
  */
  mapForm (f) {
    let form = {...f}
    return form
  }

  /*
  * Create a new communication for a hierarchy
  */
  onCreateCommunicationsHierarchy (form) {
    const type = form.type
    if(!type){
      showSnackbar('Please select communication type')
      return
    }
    if(!form.contentId)
    {
      showSnackbar('Please select a valid template')
      return
    }
    if(type === 'EMAIL') {
      actions.emailTemplates.createEmailHierarchy(form).then((hierarchy) => {
        this.props.onSubmit(hierarchy)
        this.props.onClose()
      }).catch(() => false)
    }else if(type === 'LETTER') {
      actions.mailTemplates.createMailHierarchy(form).then((hierarchy) => {
        this.props.onSubmit(hierarchy)
        this.props.onClose()
      }).catch(() => false)
    }
  }

  render () {
    const {communications} = this.state
    const fields = this.getFields()
    return (
      <div>
        <div className='modal-body'>
          <div className='row margin-top'>
            <div className='col'>
              <Field key={`${fields[0].name}`} {...fields[0]} />
            </div>
          </div>
        </div>
        <div className='modal-body'>
          <div className='row margin-top'>
            <div className='col'>
              <Field key={`${fields[1].name}`} {...fields[1]} data={communications} />
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          <div className='modal-actions'>
            <button
              className='button primary-button'
              onClick={this.onSubmit.bind(this)}
            >SUBMIT</button>
          </div>
        </div>
      </div>
    )
  }
}

export default CommunicationsHierarchyForm
