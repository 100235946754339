import Fetch from 'lib/fetch'

/*
* Post HSAX Employer
*/
export function postHsaxEmployerConfiguration (params) {
  return Fetch.post(`${process.env.REACT_APP_HSAX_DOMAIN}/employers/tokenConfiguration`, params, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

/*
* Edit HSAX Employer
*/
export function editHsaxEmployerConfiguration (params) {
  return Fetch.post(`${process.env.REACT_APP_HSAX_DOMAIN}/employers/edit-configuration`, params, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

/*
* Get hsax employers.
*/
export function getAllHsaxEmployers () {
  return Fetch.get(`${process.env.REACT_APP_HSAX_DOMAIN}/employers/tokenConfigurations`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

/*
* Get UI versions.
*/
export function getUIVersion () {
  return Fetch.get(`${process.env.REACT_APP_HSAX_DOMAIN}/employers/get-ui-versions`, null)
  .then(data => data)
  .catch(error => Promise.reject(error))
}


/*
* Get hsax employers.
*/
export function getHsaxEmployer (token) {
  return Fetch.get(`${process.env.REACT_APP_HSAX_DOMAIN}/employers/${token}/configuration`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}
