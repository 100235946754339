import React from 'react'
import PropTypes from 'prop-types'
import TableDisplay from "../../../../compositions/TableDisplay";
import {filterDataSource} from 'lib/filter'

const headers = [
  {
    filterText: 'Employer Name',
    header: () => 'Employer Name',
    key: 'name'
  }
]

class EmployersTable extends React.Component{
  constructor(props) {
    super(props)

    let mappedEmployers = []
    if (props.dataSource) {
      mappedEmployers = props.dataSource.map(employer => {
        return this.mapEmployerToDisplay(employer)
      })
    }

    this.state = {
      dataSource: mappedEmployers,
      filteredDataSource: mappedEmployers,
      originalSource: props.dataSource || []
    }

    this.actions = [
      {
        action: this.props.onRowClick
      }
    ]
  }

  componentWillReceiveProps (newProps) {
    const mappedEmployers = newProps.dataSource.map(employer => {
      return this.mapEmployerToDisplay(employer)
    })

    this.setState({
      originalSource: newProps.dataSource,
      dataSource: mappedEmployers,
      filteredDataSource: mappedEmployers
    })
  }

  mapEmployerToDisplay(employer) {
    const {id, name} = employer
    return {
      id,
      name
    }
  }

  onFilter (queryParams) {
    const {dataSource} = this.state
    const newDataSource = filterDataSource(dataSource, queryParams)
    this.setState({filteredDataSource: newDataSource})
  }

  render() {
    return (
        <div className='search-page employers-view'>
          <TableDisplay
              dataActions={this.actions}
              dataSource={this.state.filteredDataSource}
              loading={this.props.loading}
              onAdd={() => false}
              disableAdd={true}
              onFilter={this.onFilter.bind(this)}
              onRowClick={this.props.onRowClick}
              pageDescription=''
              pageTitle='Select an Employer'
              tableHeaders={headers}
          />
        </div>
    )
  }
}

EmployersTable.propTypes = {
  dataSource: PropTypes.array,
  title: PropTypes.string,
  onRowClick: PropTypes.func
}

export default EmployersTable