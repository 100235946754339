import React from 'react'
import DatePicker from '../../../../components/forms/DatePicker'
import { showSnackbar } from '../../../../redux/utility/SnackbarUtil'

class MailCorrespondenceForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            form: this.initializeFormData(),
            dataSource: []
        }
    }

    initializeFormData() {
        const date = new Date()
        const yearMonth = date.getFullYear() + '-' + (date.getMonth()+1).toString().padStart(2,'0')
        this.props.onChange({yearMonth})
        return {
            yearMonth: yearMonth
        }
    }

    formSubmit(event) {
        event.preventDefault()
        const form = { ...this.state.form }
        if(this.isValid(form)) {
            this.props.onSubmit(form)
        }
    }

    isValid(form) {
        if(form.yearMonth) {
            return true
        }
        showSnackbar('Enter Month and Year')
        return false
    }

    onChange(name, value){
        let form = {...this.state.form}
        form[name] = value
        this.setState({form})
        this.props.onChange(form)
    }

    render() {
        return (
            <form onSubmit={this.formSubmit.bind(this)}>
                <DatePicker
                        label='Month Year'
                        name='yearMonth'
                        onChange={(name, value) => {
                            this.onChange(name, value)
                        }}
                        value={this.state.form.yearMonth}
                        startMode='month'
                        displayFormat='MM/YYYY'
                        returnFormat='YYYY-MM'
                        className='grid-width-25'
                />
                <button
                        style={{
                            width: 'auto',
                            display: 'block',
                            marginTop: '1em'
                        }}
                        className='button primary-button'
                        type='submit'
                    >Search
                </button>
            </form>
        )

    }
}

export default MailCorrespondenceForm;