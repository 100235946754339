import React from 'react'
import EmployerSearch from "../EmployerSearch";
import Card from "../../../../components/Card";
import actions from "../../../../actions";
import EmployersTable from "../EmployerSearch/EmployersTable";

class UploadConsentData extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasSearched: false,
      showSearch: true
    }
  }

  onEmployerSearch(value) {
    this.setState({loadingEmployerSearch: true})
    return actions.employers.getEmployers(value).then(employers => {
      const mappedEmployers = employers.map(employer => {
        const {id, name} = employer
        return {
          id,
          name
        }
      })
      this.setState({
        dataSource: mappedEmployers,
        loadingEmployerSearch: false,
        showSearch: false,
        hasSearched: true
      })
    }).catch(() => this.setState({loadingEmployerSearch: false}))
  }

  renderEmployerSearch() {
    return (
        <div className='margin-top margin-bottom'>
          <EmployersTable
              {...this.props}
              title=''
              dataSource={this.state.dataSource}
              loading={this.state.loadingEmployerSearch}
              onRowClick={this.onRowClick.bind(this)}
          />
        </div>
    )
  }

  toggleSearch() {
    if (this.state.hasSearched) {
      this.setState({showSearch: !this.state.showSearch})
    }
  }

  onRowClick(employer){
    const {history} = this.props
    history.push(`/nbtadmin/custodian-consent/upload-data/form/${employer.id}/${employer.name}`)
  }

  render() {
    return (
        <div className='search-page upload-consent-data-view'>
          <h4>Upload Consent Data For Employer</h4>
          <i className="material-icons"
             onClick={this.toggleSearch.bind(this)}>{this.state.showSearch
              ? 'chevron_left' : 'chevron_right'}</i>
          <div style={{width: "100%", margin: "0 auto", display: this.state.showSearch ? 'block' : 'none'}}>
            <Card className='padding'>
              <EmployerSearch
                  {...this.props}
                  onSubmit={this.onEmployerSearch.bind(this)}
              />
            </Card>
          </div>
          {this.state.hasSearched && this.renderEmployerSearch()}
        </div>
    )
  }
}

export default UploadConsentData