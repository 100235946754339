import React from 'react'
import TableDisplay from 'compositions/TableDisplay'
import Loading from '../../../components/Loading'
import actions from '../../../actions'
import { showSnackbar } from '../../../redux/utility/SnackbarUtil'
import { hasRole } from '../../../redux/utility/SessionUtil'
import NotificationSearch from './NotificationSearch'
import {filterDataSource} from 'lib/filter'
import {sortDataSource} from "lib/sort"

const headers = [
    {
        filterText: 'Id',
        header: () => 'Id',
        key: 'id',
        enableSort: true
    },
    {
        filterText: 'Type',
        header: () => 'Type',
        key: 'type',
        enableSort: true
    },
    {
        filterText: 'Status Code',
        header: () => 'Status Code',
        key: 'statusCode',
        enableSort: true
    },
    {
        filterText: 'Request ID',
        header: () => 'Request ID',
        key: 'requestId',
        enableSort: true
    },
    {
        filterText: 'Created',
        header: () => 'Created',
        key: 'created',
        enableSort: true
    },
    {
        filterText: 'Modified',
        header: () => 'Modified',
        key: 'modified',
        enableSort: true
    },
    {
        filterText: '',
        header: () => '',
        key: 'requeueButton',
        enableSort: false,
        disableFilter: true
    },
    {
        filterText: '',
        header: () => '',
        key: 'requeueCheckbox',
        enableSort: false,
        disableFilter: true
    }
]

class NotificationRequeue extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            dataSource: [],
            filteredDataSource: [],
            loading: false,
            hasSearched: false,
            readWriteAccess: hasRole('CipOpsView'),
            showSearch: true,
            form: {},
            selectedNotificationsForRequeue: []
        }
    }

    componentDidMount() {
        this.verifyAccess()
    }

    /**
     * Verify access.
     */
    verifyAccess() {
        if(!this.state.readWriteAccess) {
            this.props.history.push('/nbtadmin/unauthorized')
        }
    }
    
    /**
     * Search function that is binded to NotificationSearch component.
     * 
     * @param {*} form 
     */
    onSearch = (form) => {
        this.setState({ form: form}, () => this.loadData())
    }

    /*
    * Filter this.state.datasource
    *
    * @param [Object] queryParams. Key value pair containing filters.
    */
    onFilterTable (queryParams, sortableRefs) {
        const {dataSource} = this.state
        const newDataSource = filterDataSource(dataSource, queryParams)
        const sortedDataSource = sortDataSource(newDataSource, queryParams, sortableRefs)
        this.setState({filteredDataSource: sortedDataSource})
    }

    /**
     * Sort this.state.filteredDataSource
     * 
     * @param {*} queryParams 
     * @param {*} sortableRefs 
     */
    onSortTable(queryParams, sortableRefs) {
        const {filteredDataSource} = this.state
        const sortedDataSource = sortDataSource(filteredDataSource, queryParams, sortableRefs)
        this.setState({data: sortedDataSource})
    }

    /**
     * Generated button for requeuing the present notification based on Request ID.
     * 
     * @param {*} requestId 
     * @returns Component for rendering
     */
    generateRequeueButton(requestId) {
        return (
            <div>
                <button className="button primary-button" onClick={() => {
                    this.setState({loading:true})
                    actions.notificationRequeue.requeueSingleNotification(requestId)
                        .then(response => {
                            if(response.message !== undefined && response.success === true) {
                                showSnackbar(response.message, "info");
                            } else {
                                showSnackbar("Notification Requeue has failed.");
                            }
                            this.loadData();
                        })
                        .catch(() => {
                            this.setState({loading:false})
                            showSnackbar("An error has occurred when requeuing notification.");
                        })
                    }}>
                    Requeue
                </button>
            </div>
        )
    }

    /**
     * Generated checkbox for selecting present notification and updating this.state.selectedNotificationsForRequeue with Request ID.
     * 
     * @param {*} requestId 
     * @returns Component for rendering
     */
    generateRequeueCheckbox(requestId) {
        return (
            <div>
                <input type="checkbox" id="requeueCheckbox" value={requestId} onClick={() => {
                    let selectedNotificationsForRequeue = this.state.selectedNotificationsForRequeue;
                    let isNotificationSelected = selectedNotificationsForRequeue.includes(requestId);
                    if(isNotificationSelected===true) {
                        selectedNotificationsForRequeue = selectedNotificationsForRequeue.filter(function(value){
                            return value!==requestId;
                        })
                    } else {
                        selectedNotificationsForRequeue.push(requestId);
                    }
                    this.setState({selectedNotificationsForRequeue: selectedNotificationsForRequeue})
                }} />
            </div>
        )
    }

    /**
     * Loads data from notification-service and sets the mapped data in state.
     */
    loadData() {
        this.setState({loading: true});
        actions.notificationRequeue.getNotificationRequeue(this.state.form)
            .then(response => {
                // Collect the mapped response in an array
                const mappedResponse = response.map(notificationDetails => {
                    let {id, requestId, type, statusCode, contextJson, notifications, created, modified} = notificationDetails;
                    return {
                        id: id, 
                        requestId: requestId, 
                        type: type, 
                        statusCode: statusCode,
                        created: new Date(created).toISOString(),
                        modified: new Date(modified).toISOString(),
                        requeueButton: this.generateRequeueButton(requestId),
                        requeueCheckbox : this.generateRequeueCheckbox(requestId)
                    }
                })

                // Sort response by Created in Descending order
                mappedResponse.sort(function(a,b) {
                    if(a.created < b.created) {
                        return 1;
                    } else {
                        return -1;
                    }
                });
                this.setState({dataSource: mappedResponse, filteredDataSource: mappedResponse, loading: false, 
                    showSearch: false, hasSearched: true, selectedNotificationsForRequeue: []});
            }).catch(() => {
                this.setState({dataSource: [], loading: false, showSearch: true, selectedNotificationsForRequeue: []});
                showSnackbar('Failed to retrieve data');
            })
    }

    /*
    * Toggle if the search is showing
    *
    */
    toggleSearch () {
        this.setState({showSearch: !this.state.showSearch})
    }

    /**
     * Sends selected Request IDs to notification-service for requeue.
     * @returns 
     */
    requeueSelectedNotifications() {
        if(this.state.selectedNotificationsForRequeue.length === 0) {
            showSnackbar("No notifications selected for requeue.");
        } else {
            this.setState({loading:true})
            actions.notificationRequeue.requeueMultipleNotifications(this.state.selectedNotificationsForRequeue)
                .then(response => {
                    if(Array.isArray(response)) {
                        showSnackbar(response.length + " notifications have been successfully requeued", "info");
                    } else {
                        showSnackbar("Notification requeue failed for selected notifications.")
                    }
                    this.loadData();
                })
                .catch(() => {
                    this.setState({loading:false})
                    showSnackbar("An error has occurred when requeuing notification.");
                })
        }
    }

    /**
     * Sends filtered Request IDs to notification-service for requeue.
     * @returns 
     */
     requeueFilteredNotifications() {
        let filteredRequestIdList = [];
        for(let notification of this.state.filteredDataSource) {
            filteredRequestIdList.push(notification.requestId);
        }
        this.setState({loading:true})
        actions.notificationRequeue.requeueMultipleNotifications(filteredRequestIdList)
            .then(response => {
                if(Array.isArray(response)) {
                    showSnackbar(response.length + " notifications have been successfully requeued", "info");
                } else {
                    showSnackbar("Notification requeue failed.")
                }
                this.loadData();
            })
            .catch(() => {
                this.setState({loading:false})
                showSnackbar("An error has occurred when requeuing notification.");
            })
    }
    
    /**
     * Render table from this.state.filteredDataSource.
     * 
     * @returns Component for rendering table.
     */
    renderTable() {
        if (this.state.loading) return <div className='margin-top'><Loading/></div>

        return (this.state.hasSearched &&  
            <div>
                <h5>{this.state.filteredDataSource.length} records found</h5>
                <div className="fixed-height-scroll">
                    <TableDisplay
                        dataActions={[]}
                        dataSource={this.state.filteredDataSource}
                        disableAdd={true}
                        onRowClick={() => false}
                        onFilter={this.onFilterTable.bind(this)}
                        onSort={this.onSortTable.bind(this)}
                        singleColumnSort={true}
                        tableHeaders={headers}
                        tableDataClassName='align-items-start overflow-wrap'
                        initialSortLabel='Created'
                        initialSortDirection='desc'
                    />
                </div>
                <button style={{width: 'auto', display: 'block', marginLeft: 'auto'}} disabled={this.state.filteredDataSource.length===0}
                            className={this.state.filteredDataSource.length===0 ? 'button margin-top' : 'button margin-top primary-button'}
                            onClick={this.requeueSelectedNotifications.bind(this)}>Requeue Selected</button>
                <button style={{width: 'auto', display: 'block', marginLeft: 'auto'}} disabled={this.state.filteredDataSource.length===0}
                            className={this.state.filteredDataSource.length===0 ? 'button margin-top' : 'button margin-top primary-button'}
                            onClick={this.requeueFilteredNotifications.bind(this)}>Requeue All</button>
            </div>
        )
    }



    render() {
        return (
            <div className='search-page'>
                <h4>Notification Requeue Search</h4>
                <i className="material-icons" onClick={this.toggleSearch.bind(this)}>{this.state.showSearch ? 'chevron_left' : 'chevron_right'}</i>
                <div style={{ width: "100%", margin: "0 auto", display: this.state.showSearch ? 'block' : 'none' }}>
                    <NotificationSearch
                        {...this.state}
                        onSubmit={this.onSearch}
                    />
                </div>
                {this.renderTable()}
            </div>
        )
    }
}

export default NotificationRequeue