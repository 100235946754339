import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Valid from 'lib/valid'

// Styles
import 'components/notifications/signal.scss'

class Signal extends Component {

  static propTypes = {
    className: PropTypes.string,
    messages: PropTypes.array.isRequired
  }

  static defaultProps = {
    className: '',
    messages: []
  }


  /**
   * TODO actually make this do more than just show a dismiss message
   * We will need to pass the props down into state and have
   * them managed there most likely to manage them how we want
   */
  renderBeforeContent(opts={}, idx) {
    const content = []

    if ( opts.canDismiss ) {
      content.push(
        <button
            key={`content-dismiss-${idx}`}
            className="message-dismiss">X</button>
      )
    }

    return content
  }


  /**
   * Render messages
   *
   * @return {Array} array of JSX messages
   */
  renderMessages() {
    const { messages } = this.props

    return messages.map((m, idx) => {
      const className = `message ${Valid.getErrLvl(m[0])}`

      const opts = m[2] || {}

      return (
        <div key={`msg-${idx}`} className={className}>
          {this.renderBeforeContent(opts, idx)}
          {m[1]}
        </div>
      )
    })
  }


  /**
   * @return {JSX}
   */
  render() {
    if (this.props.messages.length === 0) return null

    const props = this.props
    const className = `signal ${props.className}`

    return (
      <div className={className}>
        {this.renderMessages()}
      </div>
    )
  }
}

export default Signal
