import React from "react";
import { hasRole } from "../../../../redux/utility/SessionUtil";
import termsAndConditionsSetup from "data/termsAndConditionsSetup";
import Field from "components/forms/Field/index";
import Card from "components/Card";
import actions from "actions";
import Loading from "components/Loading";
import Button from "components/Button";
import Modal from 'components/Modal';
import { showSnackbar } from "../../../../redux/utility/SnackbarUtil";

class TermsAndConditionsEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initializeState();
  }

  componentWillMount() {
    this.verifyAccess()
  }

  /*
   * Initialize State
   */
  initializeState() {
    if(this.props.location.state === undefined) {
      this.props.history.push('/nbtadmin/unauthorized')
      return false;
    }
    return {
      form: this.initializeFormData(),
      submitting: false,
      showSuccessModal: false,
      writeAccess: hasRole("TCMasterEdit"),
      id: this.props.location.state.termsAndConditions.id,
      name: this.props.location.state.termsAndConditions.name,
      activeState: this.props.location.state.termsAndConditions.isActive === 'Y' ? "Yes" : "No",
    };
  }

  /*
  * Verify that the user has access to account maintenance
  */
  verifyAccess () {
    if (!this.state.writeAccess) {
      this.props.history.push('/nbtadmin/unauthorized')
      return false
    }

    return true
  }

  /*
  * initialize all of the form fields
  */
  initializeFormData() {
    return {
      name: this.props.location.state.termsAndConditions.name,
      pdfName: this.props.location.state.termsAndConditions.fileName,
      type: this.props.location.state.termsAndConditions.agreementType,
      uploadDate: this.props.location.state.termsAndConditions.uploadDate,
      modifiedDate: this.props.location.state.termsAndConditions.modifiedDate,
      isActive: this.props.location.state.termsAndConditions.isActive === 'Y' ? "Yes" : "No",
      deactivationDate: this.props.location.state.termsAndConditions.deactivationDate === null ? "N/A" :
          this.props.location.state.termsAndConditions.deactivationDate
    };
  }


  /*
   * Update form values
   *
   * @param [String] name. Field name of form to update.
   * @param [Any] value. Value.
   */
  onChange(name, value) {
    let form = { ...this.state.form };
    form[name] = value;
    this.setState({ form });
  }

  onCloseModal() {
    const { history } = this.props
    this.setState({ showSuccessModal: false });
    history.push(`/nbtadmin/terms-and-conditions`)
  }

  getFields() {
    const { us, edit } = termsAndConditionsSetup;

    const {
      form: { name, pdfName, type, uploadDate, modifiedDate,
      isActive, deactivationDate}
    } = this.state;

    let f = [
      {
        ...us.fields.name,
        required: true,
        events: {
          onChange: event => {
            this.onChange(event.target.name, event.target.value);
          }
        },
        value: name
      },
      {
        ...edit.pdfName,
        events: {
          onChange: event => {
            this.onChange(event.target.name, event.target.value);
          }
        },
        value: pdfName
      },
      {
        ...edit.type,
        events: {
          onChange: event => {
            this.onChange(event.target.name, event.target.value);
          }
        },
        value: type
      },
      {
        ...edit.uploadDate,
        events: {
          onChange: event => {
            this.onChange(event.target.name, event.target.value);
          }
        },
        value: uploadDate
      },
      {
        ...edit.modifiedDate,
        events: {
          onChange: event => {
            this.onChange(event.target.name, event.target.value);
          }
        },
        value: modifiedDate
      },
      {
        ...edit.active,
        events: {
          onChange: event => {
            this.onChange(event.target.name, event.target.value);
          }
        },
        value: isActive
      },
      {
        ...edit.deactivationDate,
        events: {
          onChange: event => {
            this.onChange(event.target.name, event.target.value);
          }
        },
        value: deactivationDate
      }
    ];

    return f;
  }

  /**
   * On submit
   */
  handleSubmit(event) {
    event.preventDefault();
    this.setState({submitting: true});

    let form = { ...this.state.form };

    let nameFormData  = {
        id: this.state.id,
        name: form.name
    };
    actions.termsAndConditions
      .editTermsAndConditions(nameFormData)
      .then(() => {})
      .catch(error => {
        console.log(error);
        this.setState({submitting: false})
        showSnackbar(
            'There was an error processing your request')});

    let activeFormData  = {
        id: this.state.id,
        active: form.isActive === "Yes"
    };
    actions.termsAndConditions
      .setActive(activeFormData)
      .then(() => {
        this.setState({submitting: false, showSuccessModal: true});
      })
      .catch(error => {
        console.log(error);
        this.setState({submitting: false})
        showSnackbar(
            'There was an error processing your request')});
  }


  goBack() {
    const {history} = this.props
    history.push(`/nbtadmin/terms-and-conditions`)
  }

  /*
   * Open success modal
   */
  renderSuccessModal() {
    const { showSuccessModal } = this.state
    if (showSuccessModal) {
      return (
          <Modal
              header={"Success"}
              onClose={this.onCloseModal.bind(this)}
              showClose={true}
          >
            <div className='modal-body'>
              <div className='margin-top margin-bottom'>
                <p>Changes successfully made!</p>
              </div>
            </div>
            <div className='modal-footer'>
              <div className='modal-actions'>
                <button
                    className='button primary-button'
                    onClick={this.onCloseModal.bind(this)}
                >Proceed to Terms and Conditions</button>
              </div>
            </div>
          </Modal>
      );
    }
  }

  render() {
    return (
        <div className="page submit-terms-and-conditions-edit">
          <h4>Edit Document</h4>
          <i className="material-icons" onClick={this.goBack.bind(this)}>{"chevron_left"}</i>
          <form onSubmit={this.handleSubmit.bind(this)} className="fee-form">
            <Card className="padding">

              {this.getFields().map((f, idx) => {
                return (
                    <Field className="margin-top" key={`${f.name}-${idx}`} {...f} />
                );
              })}
            </Card>
            <Button
                className="primary-button margin-top"
                disabled={this.state.submitting}
                type="submit"
            >
            <span>
              {this.state.submitting ? (
                  <Loading containerClassName="inline" className="white small" />
              ) : (
                  "Submit"
              )}
            </span>
            </Button>
          </form>
          {this.renderSuccessModal()}
        </div>
    );
  }

}
export default TermsAndConditionsEditForm;
