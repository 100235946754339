import Fetch from 'lib/fetch'
import { showSnackbar } from '../redux/utility/SnackbarUtil'

/*
* Get cip orders by employeeId, employerId
*
* @param employeeId, employerId of account to get CIP Orders for.
*/
export function getCIPOrdersByEmployeeIdAndEmployerId(employeeId, employerId) {
  return Fetch.get(`${process.env.REACT_APP_CIP_DOMAIN}/cip/orders/cip-resolution?employeeId=${employeeId}&employerId=${employerId}`, null)
    .then(data => data['cipOrders'])
    .catch(error => Promise.reject(error))
}

/*
* Retry CIP Processing
*
* @param [Number] cipOrderId. CIP Order Id to retry CIP processing.
*/
export function retryCipProcessing(cipOrderId) {
  return Fetch.post(`${process.env.REACT_APP_CIP_DOMAIN}/cip-orders/${cipOrderId}/retryProcessing`, null, null)
    .then(data => {
      if(data.success) {
        showSnackbar('CIP Order Retry Processing Complete', 'info')
        return data
      }
      else
        showSnackbar('Something went wrong while retrying to process CIP Order. '.concat(data.message), 'error')
    })
    .catch(error => {
      showSnackbar('Something went wrong while processing CIP Order.', 'error')
      return Promise.reject(error)
    })
}

/*
* Update CIP Order
*
* @param [Object] form. CIP Order form.
*/
export function updateAndRetryCipOrder(form) {
  return Fetch.put(`${process.env.REACT_APP_CIP_DOMAIN}/cip-orders/updateAndRetry`, form, null)
    .then(data => {
      if(data.success) {
        showSnackbar('CIP Order successfully updated', 'info')
        return data
      }
      else
        showSnackbar('Something went wrong while processing CIP Order. '.concat(data.message), 'error')
    })
    .catch(error => {
      showSnackbar('Something went wrong while processing CIP Order.', 'error')
      return Promise.reject(error)
    })
}