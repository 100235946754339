const ERROR = 0
const WARN = 1
const SUGGEST = 2
const INFO = 3
const SUCCESS = 4

/**
 * TODO Write documentation
 */
const Valid = {

  SUCCESS: SUCCESS,
  ERROR: ERROR,
  WARN: WARN,
  SUGGEST: SUGGEST,
  INFO: INFO,

  // Helper Functions
  utils: {

    /**
     * Returns a list of sorted messages by severity
     *
     * @params {Array} messages to sort by severity
     * @return {Array} array of messages
     */
    sortMessages: (messages=[]) => messages.sort( (a, b) => a[0] > b[0] ),


    /**
     * Group messages by severity
     *
     * @params {Array} messages to group by severity
     * @return {Array} array of messages grouped by severity
     */
    groupMessages: (messages=[]) => {
      const grouped = []

      const errObj = messages.reduce( (groups, err) => {
        if (!Array.isArray(err)) return groups

        const val = err[0]
        groups[val] = groups[val] || []
        groups[val].push(err)

        return groups
      }, {})

      Object.keys(errObj).forEach( (k) => { grouped.push(errObj[k]) } )

      return grouped
    }
  },


  /**
   * Return the string label of the error level
   *
   * @param {Number} lvl level of error
   * @return {String} the user-friendly level
   */
  getMessageLvl: (lvl) => {
    switch (lvl) {
      case SUCCESS: return 'success'
      case ERROR: return 'error'
      case WARN: return 'warn'
      case SUGGEST: return 'suggest'
      case INFO: return 'info'
      default: return 'error'
    }
  },


  /**
   * Validations --------------------------------------------------------------
   *
   * Add and/or remove custom validations below:
   */
  name: (val) => {
    return [
      val.length < 1 ? [ERROR, 'Name must be at least one character'] : null,
      val.length > 48 ? [ERROR, 'Name must be shorter than 128 characters'] : null
    ].filter(m => m)
  },

  email: (val) => {
    // eslint-disable-next-line
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-za-z\-0-9]+\.)+[a-za-z]{2,}))$/

    return [
      val.toString().trim().search(regex) < 0 ? [ERROR, 'A valid email is required'] : null
    ].filter(m => m)
  },

  password: (val) => {
    const str = val.toString()

    return [
      str.length < 7 ? [ERROR, 'Password must be longer than seven characters'] : null,
      str.search(/(!.password|.*word|.*1234|.*qwer|.*asdf)/) > -1 ? [SUGGEST, 'Don\'t use a weak password! (e.g., "qwerty" or "password")'] : null
    ].filter(m => m)
  },

  telephone: (val) => {
    const str = val.toString()

    return [
      str.length < 8 ? [SUGGEST, 'Don\'t forget to include your area code!'] : null,
      str.length > 12 ? [ERROR, 'Telephone must be less than 13 characters'] : null,
      str.indexOf('(') > -1 || str.indexOf(')') > -1 ? [INFO, 'Please use the format: xxx-xxx-xxxx'] : null
    ].filter(m => m)
  },

  postal_code: (val) => {
    return [
      val.length > 5 ? [ERROR, 'Zip code must be less than 5 digits. Don\'t include the +4 code!'] : null,
      val.toString().trim().search(/^\d+$/g) < 0 ? [ERROR, 'Zip code must be numeric'] : null
    ].filter(m => m)
  }
}

export default Valid
