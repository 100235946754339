import React from "react";
import PropTypes from "prop-types";
import Modal from 'components/Modal';

class TransferStatusCompletion extends React.Component  {
  render () {
    return (
        <Modal
            header={'Transfer status update request sent:'}
            onClose={() => this.props.onClose()}
        >
          <div className='modal-body'>
            <div className='margin-top margin-bottom'>
              <p>Status request sent for {this.props.firstName} {this.props.lastName}: {this.props.status}</p>
              <p>Please check the request status on the right.</p>
            </div>
          </div>
          <div className='modal-footer'>
            <div className='modal-actions'>
              <button
                  className='button primary-button'
                  onClick={() => this.props.onClose()}
              >Return</button>
            </div>
          </div>
        </Modal>
    )
  }
}

TransferStatusCompletion.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  status: PropTypes.string,
  onClose: PropTypes.func
}

export default TransferStatusCompletion
