import React, { Component } from 'react';
import Card from "../../../../components/Card";
import CreateableSelect from 'react-select/creatable'
import DatePicker from '../../../../components/forms/DatePicker'
import { showSnackbar } from '../../../../redux/utility/SnackbarUtil';

const ALL_ELEMENTS_IDENTIFIER = "All";

const ALL_NON_SUCCESS_STATUS_CODES_IDENTIFIER = "AllNonSuccessCodes";

const PROCESSOR_STATUS_CODES = [
    { value: ALL_ELEMENTS_IDENTIFIER, label: ALL_ELEMENTS_IDENTIFIER},
    { value: ALL_NON_SUCCESS_STATUS_CODES_IDENTIFIER, label: "All Non-Success codes"},
    { value: -1, label: "-1: Exception"},
    { value: 0, label: "0: Success"},
    { value: 500, label: "500: Employee ID required"},
    { value: 501, label: "501: Employer ID required"},
    { value: 502, label: "502: Role ID required"},
    { value: 503, label: "503: Email Address required"},
    { value: 504, label: "504: No Emails with role"},
    { value: 505, label: "505: Account ID required"},
    { value: 506, label: "506: CYC Account ID required"},
    { value: 507, label: "507: Email Client Incomplete Delivery"},
    { value: 508, label: "508: Mail Client Incomplete Delivery"},
    { value: 509, label: "509: Statement Transmission ID required"},
    { value: 510, label: "510: Email Address not available"},
    { value: 511, label: "511: Email from address not found or configured"},
    { value: 512, label: "512: Failed to generate fee"},
    { value: 513, label: "513: No Mailing Address available"},
    { value: 514, label: "514: Error serializing mail address"},
    { value: 515, label: "515: Mailing values not found"}
]

const NON_SUCCESS_PROCESSOR_STATUS_CODES = [-1, 500, 501, 502, 503, 504, 505, 506, 507, 508, 509, 510, 511, 512, 513, 514, 515];

const NOTIFICATION_TYPES = [
    { value: "All", label:"All"},
    { value: "BANK_ACCOUNT_ABANDONED_EMAIL", label: "BANK_ACCOUNT_ABANDONED_EMAIL"},
    { value: "BANK_ACCOUNT_ABANDONED_LETTER", label: "BANK_ACCOUNT_ABANDONED_LETTER"},
    { value: "BANK_ACCOUNT_ACTIVE", label: "BANK_ACCOUNT_ACTIVE"},
    { value: "BANK_ACCOUNT_CLOSED_EMAIL", label: "BANK_ACCOUNT_CLOSED_EMAIL"},
    { value: "BANK_ACCOUNT_CLOSURE_WARNING_EMAIL", label: "BANK_ACCOUNT_CLOSURE_WARNING_EMAIL"},
    { value: "BANK_ACCOUNT_CLOSURE_WARNING_LETTER", label: "BANK_ACCOUNT_CLOSURE_WARNING_LETTER"},
    { value: "BANK_ACCOUNT_CURRENT_BALANCE_INSUFFICIENT", label: "BANK_ACCOUNT_CURRENT_BALANCE_INSUFFICIENT"},
    { value: "BANK_ACCOUNT_DORMANT_EMAIL", label: "BANK_ACCOUNT_DORMANT_EMAIL"},
    { value: "BANK_ACCOUNT_DORMANT_LETTER", label: "BANK_ACCOUNT_DORMANT_LETTER"},
    { value: "BANK_ACCOUNT_ESCHEATED_EMAIL", label: "BANK_ACCOUNT_ESCHEATED_EMAIL"},
    { value: "BANK_ACCOUNT_ESCHEATED_LETTER", label: "BANK_ACCOUNT_ESCHEATED_LETTER"},
    { value: "BANK_ACCOUNT_INACTIVE_EMAIL", label: "BANK_ACCOUNT_INACTIVE_EMAIL"},
    { value: "BANK_ACCOUNT_INACTIVE_LETTER", label: "BANK_ACCOUNT_INACTIVE_LETTER"},
    { value: "BANK_ACCOUNT_NEGATIVE_CURRENT_BALANCE", label: "BANK_ACCOUNT_NEGATIVE_CURRENT_BALANCE"},
    { value: "BANK_ACCOUNT_PENDING_CLOSURE_EMAIL", label: "BANK_ACCOUNT_PENDING_CLOSURE_EMAIL"},
    { value: "BANK_ACCOUNT_RESET_ACTIVE", label: "BANK_ACCOUNT_RESET_ACTIVE"},
    { value: "BENEFICIARY_BACKEND_UPDATE", label: "BENEFICIARY_BACKEND_UPDATE"},
    { value: "BENEFICIARY_UPDATE", label: "BENEFICIARY_UPDATE"},
    { value: "CIP_BATCH_COMPLETE", label: "CIP_BATCH_COMPLETE"},
    { value: "CIP_DECLINED", label: "CIP_DECLINED"},
    { value: "CIP_DECLINED_INDIVIDUAL", label: "CIP_DECLINED_INDIVIDUAL"},
    { value: "CIP_DECLINE_EMPLOYER_REMINDER", label: "CIP_DECLINE_EMPLOYER_REMINDER"},
    { value: "CIP_ESCALATED_CASE", label: "CIP_ESCALATED_CASE"},
    { value: "CIP_OVERRIDE_PASSED", label: "CIP_OVERRIDE_PASSED"},
    { value: "CIP_OVERRIDE_PASSED_UNREGISTERED", label: "CIP_OVERRIDE_PASSED_UNREGISTERED"},
    { value: "CIP_PASS", label: "CIP_PASS"},
    { value: "CIP_PENDING", label: "CIP_PENDING"},
    { value: "CIP_PENDING_INDIVIDUAL", label: "CIP_PENDING_INDIVIDUAL"},
    { value: "CIP_PENDING_UNREGISTERED", label: "CIP_PENDING_UNREGISTERED"},
    { value: "CIP_WAIT", label: "CIP_WAIT"},
    { value: "ENROLLMENT", label: "ENROLLMENT"},
    { value: "ESIGN_COMMUNICATION_PREFERENCE", label: "ESIGN_COMMUNICATION_PREFERENCE"},
    { value: "ESIGN_REMINDER", label: "ESIGN_REMINDER"},
    { value: "HSA_ACCOUNT_SUSPENDED_INVALID_ADDRESS", label: "HSA_ACCOUNT_SUSPENDED_INVALID_ADDRESS"},
    { value: "HSA_ORPHAN", label: "HSA_ORPHAN"},
    { value: "HSA_UNENROLLED", label: "HSA_UNENROLLED"},
    { value: "HSA_WELCOME_LETTER", label: "HSA_WELCOME_LETTER"},
    { value: "HSA_WELCOME_LETTER_EMPLOYER_EMAIL", label: "HSA_WELCOME_LETTER_EMPLOYER_EMAIL"},
    { value: "HSA_WELCOME_LETTER_EMPLOYER_MAIL", label: "HSA_WELCOME_LETTER_EMPLOYER_MAIL"},
    { value: "HSA_WELCOME_LETTER_EXPRESS_EMAIL", label: "HSA_WELCOME_LETTER_EXPRESS_EMAIL"},
    { value: "HSA_WELCOME_LETTER_ORPHAN_PARTICIPANT_INVALID_ADDRESS_WK", label: "HSA_WELCOME_LETTER_ORPHAN_PARTICIPANT_INVALID_ADDRESS_WK"},
    { value: "HSA_WELCOME_LETTER_PARTICIPANT_INVALID_ADDRESS_WK", label: "HSA_WELCOME_LETTER_PARTICIPANT_INVALID_ADDRESS_WK"},
    { value: "HSAX_FUNDING_EMAIL", label: "HSAX_FUNDING_EMAIL"},
    { value: "INSUFFICIENT_DOCUMENTATION_ER", label: "INSUFFICIENT_DOCUMENTATION_ER"},
    { value: "INSUFFICIENT_DOCUMENTATION_ER_PARTICIPANT", label: "INSUFFICIENT_DOCUMENTATION_ER_PARTICIPANT"},
    { value: "INSUFFICIENT_DOCUMENTATION_ER_PARTICIPANT_UNREGISTERED", label: "INSUFFICIENT_DOCUMENTATION_ER_PARTICIPANT_UNREGISTERED"},
    { value: "INSUFFICIENT_DOCUMENTATION_PARTICIPANT", label: "INSUFFICIENT_DOCUMENTATION_PARTICIPANT"},
    { value: "INSUFFICIENT_DOCUMENTATION_PARTICIPANT_INDIVIDUAL", label: "INSUFFICIENT_DOCUMENTATION_PARTICIPANT_INDIVIDUAL"},
    { value: "INSUFFICIENT_DOCUMENTATION_PARTICIPANT_UNREGISTERED", label: "INSUFFICIENT_DOCUMENTATION_PARTICIPANT_UNREGISTERED"},
    { value: "MICROTRANSACTION_CONFIRMATION", label: "MICROTRANSACTION_CONFIRMATION"},
    { value: "MICROTRANSACTION_FAILURE", label: "MICROTRANSACTION_FAILURE"},
    { value: "MONTHLY_QUARTERLY_HSA_STATEMENT", label: "MONTHLY_QUARTERLY_HSA_STATEMENT"},
    { value: "TRANSFER_OF_ASSETS_COMPLETE", label: "TRANSFER_OF_ASSETS_COMPLETE"},
    { value: "VERIFICATION_EMAIL", label: "VERIFICATION_EMAIL"},
    { value: "VERIFY_EXTERNAL_ACCOUNT", label: "VERIFY_EXTERNAL_ACCOUNT"}
]

function getValue(element) {
    return element.value;
}

const numericRegex = new RegExp('^[0-9]*$');

class NotificationSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form : this.initialFormState(),
            hasInvalidDates: false,
            hasIncorrectDateFormat: false
        }
    }

    initialFormState() {
        return {
            statusCodes: [PROCESSOR_STATUS_CODES[1]],
            notificationTypes: [NOTIFICATION_TYPES[0]],
            startDate: this.convertDateToUTCWithoutTime(this.getPreviousDayDate(new Date())),
            endDate: this.convertDateToUTCWithoutTime(new Date())
        }
    }

    /**
     * Return previous day date.
     * 
     * @param {*} currentDate 
     * @returns currentDate - 24 hours
     */
    getPreviousDayDate(currentDate) {
        let prevDate = new Date(currentDate);
        prevDate.setDate(prevDate.getDate()-1);
        return prevDate;
    }

    /**
     * Update dates in this.state.form based on form input.
     * 
     * @param {*} name 
     * @param {*} value 
     */
    handleDateChange(name, value) {
        let form = {...this.state.form};
        if(value==="Invalid date") {
            this.setState({hasIncorrectDateFormat: true})
        } else {
            this.setState({hasIncorrectDateFormat: false})
        }
        form[name] = value;
        if(form.startDate > form.endDate) {
            this.setState({form:form, hasInvalidDates:true});
        } else {
            this.setState({form:form, hasInvalidDates:false});
        }
    }

    /**
     * Converts date object to UTC String format "yyyy-MM-dd" without timezone.
     * 
     * @param {*} date 
     * @returns UTC Date String  without timezone
     */
    convertDateToUTCWithoutTime(date) { 
        return date.getUTCFullYear() + "-" + this.correctDate(date.getUTCMonth()+1) + "-" + this.correctDate(date.getUTCDate())
    }

    /**
     * Appends "0" to single digit numbers. To be used for dates.
     * 
     * @param {*} numberString 
     * @returns Correct Number String for request
     */
    correctDate(numberString) {
        let correctNumberString = null;
        switch(numberString.toString().length) {
            case 0 :
                correctNumberString="00"; 
                break;
            case 1 :
                correctNumberString="0"+numberString;
                break;
            default:
                correctNumberString=numberString;
                break;
        }
        return correctNumberString;
    }

    /**
     * Arrow function for handling status code change in form. 
     * Updates "statusCodes" in Form.
     * 
     * @param {*} submittedArray 
     */
    handleStatusCodeChange = (submittedStatusCodes) => {
        let statusCodes = [];
        let lastEntry = submittedStatusCodes[submittedStatusCodes.length-1];

        // If All codes are selected, individual selections need to be removed.
        if(lastEntry!==undefined && lastEntry.value===ALL_ELEMENTS_IDENTIFIER) {
            statusCodes = [PROCESSOR_STATUS_CODES[0]];
        } else if(lastEntry!==undefined && lastEntry.value===ALL_NON_SUCCESS_STATUS_CODES_IDENTIFIER) {
            statusCodes = [PROCESSOR_STATUS_CODES[1]];
        } else {
            for(let currentCode of submittedStatusCodes) {
                if(currentCode.value!==ALL_ELEMENTS_IDENTIFIER && currentCode.value!==ALL_NON_SUCCESS_STATUS_CODES_IDENTIFIER) {
                    // statusCodes.push(parseInt(currentCode.value, 10));
                    statusCodes.push(currentCode);
                }
            }
        }

        // If the selected list length is 0 which can happen when the field is cleared, intialize it to the default value.
        if(statusCodes.length === 0) 
            statusCodes = this.initialFormState().statusCodes;

        let form = this.state.form;
        form.statusCodes = statusCodes;
        this.setState({form: form});
    }

    /**
     * Arrow function for handling notification type change in form. 
     * Updates "notificationTypes" in Form.
     * 
     * @param {*} submittedNotificationTypes 
     */
    handleNotificationTypeChange = (submittedNotificationTypes) => {
        let notificationTypes = [];
        let lastEntry = submittedNotificationTypes[submittedNotificationTypes.length-1];

        // If All types are selected, individual selections need to be removed.
        if(lastEntry!==undefined && lastEntry.value===ALL_ELEMENTS_IDENTIFIER) {
            notificationTypes = [NOTIFICATION_TYPES[0]];
        } else {
            for(let currentType of submittedNotificationTypes) {
                if(currentType.value!==ALL_ELEMENTS_IDENTIFIER) {
                    notificationTypes.push(currentType);
                }
            }
        }

        // If the selected list length is 0 which can happen when the field is cleared, intialize it to the default value.
        if(notificationTypes.length === 0) 
            notificationTypes = this.initialFormState().notificationTypes;

        let form = this.state.form;
        form.notificationTypes = notificationTypes;
        this.setState({form: form});
    }

    /**
     * Prepares request for API based on form input.
     * 
     * @returns request body
     */
    prepareRequest() {
        let requestBody = {
            statuses: null,
            types: null,
            start: null,
            end: null
        };
        // Set request status codes
        switch(this.state.form.statusCodes[0].value) {
            case ALL_ELEMENTS_IDENTIFIER :
                requestBody.statuses = null; break;
            case ALL_NON_SUCCESS_STATUS_CODES_IDENTIFIER :
                requestBody.statuses = NON_SUCCESS_PROCESSOR_STATUS_CODES; break;
            default:
                requestBody.statuses = this.state.form.statusCodes.map(getValue); break;
        }
        // Set request notification types
        if(this.state.form.notificationTypes[0].value !== ALL_ELEMENTS_IDENTIFIER) {
            requestBody.types = this.state.form.notificationTypes.map(getValue);
        }
        // Set request start date
        requestBody.start = this.state.form.startDate + " 00:00:00";
        //Set request end date
        requestBody.end = this.state.form.endDate + " 23:59:59";
        
        return requestBody;
    }

    /**
     * Handle form submit
     * @param {*} event 
     */
    handleSubmit(event) {
        event.preventDefault();
        if(this.state.hasIncorrectDateFormat || this.state.hasInvalidDates) {
            showSnackbar("Please input valid dates.")
        } else {
            this.props.onSubmit(this.prepareRequest());
        }
    }

    render() { 
        let invalidDatesErrorText = this.state.hasInvalidDates ? <td style={{color:"red", fontSize:"80%"}}><sup>*</sup> Start Date should be less than or equal to End Date</td> : <td></td>;
        let dateFormatErrorText = this.state.hasIncorrectDateFormat ? <td style={{color:"red", fontSize:"80%"}}><sup>*</sup> Date format should be MM/DD/YYYY</td> : <td></td>;
        return (
            <Card className='padding'>
                <form className='' onSubmit={this.handleSubmit.bind(this)}>
                    <table width="100%">
                        <tbody>
                            <tr>
                                <td colSpan={2}>
                                    <h5>Status Code (Only integer values allowed): </h5>
                                    <CreateableSelect
                                        closeMenuOnSelect={false}
                                        isMulti
                                        value={this.state.form.statusCodes}
                                        options={PROCESSOR_STATUS_CODES}
                                        onChange={this.handleStatusCodeChange}
                                        isValidNewOption={(input) => {
                                            if(isNaN(input) || input.length===0) {
                                                return false;
                                            }
                                            return numericRegex.test(input);
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <h5>Notification Type: </h5>
                                    <CreateableSelect
                                        closeMenuOnSelect={false}
                                        isMulti
                                        value={this.state.form.notificationTypes}
                                        options={NOTIFICATION_TYPES}
                                        onChange={this.handleNotificationTypeChange}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={1}>
                                    <h5>Start Date: </h5>
                                    <DatePicker
                                        label=''
                                        name='startDate'
                                        required={true}
                                        onChange={(name, value) => {
                                            this.handleDateChange(name, value)
                                        }}
                                        value={this.state.form.startDate}
                                        returnFormat='YYYY-MM-DD'
                                        maxDate={this.state.form.endDate}
                                    />
                                </td>
                                <td colSpan={1}>
                                    <h5>End Date: </h5>
                                    <DatePicker
                                        label=''
                                        name='endDate'
                                        required={true}
                                        onChange={(name, value) => {
                                            this.handleDateChange(name, value)
                                        }}
                                        value={this.state.form.endDate}
                                        returnFormat='YYYY-MM-DD'
                                        maxDate={this.convertDateToUTCWithoutTime(new Date())}
                                    />
                                </td>
                            </tr>
                            <tr>
                                {invalidDatesErrorText}
                            </tr>
                            <tr>
                                {dateFormatErrorText}
                            </tr>
                        </tbody>
                    </table>
                    <button style={{width: 'auto', display: 'block', marginLeft: 'auto'}} disabled={this.state.hasInvalidDates || this.state.hasIncorrectDateFormat}
                            className={this.state.hasInvalidDates || this.state.hasIncorrectDateFormat ? 'button' : 'button margin-top primary-button'}
                            type='submit'>Search </button>
                </form>
            </Card>
        )
        
    }
}
 
export default NotificationSearch;