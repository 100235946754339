import React from 'react'
import TableDisplay from 'compositions/TableDisplay'
import actions from 'actions'
import {filterDataSource} from 'lib/filter'
import { hasRole } from '../../../../redux/utility/SessionUtil'
import Loading from 'components/Loading'

const defaultHeaders = [
  {
    filterText: 'Type',
    header: () => 'Type',
    key: 'agreementType'
  },
  {
    filterText: 'Name',
    header: () => 'Name',
    key: 'name'
  },
  {
    filterText: 'PDF name',
    header: () => 'PDF Name',
    key: 'fileName'
  },
  {
    filterText: 'Active',
    header: () => 'Active',
    key: 'isActive'
  },
]

const headers = [
  {
    filterText: 'Name',
    header: () => 'Name',
    key: 'name'
  },
  {
    filterText: 'PDF name',
    header: () => 'PDF Name',
    key: 'fileName'
  },
  {
    filterText: 'Type',
    header: () => 'Type',
    key: 'agreementType'
  },
  {
    filterText: 'Upload date',
    header: () => 'Upload Date',
    key: 'uploadDate'
  },
  {
    filterText: 'Modified date',
    header: () => 'Modified Date',
    key: 'modifiedDate'
  },
  {
    filterText: 'Active',
    header: () => 'Active',
    key: 'isActive'
  },
  {
    filterText: 'Deactivation date',
    header: () => 'Deactivation Date',
    key: 'deactivationDate'
  },
]

let types = {
  /**
   * Banking agreement.
   */
  BANKING_AGREEMENT: 'Custodial Agreement',
  /**
   * Privacy agreement.
   */
  PRIVACY_AGREEMENT: 'Private Policy',
  /**
   * Fee agreement.
   */
  FEE_AGREEMENT: 'Fee Schedule',
  /**
   * Esign agreement.
   */
  ESIGN_AGREEMENT: 'Esign'
};

const displayTypes = [
  /**
   * Banking agreement.
   */
  {
    display: 'Custodial Agreement',
    key: 'BANKING_AGREEMENT'
  },
  /**
   * Privacy agreement.
   */
  {
    display: 'Private Policy',
    key: 'PRIVACY_AGREEMENT'
  },
  /**
   * Fee agreement.
   */
  {
    display:'Fee Schedule',
    key: 'FEE_AGREEMENT'
  },
  /**
   * Esign agreement.
   */
  {
    display: 'Esign',
    key: 'ESIGN_AGREEMENT'
  },
]

class ManageTermsAndConditions extends React.Component {
  constructor (props) {
    super (props);
    this.state = {
      dataSource: [],
      filteredDataSource: [],
      originalSource: [],
      defaultDataSource: [],
      defaultFilteredDataSource: [],
      defaultFilteredOriginalSource: [],
      readAccess: hasRole('TCMasterView'),
      editAccess: hasRole('TCMasterEdit'),
      uploading: false
    }
    this.defaultActions = [
      this.state.editAccess ?
          {
            action: this.onDefaultEdit.bind(this),
            icon: 'edit'
          } : []
    ]
    this.actions =
        [
          this.state.editAccess ?
          {
            action: this.onEdit.bind(this),
            icon: 'edit'
          } : [],
          {
            action: this.onView.bind(this),
            icon: 'visibility'
          }
        ]

  }

  /*
  * Get rules and load employers
  */
  componentWillMount () {
    if (this.verifyAccess()) {
      this.getTermsAndConditions()
      this.getDefault()
    }
  }

  /*
    * Verify that the user has access to TC
    */
  verifyAccess () {
    if (!(this.state.readAccess || this.state.editAccess)) {
      this.props.history.push('/nbtadmin/unauthorized')
      return false
    }
    return true
  }

  /*
   * Get Terms and Conditions
   */
  getTermsAndConditions() {
    this.setState({ loadingTermsAndConditions: true });
    actions.termsAndConditions.getTermsAndConditions().then(termsAndConditions => {
      const mappedTermsAndConditions = termsAndConditions.map(tc => {
        const { id, name, fileName, agreementType, uploadDate, modifiedDate, active, deactivationDate, uuid, url } = tc;
        return {
          id,
          name,
          fileName,
          agreementType: types[agreementType],
          uploadDate,
          modifiedDate,
          isActive: active === true ? 'Y' : 'N',
          deactivationDate,
          uuid,
          url
        };
      });
      this.setState({
        loadingTermsAndConditions: false,
        filteredDataSource: mappedTermsAndConditions,
        dataSource: mappedTermsAndConditions,
      });
    })
    .catch(() => this.setState({ loadingTermsAndConditions: false }));
  }

  /*
   * Get Default Configs
   */
  getDefault() {
    this.setState({ loadingDefault: true });
    actions.termsAndConditions.getDefault().then(employerConfig => {
      const { hierarchyId } = employerConfig;
      let termsAndConditions = []
      if(employerConfig.bankingAgreement !== null) {
        termsAndConditions.push(employerConfig.bankingAgreement)
      }
      if(employerConfig.feeAgreement !== null) {
        termsAndConditions.push(employerConfig.feeAgreement)
      }
      if(employerConfig.privacyAgreement !== null) {
        termsAndConditions.push(employerConfig.privacyAgreement)
      }
      if(employerConfig.esignAgreement !== null) {
        termsAndConditions.push(employerConfig.esignAgreement)
      }
      const mappedTermsAndConditions = displayTypes.map(types => {
        const { display, key } = types;
        let tc = termsAndConditions.find((x) => x.agreementType === key)
        const none = "N/A"
        return {
          hierarchyId,
          agreementType: display,
          name: (tc !== undefined && tc !== null) ? tc.name : none,
          fileName:(tc !== undefined && tc !== null) ? tc.fileName : none,
          isActive: (tc !== undefined && tc !== null) ? (tc.active === true ? 'Y' : 'N') : none,
          id: (tc !== undefined && tc !== null)? tc.id : '',
          type: key
        };
      });
      this.setState({
        loadingDefault: false,
        defaultFilteredDataSource: mappedTermsAndConditions,
        defaultDataSource: mappedTermsAndConditions,
      });
    })
    .catch(() => this.setState({ loadingDefault: false }));
  }

  /*
  * Go to Terms and Conditions setup form
  */
  onAdd () {
    const {history} = this.props

    history.push({
      pathname: `/nbtadmin/terms-and-conditions-setup-form`,
    })
  }

  /*
  * Edit Default Docs
  */
  onDefaultEdit(tc) {
    const {history} = this.props
    history.push({
      pathname: `/nbtadmin/default-document-edit-form`,
      state: { termsAndConditions: tc }})
  }

  /*
  * Edit Terms and Conditions
  */
  onEdit (tc) {
    const {history} = this.props
    history.push({
      pathname: `/nbtadmin/terms-and-conditions-edit-form`,
      state: { termsAndConditions: tc }})
  }

  /*
  * Download PDF
   */
  onView(tc) {
    this.setState({ loadingTermsAndConditions: true });
    window.open(tc.url)
    this.setState({ loadingTermsAndConditions: false });
  }

  /*
  * Filter data source.
  *
  * @param [Object] queryParams. Key value pair containing filters.
  */
  onFilter (queryParams) {
    const {dataSource} = this.state
    const newDataSource = filterDataSource(dataSource, queryParams)
    this.setState({filteredDataSource: newDataSource})
  }

  render () {
    if (this.state.loading || this.state.loadingTermsAndConditions || this.state.loadingDefault)
      return <div className='margin-top'><Loading/></div>
    const { filteredDataSource, defaultFilteredDataSource } = this.state;
    return (
        <div>
        <div className='search-page manage-terms-and-conditions-view'>
          <h4>Terms and Conditions</h4>
          <p style={{marginBottom:20}}>Default documents</p>
          <TableDisplay
              dataActions={this.defaultActions}
              dataSource={defaultFilteredDataSource}
              loading={this.state.loading}
              disableAdd={true}
              onFilter={this.onFilter.bind(this)}
              onRowClick={this.onDefaultEdit.bind(this)}
              pageDescription=''
              pageTitle=''
              tableHeaders={defaultHeaders}
          />
          <p style={{
            marginBottom: 0,
            marginTop: 20
          }}>Manage custom terms and conditions</p>
          {this.state.editAccess &&
          <div className='text-right'>
            <a onClick={this.onAdd.bind(this)}>
              <i className='material-icons orange'>add_circle_outline</i>
            </a>
          </div>
          }
          <TableDisplay
              dataActions={this.actions}
              dataSource={filteredDataSource}
              loading={this.state.loading}
              disableAdd={true}
              onFilter={this.onFilter.bind(this)}
              onRowClick={this.onEdit.bind(this)}
              pageDescription=''
              pageTitle=''
              tableHeaders={headers}
          />
        </div>
        </div>
    )
  }
}


export default ManageTermsAndConditions
