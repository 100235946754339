/**
 * Super straightforward localization and more!
 *
 * ```
 *  email: {
 *      name: 'email',
 *      type: 'email',
 *      label: 'E-mail',
 *      validator: 'email'
 *  },
 *  date_admission: {
 *      name: 'date_admission',
 *      label: 'Date of Admission',
 *      placeholder: 'Date of Admission',
 *      type: 'month'
 *  }
 * ```
 * TODO Separate the translations from the definitions
 */

const account = {
  us: {
    fields: {
      allocationOfFundsMethod: {
        name: 'allocationOfFundsMethod',
        type: 'dropdown',
        label: 'Allocation of Funds Method',
        placeholder: 'Please select Preferred Fund Allocation. If nothing selected, select ETF.',
        data: {

        }
      },
      reasonForClosure: {
        name: 'reasonForClosure',
        type: 'dropdown',
        label: "Reason For Closure",
        placeholder: 'Reason for closing the account.',
        data: {
          CourtMandate: 'Court Mandate',
          Death: 'Death',
          Disability: 'Disability',
          Dissatisfied: 'Dissatisfied',
          Divorce: 'Divorce',
          NotQualified: 'Not Qualified',
          ParticipantRequested: 'Participant Requested',
          Other: 'Other',
          Transfer: 'Transfer'
        }
      },
      reasonForClosureZeroBalance:{
        name: 'reasonForClosure',
        type: 'dropdown',
        label: "Reason For Closure",
        placeholder: 'Reason for closing the account.',
        data: {
          CourtMandate: 'Court Mandate',
          Death: 'Death',
          Disability: 'Disability',
          Dissatisfied: 'Dissatisfied',
          Divorce: 'Divorce',
          NotQualified: 'Not Qualified',
          ParticipantRequested: 'Participant Requested',
          Other: 'Other',
          Transfer: 'Transfer',
          AccountOpenedInError: 'Account Opened In Error'
        }
      },
      reasonForClosureForPennyFunding:{
        name: 'reasonForClosure',
        type: 'dropdown',
        label: "Reason For Closure",
        placeholder: 'Reason for closing the account.',
        data: {
          Error: 'Account Opened in Error'
        }
      },

      otherReasonForClosureDescription: {
        name: 'otherReasonForClosureDescription',
        type: 'textarea',
        label: "OtherReason For Closure",
        placeholder: 'Other reason for closing account.',
      },
      signaturePresent: {
        name: 'signaturePresent',
        label: "Form received has Participant Signature"
      },
      accountHolderInfoMatchingDocumentation: {
        name: 'accountHolderInfoMatchingDocumentation',
        label: "The information listed here is correct and the documentation provided matches participant information."
      }
    },
    mailingFields: {
      fullName: {
        name: 'optionalMailingAddress.fullName',
        label: 'Full Name',
        placeholder: 'Full Name'
      },
      addressOne: {
        name: 'optionalMailingAddress.addressOne',
        label: 'Address 1',
        placeholder: 'Address 1'
      },
      addressTwo: {
        name: 'optionalMailingAddress.addressTwo',
        label: 'Address 2',
        placeholder: 'Address 2'
      },
      city: {
        name: 'optionalMailingAddress.city',
        label: 'City',
        placeholder: 'City'
      },
      st: {
        name: 'optionalMailingAddress.st',
        label: 'State',
        placeholder: 'State'
      },
      zip: {
        name: 'optionalMailingAddress.zip',
        label: 'Zip',
        placeholder: 'Zip'
      },
      advice: {
        name: 'optionalMailingAddress.advice',
        label: 'Check Advice',
        placeholder: 'Check Advice'
      }
    }
  }
}

export default account