import React from 'react'
import PropTypes from 'prop-types'

import './tabs.scss'

class Tabs extends React.Component {
  /*
  * Map the tabs to new react elements with additional props.
  * Additonal props are used to determine the currently selected
  * tab.
  */
  getTabs () {
    const {children} = this.props
    let childArray = Array.isArray(children) ? children : [children]
    childArray = childArray.filter(child => child)

    return childArray.map((child, index) => {
      const newProps = {
        active: this.isActive(index),
        onChange: () => this.onChange(index),
        key: `tab-${index}`,
        last: (index === childArray.length - 1 && childArray.length > 1) || childArray.length === 1,
        index
      }
      return React.cloneElement(child, newProps)
    })
  }

  /*
  * Determine currently selected tab.
  *
  * @param [Number] index. The index of the tab.
  */
  isActive (index) {
    return this.props.currentIndex === index
  }

  /*
  * Handle on tab index cchange.
  */
  onChange(index) {
    this.props.onChange(index)
  }


  // TODO Styles
  render () {
    const classes = ['tabs']
    if (this.props.className) classes.push(this.props.className)
    return (
      <div className={classes.join(' ')}>
        {this.getTabs()}
      </div>
    )
  }
}

Tabs.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  initialSelect: PropTypes.number
}

export default Tabs
