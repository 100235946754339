import React from 'react'
import PropTypes from 'prop-types'
import Field from '../../components/forms/Field'
import 'compositions/TableDisplay/table-filter.scss'

class Filter extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isDirty: false,
      value: ''
    }
  }

  /*
  * Initialize filter values
  */
  componentWillMount () {
    const {tableFilters, filterItem} = this.props
    this.setState({
      value: tableFilters[filterItem.key]
    })
  }

  /*
  * Clean up time out
  */
  componentWillUnmount () {
    if (this.timeout) clearTimeout(this.timeout)
  }

  /*
  * On blur, fire filter
  */
  onBlur () {
    this.props.onFilter()
  }

  /*
  * On key up, fire filter
  */
  onKeyUp (event) {
    if (this.timeout) clearTimeout(this.timeout)

    if (event.keyCode === 13)  {
      this.field.input.blur()
    } else {
      this.timeout = setTimeout(() => {
        if (this.timeout) clearTimeout(this.timeout)
        this.onBlur()
      }, 600)
    }
  }

  /*
  * Return the filter value.
  */
  getFilterValue () {
    const {filterItem} = this.props
    if (this.state.value) {
      return `${filterItem.key}=${this.state.value}`
    }
  }

  render () {
    const {filterItem, className} = this.props
    const classes = ['search-filter']
    if (className) classes.push(className)
    return (
      <Field
        ref={(component) => { this.field = component }}
        className={classes.join(' ')}
        label={filterItem.header()}
        name={filterItem.key}
        placeholder={filterItem.filterText}
        events={
          {
            onBlur: (event) =>  { this.onBlur() },
            onChange: (event) => { this.setState({value: event.target.value}) },
            onKeyUp: (event) => { this.onKeyUp(event)}
          }
        }
        value={this.state.value}
      />
    )
  }
}

Filter.propTypes = {
  filterItem: PropTypes.object
}

export default Filter
