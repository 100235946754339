import Store from "../store/Store";
import { setAuth } from "../slices/SessionSlice";

/**
 * Check if a role is present in session details.
 * @param {*} roleName 
 * @returns 
 */
export function hasRole(roleName) {
  return Store.getState().session.roles.findIndex(r =>
      r.toLowerCase() === roleName.toLowerCase()
    ) !== -1
}

/**
 * Update session state in Redux store.
 * @param {*} data 
 */
export function updateAuth(data) {
  Store.dispatch(setAuth(data))
}

/**
 * Get session state data from Redux store.
 * @returns 
 */
export function getSession() {
  return Store.getState().session;
}