import React from 'react'
import PropTypes from 'prop-types'
import Field from '../Field'
import 'components/forms/AutoCompleteField/autocomplete.scss'

class AutoCompleteField extends React.Component {
  constructor (props) {
    super(props)
    this.state = this.initializeState()
  }

  /*
  * Initialize the state
  */
  initializeState () {
    return {
      isDirty: false,
      value: null
    }
  }

  /*
  * Render auto complete results
  */
  renderAutoCompleteResults () {
    if (this.state.isDirty && this.props.dataSource.length > 0
      && this.props.value && !this.props.dataValue) {
      return (
        <div className='card autocomplete-results'>
          {
            this.props.dataSource.map((data, index) => {
              if (!this.props.limit || this.props.limit && index <= this.props.limit) {
                return this.props.renderRow(data, index, this.resetState.bind(this))
              }
            })
          }
        </div>
      )
    }
  }

  /*
  * Handle the onchange
  */
  onChange (name, value) {
    this.setState({isDirty: true})
    this.props.events.onChange(name, value)
  }

  /*
  * Allow the user to filter.
  *
  * @param [Event] event. On key up on an input field.
  */
  onKeyUp (event) {
    if (this.props.onSearch) {
      let self = this
      this.timeout = setTimeout(() => {
        if (self.timeout) clearTimeout(self.timeout)
        self.props.onSearch()
      }, 400)
    }
  }

  /*
  * Reset the state.
  */
  resetState () {
    this.setState(this.initializeState())
  }

  render () {
    return (
      <div className='autocomplete'>
        <Field
          {...this.props}
          autoComplete='off'
          events = {{
            onChange: (event) => {
              this.onChange(this.props.name, event.target.value)
            },
            onKeyUp: this.onKeyUp.bind(this)
          }}
        />
        {this.renderAutoCompleteResults()}
      </div>
    )
  }
}

AutoCompleteField.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.any
}

export default AutoCompleteField
