import React from "react";
import Card from "../../../../components/Card";
import actions from "../../../../actions";
import ConsentFileSearch from "../ConsentFileSearch";
import ConsentFileTable from "../ConsentFileSearch/ConsentFileTable";

class UploadConsentHistory extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      hasSearched: false,
      showSearch: true
    }
  }

  onConsentFileSearch(value) {
    this.setState({loadingFileSearch: true})
    return actions.consentForms.getUploadedConsentFilesByDateRange(value.startDate, value.endDate).then(uploadFiles => {
      const mappedUploadFile = uploadFiles.map(uploadFile => {
        const {id, fileName, displayUploadDate, status, comments, employerName} = uploadFile
        return {
          id, 
          fileName, 
          displayUploadDate, 
          status, 
          comments, 
          employerName
        }
      })
      this.setState({
        dataSource: mappedUploadFile,
        loadingFileSearch: false,
        showSearch: false,
        hasSearched: true
      })
    }).catch(() => this.setState({loadingFileSearch: false}))
  }

  renderConsentFileSearch() {
    return (
        <div className='margin-top margin-bottom'>
          <ConsentFileTable
              {...this.props}
              title=''
              dataSource={this.state.dataSource}
              loading={this.state.loadingFileSearch}
              onRowClick={() => false}
          />
        </div>
    )
  }

  toggleSearch() {
    if (this.state.hasSearched) {
      this.setState({showSearch: !this.state.showSearch})
    }
  }

  render() {
    return (
        <div className='search-page upload-consent-file-view'>
          <h4>Uploaded Consent Files History</h4>
          <i className="material-icons"
             onClick={this.toggleSearch.bind(this)}>{this.state.showSearch
              ? 'chevron_left' : 'chevron_right'}</i>
          <div style={{width: "100%", margin: "0 auto", display: this.state.showSearch ? 'block' : 'none'}}>
            <Card className='padding'>
              <ConsentFileSearch
                  {...this.props}
                  onSubmit={this.onConsentFileSearch.bind(this)}
              />
            </Card>
          </div>
          {this.state.hasSearched && this.renderConsentFileSearch()}
        </div>
    )
  }
}

export default UploadConsentHistory