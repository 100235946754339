import React from 'react'
import ConfigurationForm from './ConfigurationForm'

class OtherConfigurations extends React.Component {
  render () {
    return (
      <div className='search-page other-configurations-view'>
        <h4>Other Configurations</h4>
        <p>Manage your business configurations.</p>
        <ConfigurationForm {...this.props} />
      </div>
    )
  }
}

OtherConfigurations.propTypes = {

}

export default OtherConfigurations
