import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    message: null,
    open: false,
    type: null
};

const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        openSnackbar(state, action) {
            state.message = action.payload.message;
            state.open = true;
            state.type = action.payload.type || 'error';
        },
        closeSnackbar(state, action) {
            state.message = null
            state.open = false;
            state.type = null;
        }
    }
})

export const {openSnackbar, closeSnackbar} = snackbarSlice.actions;

export default snackbarSlice.reducer;