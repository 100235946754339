/*
* Mask date
*/
export function maskDate(oldValue, newValue) {
  let maskedValue = newValue.replace(/[a-zA-Z-!@#$%^&*()_+|~=`\\#{}\[\]:";'<>?,.]/g, '')
  if (newValue.length > 10) return oldValue
  if (oldValue.length > newValue.length) return maskedValue
  switch (newValue.length) {
    case 2:
      maskedValue = newValue + '/'
      break
    case 3:
      if (newValue[2] !== '/') {
        maskedValue = `${newValue.slice(0, 2)}/${newValue[2]}`
      }
      break
    case 5:
      maskedValue = newValue + '/'
      break
    case 6:
      if (newValue[5] !== '/') {
        maskedValue = `${newValue.slice(0, 5)}/${newValue[5]}`
      }
      break
    default:
      break
  }
  return maskedValue
}


/*
* Mask currency
*
* @param [String] newValue. New value to mask.
*/
export function maskCurrency (newValue) {
  newValue += '' //make sure is string
  let maskedValue = newValue.replace(/[a-zA-Z-!@#$%^&*()_+|~=`\\\/#{}\[\]:";'<>?,]/g, '')
  maskedValue += ''
  var x = maskedValue.split('.')
  var x1 = x[0]
  var x2 = x.length > 1 ? '.' + x[1] : ''
  if (x2.length > 3) x2 = x2.substring(0, 3)
  return x1 + x2
}
