import React from 'react'
import Card from '../../../../components/Card'
import MailCorrespondenceForm from './MailCorrespondenceForm'
import TableDisplay from 'compositions/TableDisplay'
import Paginate from '../../../../components/Paginate'
import Field from '../../../../components/forms/Field'
import Button from '../../../../components/Button'
import Loading from '../../../../components/Loading'
import actions from '../../../../actions'
import { hasRole } from '../../../../redux/utility/SessionUtil'
import Utils from '../../../../lib/utils'
import { showSnackbar, hideSnackbar } from '../../../../redux/utility/SnackbarUtil'

const headers = [
    {
        filterText: 'Month-Year',
        header: () => 'Month-Year',
        key: 'yearMonth',
        disableFilter: true
    },
    {
        filterText: 'Letter Type',
        header: () => 'Letter Type',
        key: 'letterType',
        enableSort: true,
        disableFilter: true
    },
    {
        filterText: 'Zip file Name',
        header: () => 'Zip file Name',
        key: 'zipFileName',
        enableSort: true,
        disableFilter: true
    },
    {
        filterText: 'Mailed Count',
        header: () => 'Mailed Count',
        key: 'mailedCount',
        enableSort: true,
        disableFilter: true
    }
]

const exportDropDownData = {
    csv : {
      name: 'CSV',
      disabled: false
    },
    xlsx : {
      name: 'Excel',
      disabled: false
    }
  }

const COUNT_PER_PAGE = 50

class MailCorrespondenceReport extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            dataSource: [],
            exportType: 'csv',
            downloading: false,
            loading: false,
            total: 0,
            readAccess: hasRole('AccountMaintView') || hasRole('AccountMaintEdit')
        }
        this.form = {}
        this.initTableData()

    }

    componentDidMount() {
        this.verifyAccess()
    }

    verifyAccess() {
        if(!this.state.readAccess) {
            this.props.history.push('/nbtadmin/unauthorized')
        }
    }

    initTableData() {
        this.queryParams = {}
        this.sortDirection = 'asc'
        this.sortColumn = 'letterType'
        this.pageNumber = 0
    }

    onSearch(form) {
        this.form = form
        this.pageNumber = 0
        this.sortDirection = 'asc'
        this.sortColumn = 'letterType'
        this.setState({ loading: true , dataSource: []})
        this.loadData()
    }

    onFormUpdate(form) {
        this.form = form
    }

    updatePage(current) {
        this.pageNumber = current - 1
        this.setState({ loading: true })
        this.loadData()
    }

    onFilterTable(queryParams) {
        this.pageNumber = 0
        this.queryParams = queryParams
        this.loadData()
    }

    onSortTable(queryParams,sortableRefs) {
        this.pageNumber = 0
        this.sortDirection = sortableRefs.filter(row => row.props.sortableItem.key === queryParams.key)
        .map(row => row.state.sortDirection)
        this.sortColumn = queryParams.key
        this.loadData()
    }

    loadData() {
        hideSnackbar();
        if(!this.form.yearMonth || this.form.yearMonth === 'Invalid date') {
            showSnackbar('Enter Month Year')
            this.setState({ loading: false , dataSource: []})
            return
        }
        const params  = {
            ...this.form,
            ...this.queryParams,
            pageNumber: this.pageNumber,
            sortDirection: this.sortDirection,
            sortColumn: this.sortColumn,
            fileType: this.state.exportType
        }
        actions.mailCorrespondenceReport.getMailCorrespondenceReport(params)
        .then(data => {
            this.setState({dataSource: data.mailCorrespondences, total: data.totalMailCorrespondences, loading: false})
        }).catch((error) => {
            this.setState({dataSource: [], loading: false})
            console.log(error)
            showSnackbar('Failed to retrieve data')
        })
    }

    exportAll() {
        const params  = {
            ...this.form,
            ...this.queryParams,
            pageNumber: this.pageNumber,
            sortDirection: this.sortDirection,
            sortColumn: this.sortColumn,
            fileType: this.state.exportType
        }
        var monthYear = this.form.yearMonth.split("-")
        this.setState({ downloading: true })
        actions.mailCorrespondenceReport.exportMailCorrespondenceReport(params)
        .then(data => data.blob())
        .then(response => {
            this.setState({ downloading: false })
            const url = window.URL.createObjectURL(response)
            const a = document.createElement('a')
            a.style.display = 'none'
            a.href = url
            a.download = 'MailCorrespondenceReport' + monthYear[1] + monthYear[0] + "-"  + Utils.getCurrentDateTime() + '.' + this.state.exportType
            a.click()
            window.URL.revokeObjectURL(url)
        }).catch((error) => {
            this.setState({ downloading: false })
            console.log(error)
            showSnackbar('Failed to download the file')
        })

    }

    render() {
        return (
            <div className='search-page'>
                <h4>Mail Correspondence Report</h4>
                <Card className='padding'>
                    <MailCorrespondenceForm
                        onSubmit={this.onSearch.bind(this)}
                        onChange={this.onFormUpdate.bind(this)}
                    />
                </Card>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginBottom: '1em'
                    }}
                >
                <Field
                    label={false}
                    name='exportType'
                    type='dropdown'
                    value={this.state.exportType}
                    data={exportDropDownData}
                    className='width-auto'
                    events={
                    {
                      onChange: (event) => {
                          this.setState({exportType: event.target.value})
                      }
                    }
                  }
                />
                <Button
                    className='margin-left margin-right width-auto'
                    disabled={this.state.downloading}
                    events={
                        {
                          onClick: (event) => {
                              if(this.state.downloading) {
                                return;
                              }
                              this.exportAll();
                          }
                        }
                      }
                >
                    <span>
                        {this.state.downloading ? <Loading containerClassName='inline' className='white small' />
                        : 'Export All'}
                    </span>
                </Button>
                </div>
                <div className="fixed-height-scroll">
                <TableDisplay
                    dataActions={[]}
                    dataSource={this.state.dataSource}
                    disableAdd={true}
                    loading={this.state.loading}
                    onAdd={() => false}
                    onFilter={this.onFilterTable.bind(this)}
                    onSort={this.onSortTable.bind(this)}
                    singleColumnSort={true}
                    tableHeaders={headers}
                    tableDataClassName='align-items-start overflow-wrap'
                    initialSortLabel='Letter Type'
                    initialSortDirection='asc'
                />
                </div>
                {this.state.dataSource.length > 0 && (<Paginate
                    total={this.state.total}
                    countPerPage={COUNT_PER_PAGE}
                    updatePage={this.updatePage.bind(this)}
                    style={{
                        marginTop: '1em'
                    }}
                />)}
            </div>
        );
    }
}

export default MailCorrespondenceReport