import React from 'react'
import Button from 'components/Button'
import Card from 'components/Card'
import Loading from 'components/Loading'
import Field from 'components/forms/Field/index'
import emailTemplate from 'data/emailTemplate'
import actions from 'actions'
import { hasRole } from '../../../../redux/utility/SessionUtil'
import CKEditor from 'react-ckeditor-component'
import DatePicker from "../../../../components/forms/DatePicker";
import { showSnackbar } from '../../../../redux/utility/SnackbarUtil'
import dayjs from 'dayjs'


class EmailTemplateForm extends React.Component {
  constructor (props) {
    super (props)
    this.state = {
      form: this.initializeFormData(),
      writeAccess: hasRole('EmailMasterEdit'),
      initialized: false
    }

    this.onChange.bind(this)
  }

  /*
  * Get email template by id .
  */
  componentWillMount () {
    if (this.verifyAccess()) {
      this.getEmailTemplate()
    }
  }

  /*
  * Verify that the user has access to email template
  */
  verifyAccess () {
    if (!(this.state.writeAccess)) {
      this.props.history.push('/nbtadmin/unauthorized')
      return false
    }

    return true
  }

  /*
  * Fetch email templates by id.
  */
  getEmailTemplate() {
    let {match: {params: {id}}} = this.props
    if( localStorage.getItem('copyTemplateId')){
      id = localStorage.getItem('copyTemplateId');
      
    }
    this.setState({loading: true})
    if(id){
	    actions.emailTemplates.getEmailTemplateById(id).then(template => {
        if(localStorage.getItem('copyTemplateId')){
          template.contentId = '';
          template.defaultTemplate = false;
          template.optumCorrespondenceId = '';
          template.startDate = this.validDate(template.endDate) ? dayjs(template.endDate).add(1, 'days').format("YYYY-MM-DD") : dayjs().format('YYYY-MM-DD');
          template.endDate = '';
          delete template.contentId
          localStorage.removeItem('copyTemplateId');
        }
	      this.setState({form: this.initializeFormData(template), loading: false, initialized: true})
      }).catch(() => {
        localStorage.removeItem('copyTemplateId');
        this.setState({loading: false, initialized: true})
      }
      )
    } else{
    	this.setState({initialized : true});
    }
  }

  /*
  * Initialize form data
  * Interest profile.
  */
  initializeFormData (data) {
    const {contentId, contentKey, contentSummary, contentDocument, link, maxLength, defaultTemplate, optumCorrespondenceId, startDate, endDate} = data || {}
    return {
      id: contentId || '',
      contentId: contentId || '',
      contentKey: contentKey || '',
      contentSummary: contentSummary || '',
      contentDocument: contentDocument || '',
      link: link,
      maxLength: maxLength || '',
      defaultTemplate: defaultTemplate || false,
      optumCorrespondenceId: optumCorrespondenceId || '',
      startDate: startDate ? startDate : '',
      endDate: endDate ? endDate : '',
      disableEndDate : false
    }

  }

  /*
  * Render the fields
  */
  getFields () {
    const {form: {
      contentKey,
      contentSummary,
      link,
      defaultTemplate,
      optumCorrespondenceId,
      startDate,
      endDate,
      disableEndDate
    }} = this.state

    const {fields} = emailTemplate.us

    return [
        {
          ...fields.contentSummary,
          required: true,
          events: {
            onChange: (event) => {
              this.onChange(fields.contentSummary.name, event.target.value)
            }
          },
          value: contentSummary
        },
        {
          ...fields.contentKey,
          required: true,
          events: {
            onChange: (event) => {
              this.onChange(fields.contentKey.name, event.target.value)
            }
          },
          value: contentKey
        },
        {
          ...fields.link,
          required: true,
          events: {
            onChange: (event) => {
              this.onChange(fields.link.name, event.target.value)
            }
          },
          value: link
        },
        {
          ...fields.defaultTemplate,
          events: {
            onChange: (event) => {
              this.onChange(fields.defaultTemplate.name, event.target.checked)
            }
          },
          attrs: {
            checked: defaultTemplate
          },
          value: defaultTemplate
        },
        {
          ...fields.optumCorrespondenceId,
          required: false,
          attrs: {
            maxLength: 25
          },
          events: {
            onChange: (event) => {
              this.onChange(fields.optumCorrespondenceId.name, event.target.value)
            }
          },
          value: optumCorrespondenceId
        },
        {
          ...fields.startDate,
          required: true,
          onChange: (name,value) => {
            this.onChange(name, value)
          },
          value: startDate
        },
        {
          ...fields.endDate,
          required: false,
          disabled: disableEndDate,
          onChange: (name,value) => {
            this.onChange(name, value)
          },
          value: endDate
        }
      ]
  }

  /*
  * Update form values
  *
  * @param [String] name. Field name of form to update.
  * @param [Any] value. Value.
  */
  onChange (name, value) {
    let form = {...this.state.form}
    form[name] = value
    if(name === 'defaultTemplate')
    {
      if(value === true){
        form['endDate'] = null
        form['disableEndDate'] = true
      }else{
        form['disableEndDate'] = false
      }
    }
    this.setState({form})
  }

  validDate = date => {
    const currentDate = dayjs().subtract(1, 'days').format("YYYY-MM-DD")
    return date > currentDate
  }

  /**
   * On submit
   */
  handleSubmit(event) {
    event.preventDefault()
    let form = {...this.state.form}
    if (!this.validDate(form.startDate)) {
      showSnackbar('Please enter a valid \'Start Date\' greater than or equal to current date')
    } else if ((form.endDate && !this.validDate(form.endDate)) || (form.endDate && form.endDate < form.startDate)) {
      showSnackbar('Please enter a valid \'End Date\' greater than start date')
    }else {
      !this.state.form.id ?
      this.onCreateEmailTemplate(form): this.onUpdateEmailTemplate(form)
    }
  }

  /*
  * Create new email template
  */
  onCreateEmailTemplate(form) {
    delete form.id
    this.setState({submitting: true})
    actions.emailTemplates.createEmailTemplate(form).then((template) => {
      const {history} = this.props
      history.push(`/nbtadmin/email-templates/${template.contentId}/edit`)
      this.setState({form: this.initializeFormData(template) , submitting: false})
    }).catch(() => this.setState({submitting: false}))
  }

  /*
  * Update email template
  */
  onUpdateEmailTemplate(form) {
    this.setState({submitting: true})
    actions.emailTemplates.updateEmailTemplate(form).then((template) => {
      this.setState({form: this.initializeFormData(template), submitting: false})
    }).catch(() => this.setState({submitting: false}) )
  }

  render () {
    const {form} = this.state
    return (
      <div className='page email-template-view'>
        <h4>{form.id ? 'Edit' : 'Create'} Email Template</h4>
        <form onSubmit={this.handleSubmit.bind(this)}>
          <Card className='padding'>
            <h5>Template Details</h5>
            {this.getFields().map((f, idx) => {
              if(f.type !=='datePicker'){
                return  <Field key={`${f.name}-${idx}`} {...f} />
              }else{
                return <DatePicker key={`${f.name}-${idx}`} {...f} />
              }
            })}
          </Card>

          <Card className='padding'>
            <h5>Template</h5>

            {this.state.initialized ?
                <CKEditor
                    activeClass="p10"
                    name='contentEditor'
                    content={form.contentDocument}
                    events={{
                        "change": event => {
                            this.onChange('contentDocument', event.editor.getData())},
                        "blur": this.onBlur,
                        "afterPaste": this.afterPaste
                    }}
                    scriptUrl={"/assets/javascript/ckeditor/4.6.2/full-all/ckeditor.js"}
                    />
                    : null
            }
          </Card>


          <Button
            className='primary-button margin-top'
            disabled={this.state.submitting}
            type="submit"
          >
            <span>
              {this.state.submitting ?
                <Loading containerClassName='inline' className='white small' />
                : 'Submit'
              }
            </span>
          </Button>
        </form>
      </div>
    )
  }
}

export default EmailTemplateForm
