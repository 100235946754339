const disclaimer = {
  us: {
    fields: {
      hierarchyLevel: {
        name: 'hierarchyLevel',
        label: 'Hierarchy Level',
        type: 'dropdown',
        data: {
          custodian: {
            name: 'Custodian',
            disabled: false
          },
          master: {
            name: 'Master',
            disabled: false
          }
        }
      },
      custodianName: {
        name: 'custodianName',
        label: 'Custodian Name'
      },
      masterName: {
        name: 'masterName',
        label: 'Name'
      },
      disclaimerType: {
        name: 'disclaimerType',
        label: 'Disclaimer'
      },
      tag: {
        name: 'tag',
        label: 'Tag'
      },
      defaultDisclaimerText: {
        name: 'defaultDisclaimerText',
        label: 'Default Text'
      },
      disclaimerText: {
        name: 'disclaimerText',
        label: 'Override Default Disclaimer Text',
        placeholder: 'Enter your text here'
      }
    }
  }
}

export default disclaimer
