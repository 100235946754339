import React from 'react'
import ConfirmDialog from 'components/ConfirmDialog'
import TableDisplay from 'compositions/TableDisplay'
import actions from 'actions'
import { hasRole } from '../../../../redux/utility/SessionUtil'
import {mapDateToSortable} from "lib/date"
import {filterDataSource} from 'lib/filter'
import {sortDataSource} from "lib/sort"

const headers = [
  {
    filterText: 'Name',
    header: () => 'Name',
    key: 'name',
    enableSort: true,
  },
  {
    filterText: 'Created Date',
    header: () => 'Created Date',
    key: 'createdDate',
    enableSort: true,
  },
  {
    filterText: 'Modified Date',
    header: () => 'Modified Date',
    key: 'lastModifiedDate',
    enableSort: true,
  },
  {
    filterText: 'Deactivated Date',
    header: () => 'Deactivated Date',
    key: 'deactivatedDate',
    enableSort: true,
  },
  {
    filterText: 'Description',
    header: () => 'Description',
    key: 'description',
    enableSort: true,
  }
]

class FeeProfiles extends React.Component {
  constructor (props) {
    super (props)

    this.state = {
      currentProfile: null,
      dataSource: [],
      filteredDataSource: [],
      originalSource: [],
      openConfirmationDialog: false,
      readAccess: hasRole('FeeProfileMasterView'),
      writeAccess: hasRole('FeeProfileMasterEdit'),
      initialSortLabel: "Created Date",
      initialSortDirection: "asc",
    }

    this.actions = this.state.writeAccess ? [
      {
        action: this.onEdit.bind(this),
        icon: 'edit'
      },
      {
        action: this.onDelete.bind(this),
        icon: 'delete'
      }
    ] : []
  }

  /*
  * Get all fees
  */
  componentWillMount () {
    if (this.verifyAccess()) {
      this.getFeeProfiles()
    }
  }

  /*
  * Verify that the user has access to fee profiles
  */
  verifyAccess () {
    if (!(this.state.readAccess || this.state.writeAccess)) {
      this.props.history.push('/nbtadmin/unauthorized')
      return false
    }

    return true
  }

  /*
  * Fetch fee profiles
  */
  getFeeProfiles() {
    this.setState({loading: true})
    actions.feeProfiles.getFeeProfiles().then(profiles => {
      const mappedProfiles = profiles.map(profile => {
        return this.mapProfileToDisplay(profile)
      }).sort((a,b) => {
        if (a.createdDate < b.createdDate)
          return -1
        if (a.createdDate > b.createdDate)
          return 1
        return 0
      })
      this.setState({originalSource: profiles, dataSource: mappedProfiles, filteredDataSource: mappedProfiles, loading: false})
    }).catch(() =>{
      this.setState({loading: false})
    })
  }

  /*
  * Map profile to display
  */
  mapProfileToDisplay (profile) {
    const {id, name, createdDate, lastModifiedDate, deactivatedDate, description} = profile
    return {
      id,
      name,
      createdDate: mapDateToSortable(createdDate),
      lastModifiedDate: mapDateToSortable(lastModifiedDate),
      deactivatedDate: mapDateToSortable(deactivatedDate),
      description: <div className='overflow'>{description}</div>
    }
  }

  /*
  * Render the confirm delete dialog
  */
  renderConfirmDialog () {
    if (this.state.openConfirmationDialog) {
      return (
        <ConfirmDialog
          description='Are you sure you want to deactivate fee profile?'
          onClose={() => { this.setState({openConfirmationDialog: false, currentProfile: null}) }}
          onConfirm={() => { this.deleteProfile(this.state.currentProfile) }}
        />
      )
    }
  }

  /*
  * Go to fee form
  */
  onAdd () {
    const {history} = this.props
    history.push(`/nbtadmin/fee-profiles/create/new`)
  }

  /*
  * View fee profile
  */
  onRowClick (profile) {
    const {history} = this.props
    history.push(`/nbtadmin/fee-profiles/${profile.id}`)
  }

  /*
  * Edit fee profle
  */
  onEdit (profile) {
    const {history} = this.props
    history.push(`/nbtadmin/fee-profiles/${profile.id}/edit`)
  }

  /*
  * On delete, open confirmation dialog
  */
  onDelete (profile) {
    this.setState({openConfirmationDialog: true, currentProfile: profile})
  }

  /*
  * Delete fee profile
  */
  deleteProfile () {
    // TODO: Api should not update fields that don't exist in form. Right now they null out if not provided
    // so keep copy of origianl record.
    const originalProfile = this.state.originalSource.find((ds) => ds.id === this.state.currentProfile.id)
    actions.feeProfiles.deactivateFeeProfile(originalProfile).then((updatedProfile) => {
      const index = this.state.dataSource.findIndex(ds => ds.id === this.state.currentProfile.id)
      let newDataSource = this.state.dataSource
      let newOriginalSource = this.state.originalSource
      newDataSource[index] = this.mapProfileToDisplay(updatedProfile)
      newOriginalSource[index] = updatedProfile
      this.setState({dataSource: newDataSource, originalSource: newOriginalSource, openConfirmationDialog: false, currentProfile: null})
    }).catch(() => false)
  }

  /*
  * Filter data source.
  *
  * @param [Object] queryParams. Key value pair containing filters.
  */
  onFilter (queryParams, sortableRefs) {
    const {dataSource} = this.state
    const newDataSource = filterDataSource(dataSource, queryParams)
    const sortedDataSource = sortDataSource(newDataSource, queryParams, sortableRefs)
    this.setState({filteredDataSource: sortedDataSource})
  }

  onSort(queryParams, sortableRefs) {
    const {filteredDataSource} = this.state
    const sortedDataSource = sortDataSource(filteredDataSource, queryParams, sortableRefs)
    this.setState({filteredDataSource: sortedDataSource})
  }

  render () {
    return (
      <div className='search-page fee-profiles-view'>
        <TableDisplay
          dataActions={this.actions}
          dataSource={this.state.filteredDataSource}
          disableAdd={!this.state.writeAccess}
          loading={this.state.loading}
          onAdd={this.onAdd.bind(this)}
          onFilter={this.onFilter.bind(this)}
          onSort={this.onSort.bind(this)}
          onRowClick={this.onRowClick.bind(this)}
          pageDescription='Manage fee profiles.'
          pageTitle='Fee Profiles'
          tableHeaders={headers}
          initialSortLabel={this.state.initialSortLabel}
          initialSortDirection={this.state.initialSortDirection}
        />
        {this.renderConfirmDialog()}
      </div>
    )
  }
}

export default FeeProfiles
