import React from "react";

import DatePicker from "../../../../components/forms/DatePicker";
import Card from "../../../../components/Card";

class UrlExpirationDateForm extends React.Component {

    render() {
        return (
            <Card className='padding'>
                <form onSubmit={this.props.onSubmit}>
                    <DatePicker
                        disabled={false}
                        label='Url Expiration Date'
                        name='urlExpirationDate'
                        required={true}
                        onChange={(name, value) => {
                            this.props.getChildFormState(name, value)
                        }}
                        placeholder='Url Expiration Date'
                    />
                    <div>
                        <button type='submit' className='next button margin-top primary-button'
                                style={{width: 'auto', display: 'block', marginLeft: 'auto'}}
                        >Next</button>
                    </div>
                </form>
            </Card>
        )
    }
}

export default UrlExpirationDateForm