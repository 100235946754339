import React from 'react'
import Card from 'components/Card'
import AccountClosure from './AccountClosure'
import ClosureDialog from './ClosureDialog'
import ConfirmDialog from 'components/ConfirmDialog'
import Loading from 'components/Loading'
import {Tabs, Tab} from 'components/Tabs'
import TextField from 'components/TextField'
import Transactions from './Transactions'
import CIPOrder from './CIPOrder'
import AccountReactivation from './AccountReactivation'
import actions from 'actions'
import { hasRole } from '../../../../redux/utility/SessionUtil'
import {mapDateToForm} from 'lib/date'
import ChangeAccountNumber from './ChangeAccountNumber'
import {getSponsorNameFromList} from 'lib/hierarchy'
import {getSponsorIdFromList} from 'lib/hierarchy'
import {getDistributorNameFromList} from 'lib/hierarchy'
import {getDistributorIdFromList} from 'lib/hierarchy'
import { showSnackbar, hideSnackbar } from '../../../../redux/utility/SnackbarUtil'

const DUPLICATE_CLOSURE_ERROR_MESSAGE = 'Open closure case already exists for member.'
const CLOSURE_TAB = 6

class Account extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      account: null,
      accountClosure: null,
      currentClosure: null,
      openConfirmationDialog: false,
      openClosureDialog: false,
      openChangeDialog: false,
      tabIndex: 0,
      hasDirectDeposit: null,
      directDepositConfirmed: null,
      readAccess: hasRole('AccountMaintView'),
      writeAccess: hasRole('AccountMaintEdit'),
      accountReactivationAccess: hasRole('AccountReactivation'),
      cipAccess: hasRole('CIPOverride'),
      hsaProcessorName : null,
      isCaseAlreadyOpen: false,
      reactivationInProgress: false,
      employerId: null
    }
  }

  /*
  * Get account by id .
  */
  componentWillMount () {
    if (this.verifyAccess()) {
      this.getAccount()
      this.getAccountClosure()
    }
  }

  updateisCaseAlreadyOpen (isOpen) {
    this.setState({isCaseAlreadyOpen: isOpen})
  }

  /*
  * Verify that the user has access to account maintenance
  */
  verifyAccess () {
    if (!(this.state.readAccess || this.state.writeAccess)) {
      this.props.history.push('/nbtadmin/unauthorized')
      return false
    }

    return true
  }

  /*
  * If direct deposit account exists, set it into state as true or false if not
  */
  getDirectDepositByEmployeeId (id) {
    actions.accounts.getDirectDepositByEmployeeId(id).then(account =>{ 
      if(account === true){
        this.setState({ hasDirectDeposit: true })
      } else {
        this.setState({ hasDirectDeposit: false })
      }
    })
  }

  /*
  * Get direct deposit confirmed
  */
  getDirectDepositConfirmedByEmployeeId (id) {
    actions.accounts.getDirectDepositConfirmedByEmployeeId(id).then(account =>{ 
      if(account === true){
        this.setState({ directDepositConfirmed: true })
      } else {
        this.setState({ directDepositConfirmed: false })
      }
    })
  }

  /*
  * Fetch account by id.
  */
  getAccount() {
    const {match: {params: {id}}} = this.props
    this.setState({loading: true})
    actions.accounts.getAccountById(id).then(account => {
      const {
        bankAccount: {id, accountNumber, externalBankAccountId, currentBalance, investmentBalance, availableBalance,  accountStatus},
        savingsAccountMinimal
      } = account
      //TODO: move this back up once api becomes stable. savingsAccountMinimal
      // coming back as null
      const  {employeeMinimal, accountType} = savingsAccountMinimal || {}
      const { firstName, lastName, ssn, birthDate, defaultAddress,
        phoneNumber, employeeId} = employeeMinimal || {}
      const {name} = accountType || {}
      const hsaodMinimal = {}
      
      //Get HsaProcessor information for the given account
      actions.accounts.getHsaProcessorById(savingsAccountMinimal.accountId).then(hsaProcessor => {
        const {name} = hsaProcessor || {}
        this.setState({hsaProcessorName : name});
      })
      this.setState({employerId: account.bankAccount.employerId});

      const mappedAccount = {
        id,
        firstName,
        lastName,
        accountType: name,
        accountNumber,
        externalBankAccountId,
        ssn,
        birthDate: mapDateToForm(birthDate),
        defaultAddress,
        phoneNumber,
        employeeId,
        currentBalance,
        investmentBalance,
        availableBalance,
        hsaodMinimal,
        accountStatus,
        savingsAccountMinimal
      }

      //Now get HSA Advance Available Balance with HSA accountId
      actions.accounts.getHsaAdvanceAvailableBalance(savingsAccountMinimal.accountId, mappedAccount).then( mappedAccount => {
            this.setState({account : mappedAccount, loading: false});
      })

      //Determine if they have a direct deposit account or not
      this.getDirectDepositByEmployeeId(mappedAccount.employeeId);

      //Determine if the direct deposit is confirmed
      this.getDirectDepositConfirmedByEmployeeId(mappedAccount.employeeId);
    }).catch(() => {this.setState({loading: false}) })
  }

  /*
  * Get account closure
  */
  getAccountClosure (tabIndex = 3) {    
    const {match: {params: {id}}} = this.props
    actions.accounts.getAccountClosure(id).then(accountClosure => {
      if (accountClosure.length > 0) {
        let isCaseAlreadyOpen = false;
        accountClosure.map(function(cases) {if(cases.caseStatus === "Open")isCaseAlreadyOpen= true})
        this.setState({accountClosure: accountClosure, tabIndex: tabIndex, isCaseAlreadyOpen})
      } 
    }).catch(() => false)
  }

  /*
  * Render the actions menu
  */
  renderActionsMenu (accountStatus) {
    if (this.state.writeAccess &&
        (accountStatus === 'Active' || accountStatus === 'Suspended' ||
         accountStatus === 'Inactive' || accountStatus === 'Dormant' || accountStatus === 'Abandoned')
         && !this.state.isCaseAlreadyOpen) {
      return (
        <div className='text-right'>
          <a style={{marginLeft: '8px'}} onClick={this.onDelete.bind(this)}>
            <i className='material-icons orange' style={{fontSize: '1.2em'}}>delete</i>
          </a>
        </div>
      )
    }
  }

  /*
  * Render the confirm delete dialog
  */
  renderConfirmDialog () {
    if (this.state.openConfirmationDialog) {
      return (
        <ConfirmDialog
          description='Are you sure you want close this account?'
          onClose={() => { this.setState({openConfirmationDialog: false}) }}
          onConfirm={() => { this.setState({openConfirmationDialog: false, openClosureDialog: true}) }}
        />
      )
    }
  }

  /*
  * Render the confirm delete dialog
  */
  renderClosureDialog () {
    if (this.state.openClosureDialog) {
      return (
        <ClosureDialog
          account={this.state.account}
          onConfirm={this.onCreateAccountClosure.bind(this)}
          onClose={() => { this.setState({openClosureDialog: false}) }}
          onCloseAccount={() => { this.setState({openClosureDialog: false}) }}
        />
      )
    }
  }

  /*
  * Render the change account id dialog
  */
  renderChangeDialog () {
    if (this.state.openChangeDialog) {
      return (
        <ConfirmDialog
          description='Are you sure you want to change the external ID of this account?'
          onClose={() => {this.setState({openChangeDialog: false})}}
          onConfirm={this.onChangeAccountId.bind(this)}
        />
      )
    }
  }

  /*
  * Render tabs
  */
  renderTabs () {
    return (
      <Tabs
        className='margin-top margin-bottom'
        currentIndex={this.state.tabIndex}
        onChange={(index) => {
          this.setState({tabIndex: index})
        }}
      >
        <Tab name='Account Reactivation' />
        <Tab name='Add Beneficiary' />
        <Tab name='Repay FSA Claim' />
        <Tab name='Transactions' />
        <Tab name='Change Account Number'/>
        <Tab name='CIP Order'/>
        {this.state.accountClosure && <Tab name='Account Closure' />}
      </Tabs>
    )
  }

  /*
  * Render Tab Content
  */
  renderTabContent () {
    const {match: {params: {id}}} = this.props
    switch (this.state.tabIndex) {
      case 0:
        if(this.state.accountReactivationAccess)
          return <AccountReactivation 
                  accountId={id} 
                  reactivationInProgress={this.state.reactivationInProgress} 
                  onReactivateAccount={this.onReactivateAccount.bind(this)} 
                  accountState={this.state.account.accountStatus} 
                  accountReactivationAccess={this.state.accountReactivationAccess}
                  />
        else 
          return <div>You don't have access to this feature</div>
      case 3:
        return <Transactions
          history={this.props.history}
          accountId={id}
          writeAccess={this.state.writeAccess}
          readAccess={this.state.readAccess}
        />
      case 4:
        return <ChangeAccountNumber
          account={this.state.account}
          onChangeAccountId={this.onChangeAccountId.bind(this)}
        />
      case 5:
        return <CIPOrder
          history={this.props.history}
          account={this.state.account}
          cipAccess={this.state.cipAccess}
          employerId={this.state.employerId}
        />
      case 6:
        return <AccountClosure
          account={this.state.account}
          accountClosure={this.state.accountClosure}
          onUpdateAccountClosure={this.onUpdateAccountClosure.bind(this)}
          writeAccess={this.state.writeAccess}
          hasDirectDeposit={this.state.hasDirectDeposit}
          directDepositConfirmed={this.state.directDepositConfirmed}
          isCaseAlreadyOpen={this.state.isCaseAlreadyOpen}
         />
      default:
        return <div>This section is coming soon.</div>
    }
  }

  renderTitleLabel(label, value){
    const styles = {lineHeight: '1'}
    return <div style={styles}>
      <h5>{label}</h5>
      <div>
        <span>{value}</span>
      </div>
    </div>
  }

  /*
  * On delete, open confirmation dialog
  */
  onDelete () {
    this.setState({openConfirmationDialog: true})
  }

  /*
  * Create account closure case
  */
  onCreateAccountClosure (form) {
    if ((this.state.hasDirectDeposit === false || this.state.directDepositConfirmed === false) && form.allocationOfFundsMethod === 'Electronic Funds Transfer (EFT)') {
      showSnackbar('No Confirmed Direct Deposit exists for this member\'s job, please update the Allocation of Funds Method to be via Check.', 'error')
    } else {
      this.setState({ loading: true })
      actions.accounts.createAccountClosure(form).then(accountClosure => {
        let allClosedCases = this.state.accountClosure ? this.state.accountClosure : [];
        allClosedCases = allClosedCases.concat(accountClosure);
        this.setState({
          accountClosure: allClosedCases,
          openClosureDialog: false,
          isCaseAlreadyOpen: true,
          loading: false
        });
        showSnackbar('Account Closure Case successfully created', 'info')
      }).catch(error => {
        this.setState({ loading: false })
        let errorMessage = 'Failed to create account closure.'
        if (error && error.message) {
          errorMessage = error.message
        }
        if (errorMessage === `Open closure case already exists for account id [${form.bankAccountId}]`) {
          this.setState({ openClosureDialog: false })
          errorMessage = DUPLICATE_CLOSURE_ERROR_MESSAGE + ' Redirecting to the existing open case.'
          setTimeout(() => {
            this.getAccount()
            this.getAccountClosure(CLOSURE_TAB)
            hideSnackbar();
          }, 1500)
        }
        showSnackbar(errorMessage)
      })
    }
  }

  /*
  * Update account closure case
  */
  onUpdateAccountClosure (form) {
    actions.accounts.updateAccountClosure(form).then(()=> {
      this.getAccount();
      this.getAccountClosure();
      this.setState({tabIndex: 5});
    }).catch(() => false)
  }

  /*
  * Change the external account id
  */
  onChangeAccountId (id) {
    actions.accounts.changeAccountNumber(id).then(this.setState({openChangeDialog: false}))
      .catch(() => false)
  }

  /*
  * Change in account status on reactivation
  */
 onReactivateAccount (id) {
  this.setState({reactivationInProgress: true});
  var accountClone = this.state.account;
  return actions.accounts.reactivateAccount(id).then((data) => {
    accountClone.accountStatus = data['accountStatus'];
    this.setState({account: accountClone, reactivationInProgress: false});
    }).catch(() => {
        this.setState({reactivationInProgress: false});
    })
 }

  renderHsaAvailableBalance(hsaodMinimal){
    if( hsaodMinimal.account_status === 'Active' && Date.parse(hsaodMinimal.expire_date) > Date.now() ){
      let availableBalance = 0;
      if( hsaodMinimal.hsaodAvailableBalance > 0){
        availableBalance = hsaodMinimal.hsaodAvailableBalance
      }
      return <div className='col'>
         {this.renderTitleLabel("HSA Advance Available", "$"+Number(availableBalance).toFixed(2).toString())}
      </div>;
    }
  }

  render () {
    if (this.state.loading) return <div className='margin-top'><Loading/></div>
    const {
      firstName,
      lastName,
      birthDate,
      ssn,
      accountNumber,
      employeeId,
      defaultAddress,
      phoneNumber,
      externalBankAccountId,
      accountStatus,
      availableBalance,
      currentBalance,
      hsaodMinimal,
      investmentBalance,
      savingsAccountMinimal
    } = this.state.account || {}
    //this.updateisCaseAlreadyOpen();
    return (
      <div className='page email-template-view'>
        <h4>{`${firstName}'s Account`}</h4>
        <Card className='padding'>
          <div className='row'>
            <div className='col'>
              {this.renderTitleLabel("Account Status", accountStatus)}
            </div>
            <div className='col'>
              {this.renderTitleLabel("Available Balance", "$"+Number(availableBalance).toFixed(2))}
            </div>
            <div className='col'>
              {this.renderTitleLabel("Current Balance", "$"+Number(currentBalance).toFixed(2))}
            </div>
              {this.renderHsaAvailableBalance(hsaodMinimal)}
            <div className='col'>
              {this.renderTitleLabel("Investment Balance", "$"+Number(investmentBalance).toFixed(2))}
            </div>
            <div className='col'>
              {this.renderTitleLabel("HSA Custodian", this.state.hsaProcessorName)}
            </div>
            <div style={{paddingRight: '.75em'}}>
          {this.renderActionsMenu(accountStatus)}
            </div>
          </div>
          <div style={{paddingBottom: '1.5em'}} className='row'>
            <span>{"   "}</span>
          </div>
          <h5>Details</h5>
          <div className='row'>
            <div className='col'>
              <TextField
                label='First Name'
                name='firstName'
                value={firstName}
              />
            </div>
            <div className='col'>
              <TextField
                label='Last Name'
                name='lastName'
                value={lastName}
              />
            </div>
          </div>

          <div className='row margin-top'>
            <div className='col'>
              <TextField
                label='SSN'
                name='ssn'
                value={ssn}
              />
            </div>
            <div className='col'>
              <TextField
                label='Account Number'
                name='accountNumber'
                value={accountNumber}
              />
            </div>
            <div className='col'>
              <TextField
                label='Ext. Account Id'
                name='externalBankAccountId'
                value={externalBankAccountId}
              />
            </div>
            <div className='col'>
              <TextField
                label='Alternate ID'
                name='alternateId'
                value={employeeId.toString()}
              />
            </div>
            <div className='col'>
              <TextField
                  label='Job ID'
                  name='jobId'
                  value={savingsAccountMinimal.jobMinimal.jobId}
              />
            </div>
          </div>

          <div className='row margin-top'>
            <div className='col'>
              <TextField
                label='DOB'
                name='birthDate'
                value={birthDate}
              />
            </div>
            <div className='col'>
              <TextField
                label='Phone Number'
                name='phoneNumber'
                value={phoneNumber}
              />
            </div>

          </div>

          {defaultAddress &&
            <div className='row margin-top'>
              <div className='col'>
                <TextField
                  label='Address'
                  name='address'
                  value={`${defaultAddress.streetAddress1} ${defaultAddress.streetAddress2 || ''}`}
                />
              </div>
              <div className='col'>
                <TextField
                  label='City'
                  name='city'
                  value={defaultAddress.city}
                />
              </div>
              <div className='col'>
                <TextField
                  label='State'
                  name='state'
                  value={defaultAddress.state}
                />
              </div>
              <div className='col'>
                <TextField
                  label='Zip'
                  name='zipcode'
                  value={defaultAddress.zipcode}
                />
              </div>
            </div>
          }
          <div className='row margin-top'>
            <div className='col'>
              <TextField
                  label='Employer Id'
                  name='employerId'
                  value={savingsAccountMinimal.jobMinimal.employerId}
              />
            </div>
            <div className='col'>
              <TextField
                  label='Employer Name'
                  name='employerName'
                  value={savingsAccountMinimal.employerMinimal.name}
              />
            </div>
          </div>
          <div className='row margin-top'>
            <div className='col'>
              <TextField
                  label='Sponsor Id'
                  name='sponsorId'
                  value={getSponsorIdFromList(savingsAccountMinimal.hierarchyMinimalList)}
              />
            </div>
            <div className='col'>
              <TextField
                  label='Sponsor Name'
                  name='sponsorName'
                  value={getSponsorNameFromList(savingsAccountMinimal.hierarchyMinimalList)}
              />
            </div>
          </div>
          <div className='row margin-top'>
            <div className='col'>
              <TextField
                  label='Distributor Id'
                  name='distributorId'
                  value={getDistributorIdFromList(savingsAccountMinimal.hierarchyMinimalList)}
              />
            </div>
            <div className='col'>
              <TextField
                  label='Distributor Name'
                  name='distributorName'
                  value={getDistributorNameFromList(savingsAccountMinimal.hierarchyMinimalList)}
              />
            </div>
          </div>
        </Card>

        {this.renderTabs()}
        {this.renderTabContent()}

        {this.renderConfirmDialog()}
        {this.renderClosureDialog()}
        {this.renderChangeDialog()}
      </div>
    )
  }
}

export default Account
