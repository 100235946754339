import React from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'
import { Cookies } from 'react-cookie';
import jwtDecode from 'jwt-decode'
import {SideNav, SideNavItem} from '../compositions/navigation/SideNav/index'
import { updateAuth } from '../redux/utility/SessionUtil';

const navItems = [
  {
    icon: () => <i className="material-icons">supervisor_account</i>,
    label: 'Account Maintenance',
    route: '/nbtadmin/accounts',
    navItems: []
  },
  {
    icon: () => <i className="material-icons">supervisor_account</i>,
    label: 'Enrollments',
    route: '/nbtadmin/enrollments',
    navItems: []
  },
  {
    icon: () => <i className="material-icons">verified_user</i>,
    label: 'Statement Approval',
    route: '/nbtadmin/statement-approval',
    navItems: []
  },
  {
    icon: () => <i className="material-icons">sync</i>,
    label: 'BAI Recon',
    route: '/nbtadmin/bai-recon',
    navItems: []
  },
  {
    icon: () => <i className="material-icons">attach_money</i>,
    label: 'Transaction Upload',
    route: '/nbtadmin/transaction-upload',
    navItems: []
  },
  {
    icon: () => <i className="material-icons">file_upload</i>,
    label: 'Beneficiary Upload',
    route: '/nbtadmin/beneficiary-upload',
    navItems: []
  },
  {
    icon: () => <i className="material-icons">supervisor_account</i>,
    label: 'Account Escheatment',
    route: '/nbtadmin/account-escheatment',
    navItems: []
  },
  {
    icon: () => <i className="material-icons">attach_money</i>,
    label: 'Batch Wire Records',
    route: '/nbtadmin/batch-wires',
    navItems: []
  },
  {
    icon: () => <i className="material-icons">business</i>,
    label: 'Hierarchies',
    route: '/nbtadmin/hierarchies',
    navItems: []
  },
  {
    icon: () => <i className="material-icons">assignment_ind</i>,
    label: 'Interest Master', //Interest Profiles
    route: '/nbtadmin/interest-profiles',
    navItems: []
  },
  {
    icon: () => <i className="material-icons">import_contacts</i>,
    label: 'Expedited CIP Upload',
    route: '/nbtadmin/expedited-cip-upload',
    navItems: []
  },
  {
    icon: () => <i className="material-icons">list</i>,
    label: 'Reporting',
    route: '/nbtadmin/employer-billing-report',
    navItems: [
      {
        label: 'Employer Billing Report',
        route: '/nbtadmin/employer-billing-report'
      },
      {
        label: 'Employee Billing Report',
        route: '/nbtadmin/employee-billing-report'
      },
      {
        label: 'ER Summary Billing Report',
        route: '/nbtadmin/er-summary-billing-report'
      },
      {
          label: 'Mail Correspondence',
          route: '/nbtadmin/mail-correspondence-report'
        }
    ]
  },
  {
    icon: () => <i className="material-icons">import_contacts</i>,
    label: 'Notification Requeue',
    route: '/nbtadmin/notification-requeue',
    navItems: []
  },
  {
    icon: () => <i className="material-icons">account_balance</i>,
    label: "Bulk Transfer Wizard",
    route: '/nbtadmin/btw/create',
    navItems: [
      {
        label: 'Create Bulk Transfer',
        route: '/nbtadmin/btw/create'
      },
      {
        label: 'Upload TOA Data',
        route: '/nbtadmin/btw/upload-data'
      },
      {
        label: 'TOA Posting Exceptions',
        route: '/nbtadmin/btw/toa-exceptions'
      },
      {
        label: 'Unenrolled Transfers',
        route: '/nbtadmin/btw/unenrolled-transfers'
      }
    ]
  },
  {
    icon: () => <i className="material-icons">notes</i>,
    label: "Transfer Consent",
    route: '/nbtadmin/custodian-consent-display',
    navItems: [
      {
        label: 'Upload Consent Data',
        route: '/nbtadmin/custodian-consent/upload-data'
      },
      {
        label: 'Consent Upload Status',
        route: '/nbtadmin/custodian-consent/upload-status'
      },
      {
        label: 'Export Consent Data',
        route: '/nbtadmin/custodian-consent/export-data'
      }
    ]
  },
  {
    icon: () => <i className="material-icons">attach_money</i>,
    label: 'Manage Fees',
    route: '/nbtadmin/fees',

    navItems: [
      {
        label: 'Fees Master', //Fees
        route: '/nbtadmin/fees'
      },
      {
        label: 'Fee Profiles',
        route: '/nbtadmin/fee-profiles'
      }
    ]
  },
  {
    icon: () => <i className="material-icons">event_note</i>,
    label: 'Notification Templates',
    route: '/nbtadmin/email-templates',
    navItems: [
      {
        label: 'Email Templates',
        route: '/nbtadmin/email-templates',
      },
      {
        label: 'Letter Templates',
        route: '/nbtadmin/mail-templates',
      },
      {
        label: 'Disclaimers',
        route: '/nbtadmin/disclaimers',
      }
    ]
  },
  {
    icon: () => <i className="material-icons">security</i>,
    label: 'Fraud & Risk Rule Master',
    route: '/nbtadmin/fraud-rules',
    navItems: [
    ]
  },
  {
    icon: () => <i className="material-icons">build</i>,
    label: 'HSAX ER Setup',
    route: '/nbtadmin/hsax-setup',
    navItems: [
    ]
  },
  {
    icon: () => <i className="material-icons">settings</i>,
    label: 'Other Configurations', //Settings
    route: '/nbtadmin/other-configurations',
    navItems: [
    ]
  },
  {
    icon: () => <i className="material-icons">pages</i>,
    label: "Terms and Conditions",
    route: '/nbtadmin/terms-and-conditions',
    navItems: []
  },
  {
    icon: () => <i className="material-icons">list</i>,
    label: "Dead Letter",
    route: '/nbtadmin/dead-letter',
    navItems: []
  },
  {
    icon: () => false,
    label: "AML Rule Master",
    route: '/nbtadmin/aml',
    navItems: []
  },
  {
    icon: () => false,
    label: "CIP Follow Up Rules",
    route: '/nbtadmin/fup',
    navItems: []
  }
]

class AdminLayout extends React.Component {
  constructor (props) {
    super (props)
    this.cookie = new Cookies()
  }

  componentWillMount () {
    const authToken = this.cookie.get('jwt_access_token', {domain: '.connectyourcare.net' })
    const refreshToken = this.cookie.get('jwt_refresh_token', {domain: '.connectyourcare.net' })

    if (authToken && refreshToken) {
      const decodedJWT = jwtDecode(authToken);
      updateAuth({
        authToken: authToken,
        refreshToken: refreshToken,
        username: decodedJWT.sub,
        roles: decodedJWT.roles,
        expires: decodedJWT.exp * 1000
      });
    
    } else {
      if (!process.env.REACT_APP_DEV) {
        window.location = `${process.env.REACT_APP_PORTAL_DOMAIN}`
      }
    }
  }

  renderSideNav() {
    const {history} = this.props
    return (
      <SideNav>
        {navItems.map((item, index) => {
          const active = history.location.pathname.includes(item.route)
            || (item.navItems && (item.navItems.findIndex(m => history.location.pathname.includes(m.route)) > 0))
          const className = active ? 'active' : ''
          return (
            <SideNavItem
              className={className}
              icon={item.icon && item.icon()}
              key={`nav-item-${index}`}
              label={item.label}
              onClick={() => history.push(item.route)}
            >
              {this.renderSubNavItems(item)}
            </SideNavItem>
          )
        })}
      </SideNav>
    )
  }

  /*
  * Render sub nav menu items.
  */
  renderSubNavItems (item) {
    const {history} = this.props
    return item.navItems.map((subItem, index) => {
      const active = history.location.pathname.includes(subItem.route)
      const className = active ? 'active' : ''

      return (
        <SideNavItem
          className={`sub-level-item ${className}`}
          key={`sub-nav-item-${index}`}
          label={subItem.label}
          onClick={() => { history.push(subItem.route) }}
        />
      )
    })
  }

  render () {
    const {children} = this.props
    return (
      <div className="side-nav-layout">
        {this.renderSideNav()}
        <div className='side-nav-content'>
          {children}
        </div>
      </div>
    )
  }
}

AdminLayout.propTypes = {
  children: PropTypes.node
}

export default withRouter(AdminLayout)
