import React from 'react'
import PropTypes from 'prop-types'
import Field from 'components/forms/Field/index'
import Modal from 'components/Modal'

import account from 'data/account'

class ClosureDialog extends React.Component {
  constructor (props) {
    super (props)
    this.state = {
      form: this.initializeFormData()
    }
  }
  /*
  * Initialize form data
  */
  initializeFormData () {

    let defaultValue = "";
    for (let key in this.getAllocationOptions()) {
      if(!this.getAllocationOptions()[key].disabled) {
        defaultValue = this.getAllocationOptions()[key].name;
        break;
      }
    }
    return {
      bankAccountId: this.props.account.id,
      allocationOfFundsMethod: this.props.account.currentBalance === 0 ? 'Participant Has 0 Balance in Account' : defaultValue,
      reasonForClosure: null,
      otherReasonForClosureDescription: null
    }

  }

  /*
  * Render the fields
  */
  getFields () {
    const {form: {
      allocationOfFundsMethod,
      reasonForClosure,
      otherReasonForClosure
    }} = this.state

    const {fields} = account.us

    let f = [
      {
        ...fields.allocationOfFundsMethod,
        required: true,
        data: this.getAllocationOptions(),
        events: {
          onChange: (event) => {
            this.onChange(fields.allocationOfFundsMethod.name, event.target.value)
          }
        },
        value: allocationOfFundsMethod
      }]
      f.push(
      {
        ...fields.reasonForClosure,
        required: true,
        events: {
          onChange: (event) => {
            this.onChange(fields.reasonForClosure.name, event.target.value)
          }
        },
        value: reasonForClosure == null ? Object.keys(fields.reasonForClosure.data)[0] : reasonForClosure
      })

      if(allocationOfFundsMethod === 'Penny Return') {
          f.pop()
          f.push(
              {
                  ...fields.reasonForClosureForPennyFunding,
                  required: true,
                  events: {
                      onChange: (event) => {
                          this.onChange(fields.reasonForClosure.name, event.target.value)
                      }
                  },
                  value: reasonForClosure == null ? Object.keys(fields.reasonForClosureForPennyFunding.data)[0] : reasonForClosure
              })
      }

    if(allocationOfFundsMethod === 'Participant Has 0 Balance in Account' ||
        allocationOfFundsMethod === 'Auto-Close Account - No funds Returned'){
      f.pop()
      f.push(
          {
            ...fields.reasonForClosureZeroBalance,
            required: true,
            events: {
              onChange: (event) => {
                this.onChange(fields.reasonForClosure.name, event.target.value)
              }
            },
            value: reasonForClosure == null ? Object.keys(fields.reasonForClosureZeroBalance.data)[0] : reasonForClosure
          })
    }

    if (reasonForClosure === 'Other')
    f.push(
      {
        ...fields.otherReasonForClosureDescription,
        required: false,
        attrs: {
          maxLength: 255
        },
        events: {
          onChange: (event) => {
            this.onChange(fields.otherReasonForClosureDescription.name, event.target.value)
          }
        },
        value: otherReasonForClosure
      }
    )

    return f
  }

  getAllocationOptions () {
    let options = {
      'Electronic Funds Transfer (EFT)': {
        name: 'Electronic Funds Transfer (EFT)',
        disabled: this.props.account.currentBalance === 0
      },
      'Send Check By Mail to Mailing Address on File': {
        name: 'Send Check By Mail to Mailing Address on File',
        disabled: this.props.account.currentBalance === 0
      },
      'Participant Has 0 Balance in Account': {
        name: 'Participant Has 0 Balance in Account',
        disabled: this.props.account.currentBalance !== 0
      },
      'Penny Return': {
        name: 'Penny Return',
        disabled: this.props.account.currentBalance !== 0.01
       },
      'Send Check By Mail to New Mailing Address': {
        name: 'Send Check By Mail to New Mailing Address',
        disabled: this.props.account.currentBalance === 0
      },
      'Auto-Close Account - No funds Returned': {
        name: 'Auto-Close Account - No funds Returned'
      }
    }

    return options
  }

  /*
  * Update form values
  *
  * @param [String] name. Field name of form to update.
  * @param [Any] value. Value.
  */
  onChange (name, value) {
    let form = {...this.state.form}
    form[name] = value
    this.setState({form})
  }

  /**
   * On submit
   */
  handleSubmit(event) {
    event.preventDefault()
    // actions.accounts.closeAccount(this.state.form).then((data) => {
    //   this.props.onCloseAccount(data)
    // }).catch(() => false)
  }

  /**
   * On close
   */
  onClose () {
    this.props.onClose()
  }

  render () {
    return (
      <Modal
        header={'Reason for closing?'}
        onClose={() => this.props.onClose()}
      >
        <div className='modal-body'>
          <div className='margin-top margin-bottom'>
            {this.getFields().map((f, idx) => {
              return <Field key={`${f.name}-${idx}`} {...f} required={true} />
            })}
          </div>

        </div>
        <div className='modal-footer'>
          <div className='modal-actions'>
            <button
              className='button primary-button'
              onClick={() => this.props.onConfirm(this.state.form)}
            >Yes</button>
            <button
              className='button secondary-button'
              onClick={() => this.onClose()}
            >No</button>
          </div>
        </div>
      </Modal>
    )
  }
}

ClosureDialog.propTypes = {
  bankAccountId: PropTypes.string
}

export default ClosureDialog
