import React from 'react'
import {Tabs, Tab} from 'components/Tabs'
import BeneficiaryUpload from './BeneficiaryUpload'
import BeneficiaryReport from './BeneficiaryReport'

class Beneficiary extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
          tabIndex: 0
        }
    }

    renderTabs() {
        return (
            <Tabs
              className='margin-top margin-bottom'
              currentIndex={this.state.tabIndex}
              onChange={(index) => {
                this.setState({tabIndex: index})
              }}
            >
              <Tab name='Upload' />
              <Tab name='View Report' />
            </Tabs>
          )
    }

    renderTabContent() {
        switch (this.state.tabIndex) {
            case 0:
              return (
                <BeneficiaryUpload
                />
              )
            case 1:
              return (
                <BeneficiaryReport
                />
              )
            default:
              return <div>This section is coming soon.</div>
          }
    }

    render() {
        return (
        <div className='page upload-toa-view'>
            <h3>Beneficiary Upload</h3>
            {this.renderTabs()}
            {this.renderTabContent()}
        </div>
        )
    }
}

export default Beneficiary