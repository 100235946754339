import Fetch from 'lib/fetch'

/*
* Get transactions for account by id
*
* @param [Number] id. Id of account to get transactions for.
*/
export function getTransactionsByAccountId (id) {
  return Fetch.get(`${process.env.REACT_APP_ACCOUNTS_DOMAIN}/accounts/${id}/transactions`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

/*
* Get transaction types
*/
export function getTransactionTypes () {
  return Fetch.get(`${process.env.REACT_APP_ACCOUNTS_DOMAIN}/accounts/getAllTransactionTypes`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

/*
* Add transactions for account by id
*
* @param [Number] id. Id of account to add transactions for.
*/
export function addTransactionByAccountId (form) {
  return Fetch.post(`${process.env.REACT_APP_ACCOUNTS_DOMAIN}/cyc-accounts/${form.bankAccountId}/transactions/loader`, form, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

/*
* Update Posting status to Settled then suppressed
*
* @param [Number] id. transaction id .
*/
export function updatePostingStatusByTransactionId (transactionId) {
  return Fetch.post(`${process.env.REACT_APP_ACCOUNTS_DOMAIN}/transactions/suppress/${transactionId}`)
    .then(data => data)
    .catch(error => Promise.reject(error))
}