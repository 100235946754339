const toa = {
  us: {
    fields: {
      custodianId: {
        name: 'custodianId',
        type: 'dropdown',
        label: "Choose Prior Custodian",
        placeholder: 'Custodian',
      },
      otherCustodian: {
        name: 'otherCustodian',
        label: 'Other Custodian',
        placeholder: 'Other Custodian'
      },
      employerId: {
        name: 'employerId',
        label: 'Choose Employer',
        placeholder: 'Choose Employer'
      },
      totalCash: {
        name: 'totalCash',
        label: 'Enter Total Cash Balance Transfer Amount',
        placeholder: 'Cash Balance'
      },
      hasConsent: {
    	  name: 'hasConsent',
    	  label: 'TOA Data will be used for consent from Prior Custodian',
    	  type: 'checkbox'
      },
      sponsorBankName: {
        name: 'sponsorBankName',
        type: 'dropdown',
    	  label: 'Choose Omnibus',
    	  placeholder: 'Choose Omnibus'
      }
    },
    employerIsMultiple : {
      name: 'isMultiple',
        label: 'Employer Choice',
        type: 'dropdown',
        data: {
          false : {
            name: 'Single Employer',
            disabled: false
          },
          true : {
            name: 'Multiple Employer',
            disabled: false
          }
        }
    }
  }
}

export default toa
