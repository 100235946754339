import Fetch from 'lib/fetch'
import { showSnackbar } from '../redux/utility/SnackbarUtil'

/*
* Get all interest profiles.
*/
export function getInterestProfiles () {
  return Fetch.get(`${process.env.REACT_APP_INTEREST_PROFILE_DOMAIN}/interest-profiles`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

/*
* Get interest profile by id.
*
* @param [Number] id. Id of interest profile to get.
*/
export function getInterestProfileById (id) {
  return Fetch.get(`${process.env.REACT_APP_INTEREST_PROFILE_DOMAIN}/interest-profiles/${id}`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

/*
* Create an interest profile.
*
* @param [Object] form. Interest profile.
*/
export function createInterestProfile (form) {
  return Fetch.post(`${process.env.REACT_APP_INTEREST_PROFILE_DOMAIN}/interest-profiles`, form, null)
    .then(data => {
      showSnackbar('Interest profile successfully created', 'info')
      return data
    })
    .catch(error => Promise.reject(error))
}

/*
* Update an interest profile.
*
* @param [Object] form. Interest profile.
*/
export function updateInterestProfile (form) {
  return Fetch.put(`${process.env.REACT_APP_INTEREST_PROFILE_DOMAIN}/interest-profiles/${form.id}`, form, null)
    .then(data => {
      showSnackbar('Interest profile successfully updated', 'info')
      return data
    })
    .catch(error => Promise.reject(error))
}

/*
* Deactivate interest proifle
*
* @param [Object] profile. Interest profile to deactivate
*/
export function deactivateInterestProfile (profile) {
  let form = profile
  form.activeDate = null
  return updateInterestProfile(form)
    .then(data => {
      showSnackbar('Interest profile deactivated.', 'info')
      return data
    }).catch(error => Promise.reject(error))
}

/*
* Attach interest profile to hierachy.
*
* @param [Object] form. Add interest form.
*/
export function createInterestProfileHierarchy (form) {
  return Fetch.post(`${process.env.REACT_APP_INTEREST_PROFILE_DOMAIN}/interest-profiles/hierarchies`, form, null)
    .then(data => {
      showSnackbar('Interest profile hierarchy succesfully created', 'info')
      return data
    })
    .catch(error => Promise.reject(error))
}

/*
* Remove interest profile from hierarchy.
*
* @param [Object] form. Interest profile form.
*/
export function updateInterestProfileHierarchy (form) {
  return Fetch.put(`${process.env.REACT_APP_INTEREST_PROFILE_DOMAIN}/interest-profiles/hierarchies/${form.id}`, form, null)
    .then(data => {
      showSnackbar('Interest profile hierarchy succesfully updated', 'info')
      return data
    })
    .catch(error => Promise.reject(error))
}

/*
* Fetch interest profiles for hierarchy.
*
* @param [Number] hierarchyId. Id of hierach to fetch interest profiles.
*/
export function getInterestProfilesForHierarchy(hierarchyId) {
  return Fetch.get(`${process.env.REACT_APP_INTEREST_PROFILE_DOMAIN}/interest-profiles/hierarchies/${hierarchyId}`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

/*
* Get interest profile count of instances by id
*
*/
export function getInterestProfileCountById (id) {
  return Fetch.get(`${process.env.REACT_APP_INTEREST_PROFILE_DOMAIN}/interest-profiles/${id}/hierarchies/count`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

/* get interest instances by Id 
* param [interestProfileId]
*/
export function getInterestInstancesById (id) {
  return Fetch.get(`${process.env.REACT_APP_INTEREST_PROFILE_DOMAIN}/interest-profiles/${id}/hierarchies`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}
