import React from "react";
import TableView from "compositions/TableDisplay/TableView";
import actions from "actions";
import {mapDateToSortable} from "lib/date";
import {filterDataSource} from "lib/filter";
import {sortDataSource} from "lib/sort";
import Loading from "components/Loading";
import Modal from "components/Modal";
import AddTransaction from "./AddTransaction";
import dayjs from "dayjs";

const headers = [
    {
        filterText: "Transaction ID",
        header: () => "Transaction ID",
        key: "transactionId",
        enableSort: true,
    },
    {
        filterText: "Modified Date",
        header: () => "Modified Date",
        key: "modifiedDate",
        enableSort: true,
    },
    {
        filterText: "Transaction Code",
        header: () => "Transaction Code",
        key: "transactionCode",
        enableSort: true,
    },
    {
        filterText: "Posting Status",
        header: () => "Posting Status",
        key: "postingStatus",
        enableSort: true,
    },
    {
        filterText: "Change Status",
        header: () => "Change Status",
        key: "suppressStatus",
        enableSort: true,
    },
    {
        filterText: "Amount",
        header: () => "Amount",
        key: "amount",
        enableSort: true,
    },
    {
        filterText: 'Description',
        header: () => 'Description',
        key: 'description',
        enableSort: true,
    },
    {
        filterText: 'Lockbox Image',
        header: () => 'Lockbox Image',
        key: 'lockboxDepositId',
        enableSort: true,
    },
]

class Transactions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            filteredDataSource: [],
            openAddTransactionModal: false,
            openSuppressModal: false,
            transactionTypes: {},
            initialSortLabel: "Modified Date",
            initialSortDirection: "desc",
        };

        this.actions = [];
    }

    /*
    * Get all transactions
    */
    componentWillMount() {
        this.getTransactions()
        this.getTransactionTypes()
        this.getAccountSavingsId()
    }

    getAccountSavingsId() {
        actions.accounts.getAccountById(this.props.accountId).then(account => {
            this.setState({savingsAccountId: account.savingsAccountMinimal.accountId})
        })
    }

    /*
    * Verify that the user has access to transactions
    */
    verifyAccess() {
        // if (!(this.state.readAccess || this.state.writeAccess)) {
        //   this.props.history.push('/nbtadmin/unauthorized')
        //   return false
        // }

        return true;
    }

    imageResults(id) {
        return actions.lockboxImage.getImageUrlByLockboxDepositId(id)
            .then(data => {
                return window.open(data.imageFile, '_blank')
            })
    }

    /*
    * Fetch transactions
    */
    getTransactions() {
        this.setState({loading: true})
        const results = {}
        return actions.transactions.getTransactionsByAccountId(this.props.accountId).then(
            transactions => {
                const mappedTransactions = transactions.map(transaction => {
                    const {
                        id, transactionId, transactionCode, amount,
                        description, modifiedDate, postingStatus
                    } = transaction
                    if (transaction.lockboxDepositId !== null) {
                        return {
                            id,
                            transactionId,
                            transactionCode,
                            description,
                            amount: amount ? amount : '',
                            modifiedDate: modifiedDate ? mapDateToSortable(modifiedDate) : '',
                            postingStatus: postingStatus,
                            suppressStatus: postingStatus === 'Posted as Settled' ? this.suppressButton(transaction.transactionId) : "",
                            lockboxDepositId: <a onClick={() => this.imageResults(transaction.lockboxDepositId)}
                                                 className="material-icons orange" title="Lockbox Image">image</a>
                        }
                    } else {
                        return {
                            id,
                            transactionId,
                            transactionCode,
                            description,
                            amount: amount ? amount : '',
                            modifiedDate: modifiedDate ? mapDateToSortable(modifiedDate) : "",
                            postingStatus: postingStatus,
                            suppressStatus: postingStatus === 'Posted as Settled' ? this.suppressButton(transaction.transactionId) : "",
                            lockboxDepositId: ''
                        }
                    }
                }).sort((a, b) => b.transactionId - a.transactionId)
                results.transactions = mappedTransactions;
                this.setState({
                    dataSource: results.transactions,
                    filteredDataSource: results.transactions,
                    loading: false,
                })
            }).catch(err => console.log(err))
    }

    /*
    * Filter data source.
    *
    * @param [Object] queryParams. Key value pair containing filters.
    */
    onFilter(queryParams, sortableRefs) {
        const {dataSource} = this.state;
        const newDataSource = filterDataSource(dataSource, queryParams);
        const sortedDataSource = sortDataSource(newDataSource, queryParams, sortableRefs)
        this.setState({filteredDataSource: sortedDataSource});
    }

    onSort(queryParams, sortableRefs) {
        const {filteredDataSource} = this.state
        const sortedDataSource = sortDataSource(filteredDataSource, queryParams, sortableRefs)
        this.setState({filteredDataSource: sortedDataSource})
    }

    openModal() {
        if (this.props.writeAccess === false) {
            this.props.history.push('/nbtadmin/unauthorized')
        } else {
            this.setState({openAddTransactionModal: true});
        }
    }

    onCloseAddTransaction() {
        this.setState({openAddTransactionModal: false});
    }

    onCloseSuppressionModal() {
        this.setState({openSuppressModal: false});
    }

    /*
     * get all transaction types
     */
    getTransactionTypes() {
        actions.transactions.getTransactionTypes().then((types) => {
            let transactionTypes = {}
            let allTypes = types.sort(function (a, b) {return a.description.localeCompare(b.description); })

            allTypes.forEach(e => {
                transactionTypes["a" + e.transactionCode] = e.description + " - " + e.transactionCode
            })
            this.setState({transactionTypes})
        })
    }

    /*
     * instead of doing a get request to put the new data into the table, just move it into state and update the row that way
     */
    mapNewStateToDisplay(newData) {
        const {amount, transactionCode, postingStatus, description, id} = newData.transaction
        return {
            amount: '$' + amount,
            transactionCode,
            bankAccountId: this.props.accountId,
            postingStatus,
            description,
            transactionId: id,
            modifiedDate: dayjs().format('MM/DD/YYYY')
        }
    }

    onSubmit(form) {
        actions.transactions.addTransactionByAccountId(form).then((data) => {
            const updatedData = [...this.state.filteredDataSource, this.mapNewStateToDisplay(data)];
            this.setState({filteredDataSource: updatedData, submitting: false})
        }).catch(() => this.setState({submitting: false}))
        this.onCloseAddTransaction()
    }

    suppressButton(id) {

        return (<div>
            <button className="button primary-button" onClick={() => {
                this.setState({openSuppressModal: true, transactionId: id})
            }}>
                Suppress
            </button>
        </div>)
    }

    handleSuppressButton(id) {
        return actions.transactions.updatePostingStatusByTransactionId(id).then(() => {
            this.getTransactions()
            this.setState({openSuppressModal: false})
        }).catch(err => console.log(err))
    }

    /*
    * Open add transaction modal
    */
    renderSuppressModal() {
        const {openSuppressModal} = this.state;
        if (openSuppressModal) {
            return (
                <Modal
                    header={`Are you sure you want to suppress transaction : ${this.state.transactionId} ?`}
                    onClose={this.onCloseSuppressionModal.bind(this)}
                    showClose={true}
                >
                    <button className="button primary-button"
                            onClick={() => this.handleSuppressButton(this.state.transactionId)}>YES
                    </button>
                </Modal>
            );
        }
    }

    /*
      * Open add transaction modal
      */
    renderAddTransactionModal() {
        const {openAddTransactionModal} = this.state;
        if (openAddTransactionModal) {
            return (
                <Modal
                    header={"Add Transaction"}
                    onClose={this.onCloseAddTransaction.bind(this)}
                    showClose={true}
                    account={this.state.dataSource}
                >
                    <AddTransaction
                        transactionTypes={this.state.transactionTypes}
                        onSubmit={this.onSubmit.bind(this)}
                        savingsAccountId={this.state.savingsAccountId}
                        {...this.props}/>
                </Modal>
            );
        }
    }

    render() {
        if (this.state.loading)
            return (
                <div>
                    <Loading/>
                </div>
            );

        const {filteredDataSource} = this.state;
        return (
            <div>
                <div className="padding">
                    <div className="row">
                        <div className="col">
                            <h5>Transactions</h5>
                        </div>
                        {this.props.writeAccess === true &&
                            <div className="text-right">
                                <div className="col">
                                    <a onClick={this.openModal.bind(this)}>
                                        <i
                                            className="material-icons orange"
                                            title="Add New Transaction"
                                        >
                                            add_circle_outline
                                        </i>
                                    </a>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="margin-top"/>
                    <TableView
                        dataActions={this.actions}
                        dataSource={filteredDataSource}
                        disableFilters={false}
                        tableHeaders={headers}
                        tableFilters={headers}
                        onFilter={this.onFilter.bind(this)}
                        onSort={this.onSort.bind(this)}
                        initialSortLabel={this.state.initialSortLabel}
                        initialSortDirection={this.state.initialSortDirection}
                    />
                </div>
                {this.renderAddTransactionModal()}
                {this.renderSuppressModal()}
            </div>
        );
    }
}

export default Transactions;
