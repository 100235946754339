const termsAndConditionsSetup = {
  us: {
    fields: {
      name: {
        name: 'name',
        label: 'Name',
        placeholder: 'Name'
      },
      pdfName: {
        name: 'pdfName',
        label: 'PDF Name',
        placeholder: 'PDF Name',
      },
      type: {
        name: 'type',
        label: 'Choose Type',
        placeholder: 'Choose Type',
        type: 'dropdown',
        data: {
          'PRIVACY_AGREEMENT': {
            name: 'Privacy Policy',
            disabled: false
          },
          'FEE_AGREEMENT': {
            name: 'Fee Schedule',
            disabled: false
          },
          'ESIGN_AGREEMENT': {
            name: 'Esign',
            disabled: false
          },
          'BANKING_AGREEMENT': {
            name: 'Custodial Agreement',
            disabled: false
          }
        }
      },
    }
  },
  edit: {
    pdfName: {
      name: 'pdfName',
      label: 'PDF Name',
      disabled: true
    },
    type: {
      name: 'type',
      label: 'Type',
      disabled: true
    },
    uploadDate: {
      name: 'uploadDate',
      label: 'Upload Date',
      disabled: true
    },
    modifiedDate: {
      name: 'modifiedDate',
      label: 'Modified Date',
      disabled: true
    },
    active: {
      name: 'isActive',
      label: 'Active',
      type: 'dropdown',
      placeholder: 'Yes',
      data: {
        'Yes': {
          name: 'Yes',
          disabled: false
        },
        'No': {
          name: 'No',
          disabled: false
        },
      }
    },
    deactivationDate: {
      name: 'deactivationDate',
      label: 'Deactivation Date',
      disabled: true
    }
  },
  hierarchy: {
    policyAgreement: {
      name: 'POLICY_AGREEMENT',
      label: 'Privacy Policy',
      type: 'dropdown'
    },
    feeAgreement: {
      name: 'FEE_AGREEMENT',
      label: 'Fee Schedule',
      type: 'dropdown'
    },
    esignAgreement: {
      name: 'ESIGN_AGREEMENT',
      label: 'Esign',
      type: 'dropdown'
    },
    bankingAgreement: {
      name: 'BANKING_AGREEMENT',
      label: 'Custodial Agreement',
      type: 'dropdown'
    },
  }
}

export default termsAndConditionsSetup
