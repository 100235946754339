import React from "react";
import TableView from "compositions/TableDisplay/TableView";
import actions from 'actions'
import Loading from 'components/Loading'

const headers = [
    {
      filterText: "Profile Name",
      header: () => "Profile Name",
      key: "profileName"
    },
    {
      filterText: "Hierarchy Name",
      header: () => "Hierarchy Name",
      key: "name"
    },
    {
      filterText: "Hierarchy Level",
      header: () => "Hierarchy Level",
      key: "hierarchyType"
    }
  ]

  const levels = {
    7300: "Master",
    7301: "Sponsor",
    7302: "Distributor",
    7303: "Employer",
    7304: "Subgroup"
  }

class Instances extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          loading: false,
          submitting: false,
          openConfirmDialog: false,
          dataSource: []
        }
        this.actions = [];
      }

      getInstancesById(id) {
        this.setState({ loading: true })
          actions.interestProfiles.getInterestInstancesById(id).then(profile => {
          let newProfile = (profile.filter(interestProfile => interestProfile.hierarchyInterestProfile.assigned === true)).map(data => {
            return {
              profileName: this.props.profile.name,
              name: data.hierarchyDetails.name,
              hierarchyType: levels[data.hierarchyDetails.hierarchyType]
            }
          })
          this.setState({ dataSource: newProfile, loading: false })
        })
      }


      componentWillMount() {
        this.getInstancesById(this.props.profile.id)
      }

  render() {
    if (this.state.loading) return <div className='margin-top'><Loading/></div>
    return (
      <div style={{overflowY:'auto'}}>
        <TableView
            dataActions={this.actions}
            dataSource={this.state.dataSource}
            tableHeaders={headers}
            tableFilters={headers}
            disableFilters={true}
            />
      </div>
    );
  }
}

export default Instances;
