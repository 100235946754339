import React from 'react'
import PropTypes from 'prop-types'

import {FlexTable, TableData, TableRow} from '../../components/FlexTable'
import {Tabs} from '../../components/Tabs'
import Filter from './Filter'
import Sortable from "./Sortable";

class TableView extends React.Component {
  constructor (props) {
    super (props)
    this.filterRefs = []
    this.sortableRefs = []
  }
  /*
  * Get the table headers for the table.
  * Merge headers and actions
  */
  getHeaders () {
    const {tableHeaders, dataActions, disableFilters} = this.props

    const headers = []
    //Clear filter and sortable refs each time this is rendered
    this.filterRefs = []
    this.sortableRefs = []
    tableHeaders.forEach((col, index) => {
      const flexPos = !col.disableFilter ? 'flex-end' : 'flex-start'
      headers.push (
        <TableData key={`data-${index}`}>
          <div style={{display: 'flex', flexDirection: 'column', flex: 1, alignSelf: flexPos}}>
            {!col.enableSort ? col.filterText :
              <Sortable
                  key={`sortable-${index}`}
                  ref={(component) => {
                    if (component) this.sortableRefs.push(component)
                  }}
                  label={col.header()}
                  {...this.props}
                  sortableItem={col}
                  onSort={this.onSort.bind(this)}
                  sortDirection={col.sortDirection}
              />
            }
            {!disableFilters && !col.disableFilter &&
              <Filter
                key={`filter-${index}`}
                ref={(component) => { if (component) this.filterRefs.push(component) }}
                {...this.props}
                filterItem={col}
                onFilter={this.onFilter.bind(this)}
              />
            }
          </div>
        </TableData>
      )
    })

    if (dataActions.length > 0) headers.push(<TableData key={`data-actions`} className='actions'/>)

    return headers
  }

  /*
  * Get table rows
  */
  getRows () {
    const {dataSource, onRowClick} = this.props

    if (dataSource.length === 0)  return <TableRow><div style={{fontSize: '14px', paddingLeft: '1em', paddingRight: '1em'}}>No results found.</div></TableRow>
    return dataSource.map((data, index) => {
      return (
        <TableRow
          key={`row-${data.id || index}`}
          className={onRowClick ? 'cursor' : ''}
          onClick={() => {
            if (onRowClick) onRowClick(data)
          }}
        >{this.getRowData(data, index)}</TableRow>
      )
    })
  }

  /*
  * Get row data.
  *
  * @param [Object] data. Row data to map to display.
  */
  getRowData (data, rowIndex) {
    const {tableHeaders, dataActions, tableDataClassName} = this.props
    let rowData = []

    // loop over each header and map header to column data
    tableHeaders.forEach((header, index) => {
      rowData.push (
        <TableData key={`header-${rowIndex}-${index}`} className={tableDataClassName}>
          {data[header.key]}
        </TableData>
      )
    })

    if (dataActions.length > 0) {
      const tabs = []
      // loop over each action to be taken on the data
      dataActions.forEach((action, index) => {
        const classes = ['action material-icons orange']
        if (action.className) classes.push(action.className)
        // Don't show delete icon if there is already a deactivated date
        if (action.icon === 'delete' && data.deactivatedDate) classes.push('md-blank')
        tabs.push(
          <div key={`tab-${rowIndex}-${index}`}
            style={{backgroundColor: 'inherit', padding: '0 2px', border: 0 }}
            onClick={(event) => {
              if (!(action.icon === 'delete' && data.deactivatedDate)) {
                event.stopPropagation()
                action.action(data)
              }
            }}
          >
            <i className={classes.join(' ')}>{action.icon}</i>
          </div>
        )
      })

      rowData.push(<TableData key={`actions-row-${rowIndex}`} className='actions'><Tabs>{tabs}</Tabs></TableData>)
    }

    return rowData
  }

  /*
  * On filter press, combine filter params.
  */
  onFilter () {
    const filters = {}
    this.filterRefs.forEach(r => {
      const filter = r.getFilterValue()
      if (filter) {
        const filterArray = filter.split('=')
        filters[filterArray[0]] = filterArray[1]
      }
    })
    this.props.onFilter(filters, this.sortableRefs)
  }

  onSort(params) {
    for (let i = 0; i < this.sortableRefs.length; i++) {
      if (this.sortableRefs[i].props.label === params.filterText) {
        const sortable = this.sortableRefs[i]
        const direction = sortable.state.sortDirection
        if (!direction ||
          // Either asc or desc for single column sort
          sortable.props.singleColumnSort && direction === 'desc') {
          this.sortableRefs[i].state.sortDirection = 'asc'
        } else if (direction === 'asc') {
          this.sortableRefs[i].state.sortDirection = 'desc'
        } else {
          this.sortableRefs[i].state.sortDirection = null;
        }
      } else if(this.sortableRefs[i].props.singleColumnSort) {
        // enable sort only for active column
        this.sortableRefs[i].state.sortDirection = null;
      }
    }
    this.props.onSort(params, this.sortableRefs)
  }

  render () {
    return (
      <FlexTable>
        <TableRow key={`header-row`} className='header'>
          {this.getHeaders()}
        </TableRow>
        {this.getRows()}
      </FlexTable>
    )
  }
}

TableView.propTypes = {
  dataActions: PropTypes.array,
  dataSource: PropTypes.array,
  tableHeaders: PropTypes.array,
  tableDataClassName: PropTypes.string
}

export default TableView
