import React from 'react'
import Card from 'components/Card'
import Loading from 'components/Loading'
import Modal from 'components/Modal'
import TextField from 'components/TextField'
import TableView from 'compositions/TableDisplay/TableView'
import FeeProfileHierarchyForm from './FeeProfileHierarchyForm'
import CommunicationsHierarchyForm from './CommunicationsHierarchyForm'
import InterestProfileHierarchyForm from './InterestProfileHierarchyForm'
import actions from 'actions'
import { hasRole } from '../../../../redux/utility/SessionUtil'
import {mapDateToForm} from 'lib/date'
import {mapDateToSortable} from 'lib/date'
import TermsAndConditionsHierarchyForm from "./TermsAndConditionsHierarchyForm";
import {getEmployerIdFromList} from 'lib/hierarchy'
import {getEmployerNameFromList} from 'lib/hierarchy'
import {getDistributorIdFromList} from 'lib/hierarchy'
import {getDistributorNameFromList} from 'lib/hierarchy'
import {getSponsorIdFromList} from 'lib/hierarchy'
import {getSponsorNameFromList} from 'lib/hierarchy'

const interestProfileHeaders = [
  {
    header: () => 'Name',
    key: 'name'
  },
  {
    header: () => 'Start Date',
    key: 'startDate'
  },
  {
    header: () => 'End Date',
    key: 'endDate'
  },
  {
    header: () => 'Assigned',
    key: 'assigned'
  }
]

const feeProfileHeaders = [
  {
    header: () => 'Name',
    key: 'name'
  },
  {
    header: () => 'Start Date',
    key: 'startDate'
  },
  {
    header: () => 'End Date',
    key: 'endDate'
  },
  {
    header: () => 'Active',
    key: 'active'
  }
]

const termsAndConditionsHeaders = [
  {
    header: () => 'Type',
    key: 'type'
  },
  {
    header: () => 'Name',
    key: 'name'
  },
  {
    header: () => 'PDF Name',
    key: 'pdfName'
  },
  {
    header: () => 'Active',
    key: 'isActive'
  }
]

const communicationsHeaders = [
  {
    header: () => 'Type',
    key: 'type'
  },
  {
    header: () => 'Content Key',
    key: 'contentKey'
  },
  {
    header: () => 'Optum Id',
    key: 'optumCorrespondenceId'
  },
  {
    header: () => 'End Date',
    key: 'endDate'
  }
]

const displayTypes = [
  /**
   * Banking agreement.
   */
  {
    display: 'Custodial Agreement',
    key: 'BANKING_AGREEMENT'
  },
  /**
   * Privacy agreement.
   */
  {
    display: 'Private Policy',
    key: 'PRIVACY_AGREEMENT'
  },
  /**
   * Fee agreement.
   */
  {
    display:'Fee Schedule',
    key: 'FEE_AGREEMENT'
  },
  /**
   * Esign agreement.
   */
  {
    display: 'Esign',
    key: 'ESIGN_AGREEMENT'
  },
]

class Hierarchy extends React.Component {
  constructor (props) {
    super (props)
    this.state = {
      currentFeeProfileHierarchy: null,
      currentInterestProfileHierarchy: null,
      currentTermsAndConditions: null,
      currentCommunicationsHierarchy: null,
      hierarchy: null,
      hierarchyParents: [],
      interestProfileHierarchies: [],
      feeProfileHierarchies: [],
      hierarchyTermsAndConditions: [],
      emailCommunicationsHierarchies : [],
      mailCommunicationsHierarchies : [],
      openInterestProfileModal: false,
      openFeeProfileModal: false,
      openTermsAndConditionsModal: false,
      openCommunicationsModal: false,
      readAccess: hasRole('HierarchyMasterView'),
      writeAccess: hasRole('HierarchyMasterEdit'),
    }
  }

  /*
  * Get hierarchy by id.
  */
  componentWillMount () {
    this.getHierarchy()
    this.getInterestProfileHierarchies()
    this.getFeeProfileHierarchies()
    this.getTermsAndConditionsHierarchies()
    this.getCommunicationsHierarchies()
    this.getHierarchyMinimalListByHierarchyIdAndHierarchyType()
  }

  /*
  * Fetch hierarchy by id.
  */
  getHierarchy () {
    const {match: {params: {id}}} = this.props

    this.setState({loading: true})
    actions.hierarchies.getHierarchyById(id).then(hierarchy => {
      this.setState({hierarchy, loading: false})
    }).catch(() => { this.setState({loading: false}) })
  }

  /*
  * Fetch hierarchy by id.
  */
  getHierarchyMinimalListByHierarchyIdAndHierarchyType () {
    const {match: {params: {id}}} = this.props

    this.setState({loading: true})
    actions.hierarchies.getHierarchyMinimalListByHierarchyIdAndHierarchyType(id).then(hierarchyParents => {
      this.setState({hierarchyParents})
    }).catch(() => { this.setState({loading: false}) })
  }

  /*
  * Fetch interest profiles for hierarchy
  */
  getInterestProfileHierarchies () {
    const {match: {params: {id}}} = this.props

    this.setState({loadingInterestProfiles: true})
    actions.interestProfiles.getInterestProfilesForHierarchy(id).then(interestProfileHierarchies => {
      interestProfileHierarchies = interestProfileHierarchies.map (profile => {
        const {hierarchyInterestProfile, hierarchyDetails, interestProfileName} = profile
        let mappedProfile = hierarchyInterestProfile
        mappedProfile.name = interestProfileName
        return mappedProfile
      })
      this.setState({interestProfileHierarchies, loadingInterestProfiles: false})
    }).catch(() => { this.setState({loadingInterestProfiles: false}) })
  }

  /*
  * Fetch fee profiles for hierarchy
  */
  getFeeProfileHierarchies () {
    const {match: {params: {id}}} = this.props

    this.setState({loadingFeeProfiles: true})
    actions.feeProfiles.getFeeProfilesForHierarchy(id).then(feeProfileHierarchies => {
      feeProfileHierarchies = feeProfileHierarchies.map(profile => this.getMappedProfileFromFeeProfileResponse(profile))

      this.setState({feeProfileHierarchies, loadingFeeProfiles: false})
    }).catch(() => { this.setState({loadingFeeProfiles: false}) })
  }

  getMappedProfileFromFeeProfileResponse(response) {
    const {hierarchyFeeProfile, hierarchyDetails, feeProfileName} = response
    let mappedProfile = hierarchyFeeProfile
    mappedProfile.name = feeProfileName
    return mappedProfile
  }
  /*
  * Fetch terms and conditions for hierarchy
  */
  getTermsAndConditionsHierarchies () {
    const {match: {params: {id}}} = this.props

    this.setState( {loadingTermsAndConditions: true})
    actions.termsAndConditions.getByHierarchyId(id).then(
        employerConfig => {
          let termsAndConditions = []

          if(employerConfig.bankingAgreement !== null) {
            termsAndConditions.push(employerConfig.bankingAgreement)
          }
          if(employerConfig.feeAgreement !== null) {
            termsAndConditions.push(employerConfig.feeAgreement)
          }
          if(employerConfig.privacyAgreement !== null) {
            termsAndConditions.push(employerConfig.privacyAgreement)
          }
          if(employerConfig.esignAgreement !== null) {
            termsAndConditions.push(employerConfig.esignAgreement)
          }

          const hierarchyTermsAndConditions = displayTypes.map(types => {
            const {display, key} = types;
            let mappedTc = termsAndConditions.find((x) => x.agreementType === key)
            const none = "N/A"
            return {
              type: display,
              name: (mappedTc !== undefined && mappedTc !== null) ? mappedTc.name : none,
              pdfName: (mappedTc !== undefined && mappedTc !== null) ? mappedTc.fileName : none,
              isActive: (mappedTc !== undefined && mappedTc !== null) ? (mappedTc.active === true ? 'Y' : 'N') : none,
              id: (mappedTc !== undefined && mappedTc !== null) ? mappedTc.id : null,
            };
          })
          this.setState({hierarchyTermsAndConditions, loadingTermsAndConditions: false})
        }).catch(() => {
      this.setState({loadingTermsAndConditions: false}) });
  }

  /*
  * Fetch communications for the hierarchy
  */
  getCommunicationsHierarchies () {
    const {match: {params: {id}}} = this.props
    this.setState({loadingCommunications: true})
    actions.emailTemplates.getEmailHierarchyTemplatesById(id).then(emailCommunicationsHierarchies => {
      emailCommunicationsHierarchies = emailCommunicationsHierarchies.map(contentHierarchy => this.getMappedContentHierarchyResponse(contentHierarchy))
      this.setState({emailCommunicationsHierarchies})
    }).catch(() => { })
    actions.mailTemplates.getMailHierarchyTemplatesById(id).then(mailCommunicationsHierarchies => {
      mailCommunicationsHierarchies = mailCommunicationsHierarchies.map(contentHierarchy => this.getMappedContentHierarchyResponse(contentHierarchy))
      this.setState({mailCommunicationsHierarchies})
    }).catch(() => { })
    this.setState({loadingCommunications: false})
  }

  getMappedContentHierarchyResponse(response) {
    const {content, contentId, hierarchyId, id} = response
    let contentHierarchyResponse = content
    contentHierarchyResponse.hierarchyId = hierarchyId
    contentHierarchyResponse.id = id
    return contentHierarchyResponse
  }

  /*
  * Render the interest hierarchy meta data
  */
  renderDetails () {
    return (
      <Card className='padding'>
        {this.renderActionsMenu()}
        <h5>Hierarchy Details</h5>
          <div className='row margin-top'>
            <div className='col'>
              <TextField
                  label='Employer Hierarchy ID'
                  name='employerId'
                  value={getEmployerIdFromList(this.state.hierarchyParents)}
              />
            </div>
            <div className='col'>
              <TextField
                  label='Employer Hierarchy Name'
                  name='employerName'
                  value={getEmployerNameFromList(this.state.hierarchyParents)}
              />
            </div>
          </div>
          <div className='row margin-top'>
            <div className='col'>
              <TextField
                  label='Distributor ID'
                  name='distributorId'
                  value={getDistributorIdFromList(this.state.hierarchyParents)}
              />
            </div>
            <div className='col'>
              <TextField
                  label='Distributor Name'
                  name='distributorName'
                  value={getDistributorNameFromList(this.state.hierarchyParents)}
              />
            </div>
          </div>
          <div className='row margin-top'>
            <div className='col'>
              <TextField
                  label='Sponsor ID'
                  name='sponsorId'
                  value={getSponsorIdFromList(this.state.hierarchyParents)}
              />
            </div>
            <div className='col'>
              <TextField
                  label='Sponsor Name'
                  name='sponsorName'
                  value={getSponsorNameFromList(this.state.hierarchyParents)}
              />
            </div>
          </div>
      </Card>
    )
  }

  /*
  * Render the actions menu
  */
  renderActionsMenu () {
    return (
      <div/>
    )
  }

  /*
  * On edit
  */
  onEdit () {
    const {history} = this.props
    history.push(`/nbtadmin/hierarchies/${this.state.hierarchy.id}/edit`)
  }

  /*
  * On delete
  */
  onDelete () {

  }

  /*
  * Render interest profile hierarchies.
  */
  renderInterestProfileHierarchies () {
    const {interestProfileHierarchies} = this.state
    const dataSource = interestProfileHierarchies.map(i => {
      let newInterstProfile = {...i}
      newInterstProfile.startDate = mapDateToForm(i.startDate)
      newInterstProfile.endDate = mapDateToForm(i.endDate)
      newInterstProfile.assigned = i.assigned === true ? 'True' : 'False  '
      return newInterstProfile
    })
    return (
      <div className='padding'>
        <h5> Interest Profiles
          {this.state.writeAccess &&
            <a style={{float: 'right'}} onClick={this.onAddInterestProfileHierarchy.bind(this)}>
              <i className='material-icons orange'>add_circle_outline</i>
            </a>
          }
          <div className='margin-top' />
          <TableView
            dataActions={this.state.writeAccess && [{
              action: this.onEditInterestProfileHierarchy.bind(this),
              icon: 'edit'
            }]}
            dataSource={dataSource}
            disableFilters={true}
            tableHeaders={interestProfileHeaders}
          />
        </h5>
      </div>
    )
  }

  /*
  * Render interest profile hierarchies form
  */
  renderInterestProfileModal () {
    const {match: {params: {id}}} = this.props
    const {currentInterestProfileHierarchy, openInterestProfileModal} = this.state
    if (openInterestProfileModal) {
      return (
        <Modal
          header={`${currentInterestProfileHierarchy && currentInterestProfileHierarchy.id ? 'Edit' : 'Add'} Interest Profile`}
          onClose={this.onCloseInterestProfileModal.bind(this)}
          showClose={true}
        >
            <InterestProfileHierarchyForm
              hierarchyId={id}
              interestProfileHierarchy={currentInterestProfileHierarchy}
              onClose={this.onCloseInterestProfileModal.bind(this)}
              onSubmit={this.onSubmitInterestProfileHierarchy.bind(this)}
            />
        </Modal>
      )
    }
  }

  /*
  * On add interest profile hierarchy
  */
  onAddInterestProfileHierarchy () {
    this.setState({openInterestProfileModal: true})
  }

  /*
  * On edit interest profile hierarchy
  */
  onEditInterestProfileHierarchy (interestProfile) {
    const currentInterestProfileHierarchy = this.state.interestProfileHierarchies
      .find(h => h.id === interestProfile.id)
    this.setState({currentInterestProfileHierarchy, openInterestProfileModal: true})
  }

  /*
  * On interest profile modal close.
  */
  onCloseInterestProfileModal () {
    this.setState({
      openInterestProfileModal: false,
      currentInterestProfileHierarchy: null
    })
  }

  /*
  * On submit interest profile hierarchy
  */
  onSubmitInterestProfileHierarchy () {
    this.getInterestProfileHierarchies();
  }

  /*
  * Render fee profile hierarchies.
  */
  renderFeeProfileHierarchies () {
    const {feeProfileHierarchies} = this.state
    const dataSource = feeProfileHierarchies.map(f => {
      let newFeeProfile = {...f}
      newFeeProfile.startDate = mapDateToSortable(f.startDate)
      newFeeProfile.endDate = mapDateToSortable(f.endDate)
      newFeeProfile.active = f.active === true ? 'True' : 'False'
      return newFeeProfile
    }).sort((a,b) => {
      if (a.startDate < b.startDate)
        return -1
      if (a.startDate > b.startDate)
        return 1
      return 0
    })
    return (
      <div className='padding'>
        <h5> Fee Profiles
          {this.state.writeAccess &&
            <a style={{float: 'right'}} onClick={this.onAddFeeProfileHierarchy.bind(this)}>
              <i className='material-icons orange'>add_circle_outline</i>
            </a>
          }
        </h5>
        <TableView
          dataActions={this.state.writeAccess && [{
            action: this.onEditFeeProfileHierarchy.bind(this),
            icon: 'edit'
          }]}
          dataSource={dataSource}
          disableFilters={true}
          tableHeaders={feeProfileHeaders}
        />
      </div>
    )
  }

  /*
  * Render fee profile hierarchies form
  */
  renderFeeProfileModal () {
    const {match: {params: {id}}} = this.props
    const {currentFeeProfileHierarchy, openFeeProfileModal} = this.state
    if (openFeeProfileModal) {
      return (
        <Modal
          header={`${ currentFeeProfileHierarchy && currentFeeProfileHierarchy.id ? 'Edit' : 'Add'} Fee Profile`}
          onClose={this.onCloseFeeProfileModal.bind(this)}
          showClose={true}
        >
            <FeeProfileHierarchyForm
              hierarchyId={id}
              feeProfileHierarchy={currentFeeProfileHierarchy}
              onClose={this.onCloseFeeProfileModal.bind(this)}
              onSubmit={this.onSubmitFeeProfileHierarchy.bind(this)}
            />

        </Modal>
      )
    }
  }

  /*
  * On add fee profile hierarchy
  */
  onAddFeeProfileHierarchy () {
    this.setState({openFeeProfileModal: true})
  }

  /*
  * On edit fee profile hierarchy
  */
  onEditFeeProfileHierarchy (feeProfile) {
    const currentFeeProfileHierarchy = this.state.feeProfileHierarchies
      .find(h => h.id === feeProfile.id)
    this.setState({currentFeeProfileHierarchy, openFeeProfileModal: true})
  }
  /*
  * On Fee profile modal close.
  */
  onCloseFeeProfileModal () {
    this.setState({
      openFeeProfileModal: false,
      currentFeeProfileHierarchy: null
    })
  }

  /*
  * On submit fee profile hierarchy
  */
  onSubmitFeeProfileHierarchy () {
    this.getFeeProfileHierarchies();
  }

  ////////////////
  /*
  * Render communications  hierarchies.
  */
  renderCommunicationsHierarchies () {
    const {emailCommunicationsHierarchies, mailCommunicationsHierarchies} = this.state
    const dataSource1 = mailCommunicationsHierarchies.map(f => {
      let content = {...f}
      content.type = "LETTER"
      content.startDate = mapDateToForm(f.startDate)
      content.endDate = mapDateToForm(f.endDate)
      return content
    })
    const dataSource2 = emailCommunicationsHierarchies.map(f => {
      let content = {...f}
      content.type = "EMAIL"
      content.startDate = mapDateToForm(f.startDate)
      content.endDate = mapDateToForm(f.endDate)
      return content
    })
  
    const dataSource = [...dataSource1, ...dataSource2]
    return (
      <div className='padding'>
        <h5> Communications
          {this.state.writeAccess &&
            <a style={{float: 'right'}} onClick={this.onAddCommunicationProfileHierarchy.bind(this)}>
              <i className='material-icons orange'>add_circle_outline</i>
            </a>
          }
        </h5>
        <TableView
          dataActions={this.state.writeAccess && [{
            action: this.onDeleteCommunicationsHierarchy.bind(this),
            icon: 'delete'
          }]}
          dataSource={dataSource}
          disableFilters={true}
          tableHeaders={communicationsHeaders}
        />
      </div>
    )
  }
  
  /*
  * Render communications hierarchies form
  */
  renderCommunicationsModal () {
    const {match: {params: {id}}} = this.props
    const {openCommunicationsModal} = this.state
    if (openCommunicationsModal) {
      return (
        <Modal
          header={`Add communication`}
          onClose={this.onCloseCommunicationsModal.bind(this)}
          showClose={true}
        >
            <CommunicationsHierarchyForm
              hierarchyId={id}
              onClose={this.onCloseCommunicationsModal.bind(this)}
              onSubmit={this.onSubmitCommunicationsHierarchy.bind(this)}
            />
  
        </Modal>
      )
    }
  }
  
  /*
  * On add communication hierarchy
  */
  onAddCommunicationProfileHierarchy () {
    this.setState({openCommunicationsModal: true})
  }
  
  /*
    * On delete communication hierarchy
    */
  onDeleteCommunicationsHierarchy (communication) {
    //Delete
    this.setState({ loadingCommunications: true })
    
    if(communication.type === 'EMAIL') {
      actions.emailTemplates.deleteEmailHierarchyTemplate(communication.id).then(() => {
        this.getCommunicationsHierarchies()
    }).catch((error) => false)
    }else if(communication.type === 'LETTER') {
      actions.mailTemplates.deleteMailHierarchyTemplate(communication.id).then(() => {
        this.getCommunicationsHierarchies()
      }).catch((error) => false)
    }
    this.setState({ loadingCommunications: false })
  }
  
  /*
  * On communications modal close.
  */
  onCloseCommunicationsModal () {
    this.setState({
      openCommunicationsModal: false,
      currentCommunicationsHierarchy: null
    })
  }
  
  /*
  * On submit fee profile hierarchy
  */
  onSubmitCommunicationsHierarchy () {
    this.getCommunicationsHierarchies();
  }

  ///////////////

  /*
  * On add Terms and Conditions
   */
  onAddTermsAndConditionsHierarchy () {
    this.setState({openTermsAndConditionsModal: true})
  }

  /*
  * On edit Terms and Conditions
   */
  onEditTermsAndConditionsHierarchy (termsAndConditions) {
    const currentTermsAndConditionsHierarchy = this.state.hierarchyTermsAndConditions
    .find(tc => tc.type === termsAndConditions.type)
    this.setState({openTermsAndConditionsModal: true,
      currentTermsAndConditions: currentTermsAndConditionsHierarchy
    })
  }

  /*
  * On Terms And Conditions profile modal close.
  */
  onCloseTermsAndConditionsModal () {
    this.setState({
      openTermsAndConditionsModal: false,
      currentTermsAndConditions: null
    })
  }

  /*
 * Render fee profile hierarchies form
 */
  renderTermsAndConditionsModal () {
    const {match: {params: {id}}} = this.props
    const {currentTermsAndConditions, openTermsAndConditionsModal} = this.state
    if (openTermsAndConditionsModal) {
      return (
          <Modal
              header={`Edit Terms And Conditions`}
              onClose={this.onCloseTermsAndConditionsModal.bind(this)}
              showClose={true}
          >
            <TermsAndConditionsHierarchyForm
                hierarchyId={id}
                currentTermsAndConditions={currentTermsAndConditions}
                onClose={this.onCloseTermsAndConditionsModal.bind(this)}
                hierarchyTermsAndConditions={this.state.hierarchyTermsAndConditions}
                edit={this.state.currentTermsAndConditions !== null}
                onSubmit={this.onSubmitTermsAndConditionsHierarchy.bind(this)}
            />
          </Modal>
      )
    }
  }

  /*
   * On submit Terms and Conditions hierarchy
  */
  onSubmitTermsAndConditionsHierarchy () {
    this.getTermsAndConditionsHierarchies();
  }

  /*
  * Render terms and conditions table
  */
  renderTermsAndConditionsHierarchy () {
    const {hierarchyTermsAndConditions} = this.state
    const dataSource = hierarchyTermsAndConditions.map(tc => {
     return {...tc}
    })
    return (
        <div className='padding'>
          <h5> Terms And Conditions
            {this.state.writeAccess &&
            <a style={{float: 'right'}} onClick={this.onAddTermsAndConditionsHierarchy.bind(this)}>
              <i className='material-icons orange'>add_circle_outline</i>
            </a>
            }
          </h5>
          <TableView
              dataActions={this.state.writeAccess && [{
                action: this.onEditTermsAndConditionsHierarchy.bind(this),
                icon: 'edit'
              }]}
              dataSource={dataSource}
              disableFilters={true}
              tableHeaders={termsAndConditionsHeaders}
          />
        </div>
    )
  }


  render () {
    if (this.state.loading || this.state.loadingInterestProfiles
        || this.state.loadingFeeProfiles || this.state.loadingTermsAndConditions)
      return <div className='margin-top'><Loading/></div>

    const {name} = this.state.hierarchy
    return (
      <div className='page interest-hierarchy-view'>
        <h4>{`${name}'s Profile`}</h4>
        {this.renderDetails()}
        <div style={{marginLeft: '3em'}}>
          {this.renderInterestProfileHierarchies()}
          {this.renderFeeProfileHierarchies()}
          {this.renderTermsAndConditionsHierarchy()}
          {this.renderCommunicationsHierarchies()}

        </div>
        {this.renderInterestProfileModal()}
        {this.renderFeeProfileModal()}
        {this.renderTermsAndConditionsModal()}
        {this.renderCommunicationsModal()}

      </div>
    )
  }
}

export default Hierarchy

