import React, { Component } from 'react'

// Styles ------------
import './button.scss'


/**
 * TODO Write documentation
 */
class Button extends Component {

  static defaultProps = {
    className: '',
    disabled: false,
    loading: false,
    type: 'button',
    events: {}
  }


  constructor(props) {
    super(props)
  }


  renderChildren() {
    const { children, loading } = this.props

    if (loading) {
      return (<span>&#9749;</span>)
    } else {
      return children
    }
  }


  render() {
    const { className, disabled, events, type } = this.props

    let classNames = ['button', `button-${type}`]
    classNames.push(className)
    if (disabled) {classNames.push('disabled-button')}

    return (
      <button {...events} className={classNames.filter(a => a).join(' ')} disabled={disabled?"disabled":""}>
        {this.renderChildren()}
      </button>
    )
  }
}

export default Button
