import React from 'react'
import Card from '../../../components/Card'
import Button from '../../../components/Button'
import Loading from '../../../components/Loading'
import { showSnackbar } from '../../../redux/utility/SnackbarUtil'
import actions from 'actions'


const CSV_TEMPLATE_HEADERS = 'HSA_FNAME,HSA_LNAME,PARTIAL_SSN,EE_ID,BENEF_TYPE,NAME,ADDR1,SSN,DOB(MM/dd/yyyy),JOB_ID,' + 
                              'PERCENTAGE,REL,ADDR2,CITY,STATE,ZIP,PARENT,PARENT_ADDR,PARENT_SSN'

class BeneficiaryUpload extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isFormSubmitting : false
        }
    }

    isFormSubmitting() {
        return this.state.isFormSubmitting || false
    }

    onSubmitTrigger(event) {
        event.preventDefault()
        if(this.validateSubmit()) {
            this.setState({isFormSubmitting: true})
            actions.beneficiary.uploadFile(this.fileInput.files[0])
            .then(() => {
              this.setState({isFormSubmitting: false})
              showSnackbar('File uploaded successfully, refer to report for more details', 'info')
              this.fileInput.value = ''
            }).catch(error => error).then(error => this.showErrorMessage(error))
        }
    }

    showErrorMessage(error) {
        if(!error){
            return
        }
        let message = 'Failed to upload the file'
        try {
            const errorObject =  JSON.parse(error)
            message = errorObject.message ? errorObject.message : 'Failed to upload the file'
        } catch(e) {} // catch any un expected error and show the error message to the user
        showSnackbar(message, 'error')
        this.setState({isFormSubmitting: false})
        this.fileInput.value = ''
    }

    validateSubmit() {
        if (!this.fileInput.value) {
            showSnackbar('File selection is required.')
            return false
        }
        return true
    }

    generateSampleTemplateFile() {
        const blob = new Blob([CSV_TEMPLATE_HEADERS], { type: 'text/csv'});
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', "Beneficiary Template.csv");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    render() {
        return (
            <form onSubmit={this.onSubmitTrigger.bind(this)}>
                <Card className='padding'>
                    <div className='form-control margin-top'>
                        <label className='input-label' htmlFor={'file-input'}>
                        Select File To Upload. <i>[Download the <b><u><a onClick={()=>this.generateSampleTemplateFile()}>Template</a></u></b>]</i>
                        </label>
                        <div>
                            <input
                                name='file-input'
                                type='file'
                                accept='.csv'
                                ref={input => { this.fileInput = input; }}
                            />
                        </div>
                    </div>
                </Card>
                <Button
                    className='primary-button margin-top'
                    disabled={this.isFormSubmitting()}
                    type="submit"
                >
                    <span>

                        {this.isFormSubmitting() ?
                            <Loading containerClassName='inline' className='white small' />
                            : 'Submit'
                        }
                    </span>
                </Button>
            </form>
        )
    }
}

export default BeneficiaryUpload