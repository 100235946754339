import Fetch from 'lib/fetch'
import { showSnackbar } from '../redux/utility/SnackbarUtil'

/*
* Get all fraud rules.
*/
export function getFraudRules () {
  return Fetch.get(`${process.env.REACT_APP_FRAUD_RULES_DOMAIN}/fraud-rules`, null)
    .then(data => {
      return data
    })
    .catch(error => Promise.reject(error))
}

/*
* Get fraud rule by id.
*
* @param [Number] id. Id of fraud rule to get.
*/
export function getFraudRuleById (id) {
  return Fetch.get(`${process.env.REACT_APP_FRAUD_RULES_DOMAIN}/fraud-rules/${id}`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

/*
* Create a new fraud rule
*
* @param [Object] form. Fraud rule.
*/
export function createFraudRule (form) {
  return Fetch.post(`${process.env.REACT_APP_FRAUD_RULES_DOMAIN}/fraud-rules`, form, null)
    .then(data => {
      showSnackbar('Rule successfully created', 'info')
      return data
    })
    .catch(error => Promise.reject(error))
}

/*
* Update a fraud rule.
*
* @param [Object] form. Fraud rule.
*/
export function updateFraudRule (form) {
  return Fetch.put(`${process.env.REACT_APP_FRAUD_RULES_DOMAIN}/fraud-rules/${form.id}`, form, null)
    .then(data => {
      showSnackbar('Rule successfully updated', 'info')
      return data
    })
    .catch(error => Promise.reject(error))
}

/*
* Deactivate fraud rule
*
* @param [Object] profile. Fee to deactivate
*/
export function deactivateFraudRule (rule) {
  let form = rule
  rule.active = false
  return updateFraudRule(form)
    .then(data => {
      showSnackbar('Rule deactivated.', 'info')
      return data
    }).catch(error => Promise.reject(error))
}
