import React from 'react'
import { hasRole } from '../../../redux/utility/SessionUtil'
import Button from 'components/Button'
import Loading from 'components/Loading'
import TableView from 'compositions/TableDisplay/TableView'
import ConfirmDialog from 'components/ConfirmDialog'
import actions from 'actions'
import { showSnackbar, hideSnackbar } from '../../../redux/utility/SnackbarUtil'

const headers = [
  {
      filterText: 'File Name',
      header: () => 'File Name',
      key: 'filename',
      enableSort: false
  },
  {
      filterText: 'Sent date',
      header: () => 'Sent date',
      key: 'created',
      enableSort: false
  },
  {
      filterText: 'Status',
      header: () => 'Status',
      key: 'status',
      enableSort: false
  },
  {
      filterText: '',
      header: () => '',
      key: 'reconAction',
      disableFilter: true,
      enableSort: false
  }
]

class BAIRecon extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.initializeState();
  }

  componentWillMount(){
    this.verifyAccess()
    this.getReconFileData()
  }

  /*
  * Initialize State
  */
 initializeState() {
    return {
      reconData: [],
      dataSource: [],
      viewAccess: hasRole('BatchWireView'),
      editAccess: hasRole('BatchWireEdit'),
      uploading: false,
      loading: true,
      total: 0,
      openConfirmationDialog: false,
      selectedFileName: '',
      selectedFileId: 0
    }
  }

  /*
  * Verify that the user has access
  */
 verifyAccess () {
  if (!(this.state.viewAccess || this.state.editAccess)) {
    this.props.history.push('/nbtadmin/unauthorized')
    return false
  }

  return true
}

  processReconData() {
    var cursorStyle = this.state.editAccess ? "" : "not-allowed"
    const reconData = this.state.dataSource.map(data => {
      const {id, filename, created, status} = data;
      return {
        filename,
        created,
        status,
        reconAction: (<div className="recon-button-wrapper" style={{ width: '50%', cursor: `${cursorStyle}`}}><Button
          className={`primary-button margin-top ${id}-${filename}`}
          type="input"
          disabled={this.state.editAccess ? false : true}
          events={
            {
              onClick: this.handleReconClick.bind(this)
            }
          }
          >
          <span style={{fontSize : '15px'}}> 
              Recon
          </span>
      </Button></div>)      
      };
    });
    this.setState({reconData, total: reconData.length})
  }

  getReconFileData(){
    actions.baiReconFile.getBAIReconFiles()
      .then(data => {
          this.setState({dataSource: data, loading: false})
          this.processReconData()
      }).catch((error) => {
          this.setState({dataSource: [], loading: false})
          console.log(error)
          showSnackbar('Failed to retrieve data', 'error')
      })
  }

  handleReconClick(event){
    var fileDetails = event.currentTarget.className.split(" ")[4].split("-");
    this.setState({ openConfirmationDialog: true, selectedFileId: fileDetails[0].trim(), selectedFileName: fileDetails[1].trim()});
  }

  submitFileforRecon() {
    this.setState({openConfirmationDialog:false, loading:true});
    if(this.state.selectedFileId != null){
      actions.baiReconFile.submitReconFile(this.state.selectedFileId)
      .then(() => {
        this.getReconFileData()
      })
      .catch(() => {
        hideSnackbar()
        this.setState({loading : false})
      })
    }
  }
  
  renderConfirmDialog () {
    if (this.state.openConfirmationDialog) {
      var desc = 'Are you sure you wanted to submit "' + this.state.selectedFileName + '" file for reconciliation ?';
      return (
        <ConfirmDialog
          description={desc}
          onClose={() => { this.setState({openConfirmationDialog: false, currentProfile: null}) }}
          onConfirm={() => { this.submitFileforRecon() }}
        />
      )
    }
  }

  render () {
    return (
      <div className='search-page'>
        <h4>BAI Manual Recon</h4>
        <div className="fixed-height-scroll">
          {this.state.loading ?
            <Loading /> :
            <TableView
              dataActions={[]}
              dataSource={this.state.reconData}
              tableHeaders={headers}
              disableFilters={true}
            />
          }
        </div>
        {this.renderConfirmDialog()}
      </div>
    );
  }
}

export default BAIRecon