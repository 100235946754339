import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import Field from 'components/forms/Field/index'
import FormSection from 'components/forms/FormSection/index'
import Loading from 'components/Loading'
import {cipSettings, notificationSettings} from 'data/otherConfig'
import actions from 'actions'
import { hasRole } from '../../../redux/utility/SessionUtil'

class ConfigurationForm extends React.Component {
  constructor(props) {
    super (props)

    this.state = {
      form: this.initializeFormData(),
      accountTypeOptions: {},
      custodianOptions: {},
      loading: false,
      readAccess: hasRole('OtherConfigView'),
      writeAccess: hasRole('OtherConfigEdit')
    }
  }

  /*
  * Get other configuration settings
  */
  componentWillMount () {
    if (this.verifyAccess()) {
      this.getSettings()
    }
  }

  /*
  * Verify that the user has access to other configuration
  */
  verifyAccess () {
    if (!(this.state.readAccess || this.state.writeAccess)) {
      this.props.history.push('/nbtadmin/unauthorized')
      return false
    }

    return true
  }

  /*
  * Get settings
  */
  getSettings () {
    this.setState({loading: true})
    actions.settings.getSettings().then(settings => {
      const {
      configuredCipEnabledAccountTypeOptions,
      configuredCipEnabledHsaCustodianOptions} = settings

      let accountTypeOptions = {}
      configuredCipEnabledAccountTypeOptions.forEach(o => {
        accountTypeOptions[o.id] = o.name
      })

      let custodianOptions = {}
      configuredCipEnabledHsaCustodianOptions.forEach(o => {
        custodianOptions[o.id] = o.name
      })

      this.setState({form: this.initializeFormData(settings), accountTypeOptions, custodianOptions, loading: false})
    }).catch(() => { this.setState({loading: false}) })
  }

  /*
  * Initialize form
  * Settings.
  */
  initializeFormData (data) {
    const {
      configuredDaysUntilCipDeclined,
      configuredCipEnabledAccountTypeIds,
      configuredCipEnabledHsaCustodianIds,
      configuredDaysForEsignReminderInterval,
      configuredDaysBeforeCipDeclinedNotification,
      configuredDaysToHoldReleaseForCardSwipes,
      configuredDaysToHoldReleaseForChecks,
      configuredDaysForCipReminder1,
      configuredDaysForCipReminder2,
      configuredDaysForCipReminder3
    } = data || {}

    return {
      configuredDaysUntilCipDeclined: configuredDaysUntilCipDeclined || '',
      configuredCipEnabledAccountTypeIds: configuredCipEnabledAccountTypeIds || [],
      configuredCipEnabledHsaCustodianIds: configuredCipEnabledHsaCustodianIds || [],
      configuredDaysForEsignReminderInterval: configuredDaysForEsignReminderInterval || '',
      configuredDaysBeforeCipDeclinedNotification: configuredDaysBeforeCipDeclinedNotification || '',
      configuredDaysToHoldReleaseForCardSwipes: configuredDaysToHoldReleaseForCardSwipes || '',
      configuredDaysToHoldReleaseForChecks: configuredDaysToHoldReleaseForChecks || '',
      configuredDaysForCipReminder1: configuredDaysForCipReminder1 || '',
      configuredDaysForCipReminder2: configuredDaysForCipReminder2 || '',
      configuredDaysForCipReminder3: configuredDaysForCipReminder3 || ''
    }
  }

  /*
  * Render the fields
  */
  getCipFields () {
    const {form: {
      configuredDaysUntilCipDeclined,
      configuredCipEnabledAccountTypeIds,
      configuredCipEnabledHsaCustodianIds
    }, accountTypeOptions, custodianOptions} = this.state

    const {fields} = cipSettings.us

    let f = [
        {
          ...fields.configuredDaysUntilCipDeclined,
          disabled: !this.state.writeAccess,
          required: true,
          events: {
            onChange: (event) => {
              this.onChange(fields.configuredDaysUntilCipDeclined.name, event.target.value)
            }
          },
          value: configuredDaysUntilCipDeclined
        },
      {
        ...fields.configuredCipEnabledAccountTypeIds,
        attrs: {
          multiple: true,
          style: {height: 'auto'},
        },
        disabled: !this.state.writeAccess,
        required: true,
        data: accountTypeOptions,
        events: {
          onChange: (event) => {
            const value =[...event.target.selectedOptions].map(o => o.value)
            this.onChange(fields.configuredCipEnabledAccountTypeIds.name, value)
          }
        },
        value: configuredCipEnabledAccountTypeIds
      }
      ]

    if (this.hsaExists()) {
      f.push(
        {
          ...fields.configuredCipEnabledHsaCustodianIds,
          attrs: {
            multiple: true,
            style: {height: 'auto'}
          },
          disabled: !this.state.writeAccess,
          required: true,
          data: custodianOptions,
          events: {
            onChange: (event) => {
              const value =[...event.target.selectedOptions].map(o => o.value)
              this.onChange(fields.configuredCipEnabledHsaCustodianIds.name, value)
            }
          },
          value: configuredCipEnabledHsaCustodianIds
        }
      )
    }

    return f
  }

  /*
  * Exists
  */
  hsaExists () {
    const {form: {
      configuredCipEnabledAccountTypeIds
    }, accountTypeOptions} = this.state

    let exists = false
    configuredCipEnabledAccountTypeIds.forEach((id) => {
      if (accountTypeOptions[id] === "Health Savings Account") {
        exists = true
      }
    })

    return exists
  }

  /*
  * Render the fields
  */
  getNotificationFields () {
    const {form: {
      configuredDaysForEsignReminderInterval,
      configuredDaysBeforeCipDeclinedNotification,
      configuredDaysToHoldReleaseForCardSwipes,
      configuredDaysToHoldReleaseForChecks,
      configuredDaysForCipReminder1,
      configuredDaysForCipReminder2,
      configuredDaysForCipReminder3
    }} = this.state

    const {fields} = notificationSettings.us

    return [
        {
          ...fields.configuredDaysForEsignReminderInterval,
          disabled: !this.state.writeAccess,
          required: true,
          events: {
            onChange: (event) => {
              this.onChange(fields.configuredDaysForEsignReminderInterval.name, event.target.value)
            }
          },
          value: configuredDaysForEsignReminderInterval
        },
        {
          ...fields.configuredDaysForCipReminder1,
          disabled: !this.state.writeAccess,
          required: true,
          events: {
            onChange: (event) => {
              this.onChange(fields.configuredDaysForCipReminder1.name, event.target.value)
            }
          },
          value: configuredDaysForCipReminder1
        },
        {
          ...fields.configuredDaysForCipReminder2,
          disabled: !this.state.writeAccess,
          required: true,
          events: {
            onChange: (event) => {
              this.onChange(fields.configuredDaysForCipReminder2.name, event.target.value)
            }
          },
          value: configuredDaysForCipReminder2
        },
        {
          ...fields.configuredDaysForCipReminder3,
          disabled: !this.state.writeAccess,
          required: true,
          events: {
            onChange: (event) => {
              this.onChange(fields.configuredDaysForCipReminder3.name, event.target.value)
            }
          },
          value: configuredDaysForCipReminder3
        },
        {
          ...fields.configuredDaysBeforeCipDeclinedNotification,
          disabled: !this.state.writeAccess,
          required: true,
          events: {
            onChange: (event) => {
              this.onChange(fields.configuredDaysBeforeCipDeclinedNotification.name, event.target.value)
            }
          },
          value: configuredDaysBeforeCipDeclinedNotification
        },
        {
          ...fields.configuredDaysToHoldReleaseForCardSwipes,
          disabled: !this.state.writeAccess,
          required: true,
          events: {
            onChange: (event) => {
              this.onChange(fields.configuredDaysToHoldReleaseForCardSwipes.name, event.target.value)
            }
          },
          value: configuredDaysToHoldReleaseForCardSwipes
        },
        {
          ...fields.configuredDaysToHoldReleaseForChecks,
          disabled: !this.state.writeAccess,
          required: true,
          events: {
            onChange: (event) => {
              this.onChange(fields.configuredDaysToHoldReleaseForChecks.name, event.target.value)
            }
          },
          value: configuredDaysToHoldReleaseForChecks
        }
      ]
  }

  /*
  * Update form values
  *
  * @param [String] name. Field name of form to update.
  * @param [Any] value. Value.
  */
  onChange (name, value) {
    let form = {...this.state.form}
    form[name] = value
    this.setState({form})
  }

  /**
   * On submit
   */
  handleSubmit(event) {
    event.preventDefault()
    if (this.state.writeAccess) {
      this.setState({submitting: true})
      const form = this.mapForm()
      actions.settings.updateSettings(form).then(settings => {
        this.setState({form: this.initializeFormData(settings), submitting: false})
      }).catch(() => { this.setState({submitting: false}) })
    }
  }

  /*
  * Map form
  */
  mapForm () {
    let f = {...this.state.form}
    if (!this.hsaExists()) {
      f.configuredCipEnabledHsaCustodianIds = []
    }
    return f
  }

  render () {
    if (this.state.loading) return <Loading />
    return (
      <form onSubmit={this.handleSubmit.bind(this)} className="login-form">
        <FormSection
          className='margin-top'
          title='CIP SETTINGS'
        >
          {this.getCipFields().map((f, idx) => {
            return <Field key={`${f.name}-${idx}`} {...f} />
          })}
        </FormSection>

        <FormSection
          className='margin-top'
          title='NOTIFICATION SETTINGS'
        >
          {this.getNotificationFields().map((f, idx) => {
            return <Field key={`${f.name}-${idx}`} {...f} />
          })}
        </FormSection>

        <Button
          className='primary-button margin-top'
          disabled={this.state.submitting || !this.state.writeAccess}
          type="submit"
        >
          <span>

            {this.state.submitting ?
              <Loading containerClassName='inline' className='white small' />
              : 'Submit'
            }
          </span>
        </Button>
      </form>
    )
  }
}

ConfigurationForm.propTypes = {

}

export default ConfigurationForm
