import React from 'react'
import {mapDateToForm} from 'lib/date'
import TextField from 'components/TextField'
import Loading from 'components/Loading'

class EnrollmentRecordForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            enrollmentRecord: this.props.enrollmentRecord,
            loading: this.props.loading
        }
    }

    componentDidUpdate() {
        if(this.isEnrollmentRecordChanged(this.state.enrollmentRecord, this.props.enrollmentRecord) 
            || this.state.loading!==this.props.loading) {
            // this.setState({loading:true})
            this.setState({
                enrollmentRecord: this.props.enrollmentRecord,
                loading: this.props.loading
            })
        }
    }

    isEnrollmentRecordChanged(recOld, recNew) {
        if(recOld!=null && recNew!=null && recOld.enrollmentId!=recNew.enrollmentId) {
            return true
        } else if(recOld!=recNew) {
            return true
        } else {
            return false
        }
    }

    renderColumn(label, name, value){
        return <div className='col'>
            <TextField
                label={label}
                name={name}
                value={value}
            />
        </div>
    }

    // Creating a function instead of using String(..) to display a blank line instead of "undefined"
    convertToString(value) {
        return value==null ? '' : String(value)
    }

    render() {
        if (this.state.loading===true) {
            return (
                <div className='margin-top'>
                    <Loading/>
                </div>
            )
        }
        if (this.state.enrollmentRecord==null) {
            return (
                <div style={{textAlign: 'center'}}>
                    <h5>No Data Found</h5>
                </div>
            )
        }
        const {enrollmentId, employerId, employeeId, cycAccountId, enrollmentStatus, cipOrderId, bankAccountId, 
                esignComplianceId, cipProcessingComplete, bankAccountProcessingComplete, esignProcessingComplete, 
                notificationProcessingComplete, welcomeNotificationSent, cycAccountProcessingComplete, hashProcessingComplete, 
                enrollmentFlowType, created, modified} = this.state.enrollmentRecord
        return (
            <div>
                <h5>Details</h5>
                <div className='row'>
                    {this.renderColumn("Enrollment ID", "enrollmentId", this.convertToString(enrollmentId))}
                    {this.renderColumn("Enrollment Status", "enrollmentStatus", enrollmentStatus)}
                    {this.renderColumn("Enrollment Flow Type", "enrollmentFlowType", this.convertToString(enrollmentFlowType))}
                </div>
                <div className='row margin-top'>
                    {this.renderColumn("Employee ID", "employeeId", this.convertToString(employeeId))}
                    {this.renderColumn("Employer ID", "employerId", this.convertToString(employerId))}
                </div>
                <div className='row margin-top'>
                    {this.renderColumn("CYC Account ID", "cycAccountId", this.convertToString(cycAccountId))}
                    {this.renderColumn("CIP Order ID", "cipOrderId", this.convertToString(cipOrderId))}
                    {this.renderColumn("Bank Account ID", "bankAccountId", this.convertToString(bankAccountId))}
                    {this.renderColumn("E-Sign Compliance ID", "esignComplianceId", this.convertToString(esignComplianceId))}
                </div>
                <div className='row margin-top'>
                    {this.renderColumn("CYC Account Processing Status", "cycAccountProcessingComplete", cycAccountProcessingComplete ? "Complete" : "Incomplete")}
                    {this.renderColumn("CIP Processing Status", "cipProcessingComplete", cipProcessingComplete ? "Complete" : "Incomplete")}
                    {this.renderColumn("Bank Account Processing Status", "bankAccountProcessingComplete", bankAccountProcessingComplete ? "Complete" : "Incomplete")}
                    {this.renderColumn("E-Sign Processing Status", "esignProcessingComplete", esignProcessingComplete ? "Complete" : "Incomplete")}
                </div>
                <div className='row margin-top'>
                    {this.renderColumn("Notification Processing Status", "notificationProcessingComplete", notificationProcessingComplete ? "Complete" : "Incomplete")}
                    {this.renderColumn("Welcome Notification Sent", "welcomeNotificationSent", welcomeNotificationSent ? "Complete" : "Incomplete")}
                    {this.renderColumn("Hash Processing Status", "hashProcessingComplete", hashProcessingComplete ? "Complete" : "Incomplete")}
                </div>
                <div className='row margin-top'>
                    {this.renderColumn("Created", "created", mapDateToForm(created))}
                    {this.renderColumn("Modified", "modified", mapDateToForm(modified))}
                </div>
            </div>
        )

    }

}

export default EnrollmentRecordForm