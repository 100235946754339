import React from 'react'
import TableDisplay from 'compositions/TableDisplay'
import actions from 'actions'
import { hasRole } from '../../../../redux/utility/SessionUtil'
import {filterDataSource} from 'lib/filter'
import { showSnackbar } from '../../../../redux/utility/SnackbarUtil'
import dayjs from 'dayjs'


const headers = [
  {
    filterText: 'Template',
    header: () => 'Subject',
    key: 'contentSummary'
  },
  {
    filterText: 'Content key',
    header: () => 'Content Key',
    key: 'contentKey'
  },
  {
    filterText: 'Default',
    header: () => 'Default',
    key: 'defaultTemplate'
  },
  {
    filterText: 'Optum Id',
    header: () => 'Optum ID',
    key: 'optumCorrespondenceId'
  },
  {
    filterText: 'Start Date',
    header: () => 'Start Date',
    key: 'startDate'
  },
  {
    filterText: 'End Date',
    header: () => 'End Date',
    key: 'endDate'
  },
  {
    filterText: 'Description',
    header: () => 'Email Content',
    key: 'contentDocument'
  }
]

class EmailTemplates extends React.Component {
  constructor (props) {
    super (props);
    this.state = {
      dataSource: [],
      filteredDataSource: [],
      readAccess: hasRole('EmailMasterView'),
      writeAccess: hasRole('EmailMasterEdit')
    }

    this.actions = this.state.writeAccess ? [
      {
        action: this.onCopy.bind(this),
        icon: 'content_copy'
      },

      {
        action: this.onEdit.bind(this),
        icon: 'edit'
      }
    ] : []
  }

  /*
  * Get all templates
  */
  componentWillMount () {
    if (this.verifyAccess()) {
      this.getTemplates()
    }
  }

  /*
  * Verify that the user has access to email templates
  */
  verifyAccess () {
    if (!(this.state.readAccess || this.state.writeAccess)) {
      this.props.history.push('/nbtadmin/unauthorized')
      return false
    }

    return true
  }

  /*
  * Fetch templates
  */
  getTemplates () {
    this.setState({loading: true})
    actions.emailTemplates.getEmailTemplates().then(templates => {
      const mappedTemplates = templates.map(template => {
        const {contentId, contentKey, contentSummary, defaultTemplate, optumCorrespondenceId, contentDocument, endDate, startDate} = template
        return {
          id: contentId,
          contentKey,
          contentSummary,
          defaultTemplate: defaultTemplate ? 'Default' : '',
          optumCorrespondenceId: optumCorrespondenceId,
          contentDocument: <div style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '25em'}}>
            {contentDocument}
          </div>,
          contentDocumentString: contentDocument,
          startDate: startDate,
          endDate: endDate
        }
      })
      this.setState({dataSource: mappedTemplates, filteredDataSource: mappedTemplates, loading: false})
    }).catch(() => this.setState({loading: false}))
  }

  /*
  * Go to email template form
  */
  onAdd () {
    const {history} = this.props
    history.push(`/nbtadmin/email-templates/create/new`)
  }

  /*
  * View email template
  */
  onRowClick (template) {
    const {history} = this.props
    history.push(`/nbtadmin/email-templates/${template.id}`)
  }

  /*
  * Edit email template
  */
  onEdit (template) {
    if (template.startDate > dayjs().format("YYYY-MM-DD")){
      const {history} = this.props
      history.push(`/nbtadmin/email-templates/${template.id}/edit`)
    } else {
      showSnackbar('Cannot edit an active template', 'error');
    }
  }

  /*
  * copy email template
  */
  onCopy (template) {
    const { history } = this.props
    localStorage.setItem('copyTemplateId', template.id);
    history.push(`/nbtadmin/email-templates/create/new`)
  }

  onDelete (template) {
    //TODO: Api request to delete item
  }

  /*
  * Filter data source.
  *
  * @param [Object] queryParams. Key value pair containing filters.
  */
  onFilter (queryParams) {
    const {dataSource} = this.state
    // contentDocument is a react element that's necessary for rendering but
    // we want to filter on the HTML string itself so we replace the filter
    // on contentDocument with a filter on contentDocumentString
    if (queryParams["contentDocument"]) {
      let contentDocumentQuery = queryParams["contentDocument"];
      queryParams["contentDocument"] = "";
      queryParams["contentDocumentString"] = contentDocumentQuery
    }
    const newDataSource = filterDataSource(dataSource, queryParams)
    this.setState({filteredDataSource: newDataSource})
  }

  render () {
    return (
      <div className='search-page email-template-view'>
        <TableDisplay
          dataActions={this.actions}
          dataSource={this.state.filteredDataSource}
          disableAdd={!this.state.writeAccess}
          loading={this.state.loading}
          onAdd={this.onAdd.bind(this)}
          onFilter={this.onFilter.bind(this)}
          onRowClick={this.onRowClick.bind(this)}
          pageDescription='Manage email templates.'
          pageTitle='Email Templates'
          tableHeaders={headers}
        />
      </div>
    )
  }
}

export default EmailTemplates
