
import React from 'react'
import Field from 'components/forms/Field'
import DatePicker from "../../../components/forms/DatePicker"
import { hasRole } from '../../../redux/utility/SessionUtil'
import Card from "../../../components/Card"
import TableDisplay from 'compositions/TableDisplay'
import actions from 'actions'
import { showSnackbar } from '../../../redux/utility/SnackbarUtil'

const headers = [
    {
        filterText: 'Dead Letter ID',
        header: () => 'Dead Letter ID',
        disableFilter: true,
        key: 'id'
    },
    {
        filterText: 'Dead Letter Type',
        header: () => 'Dead Letter Type',
        disableFilter: true,
        key: 'deadLetterType'
    },
    {
        filterText: 'Error Message',
        header: () => 'Error Message',
        disableFilter: true,
        key: 'errorMessage'
    },
    {
        filterText: 'Message',
        header: () => 'Message',
        disableFilter: true,
        key: 'message'
    },
    {
        filterText: 'Created',
        header: () => 'Created',
        disableFilter: true,
        key: 'created'
    },
    {
        filterText: 'Enrollment ID',
        header: () => 'Enrollment ID',
        disableFilter: true,
        key: 'enrollmentId'
    },
    {
        filterText: 'Enrollment Status',
        header: () => 'Enrollment Status',
        disableFilter: true,
        key: 'enrollmentStatus'
    },
    {
        filterText: 'CIP ID',
        header: () => 'CIP ID',
        disableFilter: true,
        key: 'cipOrderId'
    },
    {
        filterText: 'NBT ID',
        header: () => 'NBT ID',
        disableFilter: true,
        key: 'bankAccountId'
    },
    {
        filterText: 'CYC ID',
        header: () => 'CYC ID',
        disableFilter: true,
        key: 'cycAccountId'
    },
    {
        filterText: 'ER ID',
        header: () => 'ER ID',
        disableFilter: true,
        key: 'employerId'
    },
    {
        filterText: 'EE ID',
        header: () => 'EE ID',
        disableFilter: true,
        key: 'employeeId'
    },
    {
        filterText: 'Enrollment Flow Type',
        header: () => 'Enrollment Flow Type',
        disableFilter: true,
        key: 'enrollmentFlowType'
    },
]

class DeadLetter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            writeAccess: hasRole('AccountMaintEdit'),
            form: this.initializeFormData(),
            dataSource: [],
            loading: false
        }
    }

    componentWillMount() {
        this.verifyAccess()
    }

    initializeFormData() {
        return {
            enrollmentId: '',
            createdStart: '',
            createdEnd: ''
        }
    }

    /**
     * show the page only for write access
     */
    verifyAccess() {
        if (!this.state.writeAccess) {
            this.props.history.push('/nbtadmin/unauthorized')
        }
    }

    /**
     * Fetch the dead letter report data
     * @param {*} form 
     */
    getDeadLetterReportData(form) {
        this.setState({ loading: true })
        actions.deadLetter.getDeadLetterReport(form).then(data => {
            if(data && data.length === 0){
                showSnackbar('No data found')
            }
            this.setState({dataSource: data, loading: false})
        }).catch((error) => {
            this.setState({dataSource: [], loading: false})
            console.log(error)
            showSnackbar('Failed to retrieve data')
        })

    }

    onChange(name, value) {
        let form = { ...this.state.form }
        form[name] = value
        this.setState({ form })
    }

    formSubmit(event) {
        event.preventDefault()
        const { form } = this.state
        if(this.isValid(form)){
            this.getDeadLetterReportData(form);
        }
    }

    /**
     * validate for either Enrollment ID or a date range
     * 
     * @param {*} form 
     * @returns boolean
     */
    isValid(form) {
        if(form.enrollmentId){
            return true
        }
        if(form.createdStart && form.createdEnd){
            return true
        }
        showSnackbar('Enter either Enrollment ID or enter a date range')
        return false
    }

    render() {
        const { form } = this.state
        return (
            <div className='search-page'>
                <h4>Enrollment Dead Letter Queue</h4>
                <p>Enter either Enrollment ID or enter a date range to return dead letter data</p>
                <Card className='padding'>
                    <form onSubmit={this.formSubmit.bind(this)}>
                        <div>
                            <Field
                                label='Enrollment ID'
                                name='enrollmentId'
                                placeholder={false}
                                events={
                                    {
                                        onChange: (event) => { this.onChange('enrollmentId', event.target.value) }
                                    }
                                }
                                value={form.enrollmentId}
                                className='grid-width-50'
                            />
                            <DatePicker
                                label="Date Range Start"
                                name="createdStart"
                                onChange={(name, value) => {
                                    this.onChange(name, value)
                                }}
                                value={form.createdStart}
                                className='grid-width-25'
                            />
                            <DatePicker
                                label="Date Range End"
                                name="createdEnd"
                                onChange={(name, value) => {
                                    this.onChange(name, value)
                                }}
                                value={form.createdEnd}
                                className='grid-width-25'
                            />
                        </div>
                        <button
                            style={{
                                width: 'auto',
                                display: 'block',
                                marginLeft: 'auto'
                            }}
                            className='button margin-top margin-bottom primary-button'
                            type='submit'
                        >Search
                        </button>
                    </form>
                    {this.state.dataSource.length > 0 && (<TableDisplay
                        dataActions={[]}
                        dataSource={this.state.dataSource}
                        disableAdd={true}
                        disableFilters={true}
                        loading={this.state.loading}
                        onAdd={() => false}
                        tableHeaders={headers}
                        tableDataClassName='align-items-start overflow-wrap'
                    />)}
                </Card>
            </div>
        )
    }
}

export default DeadLetter;