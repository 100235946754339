const hsaxsetup = {
  us: {
    fields: {
      employerId: {
        name: 'employerId',
        label: 'Choose Employer',
        placeholder: 'Choose Employer'
      },
      version: {
        name: 'version',
        label: 'Choose Version',
        placeholder: 'Choose Version',
        type: 'dropdown',
      },
      minimumFundingAmount: {
        name: 'minimumFundingAmount',
        label: 'Enter Individual Minimum Funding',
        placeholder: '$',
      },
      achFunding: {
        name: 'achFunding',
        label: 'Allow ACH Funding?',
        type: 'checkbox'
      },
      debitFunding: {
        name: 'debitFunding',
        label: 'Allow Debit Card Funding?',
        type: 'checkbox'
      },
      debitForPaymentFunding: {
        name: 'debitForPaymentFunding',
        label: 'Allow Debit Card for Payment (Partner Integration)?',
        type: 'checkbox'
      }
    }
  }
}

export default hsaxsetup
