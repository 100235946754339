import React from "react";
import { hasRole } from "../../../../redux/utility/SessionUtil";
import Field from "components/forms/Field/index";
import Card from "components/Card";
import actions from "actions";
import Loading from "components/Loading";
import Button from "components/Button";
import Modal from 'components/Modal';
import { showSnackbar } from "../../../../redux/utility/SnackbarUtil";

class DefaultEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initializeState();
  }

  componentWillMount() {
    if(this.verifyAccess()) {
      this.getActiveTermsAndConditions()
    }
  }

  /*
   * Initialize State
   */
  initializeState() {
    if(this.props.location.state === undefined) {
      this.props.history.push('/nbtadmin/unauthorized')
      return false;
    }
    return {
      form: this.initializeFormData(),
      submitting: false,
      showSuccessModal: false,
      writeAccess: hasRole("TCMasterEdit"),
      dataSource: [],
      id: this.props.location.state.termsAndConditions.id,
      agreementType: this.props.location.state.termsAndConditions.agreementType,
      name: this.props.location.state.termsAndConditions.fileName,
      type: this.props.location.state.termsAndConditions.type,
      hierarchyId: this.props.location.state.termsAndConditions.hierarchyId,
      selectedIndex: ''
    };
  }
  /*
   * Get Active Terms and Conditions
   */
  getActiveTermsAndConditions() {
    this.setState({ loadingTermsAndConditions: true });
    actions.termsAndConditions.getActiveTermsAndConditions().then(termsAndConditions => {
      const mappedTermsAndConditions = termsAndConditions.map(tc => {
        const { id, agreementType, fileName } = tc;
        return {
          id,
          agreementType,
          fileName,
        };
      });
      const finalTermsAndConditions =
          mappedTermsAndConditions.filter(x => x.agreementType === this.state.type && x.id !== this.state.id)
      this.setState({
        loadingTermsAndConditions: false,
        dataSource: finalTermsAndConditions,
      });
    })
    .catch(() => this.setState({ loadingTermsAndConditions: false }));
  }


  /*
  * Verify that the user has access to account maintenance
  */
  verifyAccess () {
    if (!this.state.writeAccess) {
      this.props.history.push('/nbtadmin/unauthorized')
      return false
    }

    return true
  }

  /*
  * initialize all of the form fields
  */
  initializeFormData() {
    return {
      name: this.props.location.state.termsAndConditions.agreementType,
      pdfName: ''
    };
  }


  /*
   * Update form values
   *
   * @param [String] name. Field name of form to update.
   * @param [Any] value. Value.
   */
  onChange(name, value) {
    let form = { ...this.state.form };
    form[name] = value;
    this.setState({ form });
  }

  onCloseModal() {
    const { history } = this.props
    this.setState({ showSuccessModal: false });
    history.push(`/nbtadmin/terms-and-conditions`)
  }

  /**
   * On submit
   */
  handleSubmit(event) {
    event.preventDefault();
    this.setState({submitting: true});

    const tc = this.state.dataSource[this.state.selectedIndex-1];
    let formData = {
      hierarchyId: this.state.hierarchyId
    }
    if(this.state.type === 'BANKING_AGREEMENT') {
      formData.bankingAgreementId = tc.id
    } else if(this.state.type === 'PRIVACY_AGREEMENT') {
      formData.privacyAgreementId = tc.id
    } else if(this.state.type === 'FEE_AGREEMENT') {
      formData.feeAgreementId = tc.id
    } else if(this.state.type === 'ESIGN_AGREEMENT') {
      formData.esignAgreementId = tc.id
    } else {
      this.setState({submitting: false})
      showSnackbar(
          'System error: Agreement type is not valid.');
    };
    actions.termsAndConditions
    .saveDefault(formData)
    .then(() => this.setState({submitting: false, showSuccessModal: true}))
    .catch(error => {
      console.log(error);
      this.setState({submitting: false})
      showSnackbar(
          'There was an error processing your request')});

  }


  goBack() {
    const {history} = this.props
    history.push(`/nbtadmin/terms-and-conditions`)
  }

  /*
   * Open success modal
   */
  renderSuccessModal() {
    const { showSuccessModal } = this.state
    if (showSuccessModal) {
      return (
          <Modal
              header={"Success"}
              onClose={this.onCloseModal.bind(this)}
              showClose={true}
          >
            <div className='modal-body'>
              <div className='margin-top margin-bottom'>
                <p>Changes successfully made!</p>
              </div>
            </div>
            <div className='modal-footer'>
              <div className='modal-actions'>
                <button
                    className='button primary-button'
                    onClick={this.onCloseModal.bind(this)}
                >Proceed to Terms and Conditions</button>
              </div>
            </div>
          </Modal>
      );
    }
  }

  render() {
    const { form } = this.state;
    if (this.state.loadingTermsAndConditions)
      return <div className='margin-top'><Loading/></div>
    return (
        <div className="page submit-default-edit-form">
          <h4>Edit Default Document for {this.state.agreementType}</h4>
          <i className="material-icons" onClick={this.goBack.bind(this)}>{"chevron_left"}</i>
          <form onSubmit={this.handleSubmit.bind(this)} className="fee-form">
            <Card className="padding">
              <Field
                  name="currentPdf"
                  label="Current Default PDF"
                  disabled={true}
                  type="text"
                  value={this.state.name}
              />
              <Field
                  name="pdfName"
                  required={true}
                  label="Choose New Default PDF"
                  type="dropdown"
                  placeholder="PDF Name"
                  value={form.pdfName}
                  data={this.state.dataSource.map(tc => {
                        const { fileName } = tc
                        return fileName
                      }
                    )}
                  events={{
                    onChange: event => {
                      this.setState({selectedIndex: event.target.selectedIndex})
                      this.onChange("pdfName", event.target.value);
                    }
                  }}
              />
            </Card>
            <Button
                className="primary-button margin-top"
                disabled={this.state.submitting}
                type="submit"
            >
            <span>
              {this.state.submitting ? (
                  <Loading containerClassName="inline" className="white small" />
              ) : (
                  "Submit"
              )}
            </span>
            </Button>
          </form>
          {this.renderSuccessModal()}
        </div>
    );
  }

}
export default DefaultEditForm;
