import * as React from "react"
import PropTypes from 'prop-types'

class ChangeAccountNumber extends React.Component {

  render() {
    return (
      <button
        className='button secondary-button margin-bottom'
        onClick={this.onChangeAccountNumber.bind(this)}
      >Change External Account Id</button>
    )
  }

/*
* Change the external account id
*/
  onChangeAccountNumber () {
    this.props.onChangeAccountId(this.props.account.id)
  }
}

ChangeAccountNumber.propTypes = {
  account: PropTypes.object
}

export default ChangeAccountNumber