const consentType = {
  us: {
    fields: {
      consentType: {
        name: 'consentType',
        label: 'Consent Type',
        type: 'dropdown',
        data: {
          yes : {
            name: 'Positive Consent Only',
            disabled: false
          },
          no : {
            name: 'Negative Consent Only',
            disabled: false
          },
          all : {
            name: 'Full Population',
            disabled: false
          }
        }
      }
    }
  }
}

export default consentType