import React from 'react'
import TableDisplay from 'compositions/TableDisplay'
import actions from 'actions'
import { showSnackbar } from '../../../redux/utility/SnackbarUtil'
import { hasRole } from '../../../redux/utility/SessionUtil'
import {filterDataSource} from 'lib/filter'
import Modal from 'components/Modal'
import Card from 'components/Card'
import Field from "components/forms/Field/index"
import disclaimer from 'data/disclaimer'
import DisclaimerForm from './DisclaimerForm'


const commonHeaders = [
  {
    filterText: 'Disclaimer Type',
    header: () => 'Disclaimer Type',
    key: 'disclaimerType'
  },
  {
    filterText: 'Tag',
    header: () => 'Tag',
    key: 'tag'
  },
  {
    filterText: 'Disclaimer Text',
    header: () => 'Disclaimer Text',
    key: 'disclaimerText'
  }
]

const custodianHeaders = ([
  {
    filterText: 'Custodian Name',
    header: () => 'Custodian Name',
    key: 'custodianName'
  }
]).concat(commonHeaders)

const masterHeaders = ([
  {
    filterText: 'Name',
    header: () => 'Name',
    key: 'masterName'
  }
]).concat(commonHeaders)

class Disclaimers extends React.Component {
  constructor (props) {
    super (props);
    this.state = {
      form: this.initializeFormData(),
      dataSource: [],
      filteredDataSource: [],
      currentDisclaimer: null,
      openDisclaimerModal: false,
      typeOfAction: 'Edit',
      readAccess: hasRole('EmailMasterView'),
      writeAccess: hasRole('EmailMasterEdit')
    }

    this.actions = this.state.writeAccess ? [
      {
        action: this.onEdit.bind(this),
        icon: 'edit'
      }
    ] : []
  }

  initializeFormData() {
    return { hierarchyLevel: 'custodian' || ''}
  }

  /*
  * Get all disclaimers by Custodians (default level)
  */
  componentDidMount () {
    if (this.verifyAccess()) {
      this.getCustodianDisclaimers()
    }
  }

  /*
  * Verify that the user has access to disclaimers
  */
  verifyAccess () {
    if (!(this.state.readAccess || this.state.writeAccess)) {
      this.props.history.push('/nbtadmin/unauthorized')
      return false
    }

    return true
  }

  /*
   * Get Hierarchy Levels
   */

   getHierarchyLevels() {
    const {
      form: { hierarchyLevel }
    } = this.state

    const { fields } = disclaimer.us
    return [
      {
        ...fields.hierarchyLevel,
        required: true,
        events: {
          onChange: event => {
            this.onChange(fields.hierarchyLevel.name, event.target.value)
            if (event.target.value === 'custodian') {
              this.getCustodianDisclaimers()
            }
            else if (event.target.value === 'master') {
              this.getMasterDisclaimers()
            }
          }
        },
        value: hierarchyLevel
      }
    ]
  }

  /*
  * Fetch disclaimers from API Response
  */
  getDisclaimersFromEmployerConfig(templates) {
    const mappedTemplates = templates.map(employerConfig => {
      let disclaimers=[]
      disclaimers.push({key:"hsa_disclaimer",type:"HSADisclaimer",tag:"${hsa_disclaimer}",value:employerConfig.hsaDisclaimer})
      disclaimers.push({key:"email_disclaimer",type:"EmailDisclaimer",tag:"${email_disclaimer}",value:employerConfig.emailDisclaimer})
      disclaimers.push({key:"investment_disclaimer",type:"InvestmentDisclaimer",tag:"${investment_disclaimer}",value:employerConfig.investmentDisclaimer})
      disclaimers.push({key:"mobile_disclaimer",type:"MobileDisclaimer",tag:"${mobile_disclaimer}",value:employerConfig.mobileDisclaimer})
      disclaimers.push({key:"copyright_disclaimer",type:"CopyrightDisclaimer",tag:"${copyright_disclaimer}",value:employerConfig.copyrightDisclaimer})
      disclaimers.push({key:"mail_disclaimer",type:"MailDisclaimer",tag:"${mail_disclaimer}",value:employerConfig.mailDisclaimer})
      disclaimers.push({key:"statement_disclaimer",type:"StatementDisclaimer",tag:"${statement_disclaimer}",value:employerConfig.statementDisclaimer})
      const disclaimerHierarchies=disclaimers.map(types=>{
          const {key,type,tag,value}=types;
          const none="N/A"
          const hierarchyLevel = this.state.form.hierarchyLevel
          return {
            hierarchyLevel: hierarchyLevel,
            custodianId: employerConfig.hsaProcessorId,
            custodianName: (hierarchyLevel === "custodian") ? employerConfig.custodianName : none,
            masterName: (hierarchyLevel === "master") ? "MASTER" : none,
            disclaimerType: type,
            tag: tag,
            disclaimerText: (value!==undefined&&value!==null)?<div style={{overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap',width:'75em'}}>{value}</div>:none,
            key: key,
          };
      })
      return disclaimerHierarchies;
    })
    var disclaimersList = mappedTemplates.flat(1)
    this.setState({dataSource: disclaimersList, filteredDataSource: disclaimersList, loading: false})
  }

  /*
  * Fetch disclaimers by Custodians
  */
  getCustodianDisclaimers () {
    this.setState({loading: true})
    actions.disclaimers.getDisclaimersByCustodians().then(templates => this.getDisclaimersFromEmployerConfig(templates))
    .catch(() => {
      this.setState({loading: false})
      showSnackbar('Failed to retrieve data')
    })
  }

  /*
  * Fetch disclaimers by Master
  */
  getMasterDisclaimers () {
    this.setState({loading: true})
    actions.disclaimers.getDisclaimersByMaster().then(templates => this.getDisclaimersFromEmployerConfig([templates]))
    .catch(() => {
      this.setState({loading: false})
      showSnackbar('Failed to retrieve data')
    })
  }

  /*
  * Render transfer form sections
  */
  renderTransfers() {
    return this.state.form.hierarchyLevel === "custodian"
      ? this.renderCustodian()
      : this.renderMaster()
  }

  /*
  * Render disclaimers by Custodians
  */
  renderCustodian() {
    return (
      <div>
        <TableDisplay 
          dataActions={this.actions}
          dataSource={this.state.filteredDataSource}
          disableAdd={true}
          loading={this.state.loading}
          onFilter={this.onFilter.bind(this)}
          onRowClick={this.onRowClick.bind(this)}
          tableHeaders={custodianHeaders}
        />
        {this.renderDisclaimerModal()}
      </div>
    )
  }

  /*
  * Render disclaimers by Master
  */
  renderMaster() {
    return (
      <div>
        <TableDisplay 
          dataActions={this.actions}
          dataSource={this.state.filteredDataSource}
          disableAdd={true}
          loading={this.state.loading}
          onFilter={this.onFilter.bind(this)}
          onRowClick={this.onRowClick.bind(this)}
          tableHeaders={masterHeaders}
        />
        {this.renderDisclaimerModal()}
      </div>
    )
  }

  /*
  * Render view and edit disclaimer form
  */
  renderDisclaimerModal () {
    const {match: {params: {custodianId, custodianName, masterName, key}}} = this.props
    const {currentDisclaimer, openDisclaimerModal, typeOfAction} = this.state
    if (openDisclaimerModal) {
        return (
          <Modal max-height="100%"
            header={`${this.state.typeOfAction} Disclaimer`}
            onClose={this.onCloseDisclaimerModal.bind(this)}
            showClose={true}
          >
            <DisclaimerForm
                hierarchyLevel={this.state.form.hierarchyLevel}
                custodianId={custodianId}
                custodianName={custodianName}
                masterName={masterName}
                disclaimerKey={key}
                currentDisclaimer={currentDisclaimer}
                typeOfAction={typeOfAction}
                onClose={this.onCloseDisclaimerModal.bind(this)}
                disclaimersList={this.state.dataSource}
                edit={this.state.currentDisclaimer !== null}
                onSubmit={this.onSubmit.bind(this)}
            />
          </Modal>
        )
    }
  }

  /*
  * On submit disclaimer
  */
  onSubmit () {
    (this.state.form.hierarchyLevel === "custodian") 
    ? this.getCustodianDisclaimers()
    : this.getMasterDisclaimers()
  }

  /*
  * View disclaimer
  */
  onRowClick (disclaimer) {
    const currentDisclaimerProfile = (this.state.form.hierarchyLevel === "custodian") 
    ? this.state.dataSource.find(tc => tc.custodianId === disclaimer.custodianId && tc.key === disclaimer.key) 
    : this.state.dataSource.find(tc => tc.key === disclaimer.key)

    this.setState({openDisclaimerModal: true,
        currentDisclaimer: currentDisclaimerProfile,
        typeOfAction: 'View'
    })
  }

  /*
  * On edit Disclaimer
  */
  onEdit (disclaimer) {
      const currentDisclaimerProfile = (this.state.form.hierarchyLevel === "custodian") 
      ? this.state.dataSource.find(tc => tc.custodianId === disclaimer.custodianId && tc.key === disclaimer.key) 
      : this.state.dataSource.find(tc => tc.key === disclaimer.key)

      this.setState({openDisclaimerModal: true,
        currentDisclaimer: currentDisclaimerProfile,
        typeOfAction: 'Edit'
      })
  }

  /*
  * On Disclaimer modal close.
  */
  onCloseDisclaimerModal () {
    this.setState({
      openDisclaimerModal: false,
      currentDisclaimer: null
    })
  }

  /*
  * Filter data source.
  *
  * @param [Object] queryParams. Key value pair containing filters.
  */
  onFilter (queryParams) {
    const {dataSource} = this.state
    const newDataSource = filterDataSource(dataSource, queryParams)
    this.setState({filteredDataSource: newDataSource})
  }

  /*
  * Update form values
  *
  * @param [String] name. Field name of form to update.
  * @param [Any] value. Value.
  */
  onChange(name, value) {
    let form = { ...this.state.form }
    form[name] = value
    this.setState({ form })
  }

  render () {
    return (
      <div className='search-page disclaimer-view'>
        <h4>Disclaimers</h4>
        <p>Manage disclaimers.</p>
        <form>
          <Card className="padding">
            {this.getHierarchyLevels().map((f, idx) => {
              return <Field key={`${f.name}-${idx}`} {...f} />
            })}
          </Card>
          {this.renderTransfers()}
        </form>
      </div>
    )
  }
}

export default Disclaimers
