import React from 'react'
import Card from 'components/Card'
import Field from 'components/forms/Field/index'
import feeProfile from 'data/feeProfile'

class ProfileSpecificFee extends React.Component {
  constructor (props) {
    super (props)
    this.state = {
      form: this.initializeFormData(props.fee)
    }
  }

  componentWillReceiveProps () {
    this.setState({form: this.initializeFormData(this.props.fee)})
  }

  /*
  * Initialize form data
  * Fee.
  */
  initializeFormData (data) {
    const {id, feeId, amount, paidBy, thresholdAmount, thresholdType, enrollmentStatus, disableThreshold } = data || {}
    return {
      id: id || '',
      feeId,
      amount,
      paidBy,
      thresholdAmount,
      thresholdType,
      enrollmentStatus: enrollmentStatus || 'NotApplicable',
      disableThreshold
    }
  }

  /*
  * Render the fields
  */
  getFields () {
    const {form: {
      feeId,
      amount,
      paidBy,
      thresholdAmount,
      thresholdType,
      enrollmentStatus,
      disableThreshold
    }} = this.state

    const {feeSpecificFields} = feeProfile.us
    const fields = feeSpecificFields
    const {formIndex} = this.props

    return [
        {
          ...fields.feeId,
          name: `${fields.feeId.name}-${formIndex}`,
          required: true,
          events: {
            onChange: (event) => {
              this.onChange(fields.feeId.name, event.target.value)
            },
            onBlur: () => {
              this.props.onChangeChild()
            }
          },
          value: feeId
        },
        {
          ...fields.thresholdAmount,
          name: `${fields.thresholdAmount.name}-${formIndex}`,
          disabled : disableThreshold,
          events: {
            onChange: (event) => {
              this.onChange(fields.thresholdAmount.name, event.target.value)
            },
            onBlur: () => {
              this.props.onChangeChild()
            }
          },
          type: 'number',
          attrs: {
            min: '0',
            step: "0.10",
          },
          value: thresholdAmount
        },
        {
          ...fields.thresholdType,
          name: `${fields.thresholdType.name}-${formIndex}`,
          disabled : disableThreshold,
          events: {
            onChange: (event) => {
              this.onChange(fields.thresholdType.name, event.target.value)
            },
            onBlur: () => {
              this.props.onChangeChild()
            }
          },
          value: thresholdType
        },
        {
          ...fields.amount,
          name: `${fields.amount.name}-${formIndex}`,
          required: true,
          events: {
            onChange: (event) => {
              this.onChange(fields.amount.name, event.target.value)
            }
          },
          type: 'number',
          attrs: {
            max: '100',
            min: '0',
            step: 'any'
          },
          value: amount
        },
        {
          ...fields.paidBy,
          name: `${fields.paidBy.name}-${formIndex}`,
          required: true,
          events: {
            onChange: (event) => {
              this.onChange(fields.paidBy.name, event.target.value)
            }
          },
          value: paidBy
        },
        {
          ...fields.enrollmentStatus,
          name: `${fields.enrollmentStatus.name}-${formIndex}`,
          events: {
            onChange: (event) => {
              this.onChange(fields.enrollmentStatus.name, event.target.value)
            }
          },
          value: enrollmentStatus
        }
      ]
  }

  /*
  * Update form values
  *
  * @param [String] name. Field name of form to update.
  * @param [Any] value. Value.
  */
  onChange (name, value) {
    let form = {...this.state.form}
    form[name] = value
    this.setState({form})
  }

  /*
  * Expose method to return form
  */
  getData () {
    return this.state.form
  }

  render () {
    const {formIndex, fees} = this.props
    const fields = this.getFields()
    return (
      <Card className='padding'>
        <div className=''>
          <a onClick={() => this.props.onDelete(formIndex)}>
            <i className='material-icons orange'>remove_circle_outline</i>
          </a>
        </div>
        <div className='row'>
          <div className='col'>
            <Field key={`${fields[0].name}-${formIndex}`} {...fields[0]} data={fees} />
          </div>
          <div className='col'>
            <Field key={`${fields[1].name}-${formIndex}`} {...fields[1]} />
          </div>
          <div className='col'>
            <Field key={`${fields[2].name}-${formIndex}`} {...fields[2]} />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <Field key={`${fields[3].name}-${formIndex}`} {...fields[3]}  />
          </div>
          <div className='col'>
            <Field key={`${fields[4].name}-${formIndex}`} {...fields[4]}  />
          </div>
          <div className='col'>
            <Field key={`${fields[5].name}-${formIndex}`} {...fields[5]}  />
          </div>
        </div>
        {/*this.getFields().map((f, idx) => {
          return <Field key={`${f.name}-${formIndex}-${idx}`} {...f} />
        })*/}
      </Card>
    )
  }
}

export default ProfileSpecificFee
