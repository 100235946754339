/**
 * Simple utils object...mix as you wish...
 */
const Utils = {
  String: {
    toTitleCase: (str) => {
      return str
              .replace(/_/g, ' ')
              .replace(/\w\S*/g, (txt) =>
                txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase() )
    },

    toCleanSlug: (str, glue='-') => {
      return str.toLowerCase().replace(/ /gi, glue)
    },

    toClassName: (str) => {
      return str.toLowerCase().replace(/ /gi, '-').replace(/_/gi, '-')
    }
  },

  Number: {
    toCurrency: (n) => {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      });

      return formatter.format(n).replace(/\.\d{2}/g, '');
    }
  },

  getCurrentDateTime: () => {
    const dt = new Date()
    const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr)
    return `${
      padL(dt.getMonth()+1)}${
      padL(dt.getDate())}${
      dt.getFullYear()}${
      padL(dt.getHours())}${
      padL(dt.getMinutes())}${
      padL(dt.getSeconds())}`
  }

}

export default Utils
