import React from 'react'
import PropTypes from 'prop-types'

import './side-nav.scss'

class SideNavItem extends React.Component {
  renderSubNavItems () {
    const {children} = this.props
    if (children && children.length > 0) {
      return (
        <ul>{this.props.children}</ul>
      )
    }
  }
  
  render () {
    const {className, icon, label, onClick} = this.props
    const classes = ['side-nav-item']
    if (className) classes.push(className)

    return (
      <li className={classes.join(' ')}>
        <a onClick={() => onClick()}>
          {icon}
          <span>{label}</span>
        </a>
        {this.renderSubNavItems()}
      </li>
    )
  }
}

SideNavItem.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  label: PropTypes.string,
  onClick: PropTypes.func
}
export default SideNavItem
