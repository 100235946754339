import React from 'react'
import Field from 'components/forms/Field/index'
import actions from 'actions'
import disclaimer from 'data/disclaimer'
import { showSnackbar } from '../../../redux/utility/SnackbarUtil'

class DisclaimerForm extends React.Component {
  constructor (props) {
    super (props)
    this.state = {
      disclaimers: {},
      form: this.initializeFormData(props.currentDisclaimer)
    }
  }

  /*
  * Initialize form data
  * Disclaimer
  */
  initializeFormData (data) {
    const {hierarchyLevel, custodianId, custodianName, masterName, disclaimerType, tag, disclaimerText, key, typeOfAction} = data || {}
    return {
      hierarchyLevel: hierarchyLevel || '',
      custodianId: custodianId || '',
      custodianName: custodianName || '',
      masterName: masterName || '',
      disclaimerType: disclaimerType || '',
      tag: tag || '',
      defaultDisclaimerText: disclaimerText === 'N/A' ? 'N/A' : disclaimerText.props.children,
      disclaimerKey: key || '',
      disclaimerText: '',
      typeOfAction: typeOfAction
    }
  }

  /*
  * Render the fields
  */
  getFields () {
    const {form: {
      custodianName,
      masterName,
      disclaimerType,
      tag,
      defaultDisclaimerText,
      disclaimerText
    }} = this.state

    const {fields} = disclaimer.us

    const commonFields = [
      {
        ...fields.disclaimerType,
        value: disclaimerType,
        attrs: {
          disabled: true,
          style: {
              backgroundColor: "white"
          }
        }
      },
      {
        ...fields.tag,
        value: tag,
         attrs: {
          disabled: true,
          style: {
              backgroundColor: "white"
          }
        }
      },
      {
        ...fields.defaultDisclaimerText,
        value: defaultDisclaimerText,
        attrs: {
          disabled: true,
          style: {
              height: '100px',
              minHeight: '100px',
              resize: 'vertical',
              fontFamily: ["Open Sans", "Helvetica Neue", "Helvetica", "sans-serif"],
              color: "gray"
          }
        },
        type: 'textarea',

      },
      {
        ...fields.disclaimerText,
        required: false,
        type: 'textarea',
        attrs: {
          style: {
              height: '100px',
              minHeight: '100px',
              resize: 'vertical',
              fontFamily: ["Open Sans", "Helvetica Neue", "Helvetica", "sans-serif"]
          }
        },
        events: {
          onChange: (event) => {
            this.onChange(fields.disclaimerText.name, event.target.value)
          }
        },
        value: disclaimerText
      }
    ]

    if (this.state.form.hierarchyLevel === "custodian") {
      return ([
        {
          ...fields.custodianName,
          value: custodianName,
          attrs: {
            disabled: true,
            style: {
                backgroundColor: "white"
            }
          }
        }
      ]).concat(commonFields)
    }
    return ([
        {
          ...fields.masterName,
          value: masterName,
          attrs: {
            disabled: true,
            style: {
                backgroundColor: "white"
            }
          }
        }
      ]).concat(commonFields)
  }

  /*
  * Update form values
  *
  * @param [String] name. Field name of form to update.
  * @param [Any] value. Value.
  */
  onChange (name, value) {
    let form = {...this.state.form}
    form[name] = value
    this.setState({form})
  }

  /*
  * Expose method to return form
  */
  getData () {
    return this.state.form
  }

  /*
  * Update disclaimer by custodian id and disclaimer type
  *
  */
  onSubmit (event) {
    event.preventDefault()
    if(this.state.form.disclaimerText.trim() === '') {
        this.props.onClose()
        showSnackbar('Disclaimer text cannot be empty', 'warn')
    } else {
        if (this.state.form.hierarchyLevel === "custodian") {
          actions.disclaimers.updateDisclaimerByCustodianIdAndType(this.state.form).then((disclaimer) => {
            this.props.onSubmit(disclaimer)
            this.props.onClose()
          }).catch(() => false)
        } else {
          actions.disclaimers.updateDisclaimerByMaster(this.state.form).then((disclaimer) => {
            this.props.onSubmit(disclaimer)
            this.props.onClose()
          }).catch(() => false)
        }
    }
  }

  render () {
    const {disclaimer} = this.state
    const fields = this.getFields()
    let displayType= this.props.typeOfAction == 'View'? 'none': 'block'
    return (
      <div>
        <div className='modal-body'>
          <div className='row margin-top'>
            <div className='col'>
              <Field key={`${fields[0].name}`} {...fields[0]} data={disclaimer} />
            </div>
            <div className='col'>
              <Field key={`${fields[0].name}`} {...fields[1]}  />
            </div>
            <div className='col'>
              <Field key={`${fields[1].name}`} {...fields[2]}  />
            </div>
          </div>
          <div className='row margin-top'>
            <div className='col'>
              <Field key={`${fields[2].name}`} {...fields[3]}  style={{height: '50px'}}/>
            </div>
          </div>
          <div className='row margin-bottom'>
            <div className='col' style={{display: `${displayType}`}}>
              <Field key={`${fields[3].name}`} {...fields[4]}  />
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          <div className='modal-actions'>
            <button style={{display: `${displayType}`}}
              className='button primary-button'
              onClick={this.onSubmit.bind(this)}
            >SUBMIT</button>
          </div>
        </div>
      </div>
    )
  }
}

export default DisclaimerForm
