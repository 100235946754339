import Fetch from 'lib/fetch'

/*
* Search Hierarchies
*
* @param [String] params. Params to search heiarchies by
*/
export function getHierarchies (params) {
  return Fetch.post(`${process.env.REACT_APP_NBT_ADMIN_DOMAIN}/portal-data/hierarchies/employers/search`, params, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

/*
* Get hierarchy by id.
*
* @param [Number] id. Id of heiarchy.
*/
export function getHierarchyById (id) {
  return Fetch.get(`${process.env.REACT_APP_NBT_ADMIN_DOMAIN}/portal-data/hierarchies/${id}`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

/*
* Initialize the heierarchy search params.
*
*/
export function initializeSearchParams () {
  return Fetch.get(`${process.env.REACT_APP_NBT_ADMIN_DOMAIN}/portal-data/hierarchies/initialize-search`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

/*
* Get the distributors assocaited with sponsors.
*
* @param [Array] sponsorIds. Array of sponsor ids.
*/
export function getDistributorsBySponsors (sponsorIds) {
  return Fetch.post(`${process.env.REACT_APP_NBT_ADMIN_DOMAIN}/portal-data/hierarchies/distributors-by-sponsors`, sponsorIds, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

/*
* Gets a list of HierarchyMinimals using m_hierarchy.id and m_hierarchy.hierarchy_type. 
* Returned list will include the Hierarchy of the caller.
* Hierarchy Types: 7300 = Master, 7301 = Sponsor, 7302 = Distributor, 7303 = Employer
*
* @param [Number] hierarchyId. m_hierarchy.id to get the parent hierarchies for.
*/
export function getHierarchyMinimalListByHierarchyIdAndHierarchyType (hierarchyId) {
  return Fetch.get(`${process.env.REACT_APP_PORTAL_DATA_DOMAIN}/hierarchies/${hierarchyId}/minimal-by-hierarchy-type`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}
