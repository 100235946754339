import React from 'react'
import Card from "../../../../components/Card";
import Field from "../../../../components/forms/Field";
import AutoCompleteField from "../../../../components/forms/AutoCompleteField";
import actions from '../../../../actions';

class EnrollmentSearchForm extends React.Component {
    constructor(props) {
        super(props); 
        this.state = {
            form: this.initializeFormData(),
            employerText: '',
            employerDataSource: [],
            loadingEmployers: false
        }
    }
    
    componentDidMount() {
        this.initializeEmployerText()
    }

    initializeFormData() {
        const {match: {params: {employeeId, employerId}}} = this.props
        return {
          employeeId: employeeId || '',
          employerId: employerId || ''
        }
    }

    initializeEmployerText() {
        const {match: {params: {employerId}}} = this.props
        if(employerId != null) {
            actions.employers.getEmployerById(employerId)
                .then(employer => {
                    if (employer.name!=null) {
                        this.setState({
                            employerText:employer.name
                        })
                    }
                })
        }
    }

    handleSubmit(event) {
        event.preventDefault()
        if (this.validateForm()) {
            this.props.onSubmit(this.state.form)
        }
    }

    onChange(name, value){
        let form = {...this.state.form}
        form[name] = value
        this.setState({form})
      }
      
    validateForm() {
        const {employeeId, employerId} = this.state.form
        return employeeId!=null && employerId!=null
    }

    getEmployers() {
        this.setState({ loadingEmployers: true });
        actions.employers
            .getEmployers({ employerName: this.state.employerText })
            .then(employers => {
                const mappedEmployers = employers.map(employer => {
                    const { id, name } = employer;
                    return {
                        id,
                        name
                    };
                });

                this.setState({
                    employerDataSource: mappedEmployers,
                    loadingEmployers: false
                });
            })
            .catch(() => this.setState({ loadingEmployers: false }));
    }

    renderAutoCompleteRow(data) {
        return (
        <div
            className="autocomplete-row"
            onClick={() => {
                this.onChange("employerId", data.id);
                this.setState({ employerText: data.name, employerDataSource: [] }); }
            }>
            {data.name}
        </div>
        );
    }

    render() {
        const {form} = this.state
        return(
            <Card className='padding'>
                <form className='' onSubmit={this.handleSubmit.bind(this)}>
                    <Field 
                        name='employeeId'
                        required={true}
                        label='Enter Employee ID'
                        placeholder='Employee ID'
                        value={form.employeeId}
                        events={
                            {
                                onChange: (event) => {this.onChange('employeeId', event.target.value)}
                            }
                        }
                        type='number'
                    />
                    <AutoCompleteField
                        name='employerText'
                        required={true}
                        label='Choose Employer'
                        placeholder='Choose Employer'
                        value={this.state.employerText}
                        events={
                            {
                                onChange: (name, value) => {
                                    if (value.length < this.state.employerText.length)
                                        this.onChange("employerId", "");
                                    this.setState({ employerText: value });
                                }
                            }
                        }
                        dataValue={form.employerId}
                        dataSource={this.state.employerDataSource}
                        limit={20}
                        onSearch={this.getEmployers.bind(this)}
                        renderRow={this.renderAutoCompleteRow.bind(this)}
                    />
                    <button style={{width: 'auto', display: 'block', marginLeft: 'auto'}}
                            className='button margin-top primary-button'
                            type='submit'>Search </button>
                </form>
            </Card>
        )
    }
}

export default EnrollmentSearchForm