import React from 'react'
import Button from 'components/Button'
import Card from 'components/Card'
import Loading from "components/Loading"
import { hasRole } from '../../../redux/utility/SessionUtil'
import actions from 'actions'
import { showSnackbar } from '../../../redux/utility/SnackbarUtil'


class TransactionUpload extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.initializeState()
    this.onChange.bind(this)
  }

  componentWillMount () {
   this.verifyAccess()
  }

  /*
  * Verify that the user has access to upload transaction
  */
  verifyAccess () {
    if (!(this.state.readAccess || this.state.writeAccess)) {
      this.props.history.push('/nbtadmin/unauthorized')
      return false
    }

    return true
  }

  /*
  * Initialize State
  */
  initializeState() {
    return {
      submitting: false,
      readAccess: hasRole('AccountMaintView'),
      writeAccess: hasRole('AccountMaintEdit')
    }
  }

  /*
  * Update form values
  *
  * @param [String] name. Field name of form to update.
  * @param [Any] value. Value.
  */
  onChange (name, value) {
    let form = {...this.state.form}
    form[name] = value
    this.setState({form})
  }

  /**
   * On submit
   */
  handleSubmit(event) {
    event.preventDefault()
    if (this.validateSubmit()) {
      return actions.transactionUpload.transactionUpload(this.fileInput.files[0])
        .then((response) => {
          this.setState(this.initializeState())
          this.fileInput.value = ''
        })
    }
  }

  validateSubmit() {
    if (!this.fileInput.value) showSnackbar('File selection is required.')
    else return true

    return false
  }

  render () {

    return (
      <div className='page upload-toa-view'>
        <h4>Upload Batch Transactions</h4>
        <form onSubmit={this.handleSubmit.bind(this)} className="fee-form">
          <Card className='padding'>
            <div className='form-control margin-top'>
              <label className='input-label' htmlFor={'file-input'}>Select File To Upload</label>
              <div>
                <input
                  name='file-input'
                  type='file'
                  ref={input => {this.fileInput = input;}}
                />
              </div>
            </div>
          </Card>
          <Button
            className='primary-button margin-top'
            disabled={this.state.submitting}
            type="submit"
          >
            <span>

              {this.state.submitting ?
                <Loading containerClassName='inline' className='white small' />
                : 'Submit'
              }
            </span>
          </Button>
        </form>
      </div>
    )
  }
}

export default TransactionUpload
