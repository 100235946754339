import React from "react"

import actions from "actions"
import { hasRole } from '../../../../redux/utility/SessionUtil'
import Card from "components/Card";
import EmployerSearch from "../../TransferConsent/EmployerSearch";
import EmployersTable from "../../TransferConsent/EmployerSearch/EmployersTable";

class UnenrolledTransfers extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initializeState();

    this.actions = [
      {
        // action: this.onView.bind(this),
        icon: 'refresh'
      }
    ]
  }

  initializeState() {
    return {
      readAccess: hasRole('BulkTransferEdit'),
      writeAccess: hasRole('BulkTransferEdit'),
      employer: null,
      hasSearched: false,
      showSearch: true
    }
  }

  verifyAccess() {
    if (!(this.state.readAccess || this.state.writeAccess)) {
      this.props.history.push('/nbtadmin/unauthorized')
      return false
    }

    return true
  }

  componentDidMount() {
    this.verifyAccess()
  }

  render() {
    return (
        <div className='search-page unenrolled-transfer-data-view'>
          <h4>Find Unenrolled Transfers For Employer</h4>
          { <i className="material-icons"
             onClick={this.toggleSearch.bind(this)}>{this.state.showSearch
              ? 'chevron_left' : 'chevron_right'}</i> }
          <div style={{width: "100%", margin: "0 auto", display: this.state.showSearch ? 'block' : 'none'}}>
            <Card className='padding'>
              <EmployerSearch
                  {...this.props}
                  onSubmit={this.onEmployerSearch.bind(this)}
              />
            </Card>
          </div>
          {this.state.hasSearched && this.renderEmployerSearch()}
        </div>
    )
  }

  renderEmployerSearch() {
    return (
        <div className='margin-top margin-bottom'>
          <EmployersTable
              {...this.props}
              title=''
              dataSource={this.state.dataSource}
              loading={this.state.loadingEmployerSearch}
              onRowClick={this.onRowClick.bind(this)}
          />
        </div>
    )
  }


  onEmployerSearch(value) {
    this.setState({loadingEmployerSearch: true})
    return actions.employers.getEmployers(value).then(employers => {
      const mappedEmployers = employers.map(employer => {
        const {id, name} = employer
        return {
          id,
          name
        }
      })
      this.setState({
        dataSource: mappedEmployers,
        loadingEmployerSearch: false,
        showSearch: false,
        hasSearched: true
      })
    }).catch(() => this.setState({loadingEmployerSearch: false}))
  }

  toggleSearch() {
    if (this.state.hasSearched) {
      this.setState({showSearch: !this.state.showSearch})
    }
  }

  onRowClick(employer){
    const {history} = this.props
    history.push(`/nbtadmin/btw/unenrolled-transfers/table/${employer.id}`)
  }

}
export default UnenrolledTransfers
