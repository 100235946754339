import Fetch from 'lib/fetch'

export function uploadFile(file) {
    let form = new FormData();
    form.append(`file`, file);
    return Fetch.postMultipartBatch(`${process.env.REACT_APP_PORTAL_DATA_DOMAIN}/beneficiary-upload`, form)
        .then(data => data)
        .catch(error => Promise.reject(error))
}

export function getFileTrackingRecords(form) {
    let queryParams = Object.keys(form).filter(key => form[key]).map(key => {
        return [key, form[key]].map(encodeURIComponent).join("=")
    }).join("&");
    return Fetch.get(`${process.env.REACT_APP_PORTAL_DATA_DOMAIN}/beneficiary-upload/file-tracking?${queryParams}`, null)
        .catch(error => Promise.reject(error))
}

export function getFileDetailRecords(form) {
    let queryParams = Object.keys(form).filter(key => form[key]).map(key => {
        return [key, form[key]].map(encodeURIComponent).join("=")
    }).join("&");
    return Fetch.get(`${process.env.REACT_APP_PORTAL_DATA_DOMAIN}/beneficiary-upload/file-detail?${queryParams}`, null)
        .catch(error => Promise.reject(error))
}

export function getFileDetailDownload(trackingId) {
    return Fetch.getFile(`${process.env.REACT_APP_PORTAL_DATA_DOMAIN}/beneficiary-upload/file-detail-download?trackingId=${trackingId}`, null)
        .then(response => {
            if (response.ok) {
                return response
            }
            return Promise.reject(response)
        })
}
