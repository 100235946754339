import Fetch from 'lib/fetch'
import { showSnackbar } from '../redux/utility/SnackbarUtil'

/*
* Get all files for BAI Manual Reconciliation
*/
export function getBAIReconFiles () {
    return Fetch.get(`${process.env.REACT_APP_ACCOUNTS_DOMAIN}/wire-transfers/pending-reconciliation`, null)
        .then(data => data)
        .catch(error => Promise.reject(error))
}

export function submitReconFile(fileId) {
    return Fetch.post(`${process.env.REACT_APP_ACCOUNTS_DOMAIN}/wire-transfers/submit-for-recon/${fileId}`, null)
    .then(data => showSnackbar('File successfully submitted for reconciliation', 'info'))
    .catch(error => {
        showSnackbar('Failed to submit file for reconciliation', 'error');
        Promise.reject(error)
    })
}