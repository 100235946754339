import React from 'react'
import Loading from "components/Loading"
import actions from 'actions'
import TableView from "compositions/TableDisplay/TableView";
import { filterDataSource } from "lib/filter";
import Papa from 'papaparse'

const headers = [
  {
    filterText: "File Name",
    header: () => "Final Report (Click to download)",
    key: "filename"
  },
  {
    filterText: "Status",
    header: () => "Status",
    key: "status"
  },
  {
    filterText: "Uploaded By",
    header: () => "Uploaded By",
    key: "uploadedBy"
  },
  {
    filterText: "Date",
    header: () => "Date (yyyy-mm-dd)",
    key: "date"
  }
]

class ViewEscheatmentReport extends React.Component{
    constructor (props) {
        super (props);
        this.state = {
            dataSource: [],
            filteredDataSource: [],
            data: []
        };
        this.timer = null
        this.actions = [];
    }

    onFilter (queryParams) {
      const { dataSource } = this.state;
      const newDataSource = filterDataSource(dataSource, queryParams);
      this.setState({ filteredDataSource: newDataSource });
    }

    componentDidMount() {
      this.getEscheatmentReport()
      this.timer = setInterval(()=> this.getUpdatedEscheatmentReport(), 15000);
    }

    /*
    * Get all transactions
    */
    componentWillUnmount () {
      clearInterval(this.timer)
      this.timer=null;
    }

    async generateCsvFile(jobId, filename) {
      const data = await actions.accountEscheatment.getAccountEscheatmentJobDetails(jobId).then(data => {
        return data
      }).catch(() => {
        this.setState({loading: false})
      })

      const parsedCsv = Papa.unparse(data, { newline: '\n' });
      const blob = new Blob([parsedCsv], { type: 'text/csv'});
      
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', filename);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }

    getFormattedDownloadLink(filename, jobId, status){
        if(status == 'Processed')
          return (<button onClick={() => this.generateCsvFile(jobId, filename)}>{filename}</button>)
        else
          return (<h4 title="Report can be downloaded once status changes to 'Processed'">{filename}</h4>)
    }

    getFormattedStatus(status){
        if(status=='Processed')
            return (<h4 style={{color: "green"}}>{status}</h4>)
        else if(status=='Failed')
          return (<h4 style={{color: "red"}}>{status}</h4>)
        return (<h4 style={{color: "orange"}}>{status}</h4>)
    }

    getUpdatedEscheatmentReport () {
        const results = {};
        return actions.accountEscheatment.getAccountEscheatmentReport().then(
            escheatments => {
                const mappedEscheatments = escheatments.map(escheatment => {
                  const {id, filename, uploadedBy, status, modified} = escheatment
                    return {
                        filename: this.getFormattedDownloadLink(filename, id, status),
                        uploadedBy, 
                        date: modified,
                        status: this.getFormattedStatus(status)
                    }
                    }).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
                    )
                      results.escheatments = mappedEscheatments;
                      this.setState({ dataSource: results.escheatments, filteredDataSource: results.escheatments, loading: false})
                }).catch((err) => {})
    }

    getEscheatmentReport () {
      this.setState({loading: true})
      this.getUpdatedEscheatmentReport ()
    }

  render() {
    if(!this.props.access){
        return(
            <div>
                <h4 style={{color:"red", textAlign: 'center'}}>Unauthorized. You are not authorized to view the report.</h4>
            </div>
        );
    }
    if (this.state.loading)
        return (
            <div>
                <Loading />
            </div>
        );
    const { filteredDataSource } = this.state;
    return (
        <div>
          <div className="padding">
            <div className="row">
              <div className="col">
          <h5>Account Escheatment Report</h5>
              </div>
            </div>
            <div className="margin-top" />
            <div className="fixed-height-scroll">
            <TableView
              dataActions={this.actions}
              dataSource={filteredDataSource}
              disableFilters={false}
              tableHeaders={headers}
              tableFilters={headers}
              onFilter ={this.onFilter.bind(this)}
            /></div>
        </div>
        </div>
      );
  }
}
export default ViewEscheatmentReport;