import Fetch from 'lib/fetch'
import { showSnackbar } from '../redux/utility/SnackbarUtil'

/*
* Get all email templates
*/
export function getEmailTemplates () {
  return Fetch.get(`${process.env.REACT_APP_EMAIL_DOMAIN}/email/templates`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

/*
* Get email tempalte by id.
*
* @param [Number] id. Id of email template to get.
*/
export function getEmailTemplateById (id) {
  return Fetch.get(`${process.env.REACT_APP_EMAIL_DOMAIN}/email/templates/${id}`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

/*
* Create a email-template.
*
* @param [Object] form. Email template.
*/
export function createEmailTemplate (form) {
  return Fetch.post(`${process.env.REACT_APP_EMAIL_DOMAIN}/email/templates`, form, null)
    .then(data => {
      showSnackbar('Email template successfully created', 'info')
      return data
    })
    .catch(error => Promise.reject(error))
}

/*
* Update a email template.
*
* @param [Object] form. Email template.
*/
export function updateEmailTemplate (form) {
  return Fetch.put(`${process.env.REACT_APP_EMAIL_DOMAIN}/email/templates/${form.id}`, form, null)
    .then(data => {
      showSnackbar('Email template successfully updated', 'info')
      return data
    })
    .catch(error => Promise.reject(error))
}


/*
* Create a new hierachy for email-template.
*
* @param [Object] form. hierachy .
*/
export function createEmailHierarchy (form) {
  return Fetch.post(`${process.env.REACT_APP_EMAIL_DOMAIN}/email/template/hierarchy`, form, null)
    .then(data => {
      showSnackbar('Communication Hierarchy successfully created', 'info')
      return data
    })
    .catch(error => Promise.reject(error))
}

/*
* Get all contents for by hierarchy id.
*
* @param [Number] id. Id of  to get.
*/
export function getEmailHierarchyTemplatesById (id) {
  return Fetch.get(`${process.env.REACT_APP_EMAIL_DOMAIN}/email/template/hierarchy/${id}`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

/*
* Delete email hierarchy association
*
* @param [Number] conetentHierarchyId. 
*/
export function deleteEmailHierarchyTemplate (contentHierarchyId) {
  return Fetch.delete(`${process.env.REACT_APP_EMAIL_DOMAIN}/email/template/hierarchy/${contentHierarchyId}`, null)
    .then(data => {
      showSnackbar('Communication Hierarchy successfully deleted', 'info')
      return data
    })
    .catch(error => Promise.reject(error))
}