import React from 'react'
import PropTypes from 'prop-types'

import './tabs.scss'

class Tab extends React.Component {
  /*
  * Handle user selecting a tab. Set the current active tab.
  * Then handle the what is supposed to happen on field select.
  *
  * @param [Number] index. The index of the tab.
  */
  onTabTouch (event, index) {
    this.props.onChange(index)
  }

  render () {
    const {active, name, index} = this.props
    const activeClass = active ? 'active' : ''
    const classes = ['button tab']
    classes.push(activeClass)

    return (
      <button
        className={classes.join(' ')}
        onClick={(event) => this.onTabTouch(event, index)}
      >
        <span>{name}</span>
      </button>
    )
  }
}

Tab.propTypes = {
  active: PropTypes.bool,
  index: PropTypes.number,
  last: PropTypes.bool,
  name: PropTypes.node,
  onTabTouch: PropTypes.func,
  setActive: PropTypes.func
}

export default Tab
