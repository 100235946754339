import Fetch from 'lib/fetch'
import { showSnackbar } from '../redux/utility/SnackbarUtil'

/*
* Get statements list with status
*
*/
export function getStatementsList () {
  return Fetch.get(`${process.env.REACT_APP_STATEMENT_DOMAIN}/statementApprovals/status/list`, null)
    .then(data => data)
    .catch(error => Promise.reject(error)) 
}


/*
* Get statement sample accounts 
*
*/
export function getStatementSampleAccounts (month, year) {
  return Fetch.get(`${process.env.REACT_APP_STATEMENT_DOMAIN}/statement/preview/${year}/${month}`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

/*
* Approve Statement
*
* @param [Number] statementId.
*/
export function approveStatement (statementId) {
  return Fetch.post(`${process.env.REACT_APP_STATEMENT_DOMAIN}/statementApprovals/approval`, {approvalStatusId:statementId}, null)
  .then(response => {
      showSnackbar('Approved!', 'info')
      return response
    })
    .catch(error => {
      return Promise.reject(error)
    })
}



/*
* Generate Statement
*
* @param [Number] statementId.
*/
export function generateStatement (statementId) {
  return Fetch.post(`${process.env.REACT_APP_STATEMENT_DOMAIN}/statement/generationEvent`, {approvalStatusId:statementId}, null)
    .then(response => {
        showSnackbar('Statement generated successfully!', 'info')
        return response
    })
    .catch(error => {
      return Promise.reject(error)
    })
}


export function getSampleAccountStatementDownload(documentUrl) {
  return Fetch.getFile(`${process.env.REACT_APP_STATEMENT_DOMAIN}/statement/get-statement/${documentUrl}`, null)
      .then(response => {
          if (response.ok) {
              return response
          }
          return Promise.reject(response)
      })
}

export function getGeneratedAccountStatementDownload(form) {
  let queryParams = Object.keys(form).filter(key => form[key]).map(key => {
    return [key, form[key]].map(encodeURIComponent).join("=")
  }).join("&");
  return Fetch.getFile(`${process.env.REACT_APP_STATEMENT_DOMAIN}/statement/preview?${queryParams}`, null)
      .then(response => {
          if (response.ok) {
              return response
          }
          return Promise.reject(response)
      })
}

export function getStatementApprovers(form) {
  let queryParams = Object.keys(form).filter(key => form[key]).map(key => {
    return [key, form[key]].map(encodeURIComponent).join("=")
  }).join("&");
  return Fetch.get(`${process.env.REACT_APP_STATEMENT_DOMAIN}/statementApprovals/statementApprovers?${queryParams}`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}