import React from 'react'
import Card from "../../../../components/Card";
import TextField from "../../../../components/TextField";
import actions from "../../../../actions";
import Loading from "../../../../components/Loading";
import CustodianUrlTable from "./CustodianUrlTable";

class StoredCustodianForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      form: this.initializeForm()
    }

    this.actions = []
  }

  initializeForm() {
    return {
      transferDate: '',
      urlExpirationDate: '',
      employerName: ''
    }
  }

  onChange (name, value) {
    let form = {...this.state.form}
    form[name] = value
    this.setState({form})
  }
  
  componentWillMount() {
    this.getStoredConsentForm()
    this.getStoredConsentUrls()
  }

  getStoredConsentForm() {
    const {match: {params: {id}}} = this.props
    this.setState({loading: true})
    actions.consentForms.getConsentFormsById(id).then(consentForm => {
      this.setState({consentForm, loading: false})
    }).catch(() => {
      this.setState({loading: false})
    })
  }
  
  getStoredConsentUrls() {
    const {match: {params: {id}}} = this.props
    this.setState({loading: true, hasConsentUrls: false})
    actions.consentForms.getConsentUrlsByConsentFormId(id).then(consentUrls => {
      this.setState({consentUrls, loading: false, hasConsentUrls: true})
    }).catch(() => {
      this.setState({loading: false})
    })
  }
  
  renderStoredForm() {
    if(!this.state.consentForm){
      return (
          <div className= 'page no-consent-form-found-error'>
          </div>
      )
    }
    const{custodianName, receivingCustodian, createDateDisplay, custodianVerbiage, displayTransferInKind} = this.state.consentForm
    return (
        <Card className='padding'>
          <h5>Custodian Consent Form Details</h5>
          <div className='row'>
            <div className='col'>
              <TextField
                  label='Custodian Name'
                  name='custodianName'
                  value={custodianName}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <TextField
                  label='Receiving Custodian'
                  name='receivingCustodian'
                  value={receivingCustodian}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <TextField
                  label='Create Date'
                  name='createDateDisplay'
                  value={createDateDisplay}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <TextField
                  label='Transfer In Kind'
                  name='displayTransferInKind'
                  value={displayTransferInKind}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <TextField
                  label='Authorization Text'
                  name='custodianVerbiage'
                  value={custodianVerbiage}
              />
            </div>
          </div>
          {this.renderExistingUrls()}
        </Card>
    )
  }
  
  renderExistingUrls() {
    if (!this.state.consentUrls) {
      return (
          <div className='text-right'>
            <h5>Generate Consent URL</h5>
            <a onClick={this.onAdd.bind(this)}>
              <i className='material-icons orange'>add_circle_outline</i>
            </a>
          </div>
      )
    }
    return (
        <CustodianUrlTable
            {...this.props}
            title=''
            dataSource={this.state.consentUrls}
            onAdd={this.onAdd.bind(this)}
        />
    )
  }
  
  onRowClick(consentUrl)  {
    const {history} = this.props
    history.push(`/nbtadmin/custodian-consent/generated-url/view/${consentUrl.id}`)
  }
  
  onAdd(){
    const {history} = this.props
    history.push(`/nbtadmin/custodian-consent/generated-url/add/${this.state.consentForm.id}`)
  }

  render() {
    if (this.state.loading) {
      return <div className='margin-top'><Loading/></div>
    }
    return(
        <div className='page stored-consent-form-view'>
          {this.renderStoredForm()}
        </div>
    )
  }
}

export default StoredCustodianForm
