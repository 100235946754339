import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import { CompatRoute } from 'react-router-dom-v5-compat'

// Layouts --------------------------------------------
import AuthorizedLayout from 'layouts/AuthorizedLayout'
import AdminLayout from 'layouts/AdminLayout'

import Unauthorized from 'views/Unauthorized'

// Views --------------------------------------
import Account  from 'views/Admin/Accounts/Account'
import Accounts  from 'views/Admin/Accounts/Accounts'

import BatchWires from './views/Admin/BatchWires'

import EnrollmentSearch from './views/Admin/Enrollments/EnrollmentSearch'
import EnrollmentDisplay from './views/Admin/Enrollments/EnrollmentDisplay'
import EnrollmentsTableDisplay from './views/Admin/Enrollments/EnrollmentsTableDisplay'

import StatementApproval from './views/Admin/StatementApproval/StatementApproval'

import TransactionUpload  from 'views/Admin/TransactionUpload'
import AccountEscheatment  from 'views/Admin/AccountEscheatment'

import Hierarchy from 'views/Admin/Hierarchies/Hierarchy'
import Hierarchies from 'views/Admin/Hierarchies/Hierarchies'

import FeeForm from 'views/Admin/Fees/FeeForm'
import Fee from 'views/Admin/Fees/Fee'
import Fees from 'views/Admin/Fees/Fees'

import FeeProfileForm from 'views/Admin/FeeProfiles/FeeProfileForm'
import FeeProfile from 'views/Admin/FeeProfiles/FeeProfile'
import FeeProfiles from 'views/Admin/FeeProfiles/FeeProfiles'

import InterestProfileForm from 'views/Admin/InterestProfiles/InterestProfileForm'
import InterestProfile from 'views/Admin/InterestProfiles/InterestProfile'
import InterestProfiles from 'views/Admin/InterestProfiles/InterestProfiles'

import OtherConfigurations from 'views/Admin/OtherConfigurations'

import BulkTransfer from 'views/Admin/BulkTransfer/BulkTransfer'
import UploadTOA from 'views/Admin/BulkTransfer/UploadTOA'
import UploadSingleOrMultipleTOA from 'views/Admin/BulkTransfer/UploadTOA/UploadSingleOrMultipleTOA'
import TOAPostingExceptions from 'views/Admin/BulkTransfer/TOAPostingExceptions'
import UnenrolledTransfers from 'views/Admin/BulkTransfer/UnenrolledTransfers'
import EmployerUnenrolledTransferTable from 'views/Admin/BulkTransfer/UnenrolledTransfers/EmployerUnenrolledTransferTable'

import EmailTemplateForm from 'views/Admin/EmailTemplates/EmailTemplateForm/index'
import EmailTemplate from 'views/Admin/EmailTemplates/EmailTemplate'
import EmailTemplates from 'views/Admin/EmailTemplates/EmailTemplates'

import MailTemplateForm from 'views/Admin/MailTemplates/MailTemplateForm/index'
import MailTemplate from 'views/Admin/MailTemplates/MailTemplate'
import MailTemplates from 'views/Admin/MailTemplates/MailTemplates'

import Disclaimers from 'views/Admin/Disclaimers'

import FraudRule from 'views/Admin/FraudRules/FraudRule'
import FraudRules from 'views/Admin/FraudRules/FraudRules'

import CustodianConsentDisplay from 'views/Admin/TransferConsent'
import CustodianConsentForm from 'views/Admin/TransferConsent/CustodianForm/StoredCustodianForm'
import CreateCustodianConsentForm from 'views/Admin/TransferConsent/CustodianForm/CreateCustodianForm'
import GeneratedUrlDisplay from 'views/Admin/TransferConsent/GeneratedUrl/GeneratedUrlDisplay'
import AddConsentUrlForm from 'views/Admin/TransferConsent/CustodianUrlGeneration/CustodianUrlGenerationForm'

import UploadConsentData from 'views/Admin/TransferConsent/UploadConsentData'
import UploadConsentDataForm from 'views/Admin/TransferConsent/UploadConsentData/UploadConsentDataForm'
import UploadConsentHistory from 'views/Admin/TransferConsent/UploadConsentData/UploadConsentHistory'

import ExportConsentData from 'views/Admin/TransferConsent/ExportConsentData'
import ExportConsentDataForm from 'views/Admin/TransferConsent/ExportConsentData/ExportConsent'

import ExpeditedCipUpload from 'views/Admin/ExpeditedUpload/ExpeditedCipUpload'

import HsaxSetup from 'views/Admin/HsaxSetup/HsaxSetup'
import HsaxSetupForm from 'views/Admin/HsaxSetup/HsaxSetupForm'
import HsaxEditForm from 'views/Admin/HsaxSetup/HsaxEditForm'

import ManageTermsAndConditions from 'views/Admin/TermsAndConditions/ManageTermsAndConditions'
import TermsAndConditionsSetupForm from 'views/Admin/TermsAndConditions/TermsAndConditionsSetupForm'
import TermsAndConditionsEditForm from 'views/Admin/TermsAndConditions/TermsAndConditionsEditForm'
import DeadLetter from "./views/Admin/DeadLetter"
import DefaultEditForm from "./views/Admin/TermsAndConditions/DefaultEditForm";
import EmployerBillingReport from './views/Admin/BillingReport/EmployerBillingReport'
import EmployeeBillingReport from './views/Admin/BillingReport/EmployeeBillingReport'
import ErSummaryBillingReport from './views/Admin/BillingReport/ErSummaryBillingReport'
import MailCorrespondenceReport from './views/Admin/BillingReport/MailCorrespondenceReport'
import BAIRecon from './views/Admin/BAIRecon';
import Beneficiary from './views/Admin/Beneficiary'
import NotificationRequeue from './views/Admin/NotificationRequeue'

// Compositions ------------------------------
import SiteNav from 'compositions/navigation/SiteNav'

// Components -------------------------------------- d

const AppRoutes = () => (
  <Switch>
    {/* <Route exact path="/" component={AdminRoutes} />
    <Route path='/nbtadmin' component={AdminRoutes} /> */}
    {AdminRoutes()}
    <Redirect path="*" to="/nbtadmin" />
  </Switch>
)

// TODO: Replace CompatRoute with v6 Route based on the upgrade Documentation shared here.
// https://www.npmjs.com/package/react-router-dom-v5-compat?activeTab=readme
const AdminRoutes = () => (
  <AuthorizedLayout>
    <SiteNav />
    <AdminLayout>
      <CompatRoute exact path='/nbtadmin/accounts' component={Accounts} />
      <CompatRoute exact path='/nbtadmin/accounts/:id' component={Account} />

      <CompatRoute exact path='/nbtadmin/batch-wires' component={BatchWires} />

      <CompatRoute exact path='/nbtadmin/enrollments' component={EnrollmentSearch} />
      <CompatRoute exact path='/nbtadmin/enrollments/:enrollmentId' component={EnrollmentDisplay} />
      <CompatRoute exact path='/nbtadmin/enrollments/:employeeId/:employerId' component={EnrollmentsTableDisplay} />

      <CompatRoute exact path='/nbtadmin/statement-approval' component={StatementApproval} />

      <CompatRoute exact path='/nbtadmin/transaction-upload' component={TransactionUpload} />
      <CompatRoute exact path='/nbtadmin/account-escheatment' component={AccountEscheatment} />
      <CompatRoute exact path='/nbtadmin/beneficiary-upload' component={Beneficiary} />

      <CompatRoute exact path='/nbtadmin/hierarchies' component={Hierarchies} />
      <CompatRoute exact path='/nbtadmin/hierarchies/:id' component={Hierarchy} />


      <CompatRoute exact path='/nbtadmin/interest-profiles' component={InterestProfiles} />
      <CompatRoute exact path='/nbtadmin/interest-profiles/:id' component={InterestProfile} />
      <CompatRoute exact path='/nbtadmin/interest-profiles/create/new' component={InterestProfileForm} />
      <CompatRoute exact path='/nbtadmin/interest-profiles/:id/edit' component={InterestProfileForm} />

      <CompatRoute exact path='/nbtadmin/fees' component={Fees} />
      <CompatRoute exact path='/nbtadmin/fees/:id' component={Fee} />
      <CompatRoute exact path='/nbtadmin/fees/create/new' component={FeeForm} />
      <CompatRoute exact path='/nbtadmin/fees/:id/edit' component={FeeForm} />

      <CompatRoute exact path='/nbtadmin/fee-profiles' component={FeeProfiles} />
      <CompatRoute exact path='/nbtadmin/fee-profiles/:id' component={FeeProfile} />
      <CompatRoute exact path='/nbtadmin/fee-profiles/create/new' component={FeeProfileForm} />
      <CompatRoute exact path='/nbtadmin/fee-profiles/:id/edit' component={FeeProfileForm} />

      <CompatRoute exact path='/nbtadmin/other-configurations' component={OtherConfigurations} />

      <CompatRoute exact path='/nbtadmin/email-templates' component={EmailTemplates} />
      <CompatRoute exact path='/nbtadmin/email-templates/:id' component={EmailTemplate} />
      <CompatRoute exact path='/nbtadmin/email-templates/create/new' component={EmailTemplateForm} />
      <CompatRoute exact path='/nbtadmin/email-templates/:id/edit' component={EmailTemplateForm} />

      <CompatRoute exact path='/nbtadmin/mail-templates' component={MailTemplates} />
      <CompatRoute exact path='/nbtadmin/mail-templates/:id' component={MailTemplate} />
      <CompatRoute exact path='/nbtadmin/mail-templates/create/new' component={MailTemplateForm} />
      <CompatRoute exact path='/nbtadmin/mail-templates/:id/edit' component={MailTemplateForm} />

      <CompatRoute exact path='/nbtadmin/disclaimers' component={Disclaimers} />

      <CompatRoute exact path='/nbtadmin/btw/create' component={BulkTransfer} />
      <CompatRoute exact path='/nbtadmin/btw/upload-data' component={UploadTOA} />
      <CompatRoute exact path='/nbtadmin/btw/toa-exceptions' component={TOAPostingExceptions}/>
      <CompatRoute exact path='/nbtadmin/btw/toa-single-multiple' component={UploadSingleOrMultipleTOA}/>
      <CompatRoute exact path='/nbtadmin/btw/unenrolled-transfers' component={UnenrolledTransfers}/>
      <CompatRoute exact path='/nbtadmin/btw/unenrolled-transfers/table/:employerId' component={EmployerUnenrolledTransferTable}/>

      <CompatRoute exact path='/nbtadmin/fraud-rules' component={FraudRules} />
      <CompatRoute exact path='/nbtadmin/fraud-rules/:id' component={FraudRule} />

      <CompatRoute exact path='/nbtadmin/custodian-consent-display' component={CustodianConsentDisplay} />
      <CompatRoute exact path='/nbtadmin/custodian-consent-form/:id' component={CustodianConsentForm} />
      <CompatRoute exact path='/nbtadmin/custodian-consent-form/create/new' component={CreateCustodianConsentForm} />
      <CompatRoute exact path='/nbtadmin/custodian-consent/generated-url/view/:id' component={GeneratedUrlDisplay} />
      <CompatRoute exact path='/nbtadmin/custodian-consent/generated-url/add/:formId' component={AddConsentUrlForm} />

      <CompatRoute exact path='/nbtadmin/custodian-consent/upload-data' component={UploadConsentData} />
      <CompatRoute exact path='/nbtadmin/custodian-consent/upload-data/form/:employerId/:employerName' component={UploadConsentDataForm} />
      <CompatRoute exact path='/nbtadmin/custodian-consent/upload-status' component={UploadConsentHistory} />

      <CompatRoute exact path='/nbtadmin/custodian-consent/export-data' component={ExportConsentData} />
      <CompatRoute exact path='/nbtadmin/custodian-consent/export-data/form/:employerId/:employerName' component={ExportConsentDataForm} />

      <CompatRoute exact path='/nbtadmin/expedited-cip-upload' component={ExpeditedCipUpload} />

      <CompatRoute exact path='/nbtadmin/hsax-setup' component={HsaxSetup} />
      <CompatRoute exact path='/nbtadmin/hsax-setup-form/:tokenString' component={HsaxSetupForm} />
      <CompatRoute exact path='/nbtadmin/hsax-setup-form/:token/edit' component={HsaxEditForm} />

      <CompatRoute exact path='/nbtadmin/terms-and-conditions' component={ManageTermsAndConditions} />
      <CompatRoute exact path='/nbtadmin/terms-and-conditions-setup-form' component={TermsAndConditionsSetupForm} />
      <CompatRoute exact path='/nbtadmin/terms-and-conditions-edit-form' component={TermsAndConditionsEditForm} />
      <CompatRoute exact path='/nbtadmin/dead-letter' component={DeadLetter} />
      <CompatRoute exact path='/nbtadmin/default-document-edit-form' component={DefaultEditForm} />
      <CompatRoute exact path='/nbtadmin/employer-billing-report' component={EmployerBillingReport} />
      <CompatRoute exact path='/nbtadmin/employee-billing-report' component={EmployeeBillingReport} />
      <CompatRoute exact path='/nbtadmin/er-summary-billing-report' component={ErSummaryBillingReport} />
      <CompatRoute exact path='/nbtadmin/mail-correspondence-report' component={MailCorrespondenceReport} />

      <CompatRoute exact path='/nbtadmin/bai-recon' component={BAIRecon} />
      <CompatRoute exact path='/nbtadmin/notification-requeue' component={NotificationRequeue} />

      <CompatRoute path='/nbtadmin/unauthorized' component={Unauthorized} />
    </AdminLayout>
  </AuthorizedLayout>
)

export default AppRoutes
