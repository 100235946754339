import Fetch from 'lib/fetch'

/*
* Get all available terms and conditions documents.
*/
export function getTermsAndConditions () {
  return Fetch.get(`${process.env.REACT_APP_EMPLOYER_CONFIG_DOMAIN}/terms-and-conditions/get-all`, null)
  .then(data => data)
  .catch(error => Promise.reject(error))
}

/*
* Get all active terms and conditions documents.
*/
export function getActiveTermsAndConditions () {
  return Fetch.get(`${process.env.REACT_APP_EMPLOYER_CONFIG_DOMAIN}/terms-and-conditions/get-active`, null)
  .then(data => data)
  .catch(error => Promise.reject(error))
}


/*
* Gets the terms and conditions document.
*/

export function getFile (uuid, fileName) {
  return Fetch.get(`${process.env.REACT_APP_EMPLOYER_CONFIG_DOMAIN}/terms-and-conditions/get-document/${uuid}/${fileName}`, null)
  .then(data => data)
  .catch(error => Promise.reject(error))
}

/*
* Upload a terms and conditions file to the s3.
*/

export function uploadTermsAndConditions (form) {
  return Fetch.postMultipart(`${process.env.REACT_APP_EMPLOYER_CONFIG_DOMAIN}/terms-and-conditions/upload`, form, null)
  .then(data => data)
  .catch(error => Promise.reject(error))
}

/*
* Edit terms and conditions name.
*/

export function editTermsAndConditions (form) {
  return Fetch.post(`${process.env.REACT_APP_EMPLOYER_CONFIG_DOMAIN}/terms-and-conditions/edit`, form, null)
  .then(data => data)
  .catch(error => Promise.reject(error))
}


/*
* Set the terms and conditions to active / inactive.
*/

export function setActive (form) {
  return Fetch.post(`${process.env.REACT_APP_EMPLOYER_CONFIG_DOMAIN}/terms-and-conditions/set-active`, form, null)
  .then(data => data)
  .catch(error => Promise.reject(error))
}

/*
* Get the configuration based on the hierarchy id.
*/

export function getByHierarchyId (hierarchyId) {
  return Fetch.get(`${process.env.REACT_APP_EMPLOYER_CONFIG_DOMAIN}/employer-config/hierarchy-id/${hierarchyId}`, null)
  .then(data => data)
  .catch(error => Promise.reject(error))
}

/*
* Get default configurations
*/

export function getDefault () {
  return Fetch.get(`${process.env.REACT_APP_EMPLOYER_CONFIG_DOMAIN}/employer-config/get-default`, null)
  .then(data => data)
  .catch(error => Promise.reject(error))
}

/*
* Get default configurations
*/

export function saveDefault (form) {
  return Fetch.post(`${process.env.REACT_APP_EMPLOYER_CONFIG_DOMAIN}/employer-config/save-default`, form, null)
  .then(data => data)
  .catch(error => Promise.reject(error))
}

/*
* Get employer configurations
*/

export function saveConfig (form) {
  return Fetch.post(`${process.env.REACT_APP_EMPLOYER_CONFIG_DOMAIN}/employer-config/save`, form, null)
  .then(data => data)
  .catch(error => Promise.reject(error))
}
