import React from "react";
import PropTypes from 'prop-types'
import Card from "../../../../components/Card";
import TextField from "../../../../components/TextField";
import Loading from "../../../../components/Loading";
import Button from "../../../../components/Button";

class ConfirmGenerationForm extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      submitting: false,
    }
  }
  
  render(){
    return(
        <form className='' onSubmit={this.props.onSubmit.bind(this)}>
          <Card className='padding'>
            <div>
              <div className='row'>
                <div className='col'>
                  <TextField
                      label='Transfer Date'
                      name='transferDate'
                      value={this.props.transferDate}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <TextField
                      label='Url Expiration Date'
                      name='urlExpirationDate'
                      value={this.props.urlExpirationDate}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <TextField
                      label='Employer Name'
                      name='employerName'
                      value={this.props.employerName}
                  />
                </div>
              </div>
            </div>
            <Button
                style={{width: 'auto', display: 'block', marginLeft: 'auto'}}
                className='button margin-top primary-button'
                disabled={this.state.submitting}
                type='submit'
            >
              <span>
              {this.state.submitting ?
                  <Loading containerClassName='inline' className='white small' />
                  : 'Submit'
              }
            </span>
            </Button>
          </Card>
        </form>
    )
  }
}

ConfirmGenerationForm.propTypes = {
  employerName: PropTypes.string,
  employerId: PropTypes.number,
  transferDate: PropTypes.string,
  urlExpirationDate: PropTypes.string,
  onSubmit: PropTypes.func.isRequired
}

export default ConfirmGenerationForm