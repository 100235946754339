import React from 'react'
import EnrollmentSearchForm from '../EnrollmentForms/EnrollmentSearchForm'
import { hasRole } from '../../../../redux/utility/SessionUtil'

class EnrollmentSearch extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            readAccess: hasRole('AccountMaintView') || hasRole('AccountMaintEdit')
        }
    }

    componentDidMount() {
        this.verifyAccess()
    }

    verifyAccess() {
        if(!this.state.readAccess) {
            this.props.history.push('/nbtadmin/unauthorized')
        }
    }

    onSearch(form){
        this.props.history.push(`/nbtadmin/enrollments/${form.employeeId}/${form.employerId}`)
    }

    render() {
        return(
            <div className='search-page enrollments-view'>
                <h4>Enrollments Search</h4>
                <div style={{width: "100%", margin: "0 auto"}}>
                    <EnrollmentSearchForm
                        {...this.props}
                        onSubmit={this.onSearch.bind(this)}/>
                </div>
            </div>
        )
    }

}

export default EnrollmentSearch