import Fetch from 'lib/fetch'
import { showSnackbar } from '../redux/utility/SnackbarUtil'

/*
* Get all accounts
*/
export function getAccounts (params = null) {
  return Fetch.post(`${process.env.REACT_APP_NBT_ADMIN_DOMAIN}/nbt-admin/bank-accounts/search`, params, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

/*
* Get account by id.
*
* @param [Number] id. Id of account to get.
*/
export function getAccountById (id) {
  return Fetch.get(`${process.env.REACT_APP_NBT_ADMIN_DOMAIN}/nbt-admin/bank-accounts/${id}`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

export function getHsaAdvanceAvailableBalance (id, mappedAccount){
    return Fetch.get(`${process.env.REACT_APP_NBT_ADMIN_DOMAIN}/portal-data/${id}/hsa-advance-available-balance`, null)
        .then(data => {
            let hsaodMinimal = { accountStatus: "Inactive"}
            if (data !== undefined
                && data.length > 0
                && data[0].hsaodAvailableBalance
                && data[0].hsaodAvailableBalance > 0){
               hsaodMinimal = data[0];
            }
            mappedAccount.hsaodMinimal = hsaodMinimal;
            return mappedAccount;
        })
        .catch(error => Promise.reject(error))
}

/*
* Get HSA processor by id.
*/
export function getHsaProcessorById(id) {
  return Fetch.get(`${process.env.REACT_APP_NBT_ADMIN_DOMAIN}/portal-data/${id}/hsa-processor`, null)
      .then(data => data)
      .catch(error => Promise.reject(error))
}

/*
* Get closure account case
*/
export function getAccountClosure (bankAccountId) {
  return Fetch.get(`${process.env.REACT_APP_ACCOUNTS_DOMAIN}/closure-cases/bankAccountId/${bankAccountId}`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

/*
* Create account Closure
*
* @param [Object] form. Accoubt closure form.
*/
export function createAccountClosure (form) {
  return Fetch.postV2(`${process.env.REACT_APP_ACCOUNTS_DOMAIN}/closure-cases`, form, null)
    .then(data => {
        return data
    })
    .catch(error => Promise.reject(error))
}

/*
* Update account Closure
*
* @param [Object] form. Accoubt closure form.
*/
export function updateAccountClosure (form) {
  return Fetch.put(`${process.env.REACT_APP_ACCOUNTS_DOMAIN}/closure-cases/${form.id}`, form, null)
    .then(data => {
      showSnackbar('Account Closure Case successfully updated', 'info')
      return data
    })
    .catch(error => Promise.reject(error))
}

/*
* Create account Closure
*
* @param [Number] closureCaseId. Id of the account closure to create a note.
* @param [Object] form. Accoubt closure form.
*/
export function createAccountClosureNote (closureCaseId, form) {
  return Fetch.post(`${process.env.REACT_APP_ACCOUNTS_DOMAIN}/closure-cases/${closureCaseId}/notes`, form, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}


/*
* Create account closure document.
*
* @param [Number] closureCaseId. Id of the account closure to create a document.
* @params [File] file. File to upload
*/
export function createAccountClosureDocument (closureCaseId, file) {
  let form = new FormData()
  form.append(`file`, file)
  //TODO: This is there for future case if they eventually want different doc types
  form.append('documentType', 'ACCOUNT_CLOSURE_REQUEST_FORM')

  return Fetch.postMultipart(`${process.env.REACT_APP_ACCOUNTS_DOMAIN}/closure-cases/${closureCaseId}/documents`, form, null)
    .then(data => {
      showSnackbar('Account Closure Document successfully added.', 'info')
      return data
    })
    .catch(error => Promise.reject(error))
}

/*
* Delete account closure document.
*
* @param [Number] closureCaseId. Id of the account closure to create a document.
* @params [Number] documentId. Specific document to delete within the account closure
*/
export function deleteAccountClosureDocument (closureCaseId, documentId) {
  return Fetch.delete(`${process.env.REACT_APP_ACCOUNTS_DOMAIN}/closure-cases/${closureCaseId}/documents/${documentId}`, null)
    .then(data => {
      return data
    })
    .catch(error => Promise.reject(error))
}

/*
Change account number.

@param [Number] accountId. Id of the account to generate a new number for
 */
export function changeAccountNumber (bankAccountId) {
  return Fetch.post(`${process.env.REACT_APP_ACCOUNTS_DOMAIN}/accounts/change/${bankAccountId}`, bankAccountId, null)
    .then(data => {
      showSnackbar('Successfully changed Account Number', 'info')
      return data})
    .catch(error => Promise.reject(error))
}

/*
* Find Direct Deposit by bank id
*
* @param [String] params. Params to search by
*/
export function getDirectDepositByEmployeeId (id) {
  return Fetch.get(`${process.env.REACT_APP_NBT_ADMIN_DOMAIN}/portal-data/${id}/direct-deposit/`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

/*
* Find Direct Deposit Confirmed by employee id
*
* @param [String] params. Params to search by
*/
export function getDirectDepositConfirmedByEmployeeId (id) {
  return Fetch.get(`${process.env.REACT_APP_PORTAL_DATA_DOMAIN}/savings-accounts/${id}/direct-deposit-confirmed/`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

/*
* Reactivate closed account
*
* @param [String] accountId. AccountId which has to be reactivated
*/
export function reactivateAccount (accountId) {
  return Fetch.put(`${process.env.REACT_APP_ACCOUNTS_DOMAIN}/accounts/${accountId}/reactivate/`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

/*
* Get account reactivation audit logs
*
* @param [String] accountId. AccountId for which logs has to be fetched
*/
export function getReactivationAudit (accountId) {
  return Fetch.get(`${process.env.REACT_APP_ACCOUNTS_DOMAIN}/accounts/audits/reactivation-history/${accountId}/`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}
