import React from 'react'
import Card from 'components/Card'
import ConfirmDialog from 'components/ConfirmDialog'
import Loading from 'components/Loading'
import TextField from 'components/TextField'
import TableView from 'compositions/TableDisplay/TableView'
import actions from 'actions'
import { hasRole } from '../../../../redux/utility/SessionUtil'
import {mapDateToForm} from 'lib/date'
import Instances from './Instances'
import Modal from 'components/Modal'

const headers = [
  {
    header: () => 'Balance From',
    key: 'balanceFrom'
  },
  {
    header: () => 'Balance To',
    key: 'balanceTo'
  },
  {
    header: () => 'Interest Rate',
    key: 'percentInterest'
  },
  {
    header: () => 'Low APY',
    key: 'lowApy'
  },
  {
    header: () => 'High APY',
    key: 'highApy'
  }
]


class InterestProfile extends React.Component {
  constructor (props) {
    super (props)
    this.state = {
      profile: null,
      readAccess: hasRole('InterestMasterView'),
      writeAccess: hasRole('InterestMasterEdit'),
      openInstances: false
    }
  }

  /*
  * Get profile by id.
  */
  componentWillMount () {
    if (this.verifyAccess()) {
      this.getProfile()
    }
  }

  /*
  * Verify that the user has access to interest profiles
  */
  verifyAccess () {
    if (!(this.state.readAccess || this.state.writeAccess)) {
      this.props.history.push('/nbtadmin/unauthorized')
      return false
    }

    return true
  }

  /*
  * Fetch profile by id.
  */
  getProfile () {
    const {match: {params: {id}}} = this.props

    this.setState({loading: true})
    actions.interestProfiles.getInterestProfileById(id).then(profile => {
      this.getInterestProfileCountById(profile.id)
      this.setState({profile, loading: false})
    }).catch(() => { this.setState({loading: false}) })
  }

  /* get number of instances */

  getInterestProfileCountById(id) {
    return actions.interestProfiles.getInterestProfileCountById(id).then(profile => {
      this.setState({instances: profile})
    })
  }

  openModal = () => {
    this.setState({ openInstances: true });
  }

  onCloseInstanceModal() {
    this.setState({ openInstances: false });
  }

  /*
   * Open add transaction modal
   */
  renderInstanceModal() {
    const { openInstances } = this.state;
    if (openInstances) {
      return (
        <Modal
          header={"Instances"}
          onClose={this.onCloseInstanceModal.bind(this)}
          showClose={true}
        >
          <Instances
          profile={this.state.profile}
          {...this.props}/>
        </Modal>
      );
    }
  }

  /*
  * Render the interest profile meta data
  */
  renderDetails () {
    const {name, type, activeDate, description, accrualBalanceMethod,
      accrualCompoundMethod, postingSchedule} = this.state.profile

    return (
      <Card className='padding'>
        {this.renderActionsMenu()}
        <h5>Details</h5>

        <div className='row'>
          <div className='col'>
            <TextField
              label='Name'
              name='name'
              value={name}
            />
          </div>
          <div className='col'>
            <TextField
              label='Type'
              name='type'
              value={type}
            />
          </div>
          <div className='col'>
            <TextField
              label='Active date'
              name='activeDate'
              value={mapDateToForm(activeDate)}
            />
          </div>
          <div className='col'>
            <TextField
              label='Instances'
              name='instances'
              value={this.state.instances === 0 ? "None" : <a onClick={this.openModal}>{this.state.instances}</a>}
            />
          </div>
        </div>
        <div className='row margin-top'>
          <div className='col'>
            <TextField
              label='Balance Method'
              name='accrualBalanceMethod'
              value={accrualBalanceMethod}
            />
          </div>
          <div className='col'>
            <TextField
              label='Compound Method'
              name='accrualCompoundMethod'
              value={accrualCompoundMethod}
            />
          </div>
          <div className='col'>
            <TextField
              label='Posting Schedule'
              name='postingSchedule'
              value={postingSchedule}
            />
          </div>
        </div>
        <div className='row margin-top'>
          <div className='col'>
            <TextField
              label='Description'
              name='description'
              value={description}
            />
          </div>
        </div>
      </Card>
    )
  }

  /*
  * Render the actions menu
  */
  renderActionsMenu () {
    if (this.state.writeAccess) {
      return (
        <div className='text-right'>
          <a onClick={this.onEdit.bind(this)}>
            <i className='material-icons orange' style={{fontSize: '1.2em'}}>edit</i>
          </a>
          <a style={{marginLeft: '8px'}} onClick={this.onDelete.bind(this)}>
            <i className='material-icons orange' style={{fontSize: '1.2em'}}>delete</i>
          </a>
        </div>
      )
    }
  }

  /*
  * Render the interest table.
  */
  renderInterestTable () {
    const {tiers} = this.state.profile
    return (
      <Card className='padding'>
        <h5>High Interest</h5>
        <TableView
          dataActions={[]}
          dataSource={tiers}
          tableHeaders={headers}
          disableFilters={true}
        />
      </Card>
    )
  }

  /*
  * Render the confirm delete dialog
  */
  renderConfirmDialog () {
    if (this.state.openConfirmationDialog) {
      return (
        <ConfirmDialog
          description='Are you sure you want to deactivate interest profile?'
          onClose={() => { this.setState({openConfirmationDialog: false}) }}
          onConfirm={() => { this.deleteInterestProfile() }}
        />
      )
    }
  }

  /*
  * On edit
  */
  onEdit () {
    const {history} = this.props
    history.push(`/nbtadmin/interest-profiles/${this.state.profile.id}/edit`)
  }

  /*
  * On delete, open confirmation dialog
  */
  onDelete () {
    this.setState({openConfirmationDialog: true})
  }

  /*
  * Delete interest profile
  */
  deleteInterestProfile () {
    actions.interestProfiles.deactivateInterestProfile(this.state.profile).then((updatedProfile) => {
      this.setState({profile: updatedProfile, openConfirmationDialog: false})
    }).catch(() => false)
  }

  getFeeProfileCountById(id) {
    return actions.interestProfiles.getInterestProfileCountById(id).then(profile => {
      this.setState({instances: profile})
    })
  }

  render () {
    if (this.state.loading) return <div className='margin-top'><Loading/></div>

    const {name} = this.state.profile

    return (
      <div className='page interest-profile-view'>
        <h4>{`${name}'s Profile`}</h4>
        {this.renderDetails()}
        {this.renderInterestTable()}
        {this.renderConfirmDialog()}
        {this.renderInstanceModal()}
      </div>
    )
  }
}

export default InterestProfile
