import React from "react";

import DatePicker from "../../../../components/forms/DatePicker";
import Card from "../../../../components/Card";

class TransferDateForm extends React.Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return (
        <Card className='padding'>
          <form onSubmit={this.props.onSubmit}>
            <DatePicker
                disabled={false}
                label='Transfer Date'
                name='transferDate'
                required={true}
                onChange={(name, value) => {
                  this.props.getChildFormState(name, value)
                }}
                placeholder='Transfer Date'
            />
            <div>
              <button type='submit' className='next button margin-top primary-button'
                      style={{width: 'auto', display: 'block', marginLeft: 'auto'}}
              >Next</button>
            </div>
          </form>
        </Card>
    )
  }
}

export default TransferDateForm