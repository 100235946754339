/** 
 * 
 *    period: enrollmentPeriod || '',
      transferInOut: transferInOut || '',
      omnibus: omnibus || '',
      transferType: transferType || '',
      custodian: custodian || '',
      sponsor: sponsor || '',
      distributor: distributor || '',
      employer: employer || '',
      populationFile: populationFile || '',
      assetFile: assetFile || '',
      accountActiveDate: accountActiveDate || '',
      blackoutStartDate: blackoutStartDate || '',
      blackoutEndDate: blackoutEndDate || '',
      investments: investments || '',
      transferKindFile || '',

*/

const bulkTransfer = {
  us: {
    fields: {
      enrollmentPeriod: {
        name: 'enrollmentPeriod',
        type: 'dropdown',
        label: "Enrollment Period",
        data: {
          annual: {
            name: 'Annual',
            disabled: false
          },
          offcycle: {
            name: 'Off Cycle',
            disabled: false
          }
      }
    },
      transferInOut: {
        name: 'transferInOut',
        label: 'Transfer In/Out',
        type: 'dropdown',
        data: {
          transferIn: {
            name: "In",
            disabled: false
          },
          transferOut: {
            name: "Out",
            disabled: false
          }
        }
      },
      omnibus: {
        name: 'omnibus',
        label: 'Omnibus',
        type: 'dropdown',
        data: {
          MT : {
            name: 'M & T',
            disabled: false
          },
          SunTrust : {
            name: 'SunTrust',
            disabled: true
          }
        }
      },
      transferType: {
        name: 'transferType',
        label: 'Transfer Type',
        type: 'dropdown',
        data: {
          conversionResignation: {
            name: 'Conversion Resignation',
            disabled: false
          },
          conversionTransfer: {
            name: 'Conversion Transfer',
            disabled: false
          },
          newTransfer: {
            name: 'New Transfer',
            disabled: true
          },
          resignationTransfer: {
            name: 'New Resignation Transfer',
            disabled: true
          }
        }
      },
      custodian: {
        name: 'custodian',
        label: 'Custodian',
        type: 'dropdown',
        data: {
          
        }
      },
      sponsor: {
          name: 'sponsor',
          label: 'Sponsor',
          type: 'dropdown',
          data: {

          }
      },
      distributor: {
          name: 'distributor',
          label: 'Distributor',
          type: 'dropdown',
          data: {

          }
      },
      employer: {
          name: 'employer',
          label: 'Employer',
          type: 'dropdown',
          data: {

          }
      },
      populationFile: {
          name: 'populationFile',
          label: 'Population File Name',
          placeholder:'Population File Name'
      },
      assetFile: {
          name: 'assetFile',
          label: 'Asset File Name',
          placeholder:'Asset File Name'
      },
      accountActiveDate: {
          name: 'accountActiveDate',
          label: 'Account Active Date',
          placeholder:'mm/dd/yyyy'
      },
      blackoutStartDate: {
          name: 'blackoutStartDate',
          label: 'Blackout Start Date',
          placeholder:'mm/dd/yyyy'
      },
      blackoutEndDate: {
          name: 'blackoutEndDate',
          label: 'Blackout End Date',
          placeholder:'mm/dd/yyyy'
      },
      investments: {
        name: 'investments',
        label: 'Investments',
        type: 'dropdown',
        data: {
          transferInKind : {
            name: 'Transfer in Kind',
            disabled: false
          },
          investmentLiquidation : {
            name: 'Investment Liquidation',
            disabled: false
          }
        }
      },
      transferKindFile: {
          name: 'transferKindFile',
          label: 'Transfer In Kind File Name',
          placeholder:'Transfer in Kind File Name'
      },
    }
  }
}


export default bulkTransfer