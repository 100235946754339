import React from 'react'
import { hasRole } from '../../../redux/utility/SessionUtil';
import {Tabs, Tab} from 'components/Tabs'
import UploadEscheatment from "./UploadEscheatment";
import ViewEscheatmentReport from './ViewEscheatmentReport';
import UploadVerification from './UploadVerification';

class AccountEscheatment extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.initializeState()
    this.onChange.bind(this)
  }

  /*
  * Initialize State
  */
 initializeState() {
    return {
      submitting: false,
      showVerifyPageFlag: false,
      uploadResponse: null,
      verifySubmit: 0,
      readAccess: hasRole('AccountEschView'),
      writeAccess: hasRole('AccountEschEdit'),
      tabIndex: 0
    }
  }

  componentWillMount () {
   this.verifyAccess()
  }

  componentWillUnmount () {
    localStorage.removeItem("eschTabIndex")
  }

  /*
  * Verify that the user has access to upload transaction
  */
  verifyAccess () {
    if (!(this.state.readAccess || this.state.writeAccess)) {
      this.props.history.push('/nbtadmin/unauthorized')
      return false
    }

    return true
  }

  onUploadResponse(uploadData){
    this.setState({uploadResponse: uploadData});
  }


  updateShowVerifyPageFlag(flagUpdate)
  {
    this.setState({showVerifyPageFlag: flagUpdate});
  }

  verifySubmitIndex(flag){
    this.setState({tabIndex: flag});
    localStorage.setItem("eschTabIndex",flag);
  }
  renderVerificationPage(){
    if(this.state.showVerifyPageFlag){
      return (
        <UploadVerification
          submitting={this.state.submitting}
          uploadResponse={this.state.uploadResponse}
          verifySubmit={this.verifySubmitIndex.bind(this)}
          showVerifyPageFlag={this.updateShowVerifyPageFlag.bind(this)}
          uploadAccess={this.state.writeAccess}/>
      )
    }
  }

  /*
  * Update form values
  *
  * @param [String] name. Field name of form to update.
  * @param [Any] value. Value.
  */
  onChange (name, value) {
    let form = {...this.state.form}
    form[name] = value
    this.setState({form})
  }

  /*
  * Render tabs
  */
 renderTabs () {
  return (
    <Tabs
      className='margin-top margin-bottom'
      currentIndex={localStorage.getItem("eschTabIndex")?+localStorage.getItem("eschTabIndex"):0}
      onChange={(index) => {
        this.setState({tabIndex: index})
        localStorage.setItem("eschTabIndex",index);
      }}
    >
      <Tab name='Upload'/>
      <Tab name='View Report'/>
    </Tabs>
  )
}

/*
  * Render Tab Content
  */
 renderTabContent () {
  switch (localStorage.getItem("eschTabIndex")?+localStorage.getItem("eschTabIndex"):0) {
    case 0:
      return (
        <UploadEscheatment
          submitting={this.state.submitting}
          initializeState={this.initializeState()}
          uploadAccess={this.state.writeAccess}
          showVerifyPageFlag={this.updateShowVerifyPageFlag.bind(this)}
          uploadResponse={this.onUploadResponse.bind(this)}
        />
      )
    case 1:
      return (
        <ViewEscheatmentReport
          access={this.state.readAccess}
        />
      )
    default:
      return <div>This section is coming soon.</div>
  }
}

render () {
  if ( this.state.showVerifyPageFlag ){
    return (
    <div className='page upload-toa-view'>
    <h3>Account Escheatment</h3>
    {this.renderVerificationPage()}
    </div>)}
  else{
    return (
      <div className='page upload-toa-view'>
      <h3>Account Escheatment</h3>
      {this.renderTabs()}
      {this.renderTabContent()}
      </div>)}
  }
}

export default AccountEscheatment