import React from 'react'
import Card from 'components/Card'
import Loading from 'components/Loading'
import TextField from 'components/TextField'
import Field from 'components/forms/Field/index'
import actions from 'actions'
import { hasRole } from '../../../../redux/utility/SessionUtil'
import dayjs from 'dayjs'

class EmailTemplate extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      template: null,
      readAccess: hasRole('EmailMasterView'),
      writeAccess: hasRole('EmailMasterEdit')
    }
  }

  /*
  * Get email template by id .
  */
  componentWillMount () {
    if (this.verifyAccess()) {
      this.getEmailTemplate()
    }
  }

  /*
  * Verify that the user has access to email templates
  */
  verifyAccess () {
    if (!(this.state.readAccess || this.state.writeAccess)) {
      this.props.history.push('/nbtadmin/unauthorized')
      return false
    }

    return true
  }

  /*
  * Fetch email templates by id.
  */
  getEmailTemplate() {
    const {match: {params: {id}}} = this.props

    this.setState({loading: true})
    actions.emailTemplates.getEmailTemplateById(id).then(template => {
      this.setState({template, loading: false})
    }).catch(() => this.setState({loading: false}))
  }

  /*
  * Render the actions menu
  */
  renderActionsMenu () {
    if (this.state.writeAccess && this.state.template.startDate > dayjs().format("YYYY-MM-DD")) {
      return (
        <div className='text-right'>
          <a onClick={this.onEdit.bind(this)}>
            <i className='material-icons orange' style={{fontSize: '1.2em'}}>edit</i>
          </a>
        </div>
      )
    }
  }

  /*
  * On edit
  */
  onEdit () {
    const {history} = this.props
    history.push(`/nbtadmin/email-templates/${this.state.template.contentId}/edit`)
  }

  /*
  * On edit
  */
  onDelete () {

  }

  render () {
    if (this.state.loading) return <div className='margin-top'><Loading/></div>
    const {
      contentSummary,
      contentDocument,
      contentKey,
      link,
      defaultTemplate,
      optumCorrespondenceId,
      startDate,
      endDate
    } = this.state.template
    const checkBoxAttr = {
      attrs: { 
        checked: defaultTemplate 
       }
     }
    const formattedStartDate = startDate ? dayjs(startDate).format("MM/DD/YYYY") : ''
    const formattedEndDate = endDate ? dayjs(endDate).format("MM/DD/YYYY") : ''
    return (
      <div className='page email-template-view'>
        <h4>{`${contentSummary}'s Email Template`}</h4>
        <Card className='padding'>
          {this.renderActionsMenu()}
          <h5>Details</h5>
          <div className='row'>
            <div className='col'>
              <TextField
                label='Subject'
                name='name'
                value={contentSummary}
              />
            </div>
            <div className='col'>
              <TextField
                label='Key'
                name='key'
                value={contentKey}
              />
            </div>
          </div>
          <div className='row margin-top'>
            <div className='col'>
              <TextField
                label='Link'
                name='link'
                value={link}
              />
            </div>
          </div>
		      <div className='row margin-top'>
            <div className='col'>
              <Field
                disabled
                type="checkbox"
                name='Default'
                id='defaultTemplate'
                value={defaultTemplate}
                {...checkBoxAttr}
              />
            </div>
            <div className='col'>
              <TextField
                label='Optum ID'
                name='optumCorrespondenceId'
                value={optumCorrespondenceId}
              />
            </div>
          </div>
          <div className='row margin-top'>
            <div className='col'>
              <TextField
                label='Start Date'
                name='startDate'
                value={formattedStartDate}
              />
            </div>
            <div className='col'>
              <TextField
                label='End Date'
                name='endDate'
                value={formattedEndDate}
              />
            </div>
          </div>
        </Card>

        <Card className='padding'>
          <h5>Email Template</h5>
          <div dangerouslySetInnerHTML={{ __html: contentDocument}} />
        </Card>
      </div>
    )
  }
}

export default EmailTemplate
