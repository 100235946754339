/**
 * Super straightforward localization and more!
 *
 * ```
 *  email: {
 *      name: 'email',
 *      type: 'email',
 *      label: 'E-mail',
 *      validator: 'email'
 *  },
 *  date_admission: {
 *      name: 'date_admission',
 *      label: 'Date of Admission',
 *      placeholder: 'Date of Admission',
 *      type: 'month'
 *  }
 * ```
 * TODO Separate the translations from the definitions
 */

const feeProfile = {
  us: {
    fields: {
      name: {
        name: 'name',
        label: "Name",
        placeholder: 'Profile name',
      },
      deactivatedDate: {
        name: 'deactivatedDate',
        label: "Deactivated date",
        placeholder: 'Profile deactivated date',
        type: 'date'
      },
      description: {
        name: 'description',
        label: 'Description',
        placeholder: 'Profile description',
      }
    },
    feeSpecificFields: {
      feeId: {
        name: 'feeId',
        type: 'dropdown',
        label: "Fee",
        placeholder: 'Fee',
        data: {
        }
      },
      amount: {
        name: 'amount',
        label: 'Amount',
        placeholder: 'Amount',
      },
      thresholdAmount: {
        name: 'thresholdAmount',
        label: 'Threshold Amount',
        placeholder: 'Threshold Amount',
      },
      thresholdType: {
        name: 'thresholdType',
        type: 'dropdown',
        label: "Threshold Type",
        placeholder: 'Threshold Type',
        data: {
          CASH: {
            name: 'Cash',
            disabled: false
          },
          CASH_AND_INVESTMENT: {
            name: 'Cash and Investment',
            disabled: false
          }
        }
      },
      paidBy: {
        name: 'paidBy',
        type: 'dropdown',
        label: 'Paid By',
        placeholder: 'Paid by',
        data: {
          Participant: {
            name: 'Participant',
            disabled: false
          },
          Employer: {
            name: 'Employer',
            disabled: false
          },
          Distributor: {
            name: 'Distributor',
            disabled: false
          },
          Sponsor: {
            name: 'Sponsor',
            disabled: false
          },
          ConnectYourCare: {
            name: 'ConnectYourCare',
            disabled: false
          }
        }
      },
      enrollmentStatus: {
        name: 'enrollmentStatus',
        type: 'dropdown',
        label: 'Enrollment Status',
        placeholder: 'Enrollment Status',
        data: {
          NotApplicable: {
            name: 'Not Applicable',
            disabled: false
          },
          Enrolled: {
            name: 'Enrolled',
            disabled: false
          },
          Unenrolled: {
            name: 'Unenrolled',
            disabled: false
          }
        }
      }
    },
    hierarchyFields: {
      feeProfileId: {
        name: 'feeProfileId',
        label: "Fee Profile",
        placeholder: 'Fee profile',
        type: 'dropdown',
        data: {}
      },
      startDate: {
        name: 'startDate',
        label: "Start date",
        placeholder: 'MM/DD/YYYY',
      },
      endDate: {
        name: 'endDate',
        label: "End date",
        placeholder: 'MM/DD/YYYY',
      },
      active: {
        name: 'active',
        type: 'dropdown',
        label: 'Active',
        placeholder: 'Profile active',
        data: {
          true: 'True',
          false: 'False'
        }
      }
    }
  }
}

export default feeProfile
