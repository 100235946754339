const stateTerritory = {
    // 50 States plus Washington D.C.
    'AL' : {
      name : 'AL'
    },
    'AK' : {
      name : 'AK'
    },
    'AZ' : {
      name : 'AZ'
    },
    'AR' : {
      name : 'AR'
    },
    'CA' : {
      name : 'CA'
    },
    'CO' : {
      name : 'CO'
    },
    'CT' : {
      name : 'CT'
    },
    'DE' : {
      name : 'DE'
    },
    'DC' : {
      name : 'DC'
    },
    'FL' : {
      name : 'FL'
    },
    'GA' : {
      name : 'GA'
    },
    'HI' : {
      name : 'HI'
    },
    'ID' : {
      name : 'ID'
    },
    'IL' : {
      name : 'IL'
    },
    'IN' : {
      name : 'IN'
    },
    'IA' : {
      name : 'IA'
    },
    'KS' : {
      name : 'KS'
    },
    'KY' : {
      name : 'KY'
    },
    'LA' : {
      name : 'LA'
    },
    'ME' : {
      name : 'ME'
    },
    'MD' : {
      name : 'MD'
    },
    'MA' : {
      name : 'MA'
    },
    'MI' : {
      name : 'MI'
    },
    'MN' : {
      name : 'MN'
    },
    'MS' : {
      name : 'MS'
    },
    'MO' : {
      name : 'MO'
    },
    'MT' : {
      name : 'MT'
    },
    'NE' : {
      name : 'NE'
    },
    'NV' : {
      name : 'NV'
    },
    'NH' : {
      name : 'NH'
    },
    'NJ' : {
      name : 'NJ'
    },
    'NM' : {
      name : 'NM'
    },
    'NY' : {
      name : 'NY'
    },
    'NC' : {
      name : 'NC'
    },
    'ND' : {
      name : 'ND'
    },
    'OH' : {
      name : 'OH'
    },
    'OK' : {
      name : 'OK'
    },
    'OR' : {
      name : 'OR'
    },
    'PA' : {
      name : 'PA'
    },
    'RI' : {
      name : 'RI'
    },
    'SC' : {
      name : 'SC'
    },
    'SD' : {
      name : 'SD'
    },
    'TN' : {
      name : 'TN'
    },
    'TX' : {
      name : 'TX'
    },
    'UT' : {
      name : 'UT'
    },
    'VT' : {
      name : 'VT'
    },
    'VA' : {
      name : 'VA'
    },
    'WA' : {
      name : 'WA'
    },
    'WV' : {
      name : 'WV'
    },
    'WI' : {
      name : 'WI'
    },
    'WY' : {
      name : 'WY'
    },
  
    // Non-states (Territories, etc.)
    'AS' : {
      name : 'AS'
    },
    'FM' : {
      name : 'FM'
    },
    'GU' : {
      name : 'GU'
    },
    'MH' : {
      name : 'MH'
    },
    'MP' : {
      name : 'MP'
    },
    'PW' : {
      name : 'PW'
    },
    'PR' : {
      name : 'PR'
    },
    'VI' : {
      name : 'VI'
    }
  }

  export default stateTerritory