import React from 'react'
import PropTypes from 'prop-types'
import TableDisplay from "../../../../compositions/TableDisplay";
import {filterDataSource} from 'lib/filter'

const headers = [
  {
    filterText: 'File Name',
    header: () => 'File Name',
    key: 'fileName'
  },
  {
    filterText: 'File Status',
    header: () => 'File Status',
    key: 'status'
  },
  {
    filterText: 'Upload Date',
    header: () => 'Upload Date',
    key: 'displayUploadDate'
  }
]

class ConsentFileTable extends React.Component{
  constructor(props) {
    super(props)

    let mappedUploadedFile = []
    if (props.dataSource) {
      mappedUploadedFile = props.dataSource.map(uploadedFile => {
        return this.mapUploadFileToDisplay(uploadedFile)
      })
    }

    this.state = {
      dataSource: mappedUploadedFile,
      filteredDataSource: mappedUploadedFile,
      originalSource: props.dataSource || []
    }

    this.actions = [
      {
        action: this.props.onRowClick
      }
    ]
  }

  componentWillReceiveProps (newProps) {
    const mappedUploadedFile = newProps.dataSource.map(uploadedFile => {
      return this.mapUploadFileToDisplay(uploadedFile)
    })

    this.setState({
      originalSource: newProps.dataSource,
      dataSource: mappedUploadedFile,
      filteredDataSource: mappedUploadedFile
    })
  }

  mapUploadFileToDisplay(uploadedFile) {
    const {id, fileName, displayUploadDate, status, comments, employerName} = uploadedFile
    return {
      id, 
      fileName,
      displayUploadDate,
      status,
      comments,
      employerName
    }
  }

  onFilter (queryParams) {
    const {dataSource} = this.state
    const newDataSource = filterDataSource(dataSource, queryParams)
    this.setState({filteredDataSource: newDataSource})
  }

  render() {
    return (
        <div className='search-page uploaded-file-view'>
          <TableDisplay
              dataActions={this.actions}
              dataSource={this.state.filteredDataSource}
              loading={this.props.loading}
              onAdd={() => false}
              disableAdd={true}
              onFilter={this.onFilter.bind(this)}
              onRowClick={this.props.onRowClick}
              pageDescription=''
              pageTitle='Uploaded Files'
              tableHeaders={headers}
          />
        </div>
    )
  }
}

ConsentFileTable.propTypes = {
  dataSource: PropTypes.array,
  title: PropTypes.string,
  onRowClick: PropTypes.func
}

export default ConsentFileTable