import React from "react";
import TableView from "compositions/TableDisplay/TableView";
import {filterDataSource} from "lib/filter";
import {sortDataSource} from "lib/sort";
import Loading from "components/Loading";
import actions from 'actions'
import { showSnackbar } from "../../../../redux/utility/SnackbarUtil";

const headers = [
    {
        filterText: 'First Name',
        header: () => 'First Name',
        key: 'firstName',
        enableSort: true
    },
    {
        filterText: 'Last Name',
        header: () => 'Last Name',
        key: 'lastName',
        enableSort: true
    },
    {
        filterText: 'Approved?',
        header: () => 'Approved?',
        key: 'approved',
        enableSort: true
    },
    {
        filterText: 'Approval Timestamp',
        header: () => 'Approval Timestamp',
        key: 'approvedTime',
        enableSort: true
    },
]

class ApprovalTracking extends React.Component {
  
    constructor(props) {
        super(props);
        this.state = {
          dataSource: [],
          filteredDataSource: [],
          loading: false,
          statementId: this.props.statementId
        }
        this.actions = [];
      }

  componentDidMount () {
    this.getStatementApprovers();
  }

  getStatementApprovers() {
    this.setState({loading: true})
    const params  = {
      approvalStatusId: this.props.statementId
    }
    return actions.statementApproval.getStatementApprovers(params)
      .then(approvalTrackingData => {
        const approvalData = approvalTrackingData.map(approval => {
          const {
            firstName, lastName, approved, approvedTime
          } = approval
          return {
              firstName,
              lastName,
              approved: approved ? "Yes" : "",
              approvedTime: approvedTime==null ? "" : approvedTime
          }
        })
        this.setState({
          dataSource: approvalData,
          filteredDataSource: approvalData,
          loading: false
        })
      }).catch(() => {
          this.setState({loading: false});
          showSnackbar('Unable to fetch approval workflow.', 'error')
      });
  }

  /*
  * Filter data source.
  *
  * @param [Object] queryParams. Key value pair containing filters.
  */
  onFilter(queryParams, sortableRefs) {
      const {dataSource} = this.state;
      const newDataSource = filterDataSource(dataSource, queryParams);
      const sortedDataSource = sortDataSource(newDataSource, queryParams, sortableRefs)
      this.setState({filteredDataSource: sortedDataSource});
  }

  onSort(queryParams, sortableRefs) {
    const {filteredDataSource} = this.state
    const sortedDataSource = sortDataSource(filteredDataSource, queryParams, sortableRefs)
    this.setState({filteredDataSource: sortedDataSource})
}

  renderApprovalTracking(){
    const {filteredDataSource} = this.state;
    return(
    <div>
        <h4>Approval Tracking</h4>
            <TableView
                dataActions={this.actions}
                dataSource={filteredDataSource}
                tableHeaders={headers}
                tableFilters={headers}
                onFilter={this.onFilter.bind(this)}
                onSort={this.onSort.bind(this)}
                initialSortLabel={this.state.initialSortLabel}
                initialSortDirection={this.state.initialSortDirection}
            />
    </div>
    )
  }

  render() {
    if (this.state.loading)
    return (
        <div>
             <h4>Approval Tracking</h4>
            <Loading/>
        </div>
    );
    
    return (
      <div>
        {this.renderApprovalTracking()}
      </div>
    );
  }
}

export default ApprovalTracking;
