import PropTypes from 'prop-types'
import React, { Component } from 'react'

// Styles --------------------------------
import 'components/forms/SortableField/sortable.scss'

class SortableField extends Component {

    static propTypes = {
        label: PropTypes.string,
        key: PropTypes.string,
        sortDirection: PropTypes.string
    }

    render() {
        const {label, key, attrs, events} = this.props;

        return (<label
            ref={(component) => {this.input = component}}
            className='input-label sortable'
            htmlFor={key}
            {...attrs}
            {...events}
        >{label}</label>)
    }
}

export default SortableField