import React from 'react'
import { HashRouter as Router } from 'react-router-dom'
import Snackbar from 'components/Snackbar'

import AppRoutes from './routes'

// Components --------------------------------------
import Signal from 'components/notifications/Signal'

// Styles -----------------
import 'styles/global.scss'
import { CompatRouter } from 'react-router-dom-v5-compat'

/**
 * Main application component handling routes and routing,
 * displaying global messages, etc
 */
class App extends React.Component {
  constructor(props) {
    super(props)
    this.state={}
  }

  /**
   * Renders the app...
   * 
   * TODO: Replace CompatRouter and HashRouter with v6 Router based on the upgrade Documentation shared here.
   * https://www.npmjs.com/package/react-router-dom-v5-compat?activeTab=readme
   */
  render() {
    const { messages } = this.state
    return (
      <Router>
        <CompatRouter>
          <div>
            <AppRoutes />
            <Signal messages={messages} />
            <Snackbar />
          </div>
        </CompatRouter>
      </Router>
    )
  }
}

export default App