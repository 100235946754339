import Fetch from 'lib/fetch'
import { showSnackbar } from '../redux/utility/SnackbarUtil'
import dayjs from 'dayjs'

/*
* Get all fee profiles.
*/
export function getFeeProfiles () {
  return Fetch.get(`${process.env.REACT_APP_FEE_DOMAIN}/fee-profiles`, null)
    .then(data => {
      return data
    })
    .catch(error => Promise.reject(error))
}

/*
* Get fee profile by id.
*
* @param [Number] id. Id of fee profile to get.
*/
export function getFeeProfileById (id) {
  return Fetch.get(`${process.env.REACT_APP_FEE_DOMAIN}/fee-profiles/${id}`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

/*
* Create an fee profile.
*
* @param [Object] form. Fee profile.
*/
export function createFeeProfile (form) {
  return Fetch.post(`${process.env.REACT_APP_FEE_DOMAIN}/fee-profiles`, form, null)
    .then(data => {
      showSnackbar('Fee profile successfully created', 'info')
      return data
    })
    .catch(error => Promise.reject(error))
}

/*
* Update an fee profile.
*
* @param [Object] form. Fee profile.
*/
export function updateFeeProfile (form) {
  return Fetch.put(`${process.env.REACT_APP_FEE_DOMAIN}/fee-profiles/${form.id}`, form, null)
    .then(data => {
      showSnackbar('Fee profile successfully updated', 'info')
      return data
    })
    .catch(error => Promise.reject(error))
}

// body: {
//   vendorId: '',
//   feeProfileId: '',
//   startDate: 'yyyy-MM-dd HH:mm:ss',
//   endDate: 'yyyy-MM-dd HH:mm:ss'
// }
/*
* Attach fee profile to hierachy.
*
* @param [Object] form. Add fee form.
*/
export function createFeeProfileHierarchy (form) {
  return Fetch.post(`${process.env.REACT_APP_FEE_DOMAIN}/fee-profiles/${form.feeProfileId}/hierarchies`, form, null)
    .then(data => {
      showSnackbar('Fee profile hierarchy successfully created', 'info')
      return data
    })
    .catch(error => Promise.reject(error))
}


/*
* Remove fee profile from hierarchy.
*
* @param [Object] form. Fee profile form.
*/
export function updateFeeProfileHierarchy (form) {
  return Fetch.put(`${process.env.REACT_APP_FEE_DOMAIN}/fee-profiles/${form.feeProfileId}/hierarchies/${form.id}`, form, null)
    .then(data => {
      showSnackbar('Fee profile hierarchy successfully updated', 'info')
      return data
    })
    .catch(error => Promise.reject(error))
}

/*
* Fetch fee profiles for hierarchy.
*
* @param [Number] hierarchyId. Id of hierach to fetch fee profiles.
*/
export function getFeeProfilesForHierarchy(hierarchyId) {
  return Fetch.get(`${process.env.REACT_APP_FEE_DOMAIN}/fee-profiles/hierarchies/${hierarchyId}`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

/*
* Deactivate fee
*
* @param [Object] profile. Fee to deactivate
*/
export function deactivateFeeProfile (profile) {
  let form = profile
  form.deactivatedDate = dayjs.utc().format('YYYY-MM-DD HH:mm:ss')
  return updateFeeProfile(form)
    .then(data => {
      showSnackbar('Fee profile deactivated.', 'info')
      return data
    }).catch(error => Promise.reject(error))
}

/*
* Get fee profile count of instances by id
*
*/
export function getFeeProfileCountById (id) {
  return Fetch.get(`${process.env.REACT_APP_FEE_DOMAIN}/fee-profiles/${id}/hierarchies/count`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

export function getInstancesById (id) {
  return Fetch.get(`${process.env.REACT_APP_FEE_DOMAIN}/fee-profiles/${id}/hierarchies/details`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}
