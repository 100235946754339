import Fetch from 'lib/fetch'
import { showSnackbar } from '../redux/utility/SnackbarUtil'

/*
* Get all Mail templates
*/
export function getMailTemplates () {
  return Fetch.get(`${process.env.REACT_APP_MAIL_DOMAIN}/mail/templates`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

/*
* Get all Letter Types
*/
export function getLetterTypes () {
  return Fetch.get(`${process.env.REACT_APP_MAIL_DOMAIN}/mail/templates/letter-types`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

/*
* Get Mail tempalte by id.
*
* @param [Number] id. Id of Mail template to get.
*/
export function getMailTemplateById (id) {
  return Fetch.get(`${process.env.REACT_APP_MAIL_DOMAIN}/mail/templates/${id}`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

/*
* Create a Mail-template.
*
* @param [Object] form. Mail template.
*/
export function createMailTemplate (form) {
  return Fetch.post(`${process.env.REACT_APP_MAIL_DOMAIN}/mail/templates`, form, null)
    .then(data => {
      showSnackbar('Mail template successfully created', 'info')
      return data
    })
    .catch(error => Promise.reject(error))
}

/*
* Update a Mail template.
*
* @param [Object] form. Mail template.
*/
export function updateMailTemplate (form) {
  return Fetch.put(`${process.env.REACT_APP_MAIL_DOMAIN}/mail/templates/${form.id}`, form, null)
    .then(data => {
      showSnackbar('Mail template successfully updated', 'info')
      return data
    })
    .catch(error => Promise.reject(error))
}

/*
* Create a new hierachy for mail-template.
*
* @param [Object] form. hierachy .
*/
export function createMailHierarchy (form) {
  return Fetch.post(`${process.env.REACT_APP_MAIL_DOMAIN}/mail/template/hierarchy`, form, null)
    .then(data => {
      showSnackbar('Communication Hierarchy successfully created', 'info')
      return data
    })
    .catch(error => Promise.reject(error))
}

/*
* Get all contents for by hierarchy id.
*
* @param [Number] id. Id of  to get.
*/
export function getMailHierarchyTemplatesById (id) {
  return Fetch.get(`${process.env.REACT_APP_MAIL_DOMAIN}/mail/template/hierarchy/${id}`, null)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

/*
* Delete mail hierarchy association
*
* @param [Number] conetentHierarchyId. 
*/
export function deleteMailHierarchyTemplate (contentHierarchyId) {
  return Fetch.delete(`${process.env.REACT_APP_MAIL_DOMAIN}/mail/template/hierarchy/${contentHierarchyId}`, null)
    .then(data => {
      showSnackbar('Communication Hierarchy successfully deleted', 'info')
      return data
    })
    .catch(error => Promise.reject(error))
}