import Fetch from 'lib/fetch'
import { showSnackbar } from '../redux/utility/SnackbarUtil'

/*
* Get sponsors by custodian
*
* @param [Number] id. Id of custodian to get sponsors.
*/
export function getSponsorsByCustodian (id) {

  return Fetch.get(`${process.env.REACT_APP_PORTAL_DATA_DOMAIN}/hierarchies/retrieve/list/sponsors-by-custodian/${id}`, null)
    .then(data => data)
    .catch(error => {
      return Promise.reject(error)
    })

}

/*
* Get distributors by sponsor
*
* @param [Number] id. Id of sponsor to get distributors.
*/
export function getDistributorsBySponsors (id, hsaProcessor) {
  return Fetch.post(`${process.env.REACT_APP_PORTAL_DATA_DOMAIN}/hierarchies/retrieve/list/distributors-by-sponsors`, id)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

/*
* Get employers by distributors
*
* @param [Number] id. Id of distributor to get employers.
*/
export function getEmployersByDistributors (id, hsaProcessor) {
  return Fetch.post(`${process.env.REACT_APP_PORTAL_DATA_DOMAIN}/hierarchies/retrieve/list/employer-by-distributors`, id)
    .then(data => data)
    .catch(error => Promise.reject(error))
}

/*
* Create a bulk transfer.
*
* @param [Object] form. Bulk Transfer request.
*/
export function createBulkTransfer (form) {
  return Fetch.post(`${process.env.REACT_APP_BULK_TRANSFER_DOMAIN}/bulkTransfer/create`, form, null)
    .then(data => {
      showSnackbar('Bulk Transfer Created', 'info')
      return data
    })
    .catch(error => Promise.reject(error))
}
