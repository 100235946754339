import React from 'react'
import TableDisplay from 'compositions/TableDisplay'
import actions from 'actions'
import {filterDataSource} from 'lib/filter'
import { hasRole } from '../../../../redux/utility/SessionUtil'
import Loading from 'components/Loading'

const headers = [
  {
    filterText: 'Rule id',
    header: () => 'ID',
    key: 'employerId'
  },
  {
    filterText: 'Rule name',
    header: () => 'Name',
    key: 'name'
  },
  {
    filterText: 'Rule version',
    header: () => 'Version',
    key: 'uiVersion'
  },
  {
    filterText: 'Rule Url',
    header: () => 'HSAX Url',
    key: 'hsaxUrl'
  }
]

class HsaxSetup extends React.Component {
  constructor (props) {
    super (props);
    this.state = {
      dataSource: [],
      filteredDataSource: [],
      originalSource: [],
      readAccess: hasRole('HSAXERSetup'),
      writeAccess: hasRole('HSAXERSetup')
    }

    this.actions = this.state.writeAccess ? [
      {
        action: this.onEdit.bind(this),
        icon: 'edit'
      }
    ] : []
  }

  /*
  * Get rules and load employers
  */
 componentWillMount () {
  if (this.verifyAccess()) {
    this.getEmployers()
  }
}

/*
  * Verify that the user has access to hsax
  */
 verifyAccess () {
  if (!(this.state.readAccess)) {
    this.props.history.push('/nbtadmin/unauthorized')
    return false
  }

  return true
}

  /*
   * Fetch hsax employers
   */
  getEmployers() {
    this.setState({ loadingEmployers: true });
    actions.hsax
      .getAllHsaxEmployers({ employerName: this.state.employerText })
      .then(employers => {
        const mappedEmployers = employers.map(employer => {
          const { employerId, name, uiVersion, hsaxUrl } = employer;
          return {
            employerId,
            name,
            uiVersion,
            hsaxUrl
          };
        });
        this.setState({
          loadingEmployers: false,
          filteredDataSource: mappedEmployers,
          dataSource: mappedEmployers
        });
      })
      .catch(() => this.setState({ loadingEmployers: false }));
  }

  /*
  * Go to hsax er setup form
  */
  onAdd () {
    const {history} = this.props
    const employerIds = this.state.dataSource.map((e) => e.employerId)
    const tokenString = undefined
    history.push({
        pathname: `/nbtadmin/hsax-setup-form/${tokenString}`,
        state: { 
          employerIds: employerIds,
          isEditForm: false
        }
    })
  }

  /* Get token from url param
  *
  */
 // function you can use:
  getTokenString(str) {
    return str.split('=')[1];
  }

  /*
  * Edit HSAX Employer
  */
  onEdit (employer) {
    const {history} = this.props
    let tokenString = this.getTokenString(employer.hsaxUrl)
    history.push({
      pathname: `/nbtadmin/hsax-setup-form/${tokenString}`,
      state: { employerName: employer.name,
               employerId: employer.employerId,
               isEditTrue: true
        }
    })
  }

  /*
  * Filter data source.
  *
  * @param [Object] queryParams. Key value pair containing filters.
  */
  onFilter (queryParams) {
    const {dataSource} = this.state
    const newDataSource = filterDataSource(dataSource, queryParams)
    this.setState({filteredDataSource: newDataSource})
  }

  render () {
    if (this.state.loading || this.state.loadingEmployers) return <div className='margin-top'><Loading/></div>
    const { filteredDataSource } = this.state;
    return (
      <div className='search-page hsax-setup-view'>
        <TableDisplay
          dataActions={this.actions}
          dataSource={filteredDataSource}
          loading={this.state.loading}
          onAdd={this.onAdd.bind(this)}
          onFilter={this.onFilter.bind(this)}
          pageDescription='Manage HSAX ER setup here.'
          pageTitle='HSAX ER Setup'
          tableHeaders={headers}
        />
      </div>
    )
  }
}

HsaxSetup.propTypes = {

}

export default HsaxSetup
