import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Styles ------------
import './flex-table.scss'

class Table extends Component {
  render () {
    const {children, className} = this.props

    const classes = ['flex-table']
    if (className) classes.push(className)

    return (
      <div className={classes.join(' ')}>
        {children}
      </div>
    )
  }
}

Table.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export default Table
