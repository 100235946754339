import React from 'react'
import Card from "../../../../components/Card"
import actions from "../../../../actions";

class UploadConsentDataForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      uploading: false
    }
  }

  onDrop() {
    this.submitConsentForm(this.fileInput.files[0])
    this.fileInput.value = ''
  }

  submitConsentForm(file) {
    this.setState({uploading: true})
    const {match: {params: {employerId, employerName}}} = this.props
    return actions.consentForms.uploadConsentData(employerId, employerName, file)
    .then(document => {
      this.setState({uploading: false})
      const {history} = this.props
      history.push(`/nbtadmin/custodian-consent/upload-data`)
    }).catch(() => {
      this.setState({uploading: false})
    })
  }

  render() {
    const {match: {params: {employerName}}} = this.props
    return (
        <div className='page upload-consent-data-form'>
          <Card className='padding'>
            <h4>Upload Consent Data for: {employerName}</h4>
            <input
                name='file-input'
                type='file'
                ref={input => {
                  this.fileInput = input;
                }}
                onChange={() => false}
            />
          </Card>

          <button
              className='button primary-button margin-bottom'
              onClick={this.onDrop.bind(this)}
          >Upload File</button>
        </div>
    )
  }
}

export default UploadConsentDataForm