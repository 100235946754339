import React from 'react'
import PropTypes from 'prop-types'
import Field from 'components/forms/Field'
import Card from 'components/Card'

import './search.scss'

class EmployerSearch extends React.Component {
  constructor (props) {
    super (props)
    this.state = {
      form: this.initializeFormData()
    }
  }


  /*
  * Initialize form data
  * Hierarchy search form
  */
  initializeFormData () {
    return {
      sponsorId: '',
      distributorId: '',
      employerName: ''
    }
  }


  /*
  * Update form values
  *
  * @param [String] name. Field name of form to update.
  * @param [Any] value. Value.
  */
  onChange (name, value) {
    let form = {...this.state.form}
    form[name] = value
    this.setState({form})
  }


  /**
   * On submit
   */
  handleSubmit(event) {
    event.preventDefault()
    if (this.validateForm()) {
      this.props.onSubmit(this.state.form).then(() => {
        // this.setState({form: this.initializeFormData()})
      }).catch(() => false)
    }
  }

  /*
  * Validate a search field is set
  */
  validateForm () {
    const {sponsorId, distributorId, employerName} = this.state.form
    return sponsorId || distributorId || employerName
  }

  render () {
    const {form} = this.state
    return (
      <Card className='padding'>
        <form className='' onSubmit={this.handleSubmit.bind(this)}>
          <Field
            required={true}
            label='Employer'
            name='employerName'
            placeholder='Employer name'
            events={
              {
                onChange: (event) => { this.onChange('employerName', event.target.value) }
              }
            }
            value={form.employerName}
          />

          <Field
            label='Sponsor'
            name='sponsorId'
            type='dropdown'
            placeholder='Sponsor'
            events={
              {
                onChange: (event) => { this.onChange('sponsorId', event.target.value) }
              }
            }
            data={this.props.sponsorOptions}
            value={form.sponsorId}
          />

          <Field
            label='Distributor'
            name='distributorId'
            type='dropdown'
            placeholder='Distributor'
            events={
              {
                onChange: (event) => { this.onChange('distributorId', event.target.value) }
              }
            }
            data={this.props.distributorOptions}
            value={form.distributorId}
          />

          <button
            style={{width: 'auto', display: 'block', marginLeft: 'auto'}}
            className='button margin-top primary-button'
            type='submit'
          >Search</button>
        </form>
      </Card>
    )
  }
}

EmployerSearch.propTypes = {
  distributorOptions: PropTypes.object,
  sponsorOptions: PropTypes.object,
  onSubmit: PropTypes.func
}

export default EmployerSearch
