import React from 'react'

class Unauthorized extends React.Component {
  render () {
    return (
        <div>
          <h4 style={{textAlign: 'center'}}>Unauthorized</h4>
        </div>
    )
  }
}

export default Unauthorized
