import React from "react";
import Field from "../../../../components/forms/Field";
import TableView from "compositions/TableDisplay/TableView";
import {filterDataSource} from "lib/filter";
import {sortDataSource} from "lib/sort";
import { showSnackbar } from "../../../../redux/utility/SnackbarUtil";
import Button from '../../../../components/Button'
import actions from 'actions'
import Loading from "components/Loading";

const headers = [
    {
        filterText: 'Account Id',
        header: () => 'Account Id',
        key: 'accountId',
        enableSort: true
    },
    {
        header: () => 'documentUrl',
        key: 'documentUrl',
        disableFilter: true
    }
]

class ViewSamples extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          dataSource: [],
          filteredDataSource: [],
          form: this.initializeFormData(),
          downloading: false
        }
        this.actions = [];
      }

  initializeFormData() {
    return {
      statementId: this.props.statementId,
      month: this.props.monthYear.split("/")[0],
      year: this.props.monthYear.split("/")[1],
      extAccountId: "",
      jobId: "",

    }
  }

  componentDidMount () {
    this.getStatementSampleAccounts();
  }

  /*
  * Takes values on form and assigns them into the right form values in state
  */
  onChange(name, value){
    let form = {...this.state.form}
    form[name] = value
    this.setState({form})
  }

  /*
  * Filter data source.
  *
  * @param [Object] queryParams. Key value pair containing filters.
  */
  onFilter(queryParams, sortableRefs) {
      const {dataSource} = this.state;
      const newDataSource = filterDataSource(dataSource, queryParams);
      const sortedDataSource = sortDataSource(newDataSource, queryParams, sortableRefs)
      this.setState({filteredDataSource: sortedDataSource});
  }

  getStatementSampleAccounts() {
    this.setState({loading: true})
    return actions.statementApproval.getStatementSampleAccounts(this.state.form.month, this.state.form.year).then(
        sampleStatementsData => {
            const statements = sampleStatementsData.map(statement => {
                const {
                    accountId,
                    documentUrl
                } = statement
                    return {
                        accountId,
                        documentUrl: (<div><Button
                                        className={"button primary-button"}
                                        events={
                                            {
                                                onClick: (event) => {this.downloadSampleFile(documentUrl)}
                                            }
                                        }
                                        >
                                        Download
                                        </Button>
                                      </div>)
                    }
            })
            this.setState({
                dataSource: statements,
                filteredDataSource: statements,
                loading: false
            })
        }).catch(() => {
          this.setState({loading: false});
          showSnackbar('Unable to fetch sample accounts.', 'error')
      });
  }

  downloadSampleFile(documentUrl){
    actions.statementApproval.getSampleAccountStatementDownload(documentUrl)
            .then(data => data.blob())
            .then(response => {
                const url = window.URL.createObjectURL(response)
                const a = document.createElement('a')
                a.style.display = 'none'
                a.href = url
                a.download = documentUrl
                a.click()
                window.URL.revokeObjectURL(url)
            }).catch(error => {
                showSnackbar('Failed to download the file. '+error)
            })
  }

  downloadGeneratedSampleFile(){
    this.setState({ downloading: true })
    const params  = { 
      approvalStatusId: this.state.form.statementId,
      year: this.state.form.year,
      month: this.state.form.month,
      externalAccountId: this.state.form.extAccountId,
      jobId: this.state.form.jobId
    }
    actions.statementApproval.getGeneratedAccountStatementDownload(params)
      // .then(data => data.blob())
      .then(response => {
          const fileName = response.headers.get('content-disposition').split('filename=')[1].split(';')[0].slice(1, -1);
          response.blob().then(data => {
            this.setState({ downloading: false })
            const url = window.URL.createObjectURL(data)
            const a = document.createElement('a')
            a.style.display = 'none'
            a.href = url
            a.download = fileName
            a.click()
            window.URL.revokeObjectURL(url)
          })
      }).catch(error => {
          this.setState({ downloading: false })
          showSnackbar('Failed to download the file.')
      })
  }

  validateGenerateSampleForm = form => {
    if (form.extAccountId === "" && form.jobId === "") {
      showSnackbar('Please enter at least one field', 'error')
      return false
    }
    return true
  }

  handleSubmit() {
    if(this.validateGenerateSampleForm(this.state.form)){
      this.downloadGeneratedSampleFile()
    }
  }

  approveStatement() {
    this.setState({openApprovalConfirmationDialog: false})
    this.setState({loading: true})
    actions.statementApproval.approveStatement(this.state.statementId).then(() => {
        this.getStatementsList()
    }).catch(err => {
        this.setState({loading: false})
    })
}

  renderGenerateSample(){
    const {form} = this.state
      return (
        <div>
        <h4>Generate Sample</h4>
        <div>
          <div className="modal-body" style={{height: '200px'}}>
            <div className="row">
              <div className="col">
                <Field
                name="extAccountId"
                label="Ext. Account Id"
                value={form.extAccountId}
                onClear={() => false}
                events={
                    {
                    onChange: (event) => {this.onChange('extAccountId', event.target.value)}
                    }
                }
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Field
                name="jobId"
                label="Job Id"
                value={form.jobId}
                events={
                    {
                    onChange: (event) => {this.onChange('jobId', event.target.value)}
                    }
                }
                />
              </div>
            </div>
            <div className='modal-footer'>
              <div className='modal-actions'>
                <button
                  className='button primary-button'
                  disabled={this.state.downloading}
                  onClick={this.handleSubmit.bind(this)}
                >
                  <span>
                        {this.state.downloading ? <Loading containerClassName='inline' className='white small' />
                            : 'Download'}
                    </span>
                  </button>
              </div>
            </div>
          </div>
        </div>
        </div>
      )
  }

  renderViewSamples(){
    const {filteredDataSource} = this.state;
    return(
    <div>
        <h4>View Samples</h4>
            <TableView
                dataActions={this.actions}
                dataSource={filteredDataSource}
                tableHeaders={headers}
                tableFilters={headers}
                onFilter={this.onFilter.bind(this)}
                initialSortLabel={this.state.initialSortLabel}
                initialSortDirection={this.state.initialSortDirection}
            />
    </div>
    )
  }

  render() {
    if (this.state.loading)
    return (
        <div>
             <h4>View Samples</h4>
            <Loading/>
        </div>
    );
    
    return (
      <div>
        {this.renderGenerateSample()}
        {this.renderViewSamples()}
      </div>
    );
  }
}

export default ViewSamples;