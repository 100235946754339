import React from 'react';
import Field from '../../../components/forms/Field';
import {mapDateToForm} from 'lib/date'
import actions from '../../../actions';

const YES = "YES";
const YES_RESEND = "YES RESEND";

class ValidationForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userInputValidationText : "",
            requiredInputValidationText : this.props.wireTransferFileLastResent==null ? YES : YES_RESEND,
            wasIncorrectTextEntered : null
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState(
            { wasIncorrectTextEntered:!this.validateUserInput() },
            () => {
                if (!this.state.wasIncorrectTextEntered) {
                    actions.batchWireRecords
                        .sendBatchWireFileIdForFileResend(this.props.resendWireTransferFileId)
                        .then(response => {
                            if(response.wireTransferFileId === this.props.resendWireTransferFileId) {
                                this.props.onSubmit(true);
                            } else {
                                this.props.onSubmit(false);
                            }
                        })
                        
                } else {
                    this.setState({
                        userInputValidationText : ""
                    })
                }
            }
        );
    }

    validateUserInput() {
        return this.state.userInputValidationText===this.state.requiredInputValidationText;
    }

    contentForNullResendDate() {
        return (
            <p>
                Please enter <b>{YES}</b> (Case Sensitive) to resend the selected batch wire file.
            </p>
        )
    }

    contentForNotNullResendDate() {
        return (
            <p>
                <p style={{color:"red"}}>This batch-wire file was last resent on <b>{mapDateToForm(this.props.wireTransferFileLastResent)}</b>.</p>
                <br/>Please enter <b>{YES_RESEND}</b> (Case Sensitive) to again resend the batch wire file.
            </p>    
        )
    }

    render() { 
        let content = this.props.wireTransferFileLastResent==null ? this.contentForNullResendDate() : this.contentForNotNullResendDate();
        let errorText = this.state.wasIncorrectTextEntered ? <p style={{color:"red", fontSize:"80%"}}>Please enter correct validation text.</p> : "";
        return ( 
            <div>
                <br/>
                {content}
                <br/>
                {errorText}
                <form className='' onSubmit={this.handleSubmit.bind(this)}>
                    <Field 
                        name=''
                        required={true}
                        label=''
                        placeholder={"Enter " + this.state.requiredInputValidationText}
                        value={this.state.userInputValidationText}
                        events={
                            {
                                onChange: (event) => {this.setState({
                                        userInputValidationText: event.target.value
                                    })
                                }
                            }
                        }
                        type='text'
                    />
                    <button style={{width: 'auto', display: 'block', marginLeft: 'auto'}}
                        className='button margin-top primary-button'
                        type='submit'>Confirm</button>
                </form>
            </div>
         );
    }
}
 
export default ValidationForm;