import React from "react"
import Card from "components/Card"
import Loading from "components/Loading"
import Button from "components/Button"
import Field from "components/forms/Field/index"
import FormSection from "components/forms/FormSection/index"
import DatePicker from "components/forms/DatePicker"
import actions from "actions"
import bulkTransfer from "data/bulkTransfer"

class BulkTransfer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      form: this.initializeFormData(),
      hsaProcessor: "",
      custodianOptions: {},
      sponsorOptions: {},
      distributorOptions: {},
      employerOptions: {}
    }

    this.onChange.bind(this)
  }

  /*
  * initialize all of the form fields
  */
  initializeFormData(data) {
    const {
      enrollmentPeriod,
      transferInOut,
      omnibus,
      transferType,
      custodian,
      sponsor,
      distributor,
      employer,
      populationFile,
      assetFile,
      accountActiveDate,
      blackoutStartDate,
      blackoutEndDate,
      investments,
      transferKindFile,
      purseEffectiveDate,
      custodialEffectiveDate
    } =
      data || {}
    return {
      enrollmentPeriod: enrollmentPeriod || "",
      transferInOut: transferInOut || "",
      omnibus: omnibus || "",
      transferType: transferType || [],
      custodian: custodian || "",
      sponsor: sponsor || [],
      distributor: distributor || [],
      employer: employer || [],
      populationFile: populationFile || "",
      assetFile: assetFile || "",
      accountActiveDate: accountActiveDate || "",
      blackoutStartDate: blackoutStartDate || "",
      blackoutEndDate: blackoutEndDate || "",
      investments: investments || "",
      transferKindFile: transferKindFile || "",
      purseEffectiveDate: purseEffectiveDate || "",
      custodialEffectiveDate: custodialEffectiveDate || ""
    }
  }

  /*
  * Fetch the custodians
  */
  componentDidMount() {
    this.getCustodians()
  }

  /**
   * get top fields
   */

  getTopFields() {
    const {
      form: { enrollmentPeriod, transferInOut }
    } = this.state

    const { fields } = bulkTransfer.us
    return [
      {
        ...fields.enrollmentPeriod,
        required: true,
        events: {
          onChange: event => {
            this.onChange(fields.enrollmentPeriod.name, event.target.value)
          }
        },
        value: enrollmentPeriod
      },
      {
        ...fields.transferInOut,
        required: true,
        events: {
          onChange: event => {
            this.onChange(fields.transferInOut.name, event.target.value)
          }
        },
        value: transferInOut
      }
    ]
  }

  /*
  * Render the transferIn fields
  */
  getTransferInFields() {
    const {
      form: { omnibus, transferType }
    } = this.state

    const { fields } = bulkTransfer.us

    return [
      {
        ...fields.omnibus,
        required: true,
        events: {
          onChange: event => {
            this.onChange(fields.omnibus.name, event.target.value)
          }
        },
        attrs: {
          style: { width: "100%" }
        },
        value: omnibus
      },
      {
        ...fields.transferType,
        required: true,
        events: {
          onChange: event => {
            this.onChange(fields.transferType.name, event.target.value)
          }
        },
        value: transferType
      }
    ]
  }

  /**
   *  Get the custodian field
   */
  getcustodianFields() {
    const {
      form: { custodian },
      custodianOptions
    } = this.state
    const { fields } = bulkTransfer.us
    return [
      {
        ...fields.custodian,
        required: true,
        data: custodianOptions,
        events: {
          onChange: event => {
            this.onCustodianChange(fields.custodian.name, event.target.value)
          }
        },
        value: custodian
      }
    ]
  }

  /*
  * Render sponsor, distributor, employer fields
  */
  getHierarchyFields() {
    const {
      form: { sponsor, distributor, employer }
    } = this.state
    const { fields } = bulkTransfer.us
    return [
      {
        ...fields.sponsor,
        required: true,
        data: this.state.sponsorOptions,
        attrs: {
          multiple: true,
          style: { height: "auto" }
        },
        events: {
          onChange: event => {
            const value = [...event.target.selectedOptions].map(o => o.value)
            this.onSponsorChange(fields.sponsor.name, value)
          }
        },
        value: sponsor
      },
      {
        ...fields.distributor,
        required: true,
        data: this.state.distributorOptions,
        attrs: {
          multiple: true,
          style: { height: "auto" }
        },
        events: {
          onChange: event => {
            const value = [...event.target.selectedOptions].map(o => o.value)
            this.onDistributorChange(fields.distributor.name, value)
          }
        },
        value: distributor
      },
      {
        ...fields.employer,
        required: true,
        data: this.state.employerOptions,
        events: {
          onChange: event => {
            const value = [...event.target.selectedOptions].map(o => o.value)
            this.onChange(fields.employer.name, value)
          }
        },
        attrs: {
          multiple: true,
          style: { height: "auto" }
        },
        value: employer
      }
    ]
  }

  /**
   *  render the transfer in settings fields
   */
  getTransferSettingsFields() {
    const {
      form: { populationFile, assetFile, investments, transferKindFile }
    } = this.state

    const { fields } = bulkTransfer.us
    return [
      {
        ...fields.populationFile,
        events: {
          onChange: event => {
            this.onChange(fields.populationFile.name, event.target.value)
          }
        },
        attrs: {
          style: { width: "100%" }
        },
        value: populationFile
      },
      {
        ...fields.assetFile,
        required: true,
        events: {
          onChange: event => {
            this.onChange(fields.assetFile.name, event.target.value)
          }
        },
        attrs: {
          style: { width: "100%" }
        },
        value: assetFile
      },
      {
        ...fields.investments,
        required: true,
        events: {
          onChange: event => {
            this.onChange(fields.investments.name, event.target.value)
          }
        },
        attrs: {
          style: { width: "100%" }
        },
        value: investments
      },
      {
        ...fields.transferKindFile,
        required: true,
        events: {
          onChange: event => {
            this.onChange(fields.transferKindFile.name, event.target.value)
          }
        },
        attrs: {
          style: { width: "100%" }
        },
        value: transferKindFile
      }
    ]
  }


  /*
  * Get settings, mainly to get the listing of HSAProcessors
  * use the action.settings.getSettings method
  */
  getCustodians() {
    this.setState({ loading: true })
    actions.settings
      .getSettings()
      .then(settings => {
        const { configuredCipEnabledHsaCustodianOptions } = settings

        let custodianOptions = {}
        configuredCipEnabledHsaCustodianOptions.forEach(o => {
          custodianOptions[o.id] = o.name
        })

        this.setState({
          form: this.initializeFormData(settings),
          custodianOptions,
          loading: false
        })
      })
      .catch(() => {
        this.setState({ loading: false })
      })
  }

  /*
  * Get the sponsors by custodian id
  *
  * @param custodianId. Id of custodian
  */
  getSponsors(custodianId) {
    return actions.bulkTransfer
      .getSponsorsByCustodian(custodianId)
      .then(sponsors => {
        let sponsorOptions = {}
        sponsors.forEach(o => {
          sponsorOptions[o.id] = o.name
        })
        this.setState({ sponsorOptions })
      })
      .catch(() => false)
  }

  /*
  * Get the disctributors
  *
  * @param [Array] sponsorIds. array of sponsor ids.
  */
  getDistributors(sponsorIds) {
    let distributorOptions = {}
    return actions.bulkTransfer
      .getDistributorsBySponsors(sponsorIds, this.hsaProcessor)
      .then(distributor => {
        distributor.forEach(o => {
          distributorOptions[o.id] = o.name
        })
        this.setState({ distributorOptions })
      })
      .catch(() => false)
  }

  /*
  * Get the employers
  *
  * @param [distributorIds]. array of distributor ids.
  */
  getEmployers(distributorIds) {
    let employerOptions = {}
    return actions.bulkTransfer
      .getEmployersByDistributors(distributorIds, this.hsaProcessor)
      .then(employer => {
        employer.forEach(o => {
          employerOptions[o.id] = o.name
        })
        this.setState({ employerOptions })
      })
      .catch(() => false)
  }

  /*
  * Render transfer form sections
  */
  renderTransfers() {
    if (this.state.form.transferInOut) {
      return this.state.form.transferInOut === "transferIn"
        ? this.renderTransferIn()
        : this.renderTransferOut()
    }
  }

  /*
  * Render transfer in section
  */
  renderTransferIn() {
    const transferSettingFields = this.getTransferSettingsFields()
    const custodianFields = this.getcustodianFields()
    const { formIndex } = this.props

    return (
      <div className="margin-top">
        <FormSection title="TRANSFER IN">
          {this.getTransferInFields().map((f, idx) => {
            return <Field key={`${f.name}-${idx}`} {...f} />
          })}

          <div className="margin-top">
            <FormSection title="CUSTODIAN">
              <Field
                key={`${custodianFields[0].name}-${formIndex}`}
                {...custodianFields[0]}
              />
            </FormSection>
            <div className="margin-top" />
            <FormSection title="HIERARCHY LEVELS">
              {this.getHierarchyFields().map((f, idx) => {
                return <Field key={`${f.name}-${idx}`} {...f} />
              })}
            </FormSection>
          </div>

          <div className="margin-top" />
          <FormSection title="TRANSFER SETTINGS">
            <Card className="padding">
              <div className="row">
                <div className="col">
                  <Field
                    key={`${transferSettingFields[0].name}-${formIndex}`}
                    {...transferSettingFields[0]}
                  />
                </div>
                <div className="col">
                  <Field
                    key={`${transferSettingFields[1].name}-${formIndex}`}
                    {...transferSettingFields[1]}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <DatePicker
                    disabled={false}
                    label="Account Active Date"
                    name="accountActiveDate"
                    required={true}
                    onChange={(name, value) => {
                      this.onChange(name, value)
                    }}
                    placeholder="Account Active Date"
                    value={this.state.form.accountActiveDate}
                  />
                </div>

                <div className="col">
                  <DatePicker
                    disabled={false}
                    label="Custodial Effective Date"
                    name="custodialEffectiveDate"
                    required={true}
                    onChange={(name, value) => {
                      this.onChange(name, value)
                    }}
                    placeholder="Custodial Effective Date"
                    value={this.state.form.custodialEffectiveDate}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <DatePicker
                    disabled={false}
                    label="Blackout Start Date"
                    name="blackoutStartDate"
                    required={true}
                    onChange={(name, value) => {
                      this.onChange(name, value)
                    }}
                    placeholder="Blackout Start Date"
                    value={this.state.form.blackoutStartDate}
                  />
                </div>

                <div className="col">
                  <DatePicker
                    disabled={false}
                    label="Blackout End Date"
                    name="blackoutEndDate"
                    required={true}
                    onChange={(name, value) => {
                      this.onChange(name, value)
                    }}
                    placeholder="Blackout End Date"
                    value={this.state.form.blackoutEndDate}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <DatePicker
                    disabled={false}
                    label="Purse Effective Date"
                    name="purseEffectiveDate"
                    required={true}
                    onChange={(name, value) => {
                      this.onChange(name, value)
                    }}
                    placeholder="Purse Effective Date"
                    value={this.state.form.purseEffectiveDate}
                  />
                </div>
                <div className="col" />
              </div>

              <div className="row">
                <div className="col">
                  <Field
                    key={`${transferSettingFields[2].name}-${formIndex}`}
                    {...transferSettingFields[2]}
                  />
                </div>
              </div>
              {this.renderTransferKindFile()}
            </Card>
          </FormSection>
        </FormSection>
      </div>
    )
  }

  /*
  * Render the transfer in kind file
  */
  renderTransferKindFile() {
    const transferSettingFields = this.getTransferSettingsFields()
    if (this.state.form.investments === "transferInKind")
      return (
        <div>
          <Field key={`${transferSettingFields[3].name}`} {...transferSettingFields[3]} />
        </div>
      )
  }

  /*
  * Render transfer out
  */
  renderTransferOut() {
    return (
      <div>
        <FormSection className="margin-top" title="TRANSFER OUT">
          TBD
        </FormSection>
      </div>
    )
  }

  /**
   * On submit
   */
  handleSubmit(event) {
    event.preventDefault()
    let form = { ...this.state.form }
    this.setState({ submitting: true })
    actions.bulkTransfer
      .createBulkTransfer(form)
      .then(bulkTransfer => {
        this.setState({ form: this.initializeFormData(bulkTransfer), submitting: false })
      })
      .catch(() => this.setState({ submitting: false }))
  }

  /*
  * Update form values OnChange calls
  *
  * @param [String] name. Field name of form to update.
  * @param [Any] value. Value.
  */
  onChange(name, value) {
    let form = { ...this.state.form }
    form[name] = value
    this.setState({ form })
  }

  /*
  * on custodian change, update custodian value
  * then get listing of sponsors and update
  * the sponsor listing
  *
  * @param [String] name. Field name of form to update.
  * @param [Any] value. Value.
  */
  onCustodianChange(name, value) {
    let form = { ...this.state.form }
    this.onChange(name, value)
    form[name] = value
    this.hsaProcessor = value
    this.getSponsors(value)
      .then(() => {
        let sponsors = Object.keys(this.state.sponsorOptions)
        this.onSponsorChange("sponsor", sponsors)
      })
      .catch(() => false)
  }

  /*
  * on sponsor change, update sponsor value
  * then get listing of distributors and update
  * the distributor listing
  *
  * @param [String] name. Field name of form to update.
  * @param [Any] value. Value.
  */
  onSponsorChange(name, value) {
    let form = { ...this.state.form }
    this.onChange(name, value)
    form.distributor = []
    this.getDistributors(value)
      .then(() => {
        let distributors = Object.keys(this.state.distributorOptions)
        this.onDistributorChange("distributor", distributors)
      })
      .catch(() => false)
  }

 /*
  * on distributor change, update distributor value
  * then get listing of employers and update
  * the employer listing
  *
  * @param [String] name. Field name of form to update.
  * @param [Any] value. Value.
  */
  onDistributorChange(name, value) {
    let form = { ...this.state.form }
    this.onChange(name, value)
    form.employer = []
    this.getEmployers(value)
      .then(() => {
        let employers = Object.keys(this.state.employerOptions)
        this.onChange("employer", employers)
      })
      .catch(() => false)
  }

  render() {
    if (this.state.loading)
      return (
        <div className="margin-top">
          <Loading />
        </div>
      )

    return (
      <div className="page bulk-transfer-view">
        <h4>Bulk Transfer Wizard</h4>
        <p>Create a bulk transfer of accounts.</p>
        <form onSubmit={this.handleSubmit.bind(this)}>
          <Card className="padding">
            {this.getTopFields().map((f, idx) => {
              return <Field key={`${f.name}-${idx}`} {...f} />
            })}
          </Card>

          {this.renderTransfers()}

          <Button
            className="primary-button margin-top"
            disabled={this.state.submitting}
            type="submit"
          >
            <span>
              {this.state.submitting ? (
                <Loading containerClassName="inline" className="white small" />
              ) : (
                "Submit"
              )}
            </span>
          </Button>
        </form>
      </div>
    )
  }
}

export default BulkTransfer
